import React from 'react';

import Button from '@lux-ds/button';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Typography from '@lux-ds/typography';
import { useTranslation } from 'react-i18next';

import SimpleDialog from 'components/SimpleDialog/SimpleDialog';

import { StyledDangerousIcon, StyledHerodias } from './VLGMissingPhotoModal.styles';

interface IVLGMissingPhotoModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

const VLGMissingPhotoModal: React.FC<IVLGMissingPhotoModalProps> = ({ onClose, onConfirm }) => {
  const { t } = useTranslation(['common', 'purchaseOrders']);

  return (
    <SimpleDialog onClose={onClose} title={t('purchaseOrders:vlgBusinessModelUpdate')}>
      <LayoutJay sx={{ maxWidth: '434px' }}>
        <StyledHerodias>
          <StyledDangerousIcon />
          <Typography variant="bodyTextSmall">{t('purchaseOrders:missingVlgPhotoErrorMessage')}</Typography>
        </StyledHerodias>
        <Typography variant="bodyTextNormal">{t('purchaseOrders:missingVlgPhotoDescription')}</Typography>
        <LayoutHerodias sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={onClose} variant="text">
            {t('common:cancel')}
          </Button>
          <Button onClick={onConfirm} variant="contained">
            {t('purchaseOrders:goToOrderItems')}
          </Button>
        </LayoutHerodias>
      </LayoutJay>
    </SimpleDialog>
  );
};

export default VLGMissingPhotoModal;
