import {
  AuthenticityPhotoConfigurationGetModel,
  EItemExtraFieldsKey,
  EPhotoType2,
  GetReturnToSupplierReasonsQueryResponse
} from '@inbound/api';
import { AxiosRequestConfig } from 'axios';
import { Subject } from 'rxjs';

import { IContextFilter } from 'models/component/dataTableInterface';
import { EDamageLocation, EInboundFlow, EInclusion, EWarehouseName, IItemDetails } from 'models/warehouse';
import { IOnHoldItemInfo } from 'models/warehouse/onHold';
import WAREHOUSE_CONSTANTS from 'pages/WarehouseList/constants';
import { countryDefaultValue, ICheckinInputs } from 'pages/WarehouseList/schemas/checkInSchema';
import { IQualityCheckFormInputs } from 'pages/WarehouseList/schemas/qualityCheckSchema';
import checkIsRegularFlow from 'pages/WarehouseList/utils/checkIsRegularFlow';
import getDamageIds from 'pages/WarehouseList/utils/getDamageIds';
import { convertToMetric } from 'pages/WarehouseList/utils/measurementConversion';

import axios from '../utils/axios';

const ENABLE_RA_INTEGRATION_MODULE = process.env.REACT_APP_ENABLE_RA_INTEGRATION_MODULE === 'true';
const ENABLE_KARAT_FIELD = process.env.REACT_APP_ENABLE_KARAT_FIELD === 'true';
const ENABLE_RETURN_TO_SUPPLIER = process.env.REACT_APP_ENABLE_RETURN_TO_SUPPLIER === 'true';
const ENABLE_RETURN_TO_SUPPLIER_NEW = process.env.REACT_APP_ENABLE_RETURN_TO_SUPPLIER_NEW === 'true';

class WarehouseService {
  public static $warehouseRowUpdated = new Subject<any>();
  public async getAllProcessingItems(
    page: number,
    pageSize: number,
    status: string,
    sort: string,
    search: string,
    reviewFilter?: string,
    contextFilters?: IContextFilter[]
  ) {
    let newStatus = status;
    let warehouseListFilter = '';

    // TODO: Need to implement different types of filter in the component
    if (newStatus === 'toReview') {
      newStatus = '';
      warehouseListFilter = 'PendingReview';
    } else if (newStatus === 'withIssues') {
      newStatus = '';
      warehouseListFilter = 'WithIssues';
    } else if (newStatus === 'onHold') {
      newStatus = '';
      warehouseListFilter = 'OnHold';
    }

    const otherFilters =
      contextFilters?.reduce(
        (accumulator, contextFilter) => ({
          ...accumulator,
          [contextFilter.type]: contextFilter.value
        }),
        {}
      ) || {};

    const url = `${process.env.REACT_APP_API_URL}operations/processes`;

    return await axios
      .get(url, {
        params: {
          ...otherFilters,
          freeTextFilter: search,
          orderBy: sort,
          pageIndex: page,
          pageSize,
          stationId: newStatus,
          warehouseListFilter
        }
      })
      .then(response => response.data);
  }

  public async getProcessingItem(id: string): Promise<IItemDetails> {
    const url = `${process.env.REACT_APP_API_URL}operations/item-process/${id}/process-details`;

    return await axios.get(url).then(response => response.data);
  }

  public async getByItemId(station: string, itemProcessId: string) {
    const url = `${process.env.REACT_APP_API_URL}operations/item-process/${itemProcessId}/${station}`;

    return await axios.get(url).then(response => response.data);
  }

  public async getQualityControlItem(itemProcessId: string) {
    const url = `${process.env.REACT_APP_API_URL}operations/item-process/${itemProcessId}/quality-control`;

    return await axios.get(url).then(response => response.data);
  }

  public async getOnHoldItem(id: string) {
    const url = `${process.env.REACT_APP_API_URL}operations/item-process/${id}/on-hold`;

    return await axios.get<IOnHoldItemInfo>(url).then(response => response.data);
  }

  public async postOnHoldDecision(id: string, decision: boolean) {
    const url = `${process.env.REACT_APP_API_URL}operations/item-process/${id}/on-hold`;

    return await axios
      .post(url, {
        isAccepted: decision,
        itemProcessId: id
      })
      .then(response => response.data);
  }

  public async getPhotoAuthConfig(itemProcessId: string): Promise<AuthenticityPhotoConfigurationGetModel[]> {
    const url = `${process.env.REACT_APP_API_URL}operations/authenticity-photos-configuration/${itemProcessId}`;

    return await axios.get(url).then(response => response.data);
  }
  // TODO: Set all the files methods to a generical method and files endpont
  public async checkInUploadFile(id: any, file: any) {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/upload-file`;
    const form = new FormData();

    form.append('file', file, file.name);
    form.append('fileId', id);

    const config: AxiosRequestConfig = {
      data: form,
      headers: {
        'Content-Type': undefined
      },
      method: 'post',
      url: url
    };

    return await axios(config).then(response => {
      console.log(JSON.stringify(response.data));
    });
  }

  /**
   * upload-item-photo-gcp
   * @param file Blob file
   * @param itemSku SKU ID of the item
   * @param photoType type of the photo based from WAREHOUSE_CONSTANTS.PHOTO_TYPE
   * @param photoOrder the postfix name to determine which cursor it is for the photo's filename on Google Cloud Photos
   * @returns
   */
  public async uploadUneditedPhotos(file: any, itemSku: string, photoType: number, photoOrder: number) {
    const url = `${process.env.REACT_APP_API_URL}Files/upload-item-photo-gcp`;

    const form = new FormData();

    form.append('file', file);
    form.append('itemSku', itemSku);
    form.append('photoType', `${photoType}`);
    form.append('photoOrder', `${photoOrder}`);

    const config: AxiosRequestConfig = {
      data: form,
      headers: {
        'Content-Type': 'multipart/json'
      },
      method: 'post',
      url: url
    };

    return await axios(config).then(async response => {
      return await response.data;
    });
  }

  public async checkInUploadImages(file: File, itemSku: string, photoOrder: number) {
    const url = `${process.env.REACT_APP_API_URL}Files/upload-item-photo`;
    const form = new FormData();

    form.append('file', file);
    form.append('itemSku', itemSku);
    form.append('photoType', `${WAREHOUSE_CONSTANTS.PHOTO_TYPE.CHECK_IN_AUTHENTICITY}`);
    form.append('photoOrder', `${photoOrder}`);

    const config: AxiosRequestConfig = {
      data: form,
      headers: {
        'Content-Type': 'multipart/json'
      },
      method: 'post',
      url: url
    };

    return await axios(config).then(async response => {
      return await response.data;
    });
  }

  // TODO: Set all the files methods to a generical method and files endpont
  public async authenticityUploadFile(id: any, file: any) {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/upload-file`;
    const form = new FormData();

    form.append('file', file, file.name);
    form.append('fileId', id);

    const config: AxiosRequestConfig = {
      data: form,
      headers: {
        'Content-Type': undefined
      },
      method: 'post',
      url: url
    };

    return await axios(config).then(response => {
      console.log(JSON.stringify(response.data));
    });
  }

  // TODO: Set all the files methods to a generical method and files endpont
  public async supplierUploadFile(id: any, file: any) {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/upload-file`;
    const form = new FormData();

    form.append('file', file, file.name);
    form.append('fileId', id);

    const config: AxiosRequestConfig = {
      data: form,
      headers: {
        'Content-Type': undefined
      },
      method: 'post',
      url: url
    };

    return await axios(config).then(response => {
      console.log(JSON.stringify(response.data));
    });
  }

  public async updateCheckin(
    form: ICheckinInputs,
    itemProcessId: string,
    itemId: string,
    isSaveAndGoNextStation: boolean,
    stationStartDate?: Date
  ) {
    // photos
    const photos: string[] = ENABLE_RA_INTEGRATION_MODULE
      ? form.photos.map(photo => photo.imgPath)
      : form.uploadedPhotos.map(({ url }) => url);
    const photosFrames = [...form.uploadedPhotos, ...form.photoAuthFrames, ...form.photoAdditionalFrames]
      .filter(({ url }) => url)
      .map(photo => ({
        fileId: photo.fileId.includes('new_') ? null : photo.fileId,
        photoFrameId: photo.photoFrameId,
        photoUrl: photo.url
      }));

    // inclusions
    const inclusions = [
      { id: EInclusion.None, value: form.none },
      { id: EInclusion.DustBag, value: form.dustBag },
      { id: EInclusion.Box, value: form.box },
      { id: EInclusion.AuthenticityCard, value: form.authenticityCard },
      { id: EInclusion.Padlock, value: form.padlock },
      { id: EInclusion.Key, value: form.key },
      { id: EInclusion.Pouch, value: form.pouch },
      { id: EInclusion.NameTag, value: form.nameTag },
      { id: EInclusion.Charm, value: form.charm },
      { id: EInclusion.Mirror, value: form.mirror },
      { id: EInclusion.ShoulderStrap, value: form.shoulderStrap }
    ];

    const { inboundFlow, initialInclusions } = form;

    const selectedInclusions = checkIsRegularFlow(inboundFlow, [EInboundFlow.VLG])
      ? inclusions.reduce(
          (result: { id: EInclusion; isMissing: boolean }[], { id, value }) =>
            value ? [...result, { id, isMissing: false }] : result,
          []
        )
      : initialInclusions.map(initialInclusion => {
          const newInclusion = inclusions.find(inclusionForm => inclusionForm.id === initialInclusion.id);

          return {
            id: initialInclusion.id,
            isMissing: !newInclusion?.value ? true : false
          };
        });

    const extraFieldsKeyValue = form.measures
      .filter(e => e.value)
      .map(({ key, value }) => {
        let formattedValue = value;

        if (
          key === EItemExtraFieldsKey.Width ||
          key === EItemExtraFieldsKey.Length ||
          key === EItemExtraFieldsKey.Depth ||
          key === EItemExtraFieldsKey.ShoulderDrop ||
          key === EItemExtraFieldsKey.HandleDrop ||
          key === EItemExtraFieldsKey.Height
        ) {
          formattedValue = convertToMetric(value);
        }

        return { key, value: formattedValue.toFixed(2) };
      });

    const body = {
      Photos: photos,
      ...(ENABLE_KARAT_FIELD && { carat: form.carat.toFixed(2) }),
      extraFields: extraFieldsKeyValue,
      finalStationDateTimeUtc: new Date(),
      hasIncident: false,
      hasSecurityTag: typeof form.hasSecurityTag === 'boolean' ? form.hasSecurityTag : null,
      inclusions: selectedInclusions,
      // TODO: delete "initialStationDateTime" once BE has been updated
      initialStationDateTime: stationStartDate,
      initialStationDateTimeUtc: stationStartDate,
      isSaveAndGoNextStation: isSaveAndGoNextStation,
      itemId,
      itemProcessId,
      madeInCountryId: !form.country || form.country === countryDefaultValue ? null : form.country,
      ...(ENABLE_RA_INTEGRATION_MODULE && { photosFrames }),
      serialNumber: form.hasSerialNumber ? form.serialNumber : null
    };

    const url = `${process.env.REACT_APP_API_URL}operations/item-process/${itemProcessId}/check-in`;

    // Catch was removed while we don't have presonalyzed api response. The idea in the future is have a parameter like HasError
    // to check the success of the request.
    // In case of fail ".data" wil not exist, so it will fail the request, and we can manage the snackbar messages
    return await axios.put(url, body).then(response => response.data);
  }

  public async updateAuthenticity(form: any, itemId: string, isSaveAndGoNextStation: boolean, stationStartDate?: Date) {
    const files: any[] = [];

    form.files.files.forEach((value: any, index: number) => {
      const fileName = value.file ? value.file.name : value.name;
      const fileParts = fileName.split('.');

      const [fileType] = fileParts.slice(-1);

      files[index] = {
        fileId: value.fileId,
        fileSize: value.file ? value.file.size : value.size,
        name: fileName,
        originalName: value.file ? value.file.name : value.originalName,
        type: fileType
      };
    });

    const body = {
      comments: form.comments,
      filesToDelete: form.files.filesToDelete,
      filesToUpsert: files,
      finalStationDateTimeUtc: new Date(),
      hasIncident: false,
      // TODO: delete "initialStationDateTime" once BE has been updated
      initialStationDateTime: stationStartDate,
      initialStationDateTimeUtc: stationStartDate,
      isAuthentic: form.authenticityCheck === 'Authentic Item' ? true : false,
      isSaveAndGoNextStation: isSaveAndGoNextStation,
      itemId: itemId
    };

    const url = `${process.env.REACT_APP_API_URL}Warehouse/authenticity/`;

    // Catch was removed while we don't have presonalyzed api response. The idea in the future is have a parameter like HasError
    // to check the success of the request.
    // In case of fail ".data" wil not exist, so it will fail the request, and we can manage the snackbar messages
    return await axios.put(url, body).then(response => response.data);
  }

  public async getSupplierReturnReasons(): Promise<GetReturnToSupplierReasonsQueryResponse[]> {
    const url = `${process.env.REACT_APP_API_URL}operations/return-to-supplier-reasons`;

    return await axios.get(url).then(response => response.data);
  }

  public async computeQCDecision(form: IQualityCheckFormInputs): Promise<boolean> {
    const damagesArray = [
      // Exterior
      {
        damages: getDamageIds(form.front),
        locationId: EDamageLocation.Front
      },
      {
        damages: getDamageIds(form.back),
        locationId: EDamageLocation.Back
      },
      {
        damages: getDamageIds(form.bottom),
        locationId: EDamageLocation.Bottom
      },
      {
        damages: getDamageIds(form.handleOrStrap),
        locationId: EDamageLocation.HandleOrStrap
      },
      {
        damages: getDamageIds(form.pocket),
        locationId: EDamageLocation.Pocket
      },
      {
        damages: getDamageIds(form.corners),
        locationId: EDamageLocation.Corners
      },
      {
        damages: getDamageIds(form.side),
        locationId: EDamageLocation.Side
      },
      {
        damages: getDamageIds(form.top),
        locationId: EDamageLocation.Top
      },
      // Interior
      {
        damages: getDamageIds(form.lining),
        locationId: EDamageLocation.Lining
      },
      {
        damages: getDamageIds(form.interiorPocket),
        locationId: EDamageLocation.InteriorPocket
      },
      // Generic
      {
        damages: getDamageIds(form.smell),
        locationId: EDamageLocation.Smell
      },
      // Hardware
      {
        damages: getDamageIds(form.closure),
        locationId: EDamageLocation.Closure
      },
      {
        damages: getDamageIds(form.embellishment),
        locationId: EDamageLocation.Embellishment
      },
      {
        damages: getDamageIds(form.key),
        locationId: EDamageLocation.Key
      },
      {
        damages: getDamageIds(form.practicalAttachment),
        locationId: EDamageLocation.PracticalAttachment
      },
      {
        damages: getDamageIds(form.zipper),
        locationId: EDamageLocation.Zipper
      }
    ].filter(({ damages }) => damages.length);

    const body = {
      damages: damagesArray,
      inboundFlowId: form.inboundFlow?.id || '',
      itemProcessId: form.itemProcessId
    };

    const url = `${process.env.REACT_APP_API_URL}operations/item-process/${form.itemProcessId}/quality-control-decision`;

    return await axios.post(url, body).then(response => response.data);
  }

  public async updateQualityControl(
    form: IQualityCheckFormInputs,
    itemProcessId: string,
    itemId: string,
    isSaveAndGoNextStation: boolean,
    itemWarehouse?: EWarehouseName,
    stationStartDate?: Date
  ) {
    const damagesArray = [
      // Exterior
      {
        damages: getDamageIds(form.front),
        locationId: EDamageLocation.Front
      },
      {
        damages: getDamageIds(form.back),
        locationId: EDamageLocation.Back
      },
      {
        damages: getDamageIds(form.bottom),
        locationId: EDamageLocation.Bottom
      },
      {
        damages: getDamageIds(form.handleOrStrap),
        locationId: EDamageLocation.HandleOrStrap
      },
      {
        damages: getDamageIds(form.pocket),
        locationId: EDamageLocation.Pocket
      },
      {
        damages: getDamageIds(form.corners),
        locationId: EDamageLocation.Corners
      },
      {
        damages: getDamageIds(form.side),
        locationId: EDamageLocation.Side
      },
      {
        damages: getDamageIds(form.top),
        locationId: EDamageLocation.Top
      },
      // Interior
      {
        damages: getDamageIds(form.lining),
        locationId: EDamageLocation.Lining
      },
      {
        damages: getDamageIds(form.interiorPocket),
        locationId: EDamageLocation.InteriorPocket
      },
      // Generic
      {
        damages: getDamageIds(form.smell),
        locationId: EDamageLocation.Smell
      },
      // Hardware
      {
        damages: getDamageIds(form.closure),
        locationId: EDamageLocation.Closure
      },
      {
        damages: getDamageIds(form.embellishment),
        locationId: EDamageLocation.Embellishment
      },
      {
        damages: getDamageIds(form.key),
        locationId: EDamageLocation.Key
      },
      {
        damages: getDamageIds(form.practicalAttachment),
        locationId: EDamageLocation.PracticalAttachment
      },
      {
        damages: getDamageIds(form.zipper),
        locationId: EDamageLocation.Zipper
      }
    ].filter(({ damages }) => damages.length);

    const body = {
      ...(itemWarehouse === EWarehouseName.Japan &&
        (ENABLE_RETURN_TO_SUPPLIER_NEW
          ? {
              wasAcceptedBySupplier: form.supplierReturnReason !== undefined ? !!form.supplierReturnReason : undefined
            }
          : {
              isToReturnToSupplier: form.isReturnToSupplier,
              returnToSupplierReasons: form.isReturnToSupplier ? form.returnToSupplierReasons : []
            })),
      finalStationDateTimeUtc: new Date(),
      hasIncident: false,
      hasPassedQualityCheck: form.hasPassedQualityCheck,
      // TODO: delete "initialStationDateTime" once BE has been updated
      initialStationDateTime: stationStartDate,
      initialStationDateTimeUtc: stationStartDate,

      isSaveAndGoNextStation: isSaveAndGoNextStation,
      itemDamages: damagesArray,
      itemId,
      itemProcessId
    };

    const url = ENABLE_RETURN_TO_SUPPLIER
      ? `${process.env.REACT_APP_API_URL}operations/item-process/${itemProcessId}/quality-control`
      : `${process.env.REACT_APP_API_URL}Warehouse/${itemProcessId}/quality-control`;

    // Catch was removed while we don't have presonalyzed api response. The idea in the future is have a parameter like HasError
    // to check the success of the request.
    // In case of fail ".data" wil not exist, so it will fail the request, and we can manage the snackbar messages
    return await axios.put(url, body).then(response => response.data);
  }

  public async updatePhotography(form: any, itemId: string, isSaveAndGoNextStation: boolean, stationStartDate?: Date) {
    // photos
    const photos: any[] = [];

    form.photos.files.forEach((value: any, index: number) => {
      photos[index] = value.url;
    });

    const photosToDelete: any[] = [];

    form.photos.filesToDelete.forEach((value: any, index: number) => {
      photosToDelete[index] = value.url;
    });

    const body = {
      PhotosToDelete: photosToDelete,
      comments: form.comments,
      finalStationDateTimeUtc: new Date(),
      hasIncident: false,
      // TODO: delete "initialStationDateTime" once BE has been updated
      initialStationDateTime: stationStartDate,
      initialStationDateTimeUtc: stationStartDate,
      isSaveAndGoNextStation: isSaveAndGoNextStation,
      itemId: itemId,
      photos: photos
    };

    const url = `${process.env.REACT_APP_API_URL}Warehouse/photography`;

    // Catch was removed while we don't have presonalyzed api response. The idea in the future is have a parameter like HasError
    // to check the success of the request.
    // In case of fail ".data" wil not exist, so it will fail the request, and we can manage the snackbar messages
    return await axios.put(url, body).then(response => response.data);
  }

  public async updateHallmarkAndDamages(
    form: any,
    itemId: string,
    isSaveAndGoNextStation: boolean,
    stationStartDate?: Date
  ) {
    // photos
    const photos: any[] = [];

    form.photos.files.forEach((value: any, index: number) => {
      photos[index] = value.url;
    });

    const photosToDelete: any[] = [];

    form.photos.filesToDelete.forEach((value: any, index: number) => {
      photosToDelete[index] = value.url;
    });

    const body = {
      PhotosToDelete: photosToDelete,
      comments: form.comments,
      finalStationDateTimeUtc: new Date(),
      hasIncident: false,
      // TODO: delete "initialStationDateTime" once BE has been updated
      initialStationDateTime: stationStartDate,
      initialStationDateTimeUtc: stationStartDate,
      isSaveAndGoNextStation: isSaveAndGoNextStation,
      itemId: itemId,
      photos: photos
    };

    const url = `${process.env.REACT_APP_API_URL}Warehouse/hallmark-damages`;

    return await axios.put(url, body).then(response => response.data);
  }

  public async updateStorage(
    form: any,
    itemProcessId: string,
    itemId: string,
    isSaveAndGoNextStation: boolean,
    stationStartDate?: Date
  ) {
    const body = {
      comments: '',
      finalStationDateTimeUtc: new Date(),
      hasIncident: false,
      // TODO: delete "initialStationDateTime" once BE has been updated
      initialStationDateTime: stationStartDate,
      initialStationDateTimeUtc: stationStartDate,
      isSaveAndGoNextStation: isSaveAndGoNextStation,
      itemId,
      itemProcessId,
      storedAt: form.storedAt
    };

    const url = `${process.env.REACT_APP_API_URL}operations/item-process/${itemProcessId}/storage`;

    // Catch was removed while we don't have presonalyzed api response. The idea in the future is have a parameter like HasError
    // to check the success of the request.
    // In case of fail ".data" wil not exist, so it will fail the request, and we can manage the snackbar messages
    return await axios.put(url, body).then(response => response.data);
  }

  public async uploadFile(fileId: string, file: File): Promise<string> {
    const url = `${process.env.REACT_APP_API_URL}Files/upload-temp`;
    const formData = new FormData();

    formData.append('File', file);
    formData.append('FileId', fileId);

    const config = {
      headers: {
        'Content-Data': 'multipart/form-data'
      }
    };

    return await axios.post(url, formData, config).then(response => response.data);
  }

  public async uploadStationPhoto(itemProcessId: string, photoType: EPhotoType2, file: File): Promise<string> {
    const url = `${process.env.REACT_APP_API_URL}Files/upload-operations-photo`;
    const formData = new FormData();

    formData.append('File', file);
    formData.append('PhotoType', photoType.toLocaleString());
    formData.append('ItemProcessId', itemProcessId);

    const config = {
      headers: {
        'Content-Data': 'multipart/form-data'
      }
    };

    return await axios.post(url, formData, config).then(response => response.data);
  }

  public async getRatingList() {
    const url = `${process.env.REACT_APP_API_URL}operations/rating-list`;

    return await axios.get(url).then(response => response.data);
  }

  public async getTagList() {
    const url = `${process.env.REACT_APP_API_URL}operations/tag-list`;

    return await axios.get(url).then(response => response.data);
  }

  public async resubmitItem(itemProcessId: string, itemId: string) {
    const url = `${process.env.REACT_APP_API_URL}Warehouse/resubmit-item?itemProcessId=${itemProcessId}&itemId=${itemId}`;

    return await axios
      .put(url, { params: { itemId, itemProcessId } })
      .then(response => response.data)
      .catch(err => console.log(err));
  }
}

const warehouseService = new WarehouseService();

export default warehouseService;
