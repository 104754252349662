enum EInboundRoute {
  Authentication = '/authentication',
  Error = '/error',
  ItemIssues = '/item-issues',
  Login = '/login',
  Logout = '/logout',
  Products = '/products',
  PurchaseOrder = '/purchase-order',
  PurchaseOrders = '/purchase-orders',
  Root = '/',
  Signin = '/signin-callback',
  SilentRenew = '/silent-renew',
  Supplier = '/supplier',
  Suppliers = '/suppliers',
  Warehouse = '/warehouse'
}

export default EInboundRoute;
