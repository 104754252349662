import React, { useState } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ForAuthentication from './sections/ForAuthentication/ForAuthentication';
import ImageModal from './sections/ImageModal/ImageModal';
import PhotoUpload from './sections/PhotoUpload/PhotoUpload';

import photoAuthenticationStyles from './photoAuthentication.styles';

const ENABLE_RA_INTEGRATION_MODULE = process.env.REACT_APP_ENABLE_RA_INTEGRATION_MODULE === 'true';

const PhotoAuthentication: React.FC = () => {
  const classes = photoAuthenticationStyles();

  const [zoomPhoto, setZoomPhoto] = useState<string>('');

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.container}>
        <PhotoUpload setZoomPhoto={setZoomPhoto} />
        {ENABLE_RA_INTEGRATION_MODULE && <ForAuthentication setZoomPhoto={setZoomPhoto} />}
        {zoomPhoto && <ImageModal image={zoomPhoto} onClose={() => setZoomPhoto('')} />}
      </div>
    </DndProvider>
  );
};

export default PhotoAuthentication;
