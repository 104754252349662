import React, { useState } from 'react';

import {
  Box,
  Button,
  Grid,
  IconButton,
  MaterialIcons,
  MenuItem,
  Modal,
  Paper,
  TableContainer
} from '@luxclusif/material';
import { useFieldArray, UseFormMethods } from 'react-hook-form';

import Input from 'components/Input/Input/Input';
import SelectInput from 'components/Input/Select/Select';
import { ITargetUnits } from 'models/supplier';

import supplierDetailsStyles from '../../supplierDetails.styles';

const defaultValues = {
  endDate: '',
  goal: '',
  poReference: '',
  startDate: '',
  targetSettingsid: '',
  testItems: '',
  unit: ''
};

interface IProps {
  businessModelForm: UseFormMethods<Record<string, any>>;
  targetUnits: ITargetUnits[];
}

const TargetSettings: React.FC<IProps> = ({ businessModelForm, targetUnits }) => {
  const classes = supplierDetailsStyles();

  const [toDelete, setToDelete] = useState(0);
  const [toDeleteName, setToDeleteName] = useState('');
  const [deleteModal, setDeleteModalOpen] = useState(false);

  const { control, errors, setValue, watch } = businessModelForm;

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'targetSettings'
  });
  const targetSettings = watch('targetSettings');

  const addTargetSettings = () => {
    append(defaultValues);
  };

  const openDeleteModal = (index: number, name: string) => {
    setToDelete(index);
    setToDeleteName(name);
    setDeleteModalOpen(true);
  };

  const deleteTargetSettings = () => {
    remove(toDelete);
    setDeleteModalOpen(false);
  };

  return (
    <div>
      <h1 className={classes.title}>Target settings of test period</h1>
      {fields.map((value: any, index: number) => {
        const name = `start-date: ${targetSettings[index].startDate}, end-date: ${targetSettings[index].endDate}, goal: ${targetSettings[index].goal}`;

        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="background.paper"
            className={classes.boxTargetSettingsInfo}
            key={index}
          >
            <Box m="auto">
              <Input
                name={`targetSettings[${index}].startDate`}
                control={control}
                errors={errors}
                type="datetime-local"
                className={classes.tableInputsTargetSettings}
                defaultValue={`${value.startDate}`}
                label="Start Date*"
                inputProps={{
                  max: targetSettings[index].endDate
                }}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Box>

            <Box m="auto">
              <Input
                name={`targetSettings[${index}].endDate`}
                control={control}
                errors={errors}
                type="datetime-local"
                className={classes.tableInputsTargetSettings}
                defaultValue={`${value.endDate}`}
                label="End Date*"
                inputProps={{
                  min: targetSettings[index].startDate
                }}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Box>

            <Box m="auto">
              <Input
                name={`targetSettings[${index}].goal`}
                control={control}
                errors={errors}
                className={classes.tableInputsTargetSettings}
                type="number"
                defaultValue={`${value.goal}`}
                label="Goal*"
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Box>
            <Box m="auto">
              <Input
                name={`targetSettings[${index}].testItems`}
                control={control}
                errors={errors}
                type="number"
                className={classes.tableInputsTargetSettings}
                label="Number of Test Items*"
                variant="outlined"
                fullWidth={true}
                errorText="Required"
                defaultValue={`${value.testItems}`}
              />
            </Box>
            <Box m="auto">
              <Input
                name={`targetSettings[${index}].poReference`}
                control={control}
                errors={errors}
                type="text"
                defaultValue={`${value.poReference}`}
                className={classes.tableInputsTargetSettings}
                label="PO Reference*"
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Box>
            <Box m="auto">
              <SelectInput
                label="Unit*"
                className={classes.tableInputsTargetSettings}
                fullWidth={true}
                defaultValue={`${value.unit}`}
                name={`targetSettings[${index}].unit`}
                variant="outlined"
                control={control}
                errors={errors}
                setValue={setValue}
              >
                <MenuItem aria-label="None" value="" key={''} />
                {targetUnits.map((row: ITargetUnits) => (
                  <MenuItem key={row.targetUnitId} value={row.targetUnitId}>
                    {row.name}
                  </MenuItem>
                ))}
              </SelectInput>
            </Box>

            <Box m="auto">
              <IconButton
                className={classes.tableInputsCommand}
                edge="end"
                onClick={() => openDeleteModal(index, name)}
              >
                <MaterialIcons.Delete className={classes.tableInputsLogo} />
              </IconButton>
            </Box>
          </Box>
        );
      })}

      <div className={classes.addTargetSettingsContainer} onClick={() => addTargetSettings()}>
        <div className={classes.tableCell}>
          <p className={classes.addTargetSettingsText}>Add New Target Setting</p>
        </div>
      </div>
      <hr className={classes.line} />

      <Modal open={deleteModal} onClose={() => setDeleteModalOpen(false)}>
        <div className={classes.modalUpperContainer}>
          <TableContainer className={classes.tableContainerDelete} component={Paper}>
            <div>
              <h3 className={classes.thisActionWillRem}>Delete target settings details</h3>
              <p>
                This action will remove the details information about [{toDeleteName}]. Do you really want to delete
                these records?
              </p>
            </div>
            <Grid className={classes.grid} container direction="row" justifyContent="flex-end" alignItems="center">
              <Button onClick={() => setDeleteModalOpen(false)} className={classes.button}>
                Never Mind
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => deleteTargetSettings()}
              >
                Delete
              </Button>
            </Grid>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
};

export default TargetSettings;
