import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const carouselStyles = makeStyles(() => {
  return createStyles({
    root: {
      maxWidth: '100%',
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      padding: '32px 0'
    },
    imgCarousel: {
      display: 'flex',
      alignItems: 'center'
    },
    img: {
      display: 'block',
      overflow: 'hidden',
      width: '328px',
      height: '328px',
      objectFit: 'contain'
    },
    imgHidden: {
      height: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      width: '100%',
      opacity: 0,
      display: 'none'
    },
    mobileStepper: {
      marginTop: '24px',
      padding: 0,
      backgroundColor: 'white',
      textAlign: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      '& .MuiMobileStepper-dotActive': {
        backgroundColor: '#86867E'
      }
    },
    buttons: {
      backgroundColor: secondaryNeutral.light40,
      borderRadius: '50%',
      width: '32px',
      height: '32px'
    },
    icons: {
      fontSize: '18px',
      color: 'white'
    }
  });
});

export default carouselStyles;
