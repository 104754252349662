import React, { useCallback, useEffect, useMemo } from 'react';

import { EAuthenticationResultStatus } from '@inbound/api';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Input from '@lux-ds/input';
import Radio from '@lux-ds/radio';
import Typography from '@lux-ds/typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { IItemAuthenticationSchema } from 'pages/WarehouseList/schemas/authenticationSchema';

import { concernKeys, exteriorLocationKeys, interiorLocationKeys } from '../constants';

import { StyledRadioGroup } from './authenticationResult.styles';

const AuthenticationResult: React.FC = () => {
  const { t } = useTranslation('warehouse');
  const { isViewMode } = useWarehouseItemContext();
  const { warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IItemAuthenticationSchema>, [warehouseForm]);

  const {
    formState: { errors }
  } = form;

  const partnerResults = form.watch('partnerResults', []);

  const isCommentsRequired = useMemo(
    (): boolean =>
      [EAuthenticationResultStatus.Fake, EAuthenticationResultStatus.Inconclusive].includes(
        form.getValues('authResult')
      ),
    [form, form.watch('authResult')]
  );

  const getAuthResultIcon = useCallback(
    (status: EAuthenticationResultStatus) =>
      status === EAuthenticationResultStatus.Authentic ? (
        <CheckCircleIcon sx={{ color: 'success.main' }} />
      ) : (
        <CancelIcon sx={{ color: 'error.dark' }} />
      ),
    []
  );

  useEffect(() => {
    if (form.getValues('authResult') === EAuthenticationResultStatus.Authentic) {
      form.setValue('hasMatrixConcern', undefined, { shouldDirty: true, shouldValidate: true });
      form.setValue('supplierReturnReason', undefined, { shouldDirty: true, shouldValidate: true });
      form.setValue('uploadedPhotos', [], { shouldDirty: true, shouldValidate: true });

      [...exteriorLocationKeys, ...interiorLocationKeys].forEach(key =>
        concernKeys.forEach(concernKey =>
          form.setValue(`${key}.${concernKey}`, false, { shouldDirty: true, shouldValidate: true })
        )
      );
    } else if (form.getValues('authResult') === EAuthenticationResultStatus.Inconclusive) {
      form.setValue('supplierReturnReason', undefined, { shouldDirty: true, shouldValidate: true });
    }
  }, [form.watch('authResult')]);

  const handleChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: string) =>
      form.setValue('authResult', parseInt(value), { shouldDirty: true, shouldValidate: true }),
    [form]
  );

  return (
    <LayoutJay gap="m">
      <Typography color={errors.authResult && 'error.main'} variant="h6">
        {t('authenticationStation.authenticationResult')}
      </Typography>

      <LayoutHerodias gap="5xl">
        <Controller
          control={form.control}
          name="authResult"
          render={({ ref, ...props }) => (
            <StyledRadioGroup {...props} onChange={handleChange}>
              <Radio
                disabled={isViewMode}
                inputRef={ref}
                label={t('authenticationStation.authPass')}
                value={EAuthenticationResultStatus.Authentic}
              />
              <Radio
                disabled={isViewMode}
                label={t('authenticationStation.authFail')}
                value={EAuthenticationResultStatus.Fake}
              />
              <Radio
                disabled={isViewMode}
                label={t('authenticationStation.inconclusive')}
                value={EAuthenticationResultStatus.Inconclusive}
              />
            </StyledRadioGroup>
          )}
        />
        <Controller
          control={form.control}
          name="comments"
          render={({ ref, ...props }) => (
            <Input
              {...props}
              disabled={isViewMode}
              error={!!errors.comments}
              inputRef={ref}
              label={`${t('comments')}${isCommentsRequired ? '*' : ''}`}
              multiline
              rows={4}
              sx={{ width: '330px' }}
            />
          )}
        />
      </LayoutHerodias>
      {form.watch('authResult') === EAuthenticationResultStatus.Inconclusive && partnerResults?.length ? (
        <LayoutJay>
          <Typography color={errors.authResult && 'error.main'} variant="h6">
            {t('authenticationStation.partnerAuthentication')}
          </Typography>
          <LayoutJay>
            {partnerResults?.map((result, index) => (
              <LayoutHerodias gap="xs" key={index}>
                {getAuthResultIcon(result.statusId as EAuthenticationResultStatus)}
                <Typography variant="bodyTextNormal">
                  {result.statusId === EAuthenticationResultStatus.Authentic
                    ? t('authenticationStation.authPass')
                    : t('authenticationStation.authFail')}
                </Typography>
              </LayoutHerodias>
            ))}
          </LayoutJay>
        </LayoutJay>
      ) : null}
    </LayoutJay>
  );
};

export default AuthenticationResult;
