import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  CountryGetListModel,
  EAuthenticationResultStatus,
  EPhotoType,
  EStation,
  GetBarCodeQueryResponseModel,
  IApiException,
  PartnerAuthenticationResult,
  StationGetModel
} from '@inbound/api';
import { Alert, Snackbar } from '@luxclusif/material';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { FieldErrors, UseFormMethods, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ExitModal from 'components/ExitModal/ExitModal';
import useApi from 'hooks/useApi';
import {
  EInclusion,
  EWarehouseName,
  IAuthUploadedPhoto,
  IInclusion,
  IUploadedPhoto,
  LOCAL_STORAGE_USER_WAREHOUSE
} from 'models/warehouse';
import EInboundRoute from 'navigation/models/EInboundRoute';
import { INCLUSIONS_DATA, defaultWarehouseStations } from 'pages/WarehouseList/constants';
import {
  IItemAuthenticationSchema,
  authenticationSchema,
  formatItemAuthenticationValues,
  getDefaultItemAuthenticationValues
} from 'pages/WarehouseList/schemas/authenticationSchema';
import { ICheckinInputs, checkInSchema } from 'pages/WarehouseList/schemas/checkInSchema';
import {
  IItemDetails,
  IItemNewCheckInDetailsSchema,
  formatNewCheckInValuesRequest,
  getDefaultItemNewCheckInValues,
  newCheckInSchema
} from 'pages/WarehouseList/schemas/newCheckInSchema';
import {
  IItemPhotoAuthenticationDetailsSchema,
  formatItemPhotoAuthenticationValues,
  getDefaultItemPhotoAuthenticationValues,
  photoAuthenticationSchema
} from 'pages/WarehouseList/schemas/photoAuthenticationSchema';
import { IQualityCheckFormInputs, qualityCheckSchema } from 'pages/WarehouseList/schemas/qualityCheckSchema';
import {
  IItemStorageDetailsSchema,
  getDefaultItemStorageValues,
  storageSchema
} from 'pages/WarehouseList/schemas/storageSchema';
import checkIsRegularFlow from 'pages/WarehouseList/utils/checkIsRegularFlow';
import warehouseService from 'services/Warehouse.service';
import chunk from 'utils/chunk';
import uuidGenerator from 'utils/uuidGenerator';

export enum EExitType {
  onExit = 'onExit',
  onSwitchStation = 'onSwitchStation'
}

interface IExitModalState {
  isOpen: boolean;
  type: EExitType | null;
}

interface ISnackbarState {
  isError?: boolean;
  isOpen: boolean;
  message?: string;
}

interface IWarehouseItemDetailsContext {
  activeStation: EStation;
  barcodeDetails: GetBarCodeQueryResponseModel;
  checkInTab: number;
  countries: CountryGetListModel[];
  currentItemStation: EStation;
  getBarcodeDetails: () => void;
  getCountries: () => void;
  handleCloseExitModal: () => void;
  handleNextStation: (stationId: EStation, key: 'order' | 'stationId') => void;
  hasUserWarehouse?: boolean;
  inclusions: IInclusion[];
  isBarcodeDetailsLoading: boolean;
  isBulkDownloading: boolean;
  isDoneAutomatically?: boolean;
  isFetching: boolean;
  isFormDirty: boolean;
  isFormValid: boolean;
  isInconclusiveAuthFail: boolean;
  isRegularFlow: boolean;
  isSaving: boolean;
  isStationLoading: boolean;
  isUploadingImages: boolean;
  itemDetails: IItemDetails;
  onBulkDownloadPhotos: (urls: string[]) => void;
  onChangeCheckInTab: (value: number) => void;
  onChangeStep: (stationId: EStation) => void;
  onCloseDetailFormPrompt: () => void;
  onDeleteImage: (id: string) => void;
  onErrorSave: (errors: FieldErrors<Record<string, any>>) => void;
  onSaveChanges: (isSaveAndGoNextStation: boolean, isFinishProcess?: boolean, onSuccess?: () => void) => void;
  onToggleFinishModal: (open: boolean) => void;
  onUploadImages: (files: File[], photoType: EPhotoType) => void;
  showFinishModal: boolean;
  warehouseForm: UseFormMethods<
    | ICheckinInputs
    | IItemAuthenticationSchema
    | IItemNewCheckInDetailsSchema
    | IItemPhotoAuthenticationDetailsSchema
    | IQualityCheckFormInputs
    | IItemStorageDetailsSchema
  >;
  warehouseStations: StationGetModel[];
}

const WarehouseItemDetailsContext = createContext<IWarehouseItemDetailsContext>({
  activeStation: EStation.CheckinV2,
  barcodeDetails: {},
  checkInTab: 0,
  countries: [] as IWarehouseItemDetailsContext['countries'],
  currentItemStation: EStation.CheckinV2,
  getBarcodeDetails: () => void 0,
  getCountries: () => void 0,
  handleCloseExitModal: () => void 0,
  handleNextStation: () => void 0,
  hasUserWarehouse: false,
  inclusions: [] as IWarehouseItemDetailsContext['inclusions'],
  isBarcodeDetailsLoading: false,
  isBulkDownloading: false,
  isDoneAutomatically: false,
  isFetching: false,
  isFormDirty: false,
  isFormValid: false,
  isInconclusiveAuthFail: false,
  isRegularFlow: false,
  isSaving: false,
  isStationLoading: false,
  isUploadingImages: false,
  itemDetails: {} as IWarehouseItemDetailsContext['itemDetails'],
  onBulkDownloadPhotos: () => void 0,
  onChangeCheckInTab: () => void 0,
  onChangeStep: () => void 0,
  onCloseDetailFormPrompt: () => void 0,
  onDeleteImage: () => void 0,
  onErrorSave: () => void 0,
  onSaveChanges: () => void 0,
  onToggleFinishModal: () => void 0,
  onUploadImages: () => void 0,
  showFinishModal: false,
  warehouseForm: {} as IWarehouseItemDetailsContext['warehouseForm'],
  warehouseStations: [] as IWarehouseItemDetailsContext['warehouseStations']
});

const ENABLE_RETURN_TO_SUPPLIER_NEW = process.env.REACT_APP_ENABLE_RETURN_TO_SUPPLIER_NEW === 'true';

const WarehouseItemDetailsProvider: React.FC = ({ children }) => {
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'warehouse']);
  const { itemId: itemProcessId = '' } = useParams<{ itemId: string }>();

  const [barcodeDetails, setBarcodeDetails] = useState<IWarehouseItemDetailsContext['barcodeDetails']>({});
  const [isBarcodeDetailsLoading, setIsBarcodeDetailsLoading] =
    useState<IWarehouseItemDetailsContext['isBarcodeDetailsLoading']>(false);

  const [activeStation, setActiveStation] = useState<IWarehouseItemDetailsContext['activeStation']>(EStation.CheckinV2);
  const [currentItemStation, setCurrentItemStation] = useState<IWarehouseItemDetailsContext['currentItemStation']>(
    EStation.CheckinV2
  );

  const [hasUserWarehouse, setHasUserWarehouse] = useState<IWarehouseItemDetailsContext['hasUserWarehouse']>(false);
  const [isBulkDownloading, setIsBulkDownloading] = useState<IWarehouseItemDetailsContext['isBulkDownloading']>(false);
  const [isDoneAutomatically, setIsDoneAutomatically] =
    useState<IWarehouseItemDetailsContext['isDoneAutomatically']>(false);
  const [isFetching, setIsFetching] = useState<IWarehouseItemDetailsContext['isFetching']>(false);
  const [isSaving, setIsSaving] = useState<IWarehouseItemDetailsContext['isSaving']>(false);
  const [isStationLoading, setIsStationLoading] = useState<IWarehouseItemDetailsContext['isStationLoading']>(true);
  const [isSuccessfullySaved, setIsSuccessfullySaved] = useState<boolean>(false);
  const [isUploadingImages, setIsUploadingImages] = useState<IWarehouseItemDetailsContext['isUploadingImages']>(false);
  const [itemDetails, setItemDetails] = useState<IWarehouseItemDetailsContext['itemDetails']>({});

  const [countries, setCountries] = useState<IWarehouseItemDetailsContext['countries']>([
    { countryId: 'none', name: t('warehouse:checkinStation.notAvailable') }
  ]);
  const [inclusions, setInclusions] = useState<IWarehouseItemDetailsContext['inclusions']>([]);

  const [onQueueStation, setOnQueueStation] = useState<EStation>();

  const [exitModal, setExitModal] = useState<IExitModalState>({ isOpen: false, type: null });
  const [snackbar, setSnackbar] = useState<ISnackbarState>({ isError: false, isOpen: false, message: '' });

  const [checkInTab, setCheckInTab] = useState<IWarehouseItemDetailsContext['checkInTab']>(0);
  const [stationStartDate, setStationStartDate] = useState<Date>(new Date());

  const [showFinishModal, setShowFinishModal] = useState<IWarehouseItemDetailsContext['showFinishModal']>(false);

  const isRegularFlow = useMemo(() => checkIsRegularFlow(itemDetails.inboundFlow), [itemDetails.inboundFlow]);

  const warehouseStations = useMemo(
    (): StationGetModel[] =>
      itemDetails?.itemConfiguredStations?.length ? itemDetails.itemConfiguredStations : defaultWarehouseStations(t),
    [itemDetails.itemConfiguredStations, t]
  );

  const activeSchema = useMemo(() => {
    switch (activeStation) {
      case EStation.Authentication:
        return authenticationSchema;
      case EStation.Checkin:
        return checkInSchema;
      case EStation.CheckinV2:
        return newCheckInSchema;
      case EStation.PhotoAuthentication:
        return photoAuthenticationSchema;
      case EStation.QualityControl:
        return qualityCheckSchema(itemDetails.warehouse as EWarehouseName);
      case EStation.Storage:
        return storageSchema;
      default:
        return newCheckInSchema;
    }
  }, [activeStation, itemDetails.warehouse]);

  // TODO: Update implementation and consider updating the react-hook-form version.
  const warehouseForm = useForm<
    | ICheckinInputs
    | IItemAuthenticationSchema
    | IItemNewCheckInDetailsSchema
    | IItemPhotoAuthenticationDetailsSchema
    | IQualityCheckFormInputs
    | IItemStorageDetailsSchema
  >({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(activeSchema),
    shouldUnregister: false
  });

  const {
    formState: { isDirty: isFormDirty }
  } = warehouseForm;

  /**
   * Even though we have an isValid from formState. There's an issue that
   * the isValid is still false even though there are no errors
   * */
  const isFormValid = Object.keys(warehouseForm.errors).length === 0;

  useEffect(() => {
    const getStationDetails = async () => {
      try {
        setIsStationLoading(true);

        // TODO: Add other get details for other stations.
        switch (activeStation) {
          case EStation.Authentication: {
            await getAuthenticationDetails();
            break;
          }
          case EStation.CheckinV2: {
            await getCountries();
            await getNewCheckInDetails();
            break;
          }
          case EStation.PhotoAuthentication:
            await getPhotoAuthenticationDetails();
            break;
          case EStation.Storage:
            await getStorageDetails();
            break;
          default:
            break;
        }
      } finally {
        setIsStationLoading(false);
      }
    };

    if (itemDetails.itemProcessId) {
      setStationStartDate(new Date());
      getStationDetails();
    }
  }, [activeStation, itemDetails.itemProcessId]);

  useEffect(() => {
    setIsSuccessfullySaved(false);
  }, [activeStation, currentItemStation]);

  // TODO: Convert and replace this once new design system implemented
  const handleOpenSnackbar = useCallback((details: ISnackbarState) => setSnackbar(details), [setSnackbar]);

  // TODO: Convert and replace this once new design system implemented
  const handleCloseSnackbar = useCallback(
    () => setSnackbar({ isError: false, isOpen: false, message: '' }),
    [setSnackbar]
  );

  const handleCloseExitModal = useCallback(() => setExitModal({ isOpen: false, type: null }), [setExitModal]);
  const handleOpenExitModal = useCallback((type: EExitType) => setExitModal({ isOpen: true, type }), [setExitModal]);

  const getCountries = useCallback(async () => {
    try {
      const countries = await api.geo_GetAllCountries();

      setCountries(prevState => [...prevState, ...countries]);
    } catch {
      setCountries([]);
    }
  }, [api, setCountries]);

  const getInclusions = useCallback(async () => {
    try {
      const inclusions = await api.productInclusions_GetAll();

      const formattedInclusions = (inclusions as IInclusion[]).map(inclusion => ({
        ...inclusion,
        ...INCLUSIONS_DATA[inclusion.id as EInclusion]
      }));

      setInclusions(formattedInclusions);
    } catch {
      setInclusions([]);
    }
  }, [api, setInclusions]);

  const getItemDetails = useCallback(async () => {
    try {
      setIsFetching(true);

      const processingItem = await api.warehouse_GetProcess(itemProcessId);
      const itemConfigStations = processingItem.itemConfiguredStations || [];
      const incompleteStationIndex = itemConfigStations.findIndex(({ isComplete }) => !isComplete);
      const { stationId } =
        incompleteStationIndex !== -1
          ? itemConfigStations[incompleteStationIndex]
          : itemConfigStations[itemConfigStations.length - 1];

      setActiveStation(stationId as EStation);
      setCurrentItemStation(stationId as EStation);
      setItemDetails(processingItem);
    } finally {
      setIsFetching(false);
    }
  }, [api, itemProcessId]);

  const getAuthenticationDetails = useCallback(async () => {
    const details = await api.authenticity_GetAuthenticationStation(itemProcessId);

    warehouseForm.reset(getDefaultItemAuthenticationValues(details));
  }, [api, itemProcessId, warehouseForm]);

  const getNewCheckInDetails = useCallback(async () => {
    const details = await api.checkInStation_GetCheckIn(itemProcessId);

    warehouseForm.reset(getDefaultItemNewCheckInValues(details, itemDetails));
  }, [api, itemDetails, itemProcessId, warehouseForm]);

  const getPhotoAuthenticationDetails = useCallback(async () => {
    const details = await api.photoAuthentication_GetPhotoAuthentication(itemProcessId);
    const authConfig = await api.authenticity_GetAuthPhotosConfiguration(itemProcessId);

    warehouseForm.reset(getDefaultItemPhotoAuthenticationValues(details, authConfig));
  }, [api, itemProcessId, warehouseForm]);

  const getStorageDetails = useCallback(async () => {
    const details = await api.storage_GetItemStorageById(itemProcessId);

    warehouseForm.reset(getDefaultItemStorageValues(details));
    setIsDoneAutomatically(!!details.wasDoneAutomatically);
  }, [api, itemProcessId, warehouseForm]);

  useEffect(() => {
    const checkUserWarehouse = () => {
      const userWarehouse = localStorage.getItem(LOCAL_STORAGE_USER_WAREHOUSE);

      setHasUserWarehouse(!!userWarehouse);
    };

    if (itemProcessId) {
      getInclusions();
      getItemDetails();
      checkUserWarehouse();
    }
  }, [getInclusions, getItemDetails, itemProcessId]);

  const handleChangeCheckInTab = useCallback((value: number) => setCheckInTab(value), [setCheckInTab]);

  const isStorageNextStation = useMemo((): boolean => {
    const currentStationIndex = warehouseStations.findIndex(({ stationId }) => stationId === currentItemStation);

    return warehouseStations[currentStationIndex + 1]?.stationId === EStation.Storage;
  }, [currentItemStation, warehouseStations]);

  const handleNextStation = useCallback(
    (stationId: EStation, key: 'order' | 'stationId' = 'stationId'): EStation | undefined => {
      const currentStationIndex = warehouseStations.findIndex(station => station.stationId === stationId);

      if (currentStationIndex >= 0 && currentStationIndex < warehouseStations.length - 1 && !isStorageNextStation) {
        return warehouseStations[currentStationIndex + 1][key];
      }

      return undefined;
    },
    [isStorageNextStation, warehouseStations]
  );

  const handleCloseDetailForm = useCallback(() => {
    navigate({ pathname: EInboundRoute.Warehouse, search: location.search });
  }, [location, navigate]);

  const handleCloseDetailFormPrompt = useCallback(() => {
    if (isFormDirty && !isSuccessfullySaved && hasUserWarehouse) {
      handleOpenExitModal(EExitType.onExit);
    } else {
      handleCloseDetailForm();
    }
  }, [isFormDirty, isSuccessfullySaved, hasUserWarehouse, handleOpenExitModal, handleCloseDetailForm]);

  const handleChangeStep = useCallback(
    (stationId: EStation) => {
      if (isFormDirty && !isSuccessfullySaved && hasUserWarehouse) {
        handleOpenExitModal(EExitType.onSwitchStation);
        setOnQueueStation(stationId);
      } else {
        // TODO: Refactor validations in QC Station and see if this is still needed.
        warehouseForm.reset({});
        setActiveStation(stationId);
      }
    },
    [handleOpenExitModal, hasUserWarehouse, isFormDirty, isSuccessfullySaved, warehouseForm]
  );

  const handleDiscardChanges = useCallback(() => {
    if (exitModal.isOpen) {
      if (exitModal.type === EExitType.onExit) {
        handleCloseDetailForm();
      } else if (exitModal.type === EExitType.onSwitchStation) {
        warehouseForm.reset({});
        setActiveStation(onQueueStation as EStation);
      }

      handleCloseExitModal();
    }
  }, [exitModal.isOpen, exitModal.type, handleCloseDetailForm, handleCloseExitModal, onQueueStation, warehouseForm]);

  const handleToggleFinishModal = useCallback((open: boolean) => setShowFinishModal(open), []);

  const isInconclusiveAuthFail = useMemo(
    () =>
      warehouseForm.getValues('authResult') === EAuthenticationResultStatus.Inconclusive &&
      (
        warehouseForm.watch('partnerResults', [])?.[
          warehouseForm.watch('partnerResults', []).length - 1
        ] as PartnerAuthenticationResult
      )?.statusId === EAuthenticationResultStatus.Fake,
    [warehouseForm, warehouseForm.watch('authResult')]
  );

  const handleSaveChanges = useCallback(
    async (isSaveAndGoNextStation: boolean, isFinishProcess?: boolean, onSuccess?: () => void) => {
      try {
        setIsSaving(true);

        switch (activeStation) {
          case EStation.Authentication: {
            const formValues = warehouseForm.getValues() as IItemAuthenticationSchema;

            await api.authenticity_PutAuthenticationStation(
              itemProcessId,
              formatItemAuthenticationValues(formValues, stationStartDate, isSaveAndGoNextStation)
            );
            break;
          }
          case EStation.Checkin: {
            const formValues = warehouseForm.getValues() as ICheckinInputs;

            // TODO: Update implementation; use inbound/api function
            await warehouseService.updateCheckin(
              formValues,
              itemProcessId,
              formValues.itemId,
              isSaveAndGoNextStation,
              stationStartDate
            );

            setItemDetails(prevState => ({
              ...prevState,
              serialNumber: formValues.hasSerialNumber ? formValues.serialNumber : undefined
            }));
            break;
          }
          case EStation.CheckinV2: {
            const formValues = warehouseForm.getValues() as IItemNewCheckInDetailsSchema;

            await api.checkInStation_UpdateCheckInV2(
              itemProcessId,
              formatNewCheckInValuesRequest(formValues, itemDetails, stationStartDate, isSaveAndGoNextStation)
            );

            setItemDetails(prevState => ({
              ...prevState,
              serialNumber: formValues.hasSerialNumber ? formValues.serialNumber : undefined
            }));
            break;
          }
          case EStation.PhotoAuthentication: {
            await api.photoAuthentication_PutPhotoAuthentication(
              formatItemPhotoAuthenticationValues(
                warehouseForm.getValues() as IItemPhotoAuthenticationDetailsSchema,
                stationStartDate,
                isSaveAndGoNextStation
              ),
              itemProcessId
            );
            break;
          }
          case EStation.QualityControl: {
            const formValues = warehouseForm.getValues() as IQualityCheckFormInputs;

            // TODO: Update implementation; use inbound/api function
            await warehouseService.updateQualityControl(
              formValues,
              itemProcessId,
              formValues.itemId,
              isSaveAndGoNextStation,
              itemDetails.warehouse as EWarehouseName,
              stationStartDate
            );
            break;
          }
          case EStation.Storage: {
            const formValues = warehouseForm.getValues() as IItemStorageDetailsSchema;

            await api.storage_UpdateItemStorage(
              {
                ...formValues,
                finalStationDateTimeUtc: new Date(),
                initialStationDateTimeUtc: stationStartDate,
                isSaveAndGoNextStation,
                itemProcessId
              },
              itemProcessId
            );
            break;
          }
          default:
            break;
        }

        if (isSaveAndGoNextStation) {
          if (
            !isStorageNextStation &&
            ([EStation.Checkin, EStation.CheckinV2, EStation.PhotoAuthentication].includes(activeStation) ||
              (!ENABLE_RETURN_TO_SUPPLIER_NEW && activeStation === EStation.QualityControl) ||
              (activeStation === EStation.Authentication &&
                (warehouseForm.getValues('authResult') === EAuthenticationResultStatus.Authentic ||
                  !isInconclusiveAuthFail) &&
                !isFinishProcess) ||
              (ENABLE_RETURN_TO_SUPPLIER_NEW &&
                activeStation === EStation.QualityControl &&
                warehouseForm.getValues('supplierReturnReason') === undefined &&
                warehouseForm.getValues('hasPassedQualityCheck') !== false))
          ) {
            const resultNext = handleNextStation(currentItemStation, 'stationId');

            if (typeof resultNext === 'number') {
              warehouseForm.reset({});
              setActiveStation(resultNext);
              setCurrentItemStation(resultNext);
            }
          } else if (
            (activeStation === EStation.Storage && !exitModal.isOpen) ||
            isFinishProcess ||
            isStorageNextStation
          ) {
            handleCloseDetailForm();
            handleToggleFinishModal(false);
          }
        } else {
          setIsSuccessfullySaved(true);
        }

        onSuccess?.();

        // Refetch item details to update the stepper details
        const processingItem = await api.warehouse_GetProcess(itemProcessId);

        setItemDetails(processingItem);

        if (
          (exitModal.isOpen && exitModal.type === EExitType.onExit) ||
          !isSaveAndGoNextStation ||
          isFinishProcess ||
          activeStation === EStation.Storage
        ) {
          setSnackbar({ isOpen: true, message: t('warehouse:details.itemFinishSuccess', { sku: itemDetails.sku }) });
        }
      } catch (error) {
        console.log({ error });
        const { message = '' } = (error as IApiException)?.errors?.[0] || {};

        setSnackbar({ isError: true, isOpen: true, message });
      } finally {
        setIsSaving(false);
        handleDiscardChanges();
      }
    },
    [
      activeStation,
      api,
      currentItemStation,
      exitModal,
      handleCloseDetailForm,
      handleDiscardChanges,
      handleNextStation,
      handleToggleFinishModal,
      isInconclusiveAuthFail,
      isStorageNextStation,
      itemDetails,
      itemProcessId,
      stationStartDate,
      t,
      warehouseForm
    ]
  );

  const handleErrorSave = useCallback(
    (errors: FieldErrors<Record<string, any>>) => {
      if (activeStation === EStation.Checkin) {
        const errorKeys = Object.keys(errors);

        setCheckInTab(errorKeys.length === 1 && errors?.photoAuthFrames ? 1 : 0);
      }

      console.log({ errors, log: 'handleErrorSave' });

      handleOpenSnackbar({ isError: true, isOpen: true });
    },
    [activeStation, handleOpenSnackbar]
  );

  const handleSaveExitModal = useCallback(
    () =>
      warehouseForm.handleSubmit(async () => {
        await handleSaveChanges(exitModal.type !== EExitType.onSwitchStation);
        warehouseForm.reset({});
      }, handleErrorSave)(),
    [exitModal, handleErrorSave, handleSaveChanges, warehouseForm]
  );

  const handleUploadImages = useCallback(
    async (files: File[], photoType: EPhotoType) => {
      try {
        setIsUploadingImages(true);

        const form = warehouseForm as UseFormMethods<IItemAuthenticationSchema | IItemPhotoAuthenticationDetailsSchema>;

        let newValues: IAuthUploadedPhoto[] | IUploadedPhoto[] = [];
        const currentValues = form.watch('uploadedPhotos');

        const filesUploadChunks = chunk(
          files.map(file => api.warehouse_UploadPhoto(itemProcessId, { data: file, fileName: file.name }, photoType)),
          4
        );

        for (let index = 0; index < filesUploadChunks.length; index++) {
          const fileChunks = filesUploadChunks[index];
          const photoUrls = await Promise.all(fileChunks);

          const formattedValues = photoUrls.map(photoUrl => ({
            fileId: `new_${uuidGenerator()}`,
            uploadDate: new Date(),
            url: photoUrl
          }));

          newValues = [...newValues, ...formattedValues];
        }

        form.setValue('uploadedPhotos', [...currentValues, ...newValues], { shouldDirty: true, shouldValidate: true });
        form.trigger('uploadedPhotos');
      } finally {
        setIsUploadingImages(false);
      }
    },
    [api, itemProcessId, warehouseForm]
  );

  const handleDeleteImage = useCallback(
    (id: string) => {
      const form = warehouseForm as UseFormMethods<IItemAuthenticationSchema>;
      const uploadedPhotos = form.getValues('uploadedPhotos').filter(({ fileId }) => fileId !== id);

      form.setValue('uploadedPhotos', uploadedPhotos, { shouldDirty: true, shouldValidate: true });
      form.trigger('uploadedPhotos');
    },
    [warehouseForm]
  );

  const handleBulkDownloadPhotos = useCallback(
    async (urls: string[]) => {
      try {
        if (urls.length) {
          setIsBulkDownloading(true);

          const zip = JSZip();

          await Promise.all(
            urls.map(async url => {
              const res = await fetch(url);
              const blob = await res.blob();
              const imageName = url.split('/').pop() || '';

              zip.file(imageName, blob, { binary: true });
            })
          );

          const zipFile = await zip.generateAsync({ type: 'blob' });

          saveAs(zipFile, itemDetails.sku);
        }
      } catch (err) {
        handleOpenSnackbar({ isError: true, isOpen: true, message: t('warehouse:errors.errorBulkDownloadPhotos') });
      } finally {
        setIsBulkDownloading(false);
      }
    },
    [handleOpenSnackbar, itemDetails.sku, t]
  );

  const getBarcodeDetails = useCallback(() => {
    const getBarcodeDetailsCallback = async () => {
      try {
        setIsBarcodeDetailsLoading(true);
        const details = await api.products_GetBarCode(itemDetails.itemId as string);

        setBarcodeDetails(details);
      } finally {
        setIsBarcodeDetailsLoading(false);
      }
    };

    getBarcodeDetailsCallback();
  }, [api, itemDetails]);

  return (
    <WarehouseItemDetailsContext.Provider
      value={{
        activeStation,
        barcodeDetails,
        checkInTab,
        countries,
        currentItemStation,
        getBarcodeDetails,
        getCountries,
        handleCloseExitModal,
        handleNextStation,
        hasUserWarehouse,
        inclusions,
        isBarcodeDetailsLoading,
        isBulkDownloading,
        isDoneAutomatically,
        isFetching,
        isFormDirty,
        isFormValid,
        isInconclusiveAuthFail,
        isRegularFlow,
        isSaving,
        isStationLoading,
        isUploadingImages,
        itemDetails,
        onBulkDownloadPhotos: handleBulkDownloadPhotos,
        onChangeCheckInTab: handleChangeCheckInTab,
        onChangeStep: handleChangeStep,
        onCloseDetailFormPrompt: handleCloseDetailFormPrompt,
        onDeleteImage: handleDeleteImage,
        onErrorSave: handleErrorSave,
        onSaveChanges: handleSaveChanges,
        onToggleFinishModal: handleToggleFinishModal,
        onUploadImages: handleUploadImages,
        showFinishModal,
        warehouseForm,
        warehouseStations
      }}
    >
      {children}

      {/* TODO: Convert and replace this once new design system implemented */}
      <Snackbar
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={snackbar.isOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.isError ? 'error' : 'success'}>
          {snackbar.isError ? snackbar.message || t('warehouse:details.errorOccurred') : snackbar.message}
        </Alert>
      </Snackbar>

      {exitModal.isOpen && (
        <ExitModal
          isLoading={isSaving}
          isValid={isFormValid}
          onClose={handleCloseExitModal}
          onDiscard={handleDiscardChanges}
          onSave={handleSaveExitModal}
        />
      )}
    </WarehouseItemDetailsContext.Provider>
  );
};

const WarehouseItemDetailsConsumer = WarehouseItemDetailsContext.Consumer;

export { WarehouseItemDetailsConsumer, WarehouseItemDetailsProvider };

export default WarehouseItemDetailsContext;
