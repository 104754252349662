import React, { useMemo } from 'react';

import { MaterialIcons } from '@luxclusif/material';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';

import { EInclusion, IInclusion } from 'models/warehouse';
import { INCLUSIONS_DATA } from 'pages/WarehouseList/constants';
import useGetUnit from 'pages/WarehouseList/hooks/useGetUnit';
import { IMeasure } from 'pages/WarehouseList/schemas/checkInSchema';

import printDamageCardModalStyles from '../printDamageCardModal.styles';

interface IProps {
  itemDetails: Record<string, any>;
}

const ItemDetails: React.FC<IProps> = ({ itemDetails }) => {
  const classes = printDamageCardModalStyles();
  const { t } = useTranslation(['common', 'warehouse']);
  const getUnit = useGetUnit();

  const inclusionsCount = (itemDetails.inclusions as IInclusion[]).filter(({ id }) => id !== EInclusion.None).length;

  const inclusionsWithIcons: IInclusion[] = (itemDetails.inclusions as IInclusion[]).map(inclusion => {
    const icon = INCLUSIONS_DATA[inclusion.id]?.icon;
    const MaterialIcon = icon && (MaterialIcons as any)[icon];

    return {
      ...inclusion,
      icon: MaterialIcon ? <MaterialIcon /> : undefined
    };
  });

  const photoThumbnail = useMemo(
    (): string => (itemDetails?.summaryPhotos?.length ? itemDetails.summaryPhotos[0].url : ''),
    [itemDetails]
  );

  return (
    <div className={classes.itemDetailsContainer}>
      <div className={classes.itemMainInfoContainer}>
        <h5 className={classes.sectionTitle}>{t('warehouse:itemDetails')}</h5>
        <div className={classes.itemInfoContainer}>
          <div className={classes.itemInfo}>
            <label className={classes.infoLabel}>{t('common:category')}</label>
            <div className={classes.infoValue}>{itemDetails.category}</div>
          </div>
          <div className={classes.itemInfo}>
            <label className={classes.infoLabel}>{t('common:brand')}</label>
            <div className={classes.infoValue}>{itemDetails.brand}</div>
          </div>
          <div className={`${classes.itemInfo} ${classes.itemInfoName}`}>
            <label className={classes.infoLabel}>{t('common:name')}</label>
            <div className={classes.infoValue}>{itemDetails.name}</div>
          </div>

          <div className={classes.itemInfo}>
            <label className={classes.infoLabel}>{t('common:supplier')}</label>
            <div className={classes.infoValue}>{itemDetails.supplier}</div>
          </div>
          <div className={classes.itemInfo}>
            <label className={classes.infoLabel}>{t('common:country')}</label>
            <div className={classes.infoValue}>{itemDetails.country}</div>
          </div>
          <div className={classes.itemInfo}>
            <label className={classes.infoLabel}>{t('warehouse:serialNumber')}</label>
            <div className={classes.infoValue}>{itemDetails.serialNumber}</div>
          </div>
          <div className={classes.itemInfo}>
            <label className={classes.infoLabel}>{t('common:sellerCode')}</label>
            <div className={classes.infoValue}>{itemDetails.sellerCode}</div>
          </div>
        </div>

        <h6 className={classes.itemMeasurementLabel}>{t('warehouse:measures')}</h6>
        <div className={classes.itemMeasureContainer}>
          {itemDetails.measures.map((measure: IMeasure) => (
            <div key={measure.key} className={classes.itemInfo}>
              <label className={classes.infoLabel}>{t(`warehouse:measurements.${measure.labelKey}`)}</label>
              <div className={classes.infoValue}>
                {measure.value}
                {getUnit(measure.key)}
              </div>
            </div>
          ))}
        </div>

        <h6 className={classes.itemInclusionsLabel}>{`${t('warehouse:inclusions')} (${inclusionsCount})`}</h6>
        <div className={classes.inclusionValuesContainer}>
          {inclusionsWithIcons.map(({ icon, id, name }) => (
            <div className={classes.inclusionValue} key={id}>
              {icon}
              {name}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.itemAdditionalInfoContainer}>
        <Barcode value={itemDetails.sku} width="1" height="28" fontSize="14" font="Nunito Sans" />
        <div className={classes.itemAdditionalInfoWrapper}>
          <div className={classes.additionalInfoMainText}>{t('warehouse:printDamageCardModal.importantInfo')}</div>
          {photoThumbnail && (
            <div className={classes.itemPhotoThumbnail}>
              <img className={classes.itemPhoto} src={photoThumbnail} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
