import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const checkboxChipStyles = makeStyles(({ palette }: Theme) => {
  return createStyles({
    container: {
      backgroundColor: secondaryNeutral.light85,
      color: secondaryNeutral.normal,
      textTransform: 'capitalize',
      '& .MuiSvgIcon-root': {
        fontSize: '14px',
        marginLeft: '12px',
        color: secondaryNeutral.normal
      },
      '&[aria-checked=true],&[aria-checked=true] .MuiSvgIcon-root': {
        backgroundColor: secondaryNeutral.normal,
        color: palette.common.white
      },
      '&[aria-disabled=true],&[aria-disabled=true]:hover,&[aria-disabled=true]:focus': {
        cursor: 'not-allowed'
      }
    }
  });
});

export default checkboxChipStyles;
