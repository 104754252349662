import React, { useEffect, useRef, useState } from 'react';

import { LuxButton, IconButton, Tooltip, MaterialIcons } from '@luxclusif/material';
import { DataGrid } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  EColumnFilterTypes,
  IProps,
  TDateRangeState,
  TGOnChangeColumnFilter,
  TMultipleSelectState
} from 'models/component/luxDataTableInterface';

import DateRange from './ColumnFilters/DateRange/DateRange';
import MultipleSelect from './ColumnFilters/MultipleSelect/MultipleSelect';

import luxDataTableStyles from './luxDataTable.styles';

const LuxDataTables: React.FC<IProps> = ({
  checkboxSelection,
  currentColumnFilters,
  dataGridColumns,
  dataGridGetRowId,
  dataGridPage,
  dataGridPageOptions,
  dataGridPageSize,
  dataGridRowCount,
  dataGridRows,
  dataGridSortModel,
  filterColumns,
  headerActionsDisabled,
  headerButtons,
  headerTitle,
  isLoading,
  isRowSelectable,
  onChangePage,
  onChangePageSize,
  onChangeSortModel,
  onChangeTextSearch,
  onSelectionChange,
  rowHeight,
  selectedRows,
  statusFilter,
  textSearchPlaceholder
}) => {
  const classes = luxDataTableStyles();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const [showSearchText, setShowSearchText] = useState<boolean>(false);
  const [showColFilters, setShowColFilters] = useState<boolean>(false);
  const [currentFilterId, setCurrentFilterId] = useState<string | null>(null);

  const isServerPagination = !!onChangePage;
  const isServerSort = !!onChangeSortModel;

  useEffect(() => {
    if (showSearchText && searchInputRef.current) {
      searchInputRef.current.focus();
    } else {
      onChangeTextSearch('');
    }
  }, [showSearchText, searchInputRef]);

  useEffect(() => {
    if (headerActionsDisabled && showColFilters) {
      setShowColFilters(false);
    }
  }, [headerActionsDisabled]);

  const onChangeInputSearch = debounce(({ target }) => {
    onChangeTextSearch(target.value.trim().toLowerCase());
  }, 1500);

  const toggleSearchText = () => {
    setShowSearchText(prevState => !prevState);
  };

  const toggleColumnFilter = () => {
    if (showColFilters) {
      filterColumns.forEach(({ id, onChange, type }) => {
        if (type === EColumnFilterTypes.MultipleSelect && (currentColumnFilters[id] as TMultipleSelectState).length) {
          (onChange as TGOnChangeColumnFilter<TMultipleSelectState>)(id, []);
        } else if (type === EColumnFilterTypes.DateRange && currentColumnFilters[id] !== null) {
          (onChange as TGOnChangeColumnFilter<TDateRangeState>)(id, null);
        }
      });
    }

    setShowColFilters(prevState => !prevState);
  };

  // Get back to this once PO list is stable
  // const formattedColumns = dataGridColumns.map(column => {
  //   const newCol = { ...column }

  //   if (newCol?.type) {

  //     // eslint-disable-next-line react/display-name
  //     newCol.renderCell = (params:GridRenderCellParams => (
  //       <LuxDataTableCell
  //         type={newCol?.type || ''}
  //         value={params.value}
  //       />
  //     )

  //   }

  //   return newCol;
  // })

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {/* Filters General */}
        <div className={classes.headerContainer}>
          {!showSearchText ? (
            <>
              {typeof headerTitle === 'string' ? <h2 className={classes.headerTitle}>{headerTitle}</h2> : headerTitle}
              {headerButtons && (
                <div className={classes.headerButtonsContainer}>
                  {headerButtons.map(({ disabled, icon, label, onClick, tooltip }, index) => (
                    <Tooltip key={index} title={tooltip || ''}>
                      <span>
                        <LuxButton
                          className={`${classes.headerButton}${(!label && ` ${classes.headerIconButton}`) || ''}`}
                          disabled={disabled}
                          onClick={onClick}
                          startIcon={icon}
                          variant="outlined"
                        >
                          {label}
                        </LuxButton>
                      </span>
                    </Tooltip>
                  ))}
                </div>
              )}
              <div className={classes.headerSeparator} />
              <div className={classes.headerActionsContainer}>
                <Tooltip title={t('dataTable.search')}>
                  <span>
                    <IconButton
                      className={classes.iconButtons}
                      disabled={headerActionsDisabled}
                      onClick={toggleSearchText}
                    >
                      <MaterialIcons.Search />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title={showColFilters ? t('close') : t('dataTable.filter')}>
                  <span>
                    <IconButton
                      className={classes.iconButtons}
                      disabled={headerActionsDisabled}
                      onClick={toggleColumnFilter}
                    >
                      {showColFilters ? <MaterialIcons.Close /> : <MaterialIcons.FilterAlt />}
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </>
          ) : (
            <div className={classes.searchTextContainer}>
              <MaterialIcons.Search />
              <input
                ref={searchInputRef}
                onChange={onChangeInputSearch}
                className={classes.searchTextInput}
                placeholder={textSearchPlaceholder}
              />
              <Tooltip title={t('close')}>
                <IconButton className={classes.iconButtons} onClick={toggleSearchText}>
                  <MaterialIcons.Close />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>

        {/* Filters Column */}
        {!showColFilters ? (
          statusFilter && (
            <div className={classes.filtersContainer}>
              {statusFilter.options.map(status => {
                const isTabActive = status.id === statusFilter.activeStatus;
                const statusFilterBtnClass = isTabActive ? classes.statusButtonActive : classes.statusButtonInactive;
                const statusFilterCount = isTabActive ? classes.numberTooltipSecondary : classes.numberTooltipDefault;

                return (
                  <LuxButton
                    key={status.id}
                    variant="contained"
                    luxColor={isTabActive ? 'secondary' : 'white'}
                    onClick={() => statusFilter.onChange(status.id)}
                    className={`${classes.statusButtonFilter} ${statusFilterBtnClass}`}
                    disableElevation
                  >
                    <span>{status.label}</span>
                    <span className={`${classes.statusCountFilter} ${statusFilterCount}`}>{status.count}</span>
                  </LuxButton>
                );
              })}
            </div>
          )
        ) : (
          <div className={classes.filtersContainer}>
            {filterColumns.map(filterCol => {
              switch (filterCol.type) {
                case EColumnFilterTypes.MultipleSelect:
                  return (
                    <MultipleSelect
                      currentFilterId={currentFilterId}
                      id={filterCol.id}
                      key={filterCol.id}
                      label={filterCol.label}
                      onChange={filterCol.onChange}
                      onOpen={id => setCurrentFilterId(id)}
                      options={filterCol.options}
                    />
                  );

                case EColumnFilterTypes.DateRange:
                  return (
                    <DateRange
                      id={filterCol.id}
                      key={filterCol.id}
                      label={filterCol.label}
                      onChange={filterCol.onChange}
                      onOpen={id => setCurrentFilterId(id)}
                    />
                  );

                default:
                  return null;
              }
            })}
          </div>
        )}

        <DataGrid
          checkboxSelection={checkboxSelection}
          disableSelectionOnClick
          isRowSelectable={isRowSelectable}
          onSelectionModelChange={onSelectionChange}
          selectionModel={selectedRows}
          loading={isLoading}
          className={classes.table}
          columns={dataGridColumns}
          rows={dataGridRows}
          getRowId={dataGridGetRowId}
          rowCount={dataGridRowCount}
          page={dataGridPage}
          pageSize={dataGridPageSize}
          onPageSizeChange={onChangePageSize}
          onPageChange={onChangePage}
          sortModel={dataGridSortModel}
          onSortModelChange={onChangeSortModel}
          sortingMode={isServerSort ? 'server' : 'client'}
          rowsPerPageOptions={dataGridPageOptions}
          paginationMode={isServerPagination ? 'server' : 'client'}
          localeText={{
            MuiTablePagination: {
              labelRowsPerPage: t('dataTable.rowsPerPage')
            },
            footerRowSelected: count => `${count.toLocaleString()} ${t('dataTable.rowsSelected')}`
          }}
          autoPageSize
          autoHeight
          disableColumnFilter
          disableColumnMenu
          rowHeight={rowHeight}
        />
      </div>
    </div>
  );
};

export default LuxDataTables;
