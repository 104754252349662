import { EPhotoFrame, EStation } from '@inbound/api';
import { UseTranslationResponse } from 'react-i18next';

import {
  EInboundFlow,
  EInclusion,
  ExteriorDamageLocationKeys,
  GenericDamageKeys,
  HardwareDamageLocationKeys,
  IInclusionData,
  IItemConfigStation,
  IItemDetails,
  InteriorDamageLocationKeys,
  MeasurementUnit
} from 'models/warehouse';

import { IPhotoAuthFrame } from './schemas/checkInSchema';

export const INCLUSIONS_DATA: Record<EInclusion, IInclusionData> = {
  [EInclusion.None]: { icon: 'Block', key: 'none' },
  [EInclusion.DustBag]: { icon: 'ShoppingBasket', key: 'dustBag' },
  [EInclusion.Box]: { icon: 'CardGiftcard', key: 'box' },
  [EInclusion.AuthenticityCard]: { icon: 'CardMembership', key: 'authenticityCard' },
  [EInclusion.Padlock]: { icon: 'LockOutlined', key: 'padlock' },
  [EInclusion.Key]: { icon: 'VpnKey', key: 'key' },
  [EInclusion.Pouch]: { icon: 'LocalMall', key: 'pouch' },
  [EInclusion.NameTag]: { icon: 'LocalOffer', key: 'nameTag' },
  [EInclusion.Charm]: { icon: 'FolderSpecial', key: 'charm' },
  [EInclusion.Mirror]: { icon: 'Search', key: 'mirror' },
  [EInclusion.ShoulderStrap]: { icon: 'Gesture', key: 'shoulderStrap' }
};

const WAREHOUSE_CONSTANTS = {
  PHOTO_TYPE: {
    CHECK_IN_AUTHENTICITY: 1,
    HALLMARK_DAMAGES: 2,
    PROFERRIONAL_NON_EDITED: 4,
    QC: 3,
    SUPPLIER: 5
  },
  STATIONS: {
    AUTHENTICITY: 2,
    CHECKIN: 1,
    HALLMARK: 4,
    NEW_CHECKIN: 8,
    PHOTOGRAPHY: 5,
    PHOTO_AUTHENTICATION: 9,
    QUALITY_CHECK: 3,
    REVIEW: 7,
    STORAGE: 6
  },
  STATUS_ID: {
    PENDING_REVIEW: 5,
    PROCESSING: 4
  }
};

export const defaultWarehouseStations = (
  t: UseTranslationResponse<('warehouse' | 'common')[]>['t']
): IItemConfigStation[] => [
  { order: 1, station: t('warehouse:list.checkin'), stationId: EStation.CheckinV2 },
  {
    order: 2,
    station: t('warehouse:photoAuthentication'),
    stationId: EStation.PhotoAuthentication
  },
  { order: 3, station: t('warehouse:qualityCheck'), stationId: EStation.QualityControl },
  { order: 4, station: t('warehouse:list.storage'), stationId: EStation.Storage }
];

export const HOUR_MS = 60 * 60 * 1000; // 1 hour = 60 min -> 1 min = 60 sec -> 1 sec = 1000 ms

export const defaultItemDetailsState: IItemDetails = {
  brand: '',
  category: '',
  hasProfessionalPhotos: false,
  inboundFlow: { id: EInboundFlow.Regular, name: '' },
  isAtBBox: false,
  itemConfiguredStations: [],
  itemId: '',
  itemPhotos: [],
  itemProcessId: '',
  name: '',
  sellerCode: '',
  serialNumber: '',
  sku: '',
  stationAlert: null,
  style: '',
  warehouse: undefined
};

export const genericDamageKeys: GenericDamageKeys[] = ['smell'];

export const exteriorDamageLocationKeys: ExteriorDamageLocationKeys[] = [
  'back',
  'bottom',
  'corners',
  'front',
  'handleOrStrap',
  'pocket',
  'side',
  'top'
];

export const hardwareDamageLocationKeys: HardwareDamageLocationKeys[] = [
  'closure',
  'embellishment',
  'key',
  'practicalAttachment',
  'zipper'
];

export const interiorDamageLocationKeys: InteriorDamageLocationKeys[] = ['lining', 'interiorPocket'];

export const measurementUnit: MeasurementUnit[] = ['imperial', 'metric'];

export const additionalPhotoFrame: IPhotoAuthFrame = {
  fileId: '',
  isRequired: false,
  photoFrameId: EPhotoFrame.Additional,
  photoFrameName: 'Additional',
  url: ''
};

// export const stationNames: Record<string, string> = {
//   ['Authentication']: 'Authentication',
//   ['Authenticity']: 'Authenticity',
//   ['Checkin']: 'Checkin',
//   ['HallmarkDamagePhotos']: '',
//   ['PhotoAuthentication']: 'Photo Authentication',
//   ['Photography']: 'Photography',
//   ['QC']: 'QC',
//   ['QualityControl']: 'Quality Control',
//   ['Review']: 'Review',
//   ['Storage']: 'Storage'
// };

export default WAREHOUSE_CONSTANTS;
