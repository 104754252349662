import { EDamage } from 'models/warehouse';
import {
  IInteriorExteriorDamages,
  TGenericDamages,
  THardwareDamages
} from 'pages/WarehouseList/schemas/qualityCheckSchema';
import pascalize from 'utils/pascalize';

const getDamageIds = (damageLocation: IInteriorExteriorDamages | TGenericDamages | THardwareDamages) => {
  const damageIds = [];
  const { otherDamages, stained } = damageLocation as IInteriorExteriorDamages;
  const { damaged, missing, rustyOrTarnished, scratched } = damageLocation as THardwareDamages;
  const { cigarette, musty, others: otherSmell, perfume } = damageLocation as TGenericDamages;

  // Stained
  if (stained) {
    if (stained.mold.isChecked) {
      damageIds.push({
        id: EDamage.Mold,
        isAdditional: stained.mold.isAdditional,
        photoUrl: stained.mold.photoUrl,
        severityId: stained.mold?.severityId
      });
    }

    if (stained.waterMark.isChecked) {
      damageIds.push({
        id: EDamage.WaterMark,
        isAdditional: stained.waterMark.isAdditional,
        photoUrl: stained.waterMark.photoUrl,
        severityId: stained.waterMark?.severityId
      });
    }

    if (stained.transferOfColor.isChecked) {
      damageIds.push({
        id: EDamage.TransferOfColor,
        isAdditional: stained.transferOfColor.isAdditional,
        photoUrl: stained.transferOfColor.photoUrl,
        severityId: stained.transferOfColor?.severityId
      });
    }

    if (stained.penMark.isChecked) {
      damageIds.push({
        id: EDamage.PenMark,
        isAdditional: stained.penMark.isAdditional,
        photoUrl: stained.penMark.photoUrl,
        severityId: stained.penMark?.severityId
      });
    }

    if (stained.others.isChecked) {
      damageIds.push({
        id: EDamage.OtherStain,
        isAdditional: stained.others.isAdditional,
        photoUrl: stained.others.photoUrl,
        severityId: stained.others?.severityId
      });
    }
  }

  EDamage;

  // Smell
  Object.entries({ cigarette, musty, otherSmell, perfume }).forEach(([key, value]) => {
    if (value?.isChecked) {
      damageIds.push({
        id: EDamage[pascalize(key) as unknown as EDamage],
        isAdditional: value.isAdditional,
        photoUrl: value.photoUrl,
        severityId: value.severityId
      });
    }
  });

  // Other Damages
  if (otherDamages) {
    if (otherDamages.cracked.isChecked) {
      damageIds.push({
        id: EDamage.Cracked,
        isAdditional: otherDamages.cracked.isAdditional,
        photoUrl: otherDamages.cracked.photoUrl,
        severityId: otherDamages.cracked?.severityId
      });
    }

    if (otherDamages.creased.isChecked) {
      damageIds.push({
        id: EDamage.Creased,
        isAdditional: otherDamages.creased.isAdditional,
        photoUrl: otherDamages.creased.photoUrl,
        severityId: otherDamages.creased?.severityId
      });
    }

    if (otherDamages.cutStitching.isChecked) {
      damageIds.push({
        id: EDamage.CutStitching,
        isAdditional: otherDamages.cutStitching.isAdditional,
        photoUrl: otherDamages.cutStitching.photoUrl,
        severityId: otherDamages.cutStitching?.severityId
      });
    }

    if (otherDamages.discolored.isChecked) {
      damageIds.push({
        id: EDamage.Discolored,
        isAdditional: otherDamages.discolored.isAdditional,
        photoUrl: otherDamages.discolored.photoUrl,
        severityId: otherDamages.discolored?.severityId
      });
    }

    if (otherDamages.looseStitching.isChecked) {
      damageIds.push({
        id: EDamage.LooseStitching,
        isAdditional: otherDamages.looseStitching.isAdditional,
        photoUrl: otherDamages.looseStitching.photoUrl,
        severityId: otherDamages.looseStitching?.severityId
      });
    }

    if (otherDamages.outOfShape.isChecked) {
      damageIds.push({
        id: EDamage.OutOfShape,
        isAdditional: otherDamages.outOfShape.isAdditional,
        photoUrl: otherDamages.outOfShape.photoUrl,
        severityId: otherDamages.outOfShape?.severityId
      });
    }

    if (otherDamages.peeling.isChecked) {
      damageIds.push({
        id: EDamage.Peeling,
        isAdditional: otherDamages.peeling.isAdditional,
        photoUrl: otherDamages.peeling.photoUrl,
        severityId: otherDamages.peeling?.severityId
      });
    }

    if (otherDamages.personalized.isChecked) {
      damageIds.push({
        id: EDamage.Personalized,
        isAdditional: otherDamages.personalized.isAdditional,
        photoUrl: otherDamages.personalized.photoUrl,
        severityId: otherDamages.personalized?.severityId
      });
    }

    if (otherDamages.repaired.isChecked) {
      damageIds.push({
        id: EDamage.Repaired,
        isAdditional: otherDamages.repaired.isAdditional,
        photoUrl: otherDamages.repaired.photoUrl,
        severityId: otherDamages.repaired?.severityId
      });
    }

    if (otherDamages.ripped.isChecked) {
      damageIds.push({
        id: EDamage.Ripped,
        isAdditional: otherDamages.ripped.isAdditional,
        photoUrl: otherDamages.ripped.photoUrl,
        severityId: otherDamages.ripped?.severityId
      });
    }

    if (otherDamages.scratched.isChecked) {
      damageIds.push({
        id: EDamage.Scratched,
        isAdditional: otherDamages.scratched.isAdditional,
        photoUrl: otherDamages.scratched.photoUrl,
        severityId: otherDamages.scratched?.severityId
      });
    }

    if (otherDamages.split.isChecked) {
      damageIds.push({
        id: EDamage.Split,
        isAdditional: otherDamages.split.isAdditional,
        photoUrl: otherDamages.split.photoUrl,
        severityId: otherDamages.split?.severityId
      });
    }

    if (otherDamages.sticky.isChecked) {
      damageIds.push({
        id: EDamage.Sticky,
        isAdditional: otherDamages.sticky.isAdditional,
        photoUrl: otherDamages.sticky.photoUrl,
        severityId: otherDamages.sticky?.severityId
      });
    }

    if (otherDamages.worn.isChecked) {
      damageIds.push({
        id: EDamage.Worn,
        isAdditional: otherDamages.worn.isAdditional,
        photoUrl: otherDamages.worn.photoUrl,
        severityId: otherDamages.worn?.severityId
      });
    }
  }

  // Hardware
  if (damaged?.isChecked) {
    damageIds.push({
      id: EDamage.Damaged,
      isAdditional: damaged.isAdditional,
      photoUrl: damaged.photoUrl,
      severityId: damaged?.severityId
    });
  }

  if (missing?.isChecked) {
    damageIds.push({
      id: EDamage.Missing,
      isAdditional: missing.isAdditional,
      photoUrl: missing.photoUrl,
      severityId: missing?.severityId
    });
  }

  if (rustyOrTarnished?.isChecked) {
    damageIds.push({
      id: EDamage.RustyOrTarnished,
      isAdditional: rustyOrTarnished.isAdditional,
      photoUrl: rustyOrTarnished.photoUrl,
      severityId: rustyOrTarnished?.severityId
    });
  }

  if (scratched?.isChecked) {
    damageIds.push({
      id: EDamage.Scratched,
      isAdditional: scratched.isAdditional,
      photoUrl: scratched.photoUrl,
      severityId: scratched?.severityId
    });
  }

  return damageIds;
};

export default getDamageIds;
