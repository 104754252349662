import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const tableBorder = `1px ${secondaryNeutral.light75} solid`;

const printDamageCardModalStyles = makeStyles(() => {
  return createStyles({
    modalOverlay: {
      overflow: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      padding: '14px'
    },
    modalContainer: {
      zIndex: 999,
      margin: 'auto',
      '&:focus': {
        outline: 'none'
      }
    },
    modalContent: {
      width: '595px',
      borderRadius: 0,
      padding: '40px 32px',
      display: 'flex',
      flexDirection: 'column'
    },

    sectionTitle: {
      fontSize: '12px',
      fontWeight: 400,
      margin: '0 0 16px'
    },

    contentFooter: {
      marginTop: 'auto',
      textAlign: 'end'
    },

    itemDetailsContainer: {
      marginBottom: '44px',
      display: 'flex',
      gap: '16px'
    },
    itemDetailsLabel: {
      fontSize: '10px',
      marginBottom: '16px !important'
    },
    itemMeasurementLabel: {
      fontSize: '10px',
      margin: '16px 0 8px'
    },
    itemInclusionsLabel: {
      fontSize: '10px',
      margin: '16px 0 8px'
    },
    itemInfoContainer: {
      display: 'grid',
      gridGap: '8px',
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'
    },
    itemMeasureContainer: {
      display: 'grid',
      gridGap: '8px',
      gridTemplateColumns: 'repeat(4, 1fr)'
    },
    itemInfo: {
      display: 'grid',
      lineHeight: '14px'
    },
    infoLabel: {
      fontSize: '10px',
      color: '#86867E'
    },
    infoValue: {
      fontSize: '10px',
      wordBreak: 'break-word'
    },
    itemInfoName: {
      gridArea: '1 / 3 / 1 / 5'
    },

    inclusionValuesContainer: {
      display: 'flex',
      gap: '16px',
      flexWrap: 'wrap',
      width: '290px'
    },
    inclusionValue: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '8px',

      '& .MuiSvgIcon-root': {
        fontSize: '8px',
        marginRight: '4px'
      }
    },

    itemMainInfoContainer: {
      flex: 1
    },
    itemAdditionalInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      minWidth: '155px'
    },
    itemAdditionalInfoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      paddingLeft: '8px',
      paddingRight: '8px'
    },
    itemPhoto: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    itemPhotoThumbnail: {
      border: '1px solid #DDDDDA',
      borderRadius: '4px',
      maxHeight: '180px',
      maxWidth: '100%',
      minWidth: '155px',
      padding: '4px',
      position: 'relative'
    },

    additionalInfoMainText: {
      width: '155px',
      border: '1px solid #1D1D1B',
      borderRadius: '4px',
      padding: '8px 16px',
      fontSize: '10px',
      textAlign: 'center',
      whiteSpace: 'pre-line'
    },

    damageRecognitionList: {
      fontSize: '10px',
      width: '100%',
      borderSpacing: '0px 4px'
    },
    damageRecognitionHeader: {
      '& > th': {
        padding: '4px 0 4px',
        textAlign: 'left'
      },
      '& > th:first-child': {
        paddingLeft: '16px'
      },
      '& > th:last-child': {
        paddingRight: '16px'
      }
    },
    damageRecognitionRow: {
      '& > td': {
        borderTop: tableBorder,
        borderBottom: tableBorder,
        padding: '4px 0 4px',
        textAlign: 'left'
      },
      '& > td:first-child': {
        borderLeft: tableBorder,
        borderBottomLeftRadius: '4px',
        borderTopLeftRadius: '4px',
        paddingLeft: '16px'
      },
      '& > td:last-child': {
        borderRight: tableBorder,
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        paddingRight: '16px'
      }
    }
  });
});

export default printDamageCardModalStyles;
