import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PhotoGetModel2 } from '@inbound/api';
import { LayoutJustino } from '@lux-ds/content-layouts';
import IconButton from '@lux-ds/icon-button';
import { ImageZoom } from '@lux-ds/image';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import Slider, { Settings as SlickSettings } from 'react-slick';

import { StyledHerodias, StyledSliderCounter, StyledThumbnailImage, StyledThumbnailSlider } from './imageSlider.styles';

interface IImageSliderProps {
  images: PhotoGetModel2[];
}

const ImageSlider: FC<IImageSliderProps> = ({ images }) => {
  const { t } = useTranslation('warehouse');

  const sliderRef = useRef<Slider>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = useCallback(() => {
    const index = (activeIndex + 1) % images.length;

    setActiveIndex(index);
    sliderRef.current?.slickGoTo(index);
  }, [activeIndex, images, setActiveIndex, sliderRef]);

  const handlePrevious = useCallback(() => {
    const { length } = images;
    const index = (activeIndex + length - 1) % length;

    setActiveIndex(index);
    sliderRef.current?.slickGoTo(index);
  }, [activeIndex, images, setActiveIndex, sliderRef]);

  const handleThumbnailClick = useCallback(
    (index: number) => {
      setActiveIndex(index);
      sliderRef.current?.slickGoTo(index);
    },
    [setActiveIndex, sliderRef]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          return handlePrevious();

        case 'ArrowRight':
          return handleNext();

        default:
          return;
      }
    },
    [handleNext, handlePrevious]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    setActiveIndex(0);

    sliderRef?.current?.slickGoTo(0);
  }, [images]);

  const sliderSettings = useMemo(
    (): SlickSettings => ({
      adaptiveHeight: true,
      infinite: false,
      nextArrow: (
        <IconButton disableRipple>
          <ArrowForwardIosIcon />
        </IconButton>
      ),
      prevArrow: (
        <IconButton disableRipple>
          <ArrowBackIos />
        </IconButton>
      ),
      slidesToScroll: 8,
      slidesToShow: images.length >= 8 ? 8 : images.length
    }),
    [images]
  );

  const activeImage = useMemo((): string => images[activeIndex]?.url || '', [activeIndex, images]);

  return images.length ? (
    <LayoutJustino>
      <StyledHerodias>
        <IconButton onClick={handlePrevious}>
          <ArrowBack />
        </IconButton>
        <ImageZoom height="100%" src={activeImage} width="auto" />
        <IconButton onClick={handleNext}>
          <ArrowForward />
        </IconButton>
      </StyledHerodias>
      <StyledSliderCounter variant="label">
        {t('details.gallery.imageOf', {
          count: images.length,
          index: images.length ? activeIndex + 1 : 0
        })}
      </StyledSliderCounter>
      {images.length && (
        <StyledThumbnailSlider $length={images.length} {...sliderSettings} ref={sliderRef}>
          {images.map((image, index) => (
            <StyledThumbnailImage
              $isActive={index === activeIndex}
              border
              height={67}
              key={image.id}
              onClick={() => handleThumbnailClick(index)}
              src={image.url as string}
              width={67}
            />
          ))}
        </StyledThumbnailSlider>
      )}
    </LayoutJustino>
  ) : null;
};

export default ImageSlider;
