import React, { useState } from 'react';

import { StatusCodes } from 'http-status-codes';
import { UseFormMethods } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import UploadFiles from 'components/Input/UploadFiles/UploadFiles';
import suppliersService from 'services/Suppliers.service';

import supplierDetailsStyles from '../../supplierDetails.styles';

interface IProps {
  businessModelForm: UseFormMethods<Record<string, any>>;
}

const Files: React.FC<IProps> = ({ businessModelForm }) => {
  const classes = supplierDetailsStyles();
  const [isLoggedIn, setLoggedIn] = useState(true);

  const { errors, getValues, setValue, watch } = businessModelForm;

  const uploadFile = (id: string, file: any) => {
    suppliersService.uploadFile(id, file).catch(response => {
      const status = response.response ? response.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

      if (status === StatusCodes.UNAUTHORIZED) {
        setLoggedIn(false);
      }
    });
  };

  return !isLoggedIn ? (
    <Navigate to="/logout" />
  ) : (
    <div>
      <h1 className={classes.title}>Files</h1>
      <UploadFiles
        name="files"
        label="Files"
        watch={watch}
        errors={errors}
        setValue={setValue}
        getValue={getValues}
        accept=".pdf, .xlsx, .csv"
        uploadFile={uploadFile}
      />
      <hr className={classes.line} />
    </div>
  );
};

export default Files;
