import React from 'react';

import {
  GridColDef,
  GridRowIdGetter,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel
} from '@mui/x-data-grid';

import { EPurchaseOrderAllStatus } from 'models/purchaseOrders';

export interface IStatusFilterOption {
  count: number;
  // Add more enum keys here if new status types added
  id: EPurchaseOrderAllStatus;
  label: string;
}

export enum EColumnFilterTypes {
  DateRange = 'dateRange',
  MultipleSelect = 'multipleSelect'
}

export type TMultipleSelectOptions = {
  label: string;
  value: string;
}[];

export interface IColumnFilterMultipleSelect {
  id: string;
  label: string;
  onChange: TGOnChangeColumnFilter<TMultipleSelectState>;
  options: TMultipleSelectOptions;
  type: EColumnFilterTypes.MultipleSelect;
}

export interface IMultipleSelectProps extends Omit<IColumnFilterMultipleSelect, 'type'> {
  currentFilterId: string | null;
  onOpen: (id: string | null) => void;
}

export interface IColumnFilterDateRange {
  id: string;
  label: string;
  onChange: TGOnChangeColumnFilter<TDateRangeState>;
  type: EColumnFilterTypes.DateRange;
}

export interface IDateRangeProps extends Omit<IColumnFilterDateRange, 'type'> {
  onOpen: (id: string | null) => void;
}

export type TMultipleSelectState = string[];

interface IDateRangeState {
  endDate: string | null;
  startDate: string | null;
}

export type TDateRangeState = IDateRangeState | null;

export type TGOnChangeColumnFilter<T> = (id: string, value: T) => void;

/** Used for typing the onChange for your component */
export type TOnChangeColumnFilter = TGOnChangeColumnFilter<TDateRangeState | TMultipleSelectState>;

export type TColumnFilters = Array<IColumnFilterMultipleSelect | IColumnFilterDateRange>;

export type TColumnFiltersValues = { [id: string]: TDateRangeState | TMultipleSelectState };

export interface IHeaderButton {
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: string;
  onClick: () => void;
  tooltip?: string;
}

interface IHeader {
  headerActionsDisabled?: boolean;
  headerButtons?: IHeaderButton[];
  headerTitle: string | React.ReactNode;
  onChangeTextSearch: (param: string) => void;
  textSearchPlaceholder: string;
}

export interface IStatusFilter {
  activeStatus: string;
  onChange: (status: string) => void;
  options: IStatusFilterOption[];
}

interface IFilter {
  currentColumnFilters: TColumnFiltersValues;
  filterColumns: TColumnFilters;
  statusFilter?: IStatusFilter;
}

interface IDataGrid {
  checkboxSelection?: boolean;
  dataGridColumns: GridColDef[];
  dataGridGetRowId?: GridRowIdGetter;
  dataGridPage?: number;
  dataGridPageOptions?: number[];
  dataGridPageSize?: number;
  dataGridRowCount?: number;
  dataGridRows: GridRowsProp[];
  dataGridSortModel?: GridSortModel;
  isLoading?: boolean;
  isRowSelectable?: (row: GridRowParams) => boolean;
  onChangePage?: (newPage: number) => void;
  onChangePageSize?: (newPageSize: number) => void;
  onChangeSortModel?: (sortModel: GridSortModel) => void;
  onSelectionChange?: (selection: GridSelectionModel) => void;
  rowHeight?: number;
  selectedRows?: GridSelectionModel;
}

export interface IProps extends IHeader, IFilter, IDataGrid {}
