import React from 'react';

import { IconButton, MaterialIcons } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import Accept from 'assets/svg/accept.svg';
import Decline from 'assets/svg/decline.svg';
import { DamageLocationKeys, EDamage, EDamageLocation, InteriorExteriorDamageStainedKeys } from 'models/warehouse';
import { IOnHoldItemInfo } from 'models/warehouse/onHold';

import qualityCheckStyles from './qualityCheck.styles';

interface IProps {
  actualQCDamagesDescription: IOnHoldItemInfo['actualQCDamagesDescription'];
  actualQCDecision: IOnHoldItemInfo['actualQCDecision'];
  additionalDamagePhotos: IOnHoldItemInfo['additionalDamagePhotos'];
  previousQCDamagesDescription: IOnHoldItemInfo['previousQCDamagesDescription'];
}

const damageLocationKeys = Object.entries(EDamageLocation);
const damageMainKeys = Object.entries(EDamage);

const QualityCheck: React.FC<IProps> = ({
  actualQCDamagesDescription,
  actualQCDecision,
  additionalDamagePhotos,
  previousQCDamagesDescription
}) => {
  const classes = qualityCheckStyles();
  const { t } = useTranslation('warehouse');

  const toPascal = (string: string) => `${string.charAt(0).toLowerCase()}${string.slice(1)}`;

  return (
    <>
      <h1 className={classes.title}>{t('qualityCheck')}</h1>
      <div className={classes.reReviewDetailsContainer}>
        <div className={`${classes.reReviewDetail} ${classes.previousQcCommentsContainer}`}>
          <label>{t('previousQCDamages')}</label>
          <span>{previousQCDamagesDescription}</span>
        </div>
        <div className={classes.reReviewDetail}>
          <label>{t('qcDamages')}</label>
          <span>{actualQCDamagesDescription}</span>
        </div>
        <div className={classes.reReviewDetail}>
          <label>{t('qualityCheckDecision')}</label>
          <div className={classes.qualityCheckDecision}>
            <img alt="decision-ico" src={actualQCDecision ? Accept : Decline} className={classes.decisionIcon} />
            <span>{actualQCDecision ? t('itemOk') : t('itemNotOk')}</span>
          </div>
        </div>

        <div className={`${classes.reReviewDetail} ${classes.damagePhotosContainer}`}>
          <label>{t('reviewStation.additionalDamagePhotos')}</label>
          <div className={classes.damagePhotoList}>
            {additionalDamagePhotos?.map(({ damageId, locationId, photoUrl }) => {
              const [damageMainKey] = damageMainKeys.find(([, id]) => damageId === id) || [''];
              const damageMainLabel = t(
                `qualityCheckStation.damage.${toPascal(damageMainKey) as InteriorExteriorDamageStainedKeys}`
              );

              const [damageLocationKey] = damageLocationKeys.find(([, id]) => locationId === id) || [''];
              const damageLocationLabel = t(
                `qualityCheckStation.damageLocation.${toPascal(damageLocationKey) as DamageLocationKeys}`
              );

              const label = `${damageLocationLabel} - ${damageMainLabel}`;

              return (
                <div key={photoUrl} className={classes.imageContainer}>
                  <div className={classes.imageHeaderContainer}>
                    <InView triggerOnce>
                      {({ inView, ref }) => (
                        <img ref={ref} className={classes.imageHeader} src={inView ? photoUrl : ''} />
                      )}
                    </InView>
                    <div className={classes.headerActions}>
                      <IconButton className={classes.actionButton} onClick={() => window.open(photoUrl)}>
                        <MaterialIcons.Download />
                      </IconButton>
                    </div>
                  </div>
                  <div className={classes.imageLabel}>{label}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default QualityCheck;
