import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const imageModalStyles = makeStyles(({ palette }: Theme) => {
  return createStyles({
    modalImageContainer: {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      width: '42vw',
      minWidth: '507px',
      padding: '28px',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      '& img': {
        width: '100%',
        height: 'auto'
      }
    },
    closeZoomButton: {
      position: 'absolute',
      right: '4px',
      top: '4px',
      backgroundColor: palette.common.white,
      color: palette.secondary.light,
      padding: '4px',

      '& .MuiSvgIcon-root': {
        color: 'inherit',
        fontSize: '18px'
      }
    }
  });
});

export default imageModalStyles;
