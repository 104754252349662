import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { Box, Grid, Typography } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import BBoxIcon from 'assets/svg/bbox_ico.svg';
import LuxAccordion from 'components/Accordion/Accordion';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import WAREHOUSE_CONSTANTS from 'pages/WarehouseList/constants';
import BaseConfiguration from 'setup/BaseConfiguration';

import GalleryModal from '../GalleryModal/GalleryModal';

import warehouseCardStyles from './warehouseCard.styles';

const WarehouseCard: React.FC = () => {
  const classes = warehouseCardStyles();
  const { t } = useTranslation(['common', 'warehouse']);
  const { activeStation, itemDetails, warehouseStations } = useWarehouseItemDetailsContext();

  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [openAccordion, setOpenAccordion] = useState<boolean>(activeStation === WAREHOUSE_CONSTANTS.STATIONS.CHECKIN);

  const displayPhoto = useMemo(
    () => (itemDetails.itemPhotos?.length ? itemDetails.itemPhotos[0] : ''),
    [itemDetails.itemPhotos]
  );

  useEffect(() => {
    const stationOrder =
      warehouseStations.find(warehouseStation => warehouseStation.stationId === activeStation)?.order || 1;

    setOpenAccordion(stationOrder === 1);
  }, [activeStation]);

  return (
    <Fragment>
      <LuxAccordion
        expanded={openAccordion}
        onChange={() => setOpenAccordion(prevState => !prevState)}
        label={t('warehouse:itemDetails')}
        id="station"
        defaultExpanded={activeStation === WAREHOUSE_CONSTANTS.STATIONS.CHECKIN}
      >
        <Box flexGrow={1} display="flex" alignItems="center">
          <Box flexGrow={1} className={classes.warehouseCardDetailsContainer}>
            <Grid container spacing={1}>
              <Grid item md={3}>
                <h3 className={classes.warehouseTextTitle}>{t('common:sku')}</h3>
                <h3 className={classes.warehouseTextDetails}>{itemDetails.sku}</h3>
              </Grid>

              <Grid item md={3}>
                <h3 className={classes.warehouseTextTitle}>{t('common:sellerCode')}</h3>
                <h3 className={classes.warehouseTextDetails}>{itemDetails.sellerCode}</h3>
              </Grid>

              <Grid item md={3}>
                <h3 className={classes.warehouseTextTitle}>{t('common:category')}</h3>
                <h3 className={classes.warehouseTextDetails}>{itemDetails.category}</h3>
              </Grid>
              <Grid item md={3}>
                <h3 className={classes.warehouseTextTitle}>{t('common:brand')}</h3>
                <h3 className={classes.warehouseTextDetails}>{itemDetails.brand}</h3>
              </Grid>

              <Grid item md={6}>
                <h3 className={classes.warehouseTextTitle}>{t('common:name')}</h3>
                <h3 className={classes.warehouseTextDetails}>{itemDetails.name}</h3>
              </Grid>

              <Grid item md={3}>
                <h3 className={classes.warehouseTextTitle}>{t('warehouse:serialNumber')}</h3>
                <h3 className={classes.warehouseTextDetails}>{itemDetails.serialNumber}</h3>
              </Grid>

              <Grid item md={3}>
                <h3 className={classes.warehouseTextTitle}>{t('warehouse:details.itemIn')}</h3>
                {itemDetails.isAtBbox && (
                  <h3 className={`${classes.warehouseTextDetails} ${classes.bBoxContainer}`}>
                    <img src={BBoxIcon} />
                    <span>{t('warehouse:details.bBox')}</span>
                  </h3>
                )}
              </Grid>
            </Grid>
          </Box>
          {displayPhoto && (
            <Box className={classes.warehouseCardImageContainer}>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <img ref={ref} src={inView ? displayPhoto : ''} className={classes.warehouseCardImage} />
                )}
              </InView>
              <Typography className={classes.warehouseCardImageLink} onClick={() => setOpenGallery(true)}>
                {t('warehouse:details.viewAllImages')}
              </Typography>
            </Box>
          )}
        </Box>
      </LuxAccordion>
      {openGallery && (
        <BaseConfiguration>
          <GalleryModal
            handleClose={() => setOpenGallery(false)}
            itemId={itemDetails.itemId as string}
            name={itemDetails.name as string}
          />
        </BaseConfiguration>
      )}
    </Fragment>
  );
};

export default WarehouseCard;
