import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const photoUploadStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      marginTop: '48px',
      marginBottom: '24px'
    },

    title: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '18px',
      marginBottom: '8px',
      marginTop: '16px'
    },
    instructions: {
      margin: '0'
    },

    uploadSection: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',
      marginTop: '24px'
    },
    uploadedPlaceholder: {
      height: '139px',
      width: '139px',
      backgroundColor: '#FBFAFB',
      border: '1px solid #DFDCE0',
      borderRadius: '4px',

      '& > .MuiSvgIcon-root': {
        color: '#7D717E'
      }
    }
  });
});

export default photoUploadStyles;
