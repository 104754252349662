import {
  TExteriorMatrixLocationKeys,
  TInteriorMatrixLocationKeys,
  TMatrixConcernKeys
} from 'pages/WarehouseList/schemas/authenticationSchema';

export const concernKeys: TMatrixConcernKeys[] = ['craftmanship', 'material', 'typography', 'hardware'];
export const exteriorLocationKeys: TExteriorMatrixLocationKeys[] = [
  'body',
  'logo',
  'hardwareClosures',
  'hardwareEngravings'
];
export const interiorLocationKeys: TInteriorMatrixLocationKeys[] = [
  'interiorHardwareClosures',
  'interiorHardwareEngravings',
  'interiorLogo',
  'lining',
  'madeInTag',
  'serialNumberTag'
];
