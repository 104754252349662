import { createStyles, makeStyles, secondaryPalette } from '@luxclusif/material';

import { EStationAlert } from 'models/warehouse';

const stationAlertChipStyles = makeStyles(() => {
  return createStyles({
    container: {
      borderRadius: '4px',
      display: 'inline-block',
      fontSize: '14px',
      lineHeight: 1,
      padding: '8px',
      whiteSpace: 'nowrap',

      '& > p': {
        margin: 0
      }
    },
    subLabel: {
      color: '#7D717E',
      fontSize: '10px',
      lineHeight: '14px'
    }
  });
});

export const stationAlertColorsStyles = makeStyles(() =>
  createStyles({
    [EStationAlert.ForOutbound]: {
      backgroundColor: '#FEF3E8',
      color: secondaryPalette.warning.dark
    },
    [EStationAlert.ForPhotography]: {
      backgroundColor: '#FCF6F9',
      color: '#BD4F84'
    }
  })
);

export default stationAlertChipStyles;
