import { createStyles, makeStyles } from '@luxclusif/material';

const photoFrameInputStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexFlow: 'column',
      gap: '8px'
    },
    error: {
      color: theme.palette.error.main
    },
    label: {
      fontSize: '14px',
      lineHeight: 1
    },
    disableFrameContainer: {
      opacity: 0.35
    },
    otherActionItem: {
      gap: '9px',
      minWidth: '173px',
      '& .MuiListItemIcon-root': {
        minWidth: '26px'
      },
      '& .MuiListItemText-primary': {
        fontSize: '14px'
      }
    },
    placeholder: {
      userSelect: 'none',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '139px',
      width: '139px',
      border: '1px dashed #C7C1C7',
      borderRadius: '4px',

      '& > .MuiSvgIcon-root': {
        fontSize: '24px',
        color: '#C7C1C7'
      }
    },
    placeHolderHovered: {
      backgroundColor: '#FBFAFB',
      borderColor: '#7D717E',

      '& > .MuiSvgIcon-root': {
        color: '#7D717E'
      }
    }
  })
);

export default photoFrameInputStyles;
