import React, { useEffect } from 'react';

import { AuthService } from '@luxclusif/material';

const Login: React.FC = () => {
  useEffect(() => {
    AuthService.login();
  }, []);

  return null;
};

export default Login;
