import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import { styled } from '@lux-ds/theming/dist/cjs/styles';

export const StyledJay = styled(LayoutJay)({
  height: '719px',
  width: '1172px'
});

export const StyledHeader = styled(LayoutHerodias)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.primary.shade[80]}`,
  height: theme.space['4xl'],
  justifyContent: 'space-between',
  padding: theme.space.m
}));

export const StyledThumbnailContainer = styled(LayoutHerodias)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.shade[80]}`,
  flexDirection: 'column',
  height: '100%',
  gap: theme.space.s,
  padding: theme.space.m,
  width: '235px'
}));

export const StyledThumbnailItem = styled(LayoutJay, {
  shouldForwardProp: prop => prop !== '$selected'
})<{ $selected: boolean }>(({ $selected, theme }) => ({
  border: `1px solid ${$selected ? theme.palette.secondary.main : theme.palette.primary.shade[80]}`,
  borderRadius: theme.space.xxs,
  cursor: 'pointer',
  gap: theme.space.xs,
  padding: theme.space.s,
  width: '100%'
}));

export const StyledSliderContainer = styled(LayoutHerodias)(({ theme }) => ({
  padding: theme.space.m,
  width: '937px'
}));

export const StyledLoaderContainer = styled(LayoutHerodias)({
  flex: 1,
  justifyContent: 'center'
});
