import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const qualityCheckStyles = makeStyles(({ palette }: Theme) => {
  return createStyles({
    title: {
      color: palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '18px',
      marginTop: '48px',
      marginBottom: '40px'
    },

    reReviewDetailsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: 'auto',
      gridColumnGap: '62px',
      gridRowGap: '32px'
    },
    reReviewDetail: {
      '& label': {
        fontSize: '12px',
        color: secondaryNeutral.light40,
        display: 'block',
        marginBottom: '8px'
      },
      '& span': {
        fontSize: '16px'
      }
    },
    previousQcCommentsContainer: {
      gridArea: '1 / 1 / 2 / 3'
    },
    damagePhotosContainer: {
      gridArea: '3 / 1 / 3 / 3'
    },

    qualityCheckDecision: {
      display: 'flex',
      gap: '12px'
    },
    decisionIcon: {
      height: '24px',
      width: 'auto'
    },

    damagePhotoList: {
      display: 'flex',
      gap: '24px',
      flexWrap: 'wrap'
    },
    imageContainer: {
      borderRadius: '4px',
      height: '245px',
      width: '204px',
      border: '1px solid #DFDCE0',
      overflow: 'hidden'
    },
    imageHeaderContainer: {
      height: '203px',
      borderBottom: `1px solid ${secondaryNeutral.light75}`,
      position: 'relative'
    },

    headerActions: {
      position: 'absolute',
      padding: '8px',
      top: 0,
      right: 0
    },
    actionButton: {
      backgroundColor: palette.common.white,
      color: palette.secondary.light,
      padding: '6px !important',
      '& .MuiSvgIcon-root': {
        fontSize: '20px'
      },
      '&:hover': {
        backgroundColor: secondaryNeutral.light85
      }
    },
    imageHeader: {
      objectFit: 'cover',
      height: '100%',
      width: '100%'
    },
    imageLabel: {
      fontSize: '11px',
      color: secondaryNeutral.light40,
      padding: '7px'
    }
  });
});

export default qualityCheckStyles;
