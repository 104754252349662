import React, { useCallback, useMemo } from 'react';

import { EPhotoType } from '@inbound/api';
import { LoadingButton } from '@lux-ds/button';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Typography from '@lux-ds/typography';
import Download from '@mui/icons-material/Download';
import moment from 'moment';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ImageUpload from 'components/ImageUpload/ImageUpload';
import UploadedPhoto from 'components/UploadedPhoto/UploadedPhoto';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { IItemAuthenticationSchema } from 'pages/WarehouseList/schemas/authenticationSchema';

const AuthenticationPhotos: React.FC = () => {
  const { t } = useTranslation(['common', 'warehouse']);
  const { isViewMode } = useWarehouseItemContext();
  const { isBulkDownloading, isUploadingImages, onBulkDownloadPhotos, onDeleteImage, onUploadImages, warehouseForm } =
    useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IItemAuthenticationSchema>, [warehouseForm]);

  const {
    formState: { errors }
  } = form;

  const uploadedPhotos = form.watch('uploadedPhotos', []);

  const handleUpload = useCallback(
    async (files: File[]) => {
      onUploadImages(files, EPhotoType.AuthenticationConcern);
    },
    [onUploadImages]
  );

  const handleBulkDownload = useCallback(
    () => onBulkDownloadPhotos(uploadedPhotos.map(({ url }) => url)),
    [onBulkDownloadPhotos, uploadedPhotos]
  );

  return (
    <LayoutJay gap="l">
      <LayoutHerodias sx={{ justifyContent: 'space-between' }}>
        <Typography color={form.errors.uploadedPhotos && 'error.main'} variant="h6">
          {`${t('warehouse:authenticationStation.authenticationPhotos')} *`}
        </Typography>
        {!!uploadedPhotos.length && (
          <LoadingButton
            loading={isBulkDownloading}
            onClick={handleBulkDownload}
            startIcon={<Download />}
            variant="outlined"
          >
            {t('common:downloadAll')}
          </LoadingButton>
        )}
      </LayoutHerodias>
      <LayoutHerodias gap="m" sx={{ flexWrap: 'wrap' }}>
        {uploadedPhotos.map(photo => (
          <UploadedPhoto
            border
            description={moment(photo.uploadDate || '').format('MM/DD/YYYY')}
            height={202}
            id={photo.fileId}
            key={photo.fileId}
            onDelete={!isViewMode ? onDeleteImage : undefined}
            src={photo.url}
            width={202}
          />
        ))}
        {!isViewMode && (
          <ImageUpload error={!!errors.uploadedPhotos} isUploading={isUploadingImages} onUpload={handleUpload} />
        )}
      </LayoutHerodias>
    </LayoutJay>
  );
};

export default AuthenticationPhotos;
