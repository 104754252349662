import React, { useCallback, useMemo } from 'react';

import { LoadingButton } from '@lux-ds/button';
import { LayoutHerodias } from '@lux-ds/content-layouts';
import Typography from '@lux-ds/typography';
import Download from '@mui/icons-material/Download';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  TExteriorDamageLocationCounts,
  THardwareDamageLocationCounts,
  TInteriorDamageLocationCounts
} from 'models/warehouse';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import {
  exteriorDamageLocationKeys,
  hardwareDamageLocationKeys,
  interiorDamageLocationKeys
} from 'pages/WarehouseList/constants';
import { IDamage, IQualityCheckFormInputs } from 'pages/WarehouseList/schemas/qualityCheckSchema';
import { getSumOfDamagesCount } from 'pages/WarehouseList/utils/getCountHelper';
import BaseConfiguration from 'setup/BaseConfiguration';

import DamagePhotos from '../DamagePhotos/DamagePhotos';

import uploadDamagePhotosStyles from './uploadDamagePhotos.styles';

interface IProps {
  exteriorCount: TExteriorDamageLocationCounts;
  form: UseFormMethods<IQualityCheckFormInputs>;
  hardwareCount: THardwareDamageLocationCounts;
  interiorCount: TInteriorDamageLocationCounts;
}

const UploadDamagePhotos: React.FC<IProps> = ({ exteriorCount, form, hardwareCount, interiorCount }) => {
  const { t } = useTranslation(['common', 'warehouse']);
  const { isBulkDownloading, onBulkDownloadPhotos } = useWarehouseItemDetailsContext();
  const classes = uploadDamagePhotosStyles();

  const allDamagesCount = {
    ...exteriorCount,
    ...interiorCount,
    ...hardwareCount
  };

  const uploadedPhotos = useMemo(() => {
    const exteriorInteriorDamages = form.getValues([
      'back',
      'bottom',
      'corners',
      'front',
      'handleOrStrap',
      'interiorPocket',
      'lining',
      'pocket',
      'side',
      'top'
    ]);
    const hardwareDamages = form.getValues(['closure', 'embellishment', 'key', 'practicalAttachment', 'zipper']);

    const exteriorInteriorPhotos = Object.values(exteriorInteriorDamages || {}).reduce(
      (urls, damage) => [
        ...urls,
        ...Object.values(damage || {})
          .map((value: IDamage) =>
            Object.values(value)
              .filter(d => d.photoUrl)
              .map(d => d.photoUrl as string)
          )
          .flat()
      ],
      [] as string[]
    );

    const hardwarePhotos = Object.values(hardwareDamages || {}).reduce(
      (urls, damage) => [
        ...urls,
        ...(Object.values(damage || {})
          .filter(d => d.photoUrl)
          .map(d => d.photoUrl) as string[])
      ],
      [] as string[]
    );

    return [...exteriorInteriorPhotos, ...hardwarePhotos];
  }, [
    form.watch([
      'back',
      'bottom',
      'closure',
      'corners',
      'embellishment',
      'front',
      'handleOrStrap',
      'interiorPocket',
      'key',
      'lining',
      'pocket',
      'practicalAttachment',
      'side',
      'top',
      'zipper'
    ])
  ]);

  const handleBulkDownload = useCallback(
    () => onBulkDownloadPhotos(uploadedPhotos),
    [onBulkDownloadPhotos, uploadedPhotos]
  );

  return (
    <div className={classes.damagePhotosContainer}>
      <BaseConfiguration>
        <LayoutHerodias sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" weight="semibold">
            {t('warehouse:qualityCheckStation.recognitionPhotos')} ({getSumOfDamagesCount(allDamagesCount, 'hasPhotos')}
            )
          </Typography>
          {!!uploadedPhotos.length && (
            <LoadingButton
              loading={isBulkDownloading}
              onClick={handleBulkDownload}
              startIcon={<Download />}
              variant="outlined"
            >
              {t('common:downloadAll')}
            </LoadingButton>
          )}
        </LayoutHerodias>
      </BaseConfiguration>
      <div className={classes.damagePhotoList}>
        {exteriorDamageLocationKeys.map(
          key =>
            !!exteriorCount[key].isChecked && <DamagePhotos key={key} form={form} name={key} stained otherDamages />
        )}
        {interiorDamageLocationKeys.map(
          key =>
            !!interiorCount[key].isChecked && <DamagePhotos key={key} form={form} name={key} stained otherDamages />
        )}
        {hardwareDamageLocationKeys.map(
          key => !!hardwareCount[key].isChecked && <DamagePhotos key={key} form={form} name={key} hardware />
        )}
      </div>
    </div>
  );
};

export default UploadDamagePhotos;
