import React from 'react';

import { Grid } from '@luxclusif/material';
import { useFormContext } from 'react-hook-form';

import Input from 'components/Input/Input/Input';

import supplierDetailsStyles from '../../supplierDetails.styles';

const ExtraDetails = () => {
  const classes = supplierDetailsStyles();

  const { control, errors } = useFormContext();

  return (
    <div>
      <h1 className={classes.title}>Extra Details</h1>
      <Grid item md={12}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Input
              name="internalNotes"
              control={control}
              errors={errors}
              label="Internal Comments/Notes"
              type="text"
              className={classes.input}
              variant="outlined"
              fullWidth={true}
              errorText="Required"
              rows={4}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExtraDetails;
