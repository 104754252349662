import { createStyles, makeStyles } from '@luxclusif/material';

const photoAuthenticationStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '45px',
      marginBottom: '32px'
    },
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '45px'
    }
  })
);

export default photoAuthenticationStyles;
