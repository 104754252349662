import React, { useCallback, useState } from 'react';

import { IconButton, MaterialIcons, MobileStepper } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import carouselStyles from './carousel.styles';

interface IImages {
  imgPath: string;
  label?: string;
}
interface IProps {
  className?: string;
  images: IImages[];
}

const Carousel: React.FC<IProps> = ({ className, images }) => {
  const classes = carouselStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, []);

  return (
    <div className={`${classes.root} ${className || ''}`}>
      {images.length > 0 ? (
        <>
          <div>
            <div className={classes.imgCarousel}>
              <IconButton onClick={handleBack} disabled={activeStep === 0} className={classes.buttons}>
                <MaterialIcons.ArrowBack className={classes.icons} />
              </IconButton>
              {images.map((step, index) => (
                <div key={step.imgPath}>
                  <InView triggerOnce>
                    {({ inView, ref }) => (
                      <img
                        ref={ref}
                        className={Math.abs(activeStep - index) === 0 ? classes.img : classes.imgHidden}
                        src={inView ? step.imgPath : ''}
                        alt={step.label}
                      />
                    )}
                  </InView>
                </div>
              ))}
              <IconButton onClick={handleNext} disabled={activeStep === maxSteps - 1} className={classes.buttons}>
                <MaterialIcons.ArrowForward className={classes.icons} />
              </IconButton>
            </div>

            <MobileStepper
              className={classes.mobileStepper}
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              backButton={null}
              nextButton={null}
            />
          </div>
        </>
      ) : (
        t('carousel.noImages')
      )}
    </div>
  );
};

export default Carousel;
