import { createStyles, makeStyles } from '@luxclusif/material';

const uploadDamagePhotosStyles = makeStyles(() =>
  createStyles({
    damagePhotosContainer: {
      marginBottom: '48px',
      marginTop: '48px'
    },
    damagePhotoList: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '25px',
      marginTop: '32px'
    }
  })
);

export default uploadDamagePhotosStyles;
