import { GridValueFormatterParams } from '@lux-ds/data-grid';
import moment from 'moment';

export const dateGridValueFormatter = (valueFormatter: GridValueFormatterParams) =>
  moment(valueFormatter.value as string).format('MM/DD/YYYY');

export const currencyGridValueFormatter = (valueFormatter: GridValueFormatterParams, currency = '$') =>
  `${currency} ${(valueFormatter.value as number).toFixed(2).toLocaleString()}`;

export const stringKeyGridValueFormatter = (valueFormatter: GridValueFormatterParams, key = '') =>
  valueFormatter.value[key] || '';
