import React, { useState } from 'react';

import { IconButton, ListItemIcon, ListItemText, MaterialIcons, Menu, MenuItem } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import uploadImagesStyles from './uploadImages.styles';

export interface IDisabledUploadedImages {
  delete?: boolean;
  download?: boolean;
  zoom?: boolean;
}

interface IProps {
  disabled?: IDisabledUploadedImages;
  id: string;
  label: React.ReactNode;
  onDelete?: (url: string) => void;
  onZoom: (url: string) => void;
  url: string;
}

const UploadedImage: React.FC<IProps> = ({ disabled, id, label, onDelete, onZoom, url }) => {
  const { t } = useTranslation('common');
  const classes = uploadImagesStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => setAnchorEl(null);

  return (
    <div key={url} className={classes.imageListContainer}>
      <div className={classes.imageContainer}>
        <div className={classes.headerContainer}>
          <InView triggerOnce>
            {({ inView, ref }) => <img ref={ref} className={classes.headerImage} src={inView ? url : ''} />}
          </InView>
          <div className={classes.headerActions}>
            <IconButton className={classes.actionButton} onClick={() => onZoom(url)}>
              <MaterialIcons.ZoomIn />
            </IconButton>
            <IconButton className={classes.actionButton} onClick={onOpen}>
              <MaterialIcons.MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={onClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {onDelete && (
                <MenuItem className={classes.otherActionItem} disabled={disabled?.delete} onClick={() => onDelete(id)}>
                  <ListItemIcon>
                    <MaterialIcons.Delete />
                  </ListItemIcon>
                  <ListItemText>{t('delete')}</ListItemText>
                </MenuItem>
              )}
              <MenuItem
                className={classes.otherActionItem}
                disabled={disabled?.download}
                onClick={() => window.open(url)}
              >
                <ListItemIcon>
                  <MaterialIcons.Download />
                </ListItemIcon>
                <ListItemText>{t('uploadImages.download')}</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className={classes.imageLabel}>{label}</div>
      </div>
    </div>
  );
};

export default UploadedImage;
