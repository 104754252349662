import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const defaultLanguage = 'en';

export const defaultNS = 'common';

const namespaces = ['authentication', 'common', 'itemIssues', 'purchaseOrders', 'supplier', 'warehouse', 'welcome'];

const supportedLanguages = ['cn', 'en', 'ja'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: defaultNS,
    detection: {
      lookupLocalStorage: 'language'
    },
    fallbackLng: defaultLanguage,
    ns: namespaces,
    react: {
      useSuspense: false
    },
    supportedLngs: supportedLanguages
  });

export default i18n;
