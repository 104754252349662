import React from 'react';

import { Grid, MenuItem } from '@luxclusif/material';
import { UseFormMethods } from 'react-hook-form';

import SelectInput from 'components/Input/Select/Select';
import { IPaymentMethods, IPaymentTerms } from 'models/supplier';

import supplierDetailsStyles from '../../supplierDetails.styles';

interface IProps {
  businessModelForm: UseFormMethods<Record<string, any>>;
  paymentMethods: IPaymentMethods[];
  paymentTerms: IPaymentTerms[];
}

const Financial: React.FC<IProps> = ({ businessModelForm, paymentMethods, paymentTerms }) => {
  const classes = supplierDetailsStyles();

  const { control, errors, setValue } = businessModelForm;

  return (
    <div>
      <h1 className={classes.title}>Financial Conditons</h1>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <SelectInput
            label="Financial Payment Terms*"
            className={classes.input}
            fullWidth={true}
            name="financialPayment"
            variant="outlined"
            control={control}
            errors={errors}
            setValue={setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            {paymentTerms.map((row: IPaymentTerms) => (
              <MenuItem key={row.paymentTermId} value={row.paymentTermId}>
                {row.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Grid item md={3}>
          <SelectInput
            label="Payment method*"
            className={classes.input}
            fullWidth={true}
            name="paymentMethod"
            variant="outlined"
            control={control}
            errors={errors}
            setValue={setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            {paymentMethods.map((row: IPaymentMethods) => (
              <MenuItem key={row.paymentMethodId} value={row.paymentMethodId}>
                {row.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
      </Grid>
      <hr className={classes.line} />
    </div>
  );
};

export default Financial;
