import { Subject } from 'rxjs';

import axios from '../utils/axios';

class CurrencyService {
  public static $currencyRowUpdated = new Subject<any>();

  public async getCurrency() {
    const url = `${process.env.REACT_APP_API_URL}Currencies`;

    return await axios.get(url).then(response => response.data);
  }
}

const currencyService = new CurrencyService();

export default currencyService;
