import React, { useEffect, useState } from 'react';

import { MaterialIcons, TextField, LuxButton, FormControlLabel, Checkbox } from '@luxclusif/material';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { TMultipleSelectState, IMultipleSelectProps } from 'models/component/luxDataTableInterface';

import multipleSelectStyles from './multipleSelect.styles';

const MultipleSelect: React.FC<IMultipleSelectProps> = ({ currentFilterId, id, label, onChange, onOpen, options }) => {
  const classes = multipleSelectStyles();
  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchOption, setSearchOption] = useState('');

  const [selectedOptions, setSelectedOptions] = useState<TMultipleSelectState>([]);

  const [finalSelectedLabel, setFinalSelectedLabel] = useState('');
  const hasFinalSelected = !!finalSelectedLabel.length;

  useEffect(() => {
    if (currentFilterId !== id) {
      setShowDropdown(false);
      setSearchOption('');
    }
  }, [currentFilterId, id]);

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);

    if (showDropdown) {
      setSearchOption('');
    }

    onOpen(showDropdown ? null : id);
  };

  const onChangeSearchText = debounce(({ target }) => {
    setSearchOption(target.value);
  }, 500);

  const filteredOptions = searchOption.length
    ? options.filter(option => {
        const isIncludeText = option.label.toLocaleLowerCase().includes(searchOption.toLocaleLowerCase());

        return isIncludeText;
      })
    : [...options];

  const onSelect = (name: string) => {
    const isIncluded = selectedOptions.includes(name);
    let newSelectedOptions = [...selectedOptions];

    if (isIncluded) {
      newSelectedOptions = newSelectedOptions.filter(selectedOption => selectedOption !== name);
    } else {
      newSelectedOptions = [...newSelectedOptions, name];
    }

    setSelectedOptions(newSelectedOptions);
  };

  const onResetSelection = () => {
    setSelectedOptions([]);
  };

  const onQuickReset = (e: React.SyntheticEvent<SVGSVGElement>) => {
    e.stopPropagation();

    setFinalSelectedLabel('');
    setSelectedOptions([]);

    onChange(id, []);
  };

  const applySelectedFilters = () => {
    if (selectedOptions.length) {
      const newFinalSelectedLabel = options.find(({ value }) => value === selectedOptions[0])?.label || '';
      const othersCount = selectedOptions.length - 1;
      const othersCountLabel = othersCount ? `+${othersCount}` : '';

      setFinalSelectedLabel(`${newFinalSelectedLabel} ${othersCountLabel}`);
    } else {
      setFinalSelectedLabel('');
    }

    onChange(id, selectedOptions);

    toggleDropdown();
  };

  let DropdownTogglerIcon = <MaterialIcons.ArrowDropDown />;

  if (hasFinalSelected || showDropdown) {
    DropdownTogglerIcon = showDropdown ? <MaterialIcons.ArrowDropUp /> : <MaterialIcons.Close onClick={onQuickReset} />;
  }

  return (
    <div className={classes.container}>
      <LuxButton
        variant="contained"
        luxColor={hasFinalSelected ? 'secondary' : 'white'}
        className={`${classes.dropDownToggler} ${
          classes[hasFinalSelected ? 'dropDownTogglerActive' : 'dropDownTogglerInactive']
        }`}
        onClick={toggleDropdown}
      >
        {hasFinalSelected ? finalSelectedLabel : label}

        {DropdownTogglerIcon}
      </LuxButton>
      {showDropdown && (
        <div className={classes.dropDownContainer}>
          <h3 className={classes.dropDownTitle}>{label}</h3>
          {options.length > 5 && (
            <TextField
              className={classes.dropDownSearchInput}
              placeholder={t('dataTable.search')}
              variant="outlined"
              onChange={onChangeSearchText}
            />
          )}
          <div className={classes.dropDownOptions}>
            {/* Todo: Convert this to virtualized list if slow performance occurs */}
            {filteredOptions.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                className={classes.option}
                label={label}
                onChange={() => onSelect(value)}
                control={<Checkbox name={value} checked={selectedOptions.includes(value)} />}
              />
            ))}
          </div>
          <div className={classes.dropDownButtons}>
            <LuxButton onClick={onResetSelection}>{t('multipleSelect.clear')}</LuxButton>
            <LuxButton variant="contained" luxColor="secondary" onClick={applySelectedFilters}>
              {t('multipleSelect.apply')}
            </LuxButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleSelect;
