import React, { useEffect, useState } from 'react';

import { GetWarehouseQueryResponse } from '@inbound/api';
import { CircularProgress, MenuItem, SelectProps, TextField } from '@luxclusif/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  EPurchaseOrderAllStatus,
  TGetAllCouriers,
  TGetAllCurrencies,
  TGetAllFreightTerms,
  TGetAllPaymentMethods,
  TGetAllPaymentTerms,
  TGetWarehouseOptions
} from 'models/purchaseOrders';
import { IPurchaseInfo } from 'pages/PurchaseOrder/schema';
import productsService from 'services/Products.service';
import purchaseOrdersService from 'services/PurchaseOrders.service';

import purchaseOrderStyles from 'pages/PurchaseOrder/purchaseOrder.styles';

import orderInformationStyles from '../../orderInformation.styles';

interface IProps {
  couriers: TGetAllCouriers;
  currencies: TGetAllCurrencies;
  isLoadingOptions: boolean;
  status?: EPurchaseOrderAllStatus;
}

const FinancialAndOperationSection: React.FC<IProps> = ({ couriers, currencies, isLoadingOptions, status }) => {
  const form = useFormContext<IPurchaseInfo>();
  const { errors, isDirty } = form.formState;

  const watchSupplierId = useWatch<string>({ defaultValue: '', name: 'supplierId' });
  const watchBusinessModelId = useWatch<number>({ name: 'businessModelId' });

  const { t } = useTranslation('purchaseOrders');

  const commonClasses = purchaseOrderStyles();
  const orderInformationClasses = orderInformationStyles();

  const [loadingOptions, setLoadingOptions] = useState(false);
  const [paymentTermOptions, setPaymentTermOptions] = useState<TGetAllPaymentTerms>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<TGetAllPaymentMethods>([]);
  const [warehouseOptions, setWarehouseOptions] = useState<TGetWarehouseOptions>([]);
  const [freightTermOptions, setFreightTermOptions] = useState<TGetAllFreightTerms>([]);

  const onSetDefaultValues = async () => {
    setLoadingOptions(true);

    const getSupplierPurchaseInfo = await purchaseOrdersService.getSupplierPurchaseInfo(
      watchSupplierId,
      watchBusinessModelId
    );

    form.setValue('courierId', getSupplierPurchaseInfo.courierId);
    form.setValue('currencyId', getSupplierPurchaseInfo.billingCurrencyId);
    form.setValue('freightTermId', getSupplierPurchaseInfo.freightTerm);
    form.setValue('paymentTermId', getSupplierPurchaseInfo.paymentTermsId);
    form.setValue('paymentMethodId', getSupplierPurchaseInfo.paymentMethod);
    form.setValue('receptionWarehouseId', getSupplierPurchaseInfo.receptionWarehouseId);

    setLoadingOptions(false);
  };

  const loadAllOptions = async () => {
    const getOptions = [
      purchaseOrdersService.getAllPaymentTermOptions(),
      purchaseOrdersService.getAllPaymentMethodOptions(),
      productsService.getWarehouses(),
      purchaseOrdersService.getAllFreightTermOptions()
    ];

    setLoadingOptions(true);

    const [getAllPaymentTermOptions, getAllPaymentMethodOptions, getAllWarehouseOptions, getAllFreightTermOptions] =
      await Promise.allSettled(getOptions);

    if (getAllPaymentTermOptions.status === 'fulfilled') {
      setPaymentTermOptions(getAllPaymentTermOptions.value as TGetAllPaymentTerms);
    } else {
      setPaymentTermOptions([]);
    }

    if (getAllPaymentMethodOptions.status === 'fulfilled') {
      setPaymentMethodOptions(getAllPaymentMethodOptions.value as TGetAllPaymentMethods);
    } else {
      setPaymentMethodOptions([]);
    }

    if (getAllWarehouseOptions.status === 'fulfilled') {
      const warehouseId = form.getValues('receptionWarehouseId');

      setWarehouseOptions(
        (getAllWarehouseOptions.value as GetWarehouseQueryResponse[]).reduce(
          (result: TGetWarehouseOptions, { id, isActive, name }) => [
            ...result,
            ...(isActive || id === warehouseId
              ? [
                  {
                    id: id as string,
                    name: name as string
                  }
                ]
              : [])
          ],
          []
        ) as TGetWarehouseOptions
      );
    } else {
      setWarehouseOptions([]);
    }

    if (getAllFreightTermOptions.status === 'fulfilled') {
      setFreightTermOptions(getAllFreightTermOptions.value as TGetAllFreightTerms);
    } else {
      setFreightTermOptions([]);
    }

    setLoadingOptions(false);
  };

  useEffect(() => {
    loadAllOptions();
  }, []);

  useEffect(() => {
    if (isDirty && watchBusinessModelId) {
      onSetDefaultValues();
    }
  }, [watchBusinessModelId]);

  useEffect(() => {
    if (isDirty) {
      const { courierId, currencyId, freightTermId, paymentMethodId, paymentTermId, receptionWarehouseId } =
        form.getValues();
      const shouldReset =
        !!courierId ||
        !!currencyId ||
        !!freightTermId ||
        !!paymentMethodId ||
        !!paymentTermId ||
        !!receptionWarehouseId;

      if (shouldReset) {
        form.setValue('courierId', '');
        form.setValue('currencyId', '');
        form.setValue('freightTermId', '');
        form.setValue('paymentMethodId', '');
        form.setValue('paymentTermId', '');
        form.setValue('receptionWarehouseId', '');
      }
    }
  }, [watchSupplierId]);

  const isDisableAllOption = !watchBusinessModelId || loadingOptions || isLoadingOptions;
  const isDisableCurrency = isDisableAllOption || !!(status && status !== EPurchaseOrderAllStatus.Draft);

  const otherOptionsProps: Partial<SelectProps> = {
    IconComponent: loadingOptions || isLoadingOptions ? props => <CircularProgress size={16} {...props} /> : undefined,
    MenuProps: {
      PaperProps: {
        className: orderInformationClasses.selectMenu
      },
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom'
      }
    },
    disabled: isDisableAllOption
  };

  return (
    <>
      <h4 className={commonClasses.formSectionTitle}>{t('poInformation.financialAndOperating')}</h4>
      <div className={orderInformationClasses.inputsContainer}>
        <Controller
          control={form.control}
          name="currencyId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.currencyId}
              inputRef={ref}
              label={`${t('poInformation.billingCurrency')} *`}
              select
              SelectProps={{
                ...otherOptionsProps,
                disabled: isDisableCurrency
              }}
            >
              {currencies.map(({ currencyId, isoCode, name }) => (
                <MenuItem key={currencyId} value={currencyId}>
                  {`${isoCode} · ${name}`}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          control={form.control}
          name="paymentTermId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.paymentTermId}
              inputRef={ref}
              label={`${t('poInformation.paymentTerms')} *`}
              select
              SelectProps={otherOptionsProps}
            >
              {paymentTermOptions.map(({ name, paymentTermId }) => (
                <MenuItem key={paymentTermId} value={paymentTermId}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          control={form.control}
          name="paymentMethodId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.paymentMethodId}
              inputRef={ref}
              label={`${t('poInformation.paymentMethod')} *`}
              select
              SelectProps={otherOptionsProps}
            >
              {paymentMethodOptions.map(({ name, paymentMethodEnum }) => (
                <MenuItem key={paymentMethodEnum} value={paymentMethodEnum}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          control={form.control}
          name="receptionWarehouseId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.receptionWarehouseId}
              inputRef={ref}
              label={`${t('poInformation.receptionWarehouse')} *`}
              select
              SelectProps={otherOptionsProps}
            >
              {warehouseOptions.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          control={form.control}
          name="courierId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.courierId}
              inputRef={ref}
              label={`${t('carrier')} *`}
              select
              SelectProps={otherOptionsProps}
            >
              {couriers.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          control={form.control}
          name="freightTermId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.freightTermId}
              inputRef={ref}
              label={`${t('poInformation.freightTerms')} *`}
              select
              SelectProps={otherOptionsProps}
            >
              {freightTermOptions.map(({ freightTermEnum, name }) => (
                <MenuItem key={freightTermEnum} value={freightTermEnum}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div>
    </>
  );
};

export default FinancialAndOperationSection;
