import React from 'react';

import { Chip, MaterialIcons } from '@luxclusif/material';

import checkboxChipStyles from './checkboxChip.styles';

interface IProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  label: React.ReactNode;
  onChange?: (value: boolean) => void;
  value: boolean;
}

const CheckBoxChip: React.FC<IProps> = ({ className, disabled, icon, label, onChange, value }) => {
  const classes = checkboxChipStyles();
  const LeftIcon = icon && (MaterialIcons as any)[icon];

  return (
    <Chip
      aria-checked={value}
      aria-disabled={disabled}
      className={`${classes.container}${className ? ` ${className}` : ''}`}
      clickable={!disabled || !!onChange}
      disabled={disabled}
      icon={LeftIcon ? <LeftIcon /> : undefined}
      label={label}
      onClick={disabled || !onChange ? undefined : () => onChange(!value)}
    />
  );
};

export default CheckBoxChip;
