import React, { useEffect } from 'react';

import { LuxErrorPane } from '@luxclusif/material';
import { ErrorStatus } from '@luxclusif/material/dist/components/LuxErrorPane/interfaces';
import { StatusCodes } from 'http-status-codes';
import { useNavigate, useLocation } from 'react-router-dom';

import { LUX_CORE_APP_ROUTE_CHANGE } from 'constants/Events';

import EInboundRoute from '../../navigation/models/EInboundRoute';

import errorStyles from './error.styles';

interface ILocationProps {
  status?: ErrorStatus;
}

const Error: React.FC = () => {
  const classes = errorStyles();
  const navigate = useNavigate();
  const { state } = useLocation() as { state: ILocationProps };

  useEffect(() => {
    document.dispatchEvent(new CustomEvent(LUX_CORE_APP_ROUTE_CHANGE, { detail: { path: EInboundRoute.Error } }));
  }, []);

  const handleOnBack = () => {
    document.dispatchEvent(new CustomEvent(LUX_CORE_APP_ROUTE_CHANGE, { detail: { path: EInboundRoute.Root } }));
    navigate(EInboundRoute.Root);
  };

  const status = state?.status || StatusCodes.INTERNAL_SERVER_ERROR;

  return (
    <div className={classes.container}>
      <LuxErrorPane onBack={handleOnBack} status={status} />
    </div>
  );
};

export default Error;
