import {
  DamageCountKeys,
  DamageLocationKeys,
  InteriorExteriorDamageKeys,
  TDamageCounts,
  TDamageLocationCounts
} from 'models/warehouse';

import { IDamage, IInteriorExteriorDamages, TGenericDamages, THardwareDamages } from '../schemas/qualityCheckSchema';

const getCount = (object: IDamage[], key: keyof IDamage) =>
  object.reduce((sum: number, item) => (item[key] ? ++sum : sum), 0);

export const getAllDamagesCount = (
  object: IInteriorExteriorDamages | TGenericDamages | THardwareDamages,
  isHardware = false,
  isGeneric = false
): TDamageCounts =>
  isHardware || isGeneric
    ? {
        hasPhotos: getCount(Object.values(object), 'photoUrl'),
        isChecked: getCount(Object.values(object), 'isChecked')
      }
    : (Object.keys(object) as InteriorExteriorDamageKeys[]).reduce(
        ({ hasPhotos, isChecked }, key) => ({
          hasPhotos: getCount(Object.values((object as IInteriorExteriorDamages)[key]), 'photoUrl') + hasPhotos,
          isChecked: getCount(Object.values((object as IInteriorExteriorDamages)[key]), 'isChecked') + isChecked
        }),
        {
          hasPhotos: 0,
          isChecked: 0
        }
      );

export const getSumOfDamagesCount = (object: TDamageLocationCounts, key: DamageCountKeys) =>
  (Object.keys(object) as DamageLocationKeys[]).reduce(
    (sum: number, firstKey) => sum + object[firstKey as keyof TDamageLocationCounts][key],
    0
  );
