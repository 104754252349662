import {
  ColorGetModel2,
  EItemExtraFieldsKey,
  GetProcessCheckinQueryResponse,
  GetProcessQueryResponse,
  InboundFlowGetModel2,
  ItemInclusionGetModel,
  UpdateProcessCheckinCommand
} from '@inbound/api';
import * as yup from 'yup';

import { EInboundFlow, EInclusion } from 'models/warehouse';

import checkIsRegularFlow from '../utils/checkIsRegularFlow';
import { convertToMetric } from '../utils/measurementConversion';
import { countryDefaultValue, formatMeasures, IMeasure } from './checkInSchema';

export type IItemDetails = GetProcessQueryResponse;

export interface IItemNewCheckInDetailsSchema {
  authenticityCard: boolean;
  box: boolean;
  carat: number;
  charm: boolean;
  color: Readonly<ColorGetModel2>;
  country: string | undefined;
  description: Readonly<string>;
  dustBag: boolean;
  hasSecurityTag: boolean | undefined;
  hasSerialNumber: boolean;
  initialInclusions: Readonly<ItemInclusionGetModel[]>;
  key: boolean;
  material: Readonly<string>;
  measures: IMeasure[];
  mirror: boolean;
  nameTag: boolean;
  none: boolean;
  padlock: boolean;
  pouch: boolean;
  securityTagRequired: boolean;
  serialNumber: string | undefined;
  shoulderStrap: boolean;
  size: Readonly<string>;
  warehouse: Readonly<string>;
}

export const getInclusion = (
  inclusionId: EInclusion,
  inclusions?: ItemInclusionGetModel[],
  inboundFlow?: Readonly<InboundFlowGetModel2>
) =>
  inclusions?.some(({ id, isMissing }) =>
    checkIsRegularFlow(inboundFlow, [EInboundFlow.VLG]) ? id === inclusionId : id === inclusionId && !isMissing
  ) || false;

// TODO: Use this function to get initial inclusions in OldCheckInSchema
export const getInitialInclusions = (inclusions?: ItemInclusionGetModel[]): ItemInclusionGetModel[] => {
  const hasNoneInclusion = inclusions?.find(inclusion => inclusion.id === EInclusion.None);

  if (!hasNoneInclusion) {
    return [
      {
        id: EInclusion.None,
        isMissing: false
      },
      ...(inclusions || [])
    ];
  }

  return inclusions as ItemInclusionGetModel[];
};

export const getDefaultItemNewCheckInValues = (
  values: GetProcessCheckinQueryResponse,
  itemDetails: IItemDetails
): IItemNewCheckInDetailsSchema => ({
  authenticityCard: getInclusion(EInclusion.AuthenticityCard, values.inclusions, itemDetails.inboundFlow),
  box: getInclusion(EInclusion.Box, values.inclusions, itemDetails.inboundFlow),
  carat: Number(values.carat) || 0,
  charm: getInclusion(EInclusion.Charm, values.inclusions, itemDetails.inboundFlow),
  color: values.colors?.[0] || {
    englishName: '',
    id: '',
    isMainColor: false,
    name: ''
  },
  country: values.madeInCountryId || countryDefaultValue,
  description: values.description || '',
  dustBag: getInclusion(EInclusion.DustBag, values.inclusions, itemDetails.inboundFlow),
  hasSecurityTag: values.hasSecurityTag,
  hasSerialNumber: !!values.serialNumber,
  initialInclusions: getInitialInclusions(values.inclusions),
  key: getInclusion(EInclusion.Key, values.inclusions, itemDetails.inboundFlow),
  material: values.materials?.[0]?.name || '',
  measures: formatMeasures(values.measures || []),
  mirror: getInclusion(EInclusion.Mirror, values.inclusions, itemDetails.inboundFlow),
  nameTag: getInclusion(EInclusion.NameTag, values.inclusions, itemDetails.inboundFlow),
  none: getInclusion(EInclusion.None, values.inclusions, itemDetails.inboundFlow),
  padlock: getInclusion(EInclusion.Padlock, values.inclusions, itemDetails.inboundFlow),
  pouch: getInclusion(EInclusion.Pouch, values.inclusions, itemDetails.inboundFlow),
  securityTagRequired: values.securityTagRequired || false,
  serialNumber: values.serialNumber,
  shoulderStrap: getInclusion(EInclusion.ShoulderStrap, values.inclusions, itemDetails.inboundFlow),
  size: values.size || '',
  warehouse: values.warehouse || ''
});

export const inclusionSchema = yup.boolean().when('none', {
  is: false,
  then: yup.boolean().required()
});

export const newCheckInSchema = yup.object().shape({
  authenticityCard: inclusionSchema,
  box: inclusionSchema,
  carat: yup.number().notRequired(),
  charm: inclusionSchema,
  country: yup.string().nullable().notRequired(),
  dustBag: inclusionSchema,
  hasSecurityTag: yup.boolean().nullable().when('securityTagRequired', {
    is: true,
    then: yup.boolean().required()
  }),
  hasSerialNumber: yup.boolean().required(),
  key: inclusionSchema,
  measures: yup.array().of(
    yup.object().shape({
      isRequired: yup.boolean().required(),
      key: yup.number().required(),
      labelKey: yup.string().required(),
      value: yup.number().when('isRequired', {
        is: true,
        then: yup.number().positive().required({ message: 'warehouse:checkinStation.measureMessage' })
      })
    })
  ),
  mirror: inclusionSchema,
  nameTag: inclusionSchema,
  none: yup.boolean().notRequired(),
  padlock: inclusionSchema,
  pouch: inclusionSchema,
  securityTagRequired: yup.boolean().required(),
  serialNumber: yup
    .string()
    .nullable()
    .when(['hasSerialNumber', '$inboundFlow'], {
      is: (hasSerialNumber: boolean, inboundFlow: InboundFlowGetModel2) =>
        hasSerialNumber && checkIsRegularFlow(inboundFlow),
      then: yup.string().required()
    }),
  shoulderStrap: inclusionSchema
});

export const formatInclusions = (values: IItemNewCheckInDetailsSchema, inboundFlow?: InboundFlowGetModel2) => {
  const inclusions = [
    { id: EInclusion.None, value: values.none },
    { id: EInclusion.DustBag, value: values.dustBag },
    { id: EInclusion.Box, value: values.box },
    { id: EInclusion.AuthenticityCard, value: values.authenticityCard },
    { id: EInclusion.Padlock, value: values.padlock },
    { id: EInclusion.Key, value: values.key },
    { id: EInclusion.Pouch, value: values.pouch },
    { id: EInclusion.NameTag, value: values.nameTag },
    { id: EInclusion.Charm, value: values.charm },
    { id: EInclusion.Mirror, value: values.mirror },
    { id: EInclusion.ShoulderStrap, value: values.shoulderStrap }
  ];

  return checkIsRegularFlow(inboundFlow, [EInboundFlow.VLG])
    ? inclusions.reduce(
        (result: { id: EInclusion; isMissing: boolean }[], { id, value }) =>
          value ? [...result, { id, isMissing: false }] : result,
        []
      )
    : values.initialInclusions.map(initialInclusion => {
        const newInclusion = inclusions.find(inclusionForm => inclusionForm.id === initialInclusion.id);

        return {
          id: initialInclusion.id,
          isMissing: !newInclusion?.value ? true : false
        };
      });
};

export const formatExtraFieldsValue = (measures: IMeasure[]) =>
  measures
    .filter(e => e.value)
    .map(({ key, value }) => {
      let formattedValue = value;

      if (
        [
          EItemExtraFieldsKey.Width,
          EItemExtraFieldsKey.Length,
          EItemExtraFieldsKey.Depth,
          EItemExtraFieldsKey.ShoulderDrop,
          EItemExtraFieldsKey.HandleDrop,
          EItemExtraFieldsKey.Height
        ].includes(key)
      ) {
        formattedValue = convertToMetric(value);
      }

      return { key, value: formattedValue.toFixed(2) };
    });

export const formatNewCheckInValuesRequest = (
  values: IItemNewCheckInDetailsSchema,
  itemDetails: IItemDetails,
  initialStationDateTimeUtc: Date,
  isSaveAndGoNextStation?: boolean
): UpdateProcessCheckinCommand => ({
  carat: values.carat.toFixed(2),
  extraFields: formatExtraFieldsValue(values.measures),
  finalStationDateTimeUtc: new Date(),
  hasSecurityTag: values.hasSecurityTag,
  inclusions: formatInclusions(values, itemDetails.inboundFlow),
  initialStationDateTimeUtc,
  isSaveAndGoNextStation,
  itemProcessId: itemDetails.itemProcessId,
  madeInCountryId: !values.country || values.country === 'none' ? undefined : values.country,
  serialNumber: values.serialNumber
});
