const SUPPLIER_CONSTANTS = {
  ACTIVE_STATUS_NAME: 'Active',
  BASE64IMAGE: 'data:image/jpeg;base64,',
  BLOB_RELATIVE_PATH: '/tempfiles/',
  CSV_EXTENSION: 'csv',
  DEFAULT_GUID_VALUE: '00000000-0000-0000-0000-000000000000',
  DRAFT_STATUS_ID: 1,
  DRAFT_STATUS_NAME: 'Draft',
  EMPTY_STRING: '',
  IMAGECONDITIONFORBASE64: 'data:image',
  INACTIVE_STATUS_NAME: 'Inactive',
  JSON_SERIALIZATION_STRING: 'fake value - Json Serialization',
  PDF_CONTENT_TYPE: 'application/pdf',
  PDF_EXTENSION: 'pdf',
  STILL_IN_DRAFT: 'Still in Draft',
  STRING_ZERO_DRAFT: '0',
  SUPPLIER_TABS: [
    { label: 'Supplier', value: 0 },
    { label: 'Supplier Profile', value: 1 }
  ],
  WORKING_DAYS: 'Work',
  YES: 'Yes'
};

export default SUPPLIER_CONSTANTS;
