import React, { createContext, useCallback, useEffect, useState } from 'react';

import {
  GridSortModel,
  IDateRangeValue,
  IFilterValue,
  IMultipleSelectOptionIdName,
  IMultipleSelectOptionKeyValue,
  IValueRangeValue
} from '@lux-ds/data-grid';
import { useNotification } from '@lux-ds/notification';
import { useTranslation } from 'react-i18next';

import useApi from 'hooks/useApi';
import { IStatusFilterOption } from 'models/common';
import {
  EPurchaseOrderAllStatus,
  IPurchaseOrder,
  IPurchaseOrderFilters,
  TPurchaseOrderStatus
} from 'models/purchaseOrders';
import buyersService from 'services/Buyers.service';
import productsService from 'services/Products.service';
import purchaseOrdersService from 'services/PurchaseOrders.service';
import camelize from 'utils/camelize';

type IFilterOptions = {
  businessModel: IMultipleSelectOptionKeyValue[];
  buyers: IMultipleSelectOptionIdName[];
  status: IMultipleSelectOptionIdName[];
  supplier: IMultipleSelectOptionIdName[];
  warehouse: IMultipleSelectOptionIdName[];
};

interface IPurchaseOrdersContext {
  downloadTemplateLoading: boolean;
  filterOptions: IFilterOptions;
  filterStatus: IStatusFilterOption[];
  filters: IFilterValue;
  handleUploadSupplierItems: (file: File) => void;
  isLoading: boolean;
  page: number;
  pageSize: number;
  purchaseOrders: IPurchaseOrder[];
  quickFilter: EPurchaseOrderAllStatus;
  rowCount: number;
  search: string;
  setFilters: (filters: IFilterValue) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setQuickFilter: (event: React.SyntheticEvent<Element, Event>, quickFilter: string) => void;
  setSearch: (search: string) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  sortModel: GridSortModel;
  templateUrl: string;
}

const PurchaseOrdersContext = createContext<IPurchaseOrdersContext>({
  downloadTemplateLoading: true,
  filterOptions: {
    businessModel: [],
    buyers: [],
    status: [],
    supplier: [],
    warehouse: []
  },
  filterStatus: [],
  filters: {},
  handleUploadSupplierItems: () => void 0,
  isLoading: true,
  page: 0,
  pageSize: 25,
  purchaseOrders: [],
  quickFilter: EPurchaseOrderAllStatus.AllItems,
  rowCount: 0,
  search: '',
  setFilters: () => void 0,
  setPage: () => void 0,
  setPageSize: () => void 0,
  setQuickFilter: () => void 0,
  setSearch: () => void 0,
  setSortModel: () => void 0,
  sortModel: [],
  templateUrl: ''
});

const PurchaseOrdersProvider: React.FC = ({ children }) => {
  const api = useApi();
  const { enqueueNotification } = useNotification();
  const { t } = useTranslation(['common', 'purchaseOrders', 'supplier']);

  const defaultStatusCount: IStatusFilterOption = {
    count: 0,
    id: EPurchaseOrderAllStatus.AllItems,
    label: t('purchaseOrders:allItems')
  };

  const [downloadTemplateLoading, setDownloadTemplateLoading] =
    useState<IPurchaseOrdersContext['downloadTemplateLoading']>(true);
  const [filters, setFilters] = useState<IPurchaseOrdersContext['filters']>({});
  const [filterOptions, setFilterOptions] = useState<IPurchaseOrdersContext['filterOptions']>({
    businessModel: [],
    buyers: [],
    status: [],
    supplier: [],
    warehouse: []
  });
  const [filterStatus, setFilterStatus] = useState<IPurchaseOrdersContext['filterStatus']>([defaultStatusCount]);
  const [isLoading, setIsLoading] = useState<IPurchaseOrdersContext['isLoading']>(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
  const [page, setPage] = useState<IPurchaseOrdersContext['page']>(0);
  const [pageSize, setPageSize] = useState<IPurchaseOrdersContext['pageSize']>(25);
  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrdersContext['purchaseOrders']>([]);
  const [quickFilter, setQuickFilter] = useState<IPurchaseOrdersContext['quickFilter']>(
    EPurchaseOrderAllStatus.AllItems
  );
  const [rowCount, setRowCount] = useState<IPurchaseOrdersContext['rowCount']>(0);
  const [search, setSearch] = useState<IPurchaseOrdersContext['search']>('');
  const [sortModel, setSortModel] = useState<IPurchaseOrdersContext['sortModel']>([]);
  const [templateUrl, setTemplateUrl] = useState<IPurchaseOrdersContext['templateUrl']>('');

  const getPurchaseOrders = useCallback(async () => {
    setIsLoading(true);

    try {
      const mappedFilters = Object.entries(filters).reduce(
        (
          result: Record<string, IDateRangeValue | IValueRangeValue | IMultipleSelectOptionIdName['id'][]>,
          [key, values]
        ) => ({
          ...result,
          [key]: Array.isArray(values) ? values.map(value => ('id' in value ? value.id : value.key)) : values
        }),
        {}
      );

      const _filters: IPurchaseOrderFilters = {
        ...(search && { search }),
        ...(sortModel.length && { sortModel: sortModel[0] }),
        ...(quickFilter && { status: quickFilter }),
        ...mappedFilters
      };

      const purchaseOrdersData = await purchaseOrdersService.getAll(page, pageSize, _filters);

      setPurchaseOrders(purchaseOrdersData.items);
      setRowCount(purchaseOrdersData.counts[camelize(quickFilter) as TPurchaseOrderStatus]);

      const newFilterStatus = [
        ...filterOptions.status.map(({ id, name }) => ({
          count: purchaseOrdersData.counts[camelize(id.toString()) as TPurchaseOrderStatus],
          id: id,
          label: name
        })),
        {
          ...defaultStatusCount,
          count: purchaseOrdersData.counts[camelize(defaultStatusCount.id) as TPurchaseOrderStatus]
        }
      ].sort(({ label: labelA }, { label: labelB }) => (labelA > labelB ? 1 : -1));

      setFilterStatus(newFilterStatus as IStatusFilterOption[]);
    } catch (error) {
      enqueueNotification({ title: t('purchaseOrders:errors.errorGetPurchaseOrders') }, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [
    defaultStatusCount,
    enqueueNotification,
    filterOptions.status,
    filters,
    page,
    pageSize,
    quickFilter,
    search,
    sortModel,
    t
  ]);

  const getFilterOptions = useCallback(async () => {
    try {
      const [businessModel, buyers, status, supplier, warehouse] = await Promise.all([
        api.products_GetBusinessModels(),
        buyersService.getBuyers(),
        purchaseOrdersService.getAllStatusOptions(),
        purchaseOrdersService.getAllSupplierOptions(),
        productsService.getWarehouses()
      ]);

      const filters: IFilterOptions = {
        businessModel,
        buyers,
        status,
        supplier,
        warehouse: warehouse.map(({ id, name }) => ({ id, name }))
      } as IFilterOptions;

      setFilterOptions(filters);
    } catch {
      enqueueNotification({ title: t('common:errors.errorGetFilters') }, { variant: 'error' });
    } finally {
      setIsLoadingFilters(false);
    }
  }, [api, enqueueNotification, t]);

  const getTemplateFileUrl = useCallback(async () => {
    try {
      const url = await purchaseOrdersService.getTemplateFile();

      setTemplateUrl(url);
      setDownloadTemplateLoading(false);
    } catch {
      enqueueNotification({ title: t('purchaseOrders:errors.errorGetTemplateFile') }, { variant: 'error' });
    }
  }, [enqueueNotification, t]);

  const handleUploadSupplierItems = useCallback(
    async (file: File) => {
      try {
        setIsLoading(true);

        const { message } = await productsService.uploadExcelProductsFile(file);

        enqueueNotification({ title: message });

        getPurchaseOrders();
      } catch (error: any) {
        const { errors }: { errors: Array<{ message: string }> } = error;

        const errorMessages = errors?.reduce(
          (result: string, { message }, index) => result + `${index + 1}: ${message}\n`,
          ''
        );

        enqueueNotification(
          { content: errorMessages, title: t('purchaseOrders:errors.errorImportSupplier') },
          { variant: 'error' }
        );

        setIsLoading(false);
      }
    },
    [enqueueNotification, getPurchaseOrders, t]
  );

  useEffect(() => {
    getFilterOptions();
    getTemplateFileUrl();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filters, pageSize, quickFilter, search, sortModel]);

  useEffect(() => {
    if (!isLoadingFilters) {
      getPurchaseOrders();
    }
  }, [filters, page, pageSize, quickFilter, search, sortModel, isLoadingFilters]);

  const handleSetPage = useCallback((page: number) => setPage(page), []);

  const handleSetPageSize = useCallback((pageSize: number) => setPageSize(pageSize), []);

  const handleSetQuickFilter = useCallback(
    (_, quickFilter: string) => setQuickFilter(quickFilter as EPurchaseOrderAllStatus),
    []
  );

  const handleSetSearch = useCallback((search: string) => setSearch(search), []);

  const handleSetSortModel = useCallback((sortModel: GridSortModel) => setSortModel(sortModel), []);

  const handleSetFilters = useCallback((filters: IFilterValue) => setFilters(filters), []);

  return (
    <PurchaseOrdersContext.Provider
      value={{
        downloadTemplateLoading,
        filterOptions,
        filterStatus,
        filters,
        handleUploadSupplierItems,
        isLoading,
        page,
        pageSize,
        purchaseOrders,
        quickFilter,
        rowCount,
        search,
        setFilters: handleSetFilters,
        setPage: handleSetPage,
        setPageSize: handleSetPageSize,
        setQuickFilter: handleSetQuickFilter,
        setSearch: handleSetSearch,
        setSortModel: handleSetSortModel,
        sortModel,
        templateUrl
      }}
    >
      {children}
    </PurchaseOrdersContext.Provider>
  );
};

const PurchaseOrderConsumer = PurchaseOrdersContext.Consumer;

export { PurchaseOrderConsumer, PurchaseOrdersProvider };

export default PurchaseOrdersContext;
