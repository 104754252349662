import React, { useEffect, useState } from 'react';

import { Breadcrumbs, IconButton, Link as MaterialLink, MaterialIcons } from '@luxclusif/material';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import { IPurchaseOrderStatus as IStatusState, TGetAllStatus, TPurchaseOrderStatus } from 'models/purchaseOrders';
import EInboundRoute from 'navigation/models/EInboundRoute';

import purchaseOrderStyles from '../../purchaseOrder.styles';

interface IProps {
  isLoading: boolean;
  onClose?: () => void;
  statuses: TGetAllStatus;
}

const Header: React.FC<IProps> = ({ isLoading, onClose, statuses }) => {
  const classes = purchaseOrderStyles();
  const navigate = useNavigate();
  const { poId = '' } = useParams<{ poId: string }>();

  const [status, setStatus] = useState<IStatusState | null>(null);

  const { t } = useTranslation(['common', 'purchaseOrders']);

  const watchStatusId = useWatch<TPurchaseOrderStatus | null>({ defaultValue: null, name: 'statusId' });

  const watchPoNumber = useWatch<string>({ defaultValue: '', name: 'poNumber' });

  useEffect(() => {
    if (poId && !status && watchStatusId) {
      const currentStatus = statuses.find(({ id }) => watchStatusId === id) || null;

      setStatus(currentStatus);
    }
  }, [poId, statuses, watchStatusId]);

  return (
    <div className={classes.breadcrumbContainer}>
      <Breadcrumbs separator={<MaterialIcons.NavigateNext fontSize="small" />}>
        <MaterialLink className={classes.breadcrumbInactive} onClick={() => navigate(EInboundRoute.Root)}>
          {t('common:inbound')}
        </MaterialLink>
        <MaterialLink className={classes.breadcrumbInactive} onClick={() => navigate(EInboundRoute.PurchaseOrders)}>
          {t('common:purchaseOrders')}
        </MaterialLink>
        {!isLoading && (
          <div className={classes.breadcrumbActive}>
            {poId ? (
              <div className={classes.editHeaderActive}>
                <b>{watchPoNumber}</b>
                {status && <StatusIndicator label={status.name} statusId={status.id} />}
              </div>
            ) : (
              <b>{t('common:create')}</b>
            )}
          </div>
        )}
      </Breadcrumbs>
      <IconButton className={classes.closeForm} onClick={onClose}>
        <MaterialIcons.Close />
      </IconButton>
    </div>
  );
};

export default Header;
