import { EBusinessModel, ESubmissionStatus } from '@inbound/api';
import { LuxTheme } from '@lux-ds/theming';

import { EPurchaseOrderAllStatus } from 'models/purchaseOrders';
import { ESupplierStatus } from 'models/suppliers';
import { EInboundFlow } from 'models/warehouse';

export const purchaseStatusColor: Record<
  Exclude<EPurchaseOrderAllStatus, EPurchaseOrderAllStatus.AllItems>,
  string | undefined
> = {
  [EPurchaseOrderAllStatus.Cancelled]: LuxTheme.palette.error.dark,
  [EPurchaseOrderAllStatus.Draft]: LuxTheme.palette.primary.shade[60],
  [EPurchaseOrderAllStatus.Finalized]: LuxTheme.palette.success.dark,
  [EPurchaseOrderAllStatus.RecentlyPurchased]: LuxTheme.palette.success.main,
  [EPurchaseOrderAllStatus.Review]: LuxTheme.palette.citlali.main,
  [EPurchaseOrderAllStatus.WarehouseInbound]: LuxTheme.palette.info.main
};

export const businessModelColor: Record<EBusinessModel, string> = {
  [EBusinessModel.Buyback]: 'citlali',
  [EBusinessModel.Hybrid]: 'purdie',
  [EBusinessModel.OwnedGoods]: 'jindra',
  [EBusinessModel.PhysicalConsignment]: 'rufous',
  [EBusinessModel.VirtualConsignment]: 'shade'
};

export const supplierStatusColor: Record<Exclude<ESupplierStatus, ESupplierStatus.Supplier>, string> = {
  [ESupplierStatus.Active]: LuxTheme.palette.success.main,
  [ESupplierStatus.Draft]: LuxTheme.palette.primary.shade[60],
  [ESupplierStatus.Inactive]: LuxTheme.palette.error.main
};

export const authStatusColor: Record<ESubmissionStatus, string> = {
  [ESubmissionStatus.Completed]: LuxTheme.palette.success.main,
  [ESubmissionStatus.Inconclusive]: LuxTheme.palette.error.main,
  [ESubmissionStatus.NotSubmitted]: LuxTheme.palette.purdie.main,
  [ESubmissionStatus.Submitted]: LuxTheme.palette.primary.shade[95], // To confirm with the design team
  [ESubmissionStatus.WithIssues]: LuxTheme.palette.warning.main
};

export const inboundFlowColor: Record<EInboundFlow, string> = {
  [EInboundFlow.ClientReturn]: 'rufous',
  [EInboundFlow.ConsignmentReturn]: 'olayinka',
  [EInboundFlow.Hybrid]: 'purdie',
  [EInboundFlow.Regular]: 'citlali',
  [EInboundFlow.TransferNotProcessed]: 'shade',
  [EInboundFlow.TransferProcessed]: 'shade',
  [EInboundFlow.VLG]: 'jindra'
};

export const hasIssuesColor: Record<string, string> = {
  false: LuxTheme.palette.success.main,
  null: LuxTheme.palette.success.main,
  true: LuxTheme.palette.warning.main
};
