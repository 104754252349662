import React from 'react';

import { FormControl, FormHelperText, InputLabel, Select } from '@luxclusif/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import selectStyles from './select.styles';

interface IProps {
  className?: any;
  control: any;
  defaultValue?: any;
  disabled?: boolean;
  errors: any;
  fullWidth?: boolean;
  label?: string;
  margin?: 'none' | 'normal' | 'dense';
  name: string;
  setValue?: (name: string, value: any) => void;
  variant?: 'filled' | 'outlined' | 'standard';
}

/**
 * NOTE:
 * @deprecated
 * @todo
 * As discussed with the designer, all of the SelectInput will be
 * replaced with the `AutocompleteInput` component in the future
 */
const SelectInput: React.FC<IProps> = ({
  children,
  className,
  control,
  defaultValue,
  disabled,
  errors,
  fullWidth,
  label,
  margin,
  name,
  setValue,
  variant
}) => {
  const classes = selectStyles();
  const { t } = useTranslation(['warehouse']);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onBlur, onChange, value }) => (
        <FormControl
          variant={variant}
          className={className}
          fullWidth={fullWidth}
          error={errors[name] ? true : false}
          margin={margin}
        >
          <InputLabel id={name} className={classes.resize}>
            {label}
          </InputLabel>
          <Select
            value={value}
            onChange={(event, newValue) => {
              onChange(event);

              setValue?.(`${name}HTML`, (newValue as JSX.Element)?.props?.children);
            }}
            labelId={name}
            label={label}
            disabled={disabled}
            onBlur={onBlur}
          >
            {children}
          </Select>
          <FormHelperText>{errors[name] ? t(errors[name].message) : ''}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SelectInput;
