import React, { useMemo } from 'react';

import { LayoutJay } from '@lux-ds/content-layouts';
import Dropdown from '@lux-ds/dropdown';
import Typography from '@lux-ds/typography';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { IQualityCheckFormInputs } from 'pages/WarehouseList/schemas/qualityCheckSchema';

const QCSupplierReturn: React.FC = () => {
  const { t } = useTranslation(['common', 'warehouse']);
  const { isViewMode } = useWarehouseItemContext();
  const { warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IQualityCheckFormInputs>, [warehouseForm]);

  const reasons = useMemo(
    () => [
      { key: 1, value: t('warehouse:supplierReason.returnAcceptedItem') },
      { key: 0, value: t('warehouse:supplierReason.returnRejectedItem') }
    ],
    [t]
  );

  return (
    <LayoutJay gap="l">
      <Typography variant="h6" weight="semibold">
        {t('warehouse:supplierReturn')}
      </Typography>
      <Controller
        control={form.control}
        name="supplierReturnReason"
        render={({ ref, ...props }) => (
          <Dropdown
            disabled={isViewMode}
            items={reasons}
            label={t('common:reason')}
            ref={ref}
            sx={{ width: '306px' }}
            {...props}
          />
        )}
      />
    </LayoutJay>
  );
};

export default QCSupplierReturn;
