import React from 'react';

import { useTranslation } from 'react-i18next';

import { getSeverityLabels } from 'components/Input/DamageSeverityTicker/utils';
import {
  EDamageSeverityLevel,
  ExteriorDamageLocationKeys,
  HardwareDamageLocationKeys,
  InteriorDamageLocationKeys,
  InteriorExteriorDamageKeys,
  InteriorExteriorDamageOtherDamagesKeys,
  InteriorExteriorDamageStainedKeys
} from 'models/warehouse';
import { IDamage } from 'pages/WarehouseList/schemas/qualityCheckSchema';

import { DamageRecognition, ExteriorPart, InteriorPart } from '../common';

import printDamageCardModalStyles from '../printDamageCardModal.styles';

interface IDamageRecognition {
  damageCategoryKey?: InteriorExteriorDamageKeys;
  damageKey: InteriorExteriorDamageStainedKeys | InteriorExteriorDamageOtherDamagesKeys;
  damageLocationKey: ExteriorDamageLocationKeys | InteriorDamageLocationKeys | HardwareDamageLocationKeys;
  damagePart: 'exterior' | 'interior' | 'hardware';
  damageSeverityKey: EDamageSeverityLevel;
}

interface IProps {
  damages: DamageRecognition;
}

const DamageRecognitionList: React.FC<IProps> = ({ damages }) => {
  const { t } = useTranslation('warehouse');
  const classes = printDamageCardModalStyles();
  const severityLabels = getSeverityLabels(t);

  const hardwareDamages = Object.entries(damages.hardwarePart).reduce(
    (formattedDamages: IDamageRecognition[], [damageLocationKey, damageTypes]) => {
      const newFormattedDamages = Object.entries(damageTypes).reduce(
        (newDamages: IDamageRecognition[], [damageKey, damageValues]) => {
          const { isChecked, severityId } = damageValues as IDamage;

          return isChecked
            ? [
                ...newDamages,
                {
                  damageKey,
                  damageLocationKey,
                  damagePart: 'hardware',
                  damageSeverityKey: severityId
                } as IDamageRecognition
              ]
            : newDamages;
        },
        []
      );

      return [...formattedDamages, ...newFormattedDamages];
    },
    []
  );

  const getInteriorExteriorDamages = (partKey: 'exterior' | 'interior', damagesPart: ExteriorPart | InteriorPart) =>
    Object.entries(damagesPart).reduce((formattedDamages: IDamageRecognition[], [damageLocationKey, damageTypes]) => {
      const newFormattedDamages = Object.entries(damageTypes).reduce(
        (formattedDamageTypes: IDamageRecognition[], [damageCategoryKey, damage]) => {
          const newDamageTypes = Object.entries(damage).reduce(
            (newDamages: IDamageRecognition[], [damageKey, damageValues]) => {
              const { isChecked, severityId } = damageValues as IDamage;

              return isChecked
                ? [
                    ...newDamages,
                    {
                      damageCategoryKey,
                      damageKey,
                      damageLocationKey,
                      damagePart: partKey,
                      damageSeverityKey: severityId
                    } as IDamageRecognition
                  ]
                : newDamages;
            },
            []
          );

          return [...formattedDamageTypes, ...newDamageTypes];
        },
        []
      );

      return [...formattedDamages, ...newFormattedDamages];
    }, []);

  const exteriorDamages = getInteriorExteriorDamages('exterior', damages.exteriorPart);
  const interiorDamages = getInteriorExteriorDamages('interior', damages.interiorPart);

  const damageRecognitions = [...exteriorDamages, ...interiorDamages, ...hardwareDamages];

  return (
    <>
      <h5 className={classes.sectionTitle}>{t('damageRecognition')}</h5>
      <table className={classes.damageRecognitionList}>
        <tr className={classes.damageRecognitionHeader}>
          <th>{t('qualityCheckStation.parts')}</th>
          <th>{t('qualityCheckStation.location')}</th>
          <th>{t('qualityCheckStation.damages')}</th>
          <th>{t('qualityCheckStation.severity')}</th>
        </tr>
        {damageRecognitions.map(
          ({ damageCategoryKey, damageKey, damageLocationKey, damagePart, damageSeverityKey }, index) => (
            <tr key={index} className={classes.damageRecognitionRow}>
              <td>{t(`qualityCheckStation.${damagePart}`)} </td>
              <td>{t(`qualityCheckStation.damageLocation.${damageLocationKey}`)}</td>
              <td>
                {damageCategoryKey && <>{t(`qualityCheckStation.damageCategory.${damageCategoryKey}`)} - </>}
                {t(`qualityCheckStation.damage.${damageKey}`)}
              </td>
              <td>{severityLabels[damageSeverityKey]}</td>
            </tr>
          )
        )}
      </table>
    </>
  );
};

export default DamageRecognitionList;
