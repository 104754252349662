import React from 'react';

import { TextField } from '@luxclusif/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IPurchaseInfo } from 'pages/PurchaseOrder/schema';

import purchaseOrderStyles from 'pages/PurchaseOrder/purchaseOrder.styles';

import orderInformationStyles from '../../orderInformation.styles';

const NotesSection: React.FC = () => {
  const form = useFormContext<IPurchaseInfo>();

  const { t } = useTranslation('purchaseOrders');

  const commonClasses = purchaseOrderStyles();
  const orderInformationClasses = orderInformationStyles();

  return (
    <>
      <h4 className={commonClasses.formSectionTitle}>{t('poInformation.notes')}</h4>
      <div className={orderInformationClasses.inputsContainer}>
        <TextField
          className="Mui-textarea-multiline"
          inputRef={form.register}
          label={t('poInformation.notes')}
          InputLabelProps={{ shrink: true }}
          minRows={4}
          multiline
          name="notes"
        />
      </div>
    </>
  );
};

export default NotesSection;
