import { createStyles, makeStyles, secondaryPalette, Theme } from '@luxclusif/material';

import { EOrderItemStatus, EPurchaseOrderAllStatus } from 'models/purchaseOrders';

const statusIndicatorStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      fontSize: '14px',
      gap: '8px',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden'
    },
    statusIndicator: {
      height: '8px',
      width: '8px',
      borderRadius: '50%',
      flexShrink: 0
    },
    statusIndicatorColorDefault: {
      backgroundColor: theme.palette.warning.main,
      border: '1px solid black'
    },
    statusLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  });
});

export const statusTypeStyles = makeStyles((theme: Theme) => {
  return createStyles({
    // PURCHASE ORDER
    [EPurchaseOrderAllStatus.Cancelled]: {
      backgroundColor: secondaryPalette.error.dark
    },
    [EPurchaseOrderAllStatus.Draft]: {
      backgroundColor: '#AEA6AF'
    },
    [EPurchaseOrderAllStatus.Finalized]: {
      backgroundColor: '#4E7E4E'
    },
    [EPurchaseOrderAllStatus.RecentlyPurchased]: {
      backgroundColor: '#24A148'
    },
    [EPurchaseOrderAllStatus.Review]: {
      backgroundColor: secondaryPalette.warning.main
    },
    [EPurchaseOrderAllStatus.WarehouseInbound]: {
      backgroundColor: secondaryPalette.success.light
    },
    // ITEMS
    [EOrderItemStatus.Available]: {
      backgroundColor: theme.palette.success.main
    },
    [EOrderItemStatus.Canceled]: {
      backgroundColor: secondaryPalette.error.dark
    },
    [EOrderItemStatus.Consigned]: {
      backgroundColor: '#FFEE99'
    },
    [EOrderItemStatus.Delivered]: {
      backgroundColor: theme.palette.info.main
    },
    [EOrderItemStatus.DisposedOf]: {
      backgroundColor: theme.palette.error.main
    },
    [EOrderItemStatus.Draft]: {
      backgroundColor: '#AEA6AF'
    },
    [EOrderItemStatus.InTransit]: {
      backgroundColor: theme.palette.info.light
    },
    [EOrderItemStatus.Marketplace]: {
      backgroundColor: secondaryPalette.success.dark
    },
    [EOrderItemStatus.PendingProcessing]: {
      backgroundColor: '#5A5762'
    },
    [EOrderItemStatus.PendingReview]: {
      backgroundColor: '#FFD60A'
    },
    [EOrderItemStatus.Processing]: {
      backgroundColor: theme.palette.info.dark
    },
    [EOrderItemStatus.Published]: {
      backgroundColor: theme.palette.success.dark
    },
    [EOrderItemStatus.Reserved]: {
      backgroundColor: '#FFDF43'
    },
    [EOrderItemStatus.Sold]: {
      backgroundColor: '#59488D'
    },
    [EOrderItemStatus.SoldBySupplier]: {
      backgroundColor: theme.palette.warning.dark
    },
    [EOrderItemStatus.SupplierReturn]: {
      backgroundColor: theme.palette.warning.main
    },
    [EOrderItemStatus.Unpublished]: {
      backgroundColor: '#F8D6B5'
    }
    // Add more types here. Make sure add a comment which Module it belongs to.
  });
});

export default statusIndicatorStyles;
