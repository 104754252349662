const pascalize = (string: string) => {
  if (/^[\p{L}\d]+$/iu.test(string)) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return string
    .replace(/([\p{L}\d])([\p{L}\d]*)/giu, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())
    .replace(/[^\p{L}\d]/giu, '');
};

export default pascalize;
