import React, { useCallback, useMemo } from 'react';

import { EResultStatus, EStation } from '@inbound/api';
import { IStepperItems } from '@lux-ds/stepper';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BusinessIcon from '@mui/icons-material/Business';
import DiamondIcon from '@mui/icons-material/Diamond';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PinDropIcon from '@mui/icons-material/PinDrop';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';

import {
  StyledJay,
  StyledStepper,
  StyledIconContainer,
  StyledCheckIcon,
  StyledCancelIcon,
  StyledWarningIcon
} from './warehouseStepper.styles';

const WarehouseStepper: React.FC = () => {
  const { t } = useTranslation('warehouse');
  const { activeStation, currentItemStation, onChangeStep, warehouseStations } = useWarehouseItemDetailsContext();

  const currentStepIndex = useMemo(
    () => warehouseStations.findIndex(station => station.stationId === currentItemStation),
    [currentItemStation, warehouseStations]
  );

  const getStepIcon = useCallback((station: EStation) => {
    switch (station) {
      case EStation.Authentication:
        return <DiamondIcon />;
      case EStation.Checkin:
      case EStation.CheckinV2:
        return <PinDropIcon />;
      case EStation.PhotoAuthentication:
        return <PhotoCameraIcon />;
      case EStation.QualityControl:
        return <AssignmentTurnedInIcon />;
      case EStation.Storage:
        return <BusinessIcon />;
      default:
        return undefined;
    }
  }, []);

  const getResultStatus = useCallback(
    (resultStatus: EResultStatus) => {
      switch (resultStatus) {
        case EResultStatus.Passed:
          return { hint: t('authenticationStation.authPass'), icon: <StyledCheckIcon /> };
        case EResultStatus.Failed:
          return { hint: t('authenticationStation.authFail'), icon: <StyledCancelIcon /> };
        case EResultStatus.Inconclusive:
          return { hint: t('authenticationStation.inconclusive'), icon: <StyledWarningIcon /> };
        default:
          return undefined;
      }
    },
    [t]
  );

  const handleStepChange = useCallback((step: EStation) => onChangeStep(step), [onChangeStep]);

  const steps = useMemo(
    (): IStepperItems =>
      warehouseStations.map(({ lastPerformedAtUtc, order, resultStatusId, station, stationId }) => {
        const resultStatus = resultStatusId !== undefined ? getResultStatus(resultStatusId) : undefined;

        return {
          Icon: (
            <StyledIconContainer $active={currentStepIndex + 1 >= (order as number)}>
              {getStepIcon(stationId as EStation)}
            </StyledIconContainer>
          ),
          description: lastPerformedAtUtc ? moment(lastPerformedAtUtc).format('DD MMM YYYY') : undefined,
          descriptionHint: lastPerformedAtUtc ? t('lastUpdateDate') : undefined,
          label: station as string,
          labelIcon: resultStatus?.icon,
          labelIconHint: resultStatus?.hint,
          perItemStepButtonProps: {
            'data-cy': `stepItem-${station}`,
            onClick: () => handleStepChange(stationId as EStation)
          },
          perItemStepLabelProps: {
            className: activeStation === (stationId as EStation) ? 'current' : ''
          }
        };
      }),
    [activeStation, currentStepIndex, getResultStatus, getStepIcon, handleStepChange, t, warehouseStations]
  );

  return (
    <StyledJay>
      <StyledStepper activeStep={currentStepIndex} items={steps} orientation="vertical" />
    </StyledJay>
  );
};

export default WarehouseStepper;
