import React from 'react';

import Button from '@lux-ds/button';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Dialog from '@lux-ds/dialog';
import Typography from '@lux-ds/typography';
import { useTranslation } from 'react-i18next';

import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';

interface IFinishProcessModalProps {
  isAccepted: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const FinishProcessModal: React.FC<IFinishProcessModalProps> = ({ isAccepted, onClose, onSubmit }) => {
  const { t } = useTranslation(['common', 'warehouse']);
  const { isSaving } = useWarehouseItemDetailsContext();

  return (
    <Dialog onClose={onClose} open>
      <LayoutJay padding="l" sx={{ width: '523px' }}>
        <LayoutJay gap="m">
          <Typography variant="h5" weight="bold">
            {t('warehouse:finishProcess')}
          </Typography>
          <Typography variant="bodyTextNormal">
            {isAccepted ? t('warehouse:finishProcessAccepted') : t('warehouse:finishProcessRejected')}
          </Typography>
        </LayoutJay>
        <LayoutHerodias align="right" gap="xs">
          <Button disabled={isSaving} onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button disabled={isSaving} onClick={onSubmit} variant="contained">
            {t('common:finish')}
          </Button>
        </LayoutHerodias>
      </LayoutJay>
    </Dialog>
  );
};

export default FinishProcessModal;
