import React from 'react';

import { Grid } from '@luxclusif/material';
import { useFormContext } from 'react-hook-form';

import Input from 'components/Input/Input/Input';

import supplierDetailsStyles from '../../supplierDetails.styles';

const ContactPerson: React.FC = () => {
  const classes = supplierDetailsStyles();
  const { control, errors } = useFormContext();

  return (
    <div>
      <h1 className={classes.title}>Contact Person</h1>
      <Grid item md={8}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <div className={classes.formControl}>
              <Input
                name="contactName"
                control={control}
                errors={errors}
                label="Contact Person*"
                type="text"
                className={classes.input}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </div>
            <div className={classes.formControl}>
              <Input
                name="contactEmail"
                control={control}
                errors={errors}
                label="Email Address"
                type="text"
                className={classes.input}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </div>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <div className={classes.formControl}>
                  <Input
                    name="contactTelephone"
                    control={control}
                    errors={errors}
                    label="Phone Number"
                    type="number"
                    className={classes.input}
                    variant="outlined"
                    fullWidth={true}
                    errorText="Required"
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={classes.formControl}>
                  <Input
                    name="contactPerson"
                    control={control}
                    errors={errors}
                    label="Contact Person from Luxclusif*"
                    type="text"
                    className={classes.input}
                    variant="outlined"
                    fullWidth={true}
                    errorText="Required"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.line} />
    </div>
  );
};

export default ContactPerson;
