import React, { useEffect } from 'react';

import MomentUtils from '@date-io/moment';
import { AuthService } from '@luxclusif/material';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { SnackbarProvider } from 'notistack';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/Login/Login';
import Signin from './components/Signin/Signin';
import SilentRenew from './components/SilentRenew/SilentRenew';
import PrivateRouter from './navigation/components/PrivateRouter';
import EInboundRoute from './navigation/models/EInboundRoute';

const App: React.FC = () => {
  const clientRoot = process.env.REACT_APP_ROOT || '';
  const scope = 'openid profile email inbound roles';
  const clientId = process.env.REACT_APP_STS_CLIENT_ID || '';
  const clientSecret = process.env.REACT_APP_STS_CLIENT_SECRET || '';

  AuthService.init(clientRoot, scope, clientId, clientSecret, process.env.REACT_APP_STS_AUTHORITY);

  if (process.env.REACT_APP_INSIGHTS_CONNECTION_STRING) {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING
      }
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
  } else {
    console.error('Please provide a connection string for the ApplicationInsights');
  }

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <Routes>
            <Route path={EInboundRoute.Signin} element={<Signin />} />
            <Route path={EInboundRoute.SilentRenew} element={<SilentRenew />} />
            <Route path={EInboundRoute.Login} element={<Login />} />
            <Route path="*" element={<PrivateRouter />} />
          </Routes>
        </Router>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  );
};

export default App;
