import React from 'react';

import { TextField } from '@luxclusif/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import inputStyles from './input.styles';

interface IProps {
  InputProps?: any;
  className?: any;
  control: any;
  defaultValue?: string;
  disabled?: boolean;
  errorText?: string;
  errors: any;
  fullWidth?: boolean;
  inputProps?: any;
  label?: string;
  name: string;
  rows?: any;
  size?: 'small' | 'medium';
  type?: string;
  variant?: 'filled' | 'outlined' | 'standard';
}

/**
 * NOTE:
 * @deprecated
 * This component is an overkill/redundant usage since we can register the
 * TextField Component of MUI with 1 prop to react-hook-form.
 */

const Input: React.FC<IProps> = ({
  className,
  control,
  defaultValue,
  disabled,
  errors,
  fullWidth,
  inputProps,
  InputProps,
  label,
  name,
  rows,
  size,
  type,
  variant
}) => {
  const classes = inputStyles();
  const { t } = useTranslation(['common', 'warehouse']);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ''}
      render={({ onBlur, onChange, value }) => (
        <TextField
          label={label}
          type={type}
          value={value}
          className={className}
          onChange={onChange}
          onBlur={onBlur}
          InputLabelProps={{
            classes: {
              root: classes.resize
            },
            shrink: true
          }}
          InputProps={InputProps}
          inputProps={inputProps ? inputProps : {}}
          variant={variant}
          fullWidth={fullWidth}
          error={errors[name] ? true : false}
          rows={rows}
          multiline={rows ? true : false}
          helperText={errors[name] ? t(errors[name].message) : ''}
          disabled={disabled}
          size={size ? size : 'medium'}
        />
      )}
    />
  );
};

export default Input;
