import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const uploadImageStyles = makeStyles(() => {
  return createStyles({
    photos: {
      cursor: 'pointer',
      boxSizing: 'border-box',
      height: '159.9px',
      width: '164px',
      border: `1px solid ${secondaryNeutral.light85}`,
      borderRadius: '4px',
      textAlign: 'center',

      justifyContent: 'center'
    },
    logoContainer: {
      width: '32px',
      height: '32px',
      position: 'relative',
      top: '10px',
      right: '-120px',
      zIndex: 10,
      borderRadius: '50%',
      backgroundColor: 'white'
    },
    logo: {
      width: '14px',
      height: '14px',
      zIndex: 11,
      position: 'relative',
      top: '28%',
      cursor: 'pointer',
      textAlign: 'center',
      justifyContent: 'center'
    },
    inputPhotos: {
      opacity: 0,
      position: 'relative',
      top: '-35px',
      left: '0px',
      height: '100%',
      width: '100%',
      justifyContent: 'center'
    },
    formControlInvalid: {
      color: 'red'
    },
    textAlignCenter: {
      textAlign: 'center'
    }
  });
});

export default uploadImageStyles;
