import { createStyles, makeStyles } from '@luxclusif/material';

const printBarcodeModalStyles = makeStyles(() => {
  return createStyles({
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '&:focus': {
        outline: 'none'
      }
    },
    modalContent: {
      borderRadius: 0,
      padding: '8px',
      height: '120px',
      width: '215px'
    },
    itemInfoContainer: {
      display: 'flex',
      gap: '12px',
      fontSize: '8px',
      marginBottom: '4px'
    },
    itemLabel: {
      width: '65px',
      color: '#86867E',
      fontSize: '10px'
    },
    itemValue: {
      flex: 1,
      fontSize: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    barcodeContainer: {
      position: 'relative',
      height: 'auto',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',

      '& svg': {
        width: '100%',
        height: '35px'
      }
    },
    barcodeLabel: {
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '1'
    }
  });
});

export default printBarcodeModalStyles;
