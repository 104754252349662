import React from 'react';

import IconButton from '@lux-ds/icon-button';
import { ImageZoom } from '@lux-ds/image';
import Close from '@mui/icons-material/Close';

import { PreviewContent, PreviewDialog, PreviewHeader } from './photoPreview.styles';

interface IPhotoPreviewProps {
  onClose: () => void;
  src: string;
}

const PhotoPreview: React.FC<IPhotoPreviewProps> = ({ onClose, src }) => {
  return (
    <PreviewDialog open onClose={onClose}>
      <PreviewHeader>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </PreviewHeader>
      <PreviewContent padding="2xl">
        <ImageZoom height="100%" src={src} width="auto" />
      </PreviewContent>
    </PreviewDialog>
  );
};

export default PhotoPreview;
