import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const orderItemsStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    capitalizeCell: {
      textTransform: 'capitalize'
    },
    photoCell: {
      lineHeight: 'normal !important'
    },
    notification: {
      whiteSpace: 'pre-wrap'
    },
    notificationLink: {
      color: palette.common.white,
      cursor: 'pointer'
    }
  })
);

export default orderItemsStyles;
