import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const warehouseDetailsStyles = makeStyles((theme: Theme) => {
  return createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center'
    },
    modalRoot: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100vw',
      height: '100vh',
      overflowY: 'auto',
      backgroundColor: secondaryNeutral.light85,
      '&:focus': {
        outline: 'none'
      },
      overflow: 'hidden'
    },

    lineHeader: {
      boxSizing: 'border-box',
      height: '2px',
      width: '100%',
      border: `1px solid ${secondaryNeutral.light75}`
    },
    formContainer: {
      width: '100%'
    },
    containerWidth: {
      width: '100%'
    },
    warehouseContainer: {
      marginLeft: '24px',
      marginTop: '20px',
      marginRight: '24px',
      width: '100%'
    },
    line: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '45px 0',
      padding: '0'
    },

    submitLine: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      marginTop: 0,
      marginBottom: 0,
      padding: '0'
    },
    button: {
      boxShadow: 'none',
      marginLeft: '20px',
      marginTop: '20px'
    },
    discardButton: {
      color: theme.palette.secondary.light
    },
    saveButton: {
      minWidth: '144px',
      border: `1px solid ${secondaryNeutral.light75}`
    },
    submitPanel: {
      marginTop: '11px',
      marginBottom: '31px',
      paddingRight: '24px'
    },
    userWarehouseWarning: {
      maxWidth: '348px'
    },
    userWarehouseWarningClose: {
      fontSize: '16px',
      padding: '4px',
      position: 'absolute',
      right: '12px',
      top: '12px'
    },
    userWarehouseWarningTitle: {
      fontSize: '14px',
      lineHeight: '19px',
      margin: 0
    },
    userWarehouseWarningDescription: {
      fontSize: '12px',
      lineHeight: '17px',
      margin: '8px 0 0'
    },

    mainWrapper: {
      display: 'flex',
      gap: '24px',
      padding: '24px',
      overflow: 'hidden',
      minHeight: 'max-content',
      maxHeight: 'calc(100vh - 80px)'
    }
  });
});

export default warehouseDetailsStyles;
