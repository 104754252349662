import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const formTabStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appBar: {
      color: secondaryNeutral.light40,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '16px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      borderBottom: `1px solid ${secondaryNeutral.light75}`,
      fontSize: '16px'
    },
    tabs: {
      height: '100%',
      '& .MuiTabs-flexContainer': {
        height: '100%'
      }
    },

    fullTabs: {
      height: '100%',
      '& .MuiTabs-flexContainer': {
        height: '100%'
      },
      border: `1px solid ${secondaryNeutral.light75}`,
      borderRadius: '0 4px 4px 0'
    },
    tab: {
      color: secondaryNeutral.light40,
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'capitalize',
      height: '100%',
      minWidth: '143px',
      '&[aria-selected=true]': {
        fontSize: '16px',
        fontWeight: '600',
        color: theme.palette.primary.main,
        textTransform: 'capitalize',
        height: '100%'
      }
    },

    fullTab: {
      color: secondaryNeutral.light40,
      fontFamily: 'Nunito Sans',
      fontSize: '12px',
      fontWeight: 600,
      textTransform: 'capitalize',
      height: '100%',
      minHeight: '58px !important',
      minWidth: '143px',
      '&[aria-selected=true]': {
        fontSize: '12px',
        fontWeight: '600',
        color: theme.palette.secondary.main,
        textTransform: 'capitalize',
        height: '100%',
        backgroundColor: '#F5F5F4',
        border: `1px solid ${secondaryNeutral.light65}`,
        borderRadius: '4px 0 0 4px'
      },
      '& .MuiTab-wrapper svg': {
        fontSize: '16px',
        marginBottom: '0 !important'
      }
    }
  });
});

export default formTabStyles;
