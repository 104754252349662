import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const luxDataTableStyles = makeStyles((theme: Theme) => {
  return createStyles({
    iconButtons: {
      fontSize: '24px',
      padding: '8px',
      '&.Mui-disabled .MuiSvgIcon-root': {
        color: secondaryNeutral.light65
      }
    },
    container: {
      width: '100%',
      padding: '24px'
    },
    content: {
      backgroundColor: 'white',
      borderRadius: '4px'
    },
    headerContainer: {
      color: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      padding: '14px 24px'
    },
    headerTitle: {
      flex: 1,
      fontSize: '18px',
      margin: 0
    },
    headerButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      marginRight: '24px'
    },
    headerButton: {
      border: `1px solid ${secondaryNeutral.light75}`,
      lineHeight: 1,
      padding: '8px 16px',
      '& .MuiButton-startIcon': {
        marginRight: '8px',
        '& .MuiSvgIcon-root': {
          fontSize: '16px'
        }
      },
      '&.Mui-disabled': {
        borderColor: secondaryNeutral.light85
      }
    },
    headerIconButton: {
      minWidth: 'unset',
      padding: '8px',
      '& .MuiButton-startIcon': {
        marginRight: 0
      }
    },
    headerSeparator: {
      height: '36px',
      border: `1px solid ${secondaryNeutral.light75}`,
      marginRight: '16px'
    },
    headerActionsContainer: {
      display: 'flex',
      gap: '8px',
      '&  .MuiIconButton-root': {
        fontSize: '24px',
        padding: '8px'
      }
    },

    searchTextContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchTextInput: {
      paddingLeft: '16px',
      fontSize: '16px',
      flex: 1,
      border: 'none',
      '&:focus, &:focus-visible': {
        outline: 'none'
      },
      '&::placeholder': {
        color: '#86867E'
      }
    },

    filtersContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      padding: '16px 24px',
      backgroundColor: 'rgba(221,221,218,0.5)'
    },

    table: {
      border: 'none',
      color: theme.palette.secondary.main,
      '& .MuiDataGrid-columnHeaderWrapper > .MuiDataGrid-columnHeader:first-child:not(.MuiDataGrid-columnHeaderCheckbox), & .MuiDataGrid-row > .MuiDataGrid-cell:first-child:not(.MuiDataGrid-cellCheckbox)':
        {
          paddingLeft: '24px',
          '&.MuiDataGrid-columnHeaderCheckbox, &.MuiDataGrid-cellCheckbox': {
            paddingLeft: '8px'
          }
        },
      '& .MuiDataGrid-columnHeaderWrapper > .MuiDataGrid-columnHeader:last-child, & .MuiDataGrid-row > .MuiDataGrid-cell:last-child':
        {
          paddingRight: '24px'
        },
      '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within':
        {
          outline: 'none'
        },
      '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold'
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
        '& .MuiIconButton-root': {
          fontSize: '16px',
          marginLeft: '8px',
          padding: '4px'
        }
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '12px',
        fontWeight: 600
      },
      '& .MuiDataGrid-columnSeparator svg': {
        display: 'none'
      },
      '& .MuiDataGrid-cell': {
        borderBottom: '1px solid #DDDDDA'
      }
    },

    statusButtonFilter: {
      lineHeight: 1,
      padding: '8px 16px'
    },
    statusButtonActive: {
      textTransform: 'none',
      color: '#fff'
    },
    statusButtonInactive: {
      textTransform: 'none',
      color: secondaryNeutral.light40
    },

    statusCountFilter: {
      borderRadius: '6px 4px',
      display: 'inline',
      fontSize: '12px',
      marginLeft: '10px',
      padding: '4px 6px'
    },
    numberTooltipSecondary: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.background.paper
    },
    numberTooltipDefault: {
      backgroundColor: secondaryNeutral.light85,
      color: secondaryNeutral.light40
    }
  });
});

export default luxDataTableStyles;
