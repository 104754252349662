import * as yup from 'yup';

export const supplierBankSchema = yup.object().shape({
  bankAccountId: yup.string().required(),
  bankAccountNumber: yup.string().required(),
  bankAddress: yup.string().required(),
  bankCountry: yup.string().required(),
  bankEmail: yup.string().email().required(),
  bankName: yup.string().required(),
  beneficiaryName: yup.string().required(),
  swiftOrBic: yup.string().required()
});
