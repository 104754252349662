import Compress from 'react-image-file-resizer';

const compressImage = (file: Blob, maxWidth: number, maxHeight: number, compressFormat: string, quality: number) =>
  new Promise(resolve => {
    Compress.imageFileResizer(
      file,
      maxWidth,
      maxHeight,

      // 'WEBP',
      compressFormat,
      quality,
      0,
      uri => {
        resolve(uri);
      },
      'base64'
    );
  });

export default compressImage;
