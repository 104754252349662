import React, { createRef, useEffect, useState } from 'react';

import { Box, Button, IconButton, MaterialIcons } from '@luxclusif/material';
import Dropzone from 'react-dropzone';
import { DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import uuidGenerator from 'utils/uuidGenerator';

import SUPPLIER_CONSTANTS from '../../../pages/Suppliers/constants';

import uploadFilesStyles from './uploadFiles.styles';

export interface IFileUpload {
  file: File;
  fileId: string;
}

interface IProps {
  accept: string;
  errors: DeepMap<Record<string, any>, FieldError>;
  getValue: any;
  label?: string | undefined;
  name: string;
  setValue: (
    name: string,
    value: any,
    config?:
      | Partial<{
          shouldDirty: boolean;
          shouldValidate: boolean;
        }>
      | undefined
  ) => any;
  uploadFile?: (id: string, file: any) => void;
  watch: any;
}

const UploadFiles: React.FC<IProps> = ({ accept, getValue, name, setValue, uploadFile, watch }) => {
  const classes = uploadFilesStyles();
  const { t } = useTranslation();
  const [files, setFiles] = useState(Array<any>());
  const [filesToDelete, setFilesToDelete] = useState(Array<any>());

  useEffect(() => {
    const uploadFiles = watch(name, {
      files: [],
      filesToDelete: []
    });

    if (uploadFiles.file || uploadFiles.filesToDelete) {
      setFilesToDelete(uploadFiles.filesToDelete);
      setFiles(uploadFiles.files);
    }
  }, []);

  const removeHandler = (fileId: string) => {
    const filterArray = () => {
      return files.filter((obj: any) => {
        return obj.fileId !== fileId;
      });
    };
    const filteredArray = filterArray();

    setValue(name, {
      files: filteredArray,
      filesToDelete: [...filesToDelete, fileId]
    });

    setFiles(filteredArray);
  };

  const changeSelectFile = async (file: any) => {
    const uuidFile = uuidGenerator();

    if (uploadFile) {
      uploadFile(uuidFile, file[0]);
    }

    setFiles([...files, { file: file[0], fileId: uuidFile }]);
    setValue(name, {
      ...getValue()[name],
      files: [...files, { file: file[0], fileId: uuidFile }]
    });
  };

  const dropzoneRef: any = createRef();
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <Dropzone onDropAccepted={changeSelectFile} accept={accept} noClick={true} ref={dropzoneRef}>
      {({ getInputProps, getRootProps }) => (
        <section className="container">
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} />
            <div className={classes.tableCell}>
              <Box className={classes.boxContainer}>
                <Box>
                  <MaterialIcons.CloudUpload className={classes.cloudIcon} />
                </Box>
                <Box>
                  <p className={classes.inlineText}>
                    {t('uploadFiles.dragDropToUpload')} {t('or')}
                  </p>
                </Box>
                <Box>
                  <Button variant="outlined" onClick={openDialog}>
                    {t('browseFiles')}
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
          <aside>
            {files?.map(({ file, fileId, fileSize, fileUrl, name: fileName }, i: number) => {
              let url = '';
              let name = '';
              let size = 0;

              if (fileUrl) {
                url = fileUrl;
                name = fileName;
                size = Math.round(fileSize / 1000);
              } else {
                const { name: onFileName, size: onFileSize } = file;

                name = onFileName;
                size = Math.round(onFileSize / 1000);

                const fileParts = name.split('.');
                const [fileType] = fileParts.slice(-1);

                url = `${process.env.REACT_APP_FILES_STORAGE}${SUPPLIER_CONSTANTS.BLOB_RELATIVE_PATH}${fileId}.${fileType}`;
              }

              return (
                <div className={classes.fileContainer} key={i}>
                  <div className={classes.tableCell}>
                    <Box display="flex" alignItems="center" className={classes.fileText}>
                      <Box m={1}>
                        <MaterialIcons.Image className={classes.imageIcon} />
                      </Box>
                      <Box className={classes.fileName} flexGrow={1} m={1}>
                        {name}
                      </Box>
                      <Box m={1}>{t('kiloBytes', { size })}</Box>
                      <Box m={1}>
                        <IconButton
                          className={classes.actionButtonsContainer}
                          onClick={() => window.open(url, '_blank')}
                        >
                          <MaterialIcons.GetApp className={classes.actionButtons} />
                        </IconButton>
                      </Box>
                      <Box m={1}>
                        <IconButton className={classes.actionButtonsContainer} onClick={() => removeHandler(fileId)}>
                          <MaterialIcons.Delete className={classes.actionButtons} />
                        </IconButton>
                      </Box>
                    </Box>
                  </div>
                </div>
              );
            })}
          </aside>
        </section>
      )}
    </Dropzone>
  );
};

export default UploadFiles;
