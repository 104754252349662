import React from 'react';

import { LuxButton, Modal, Paper } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import currencyChangedModalStyle from './currencyChangedModal.styles';

interface IProps {
  onConfirm: () => void;
}

const CurrencyChangedModal: React.FC<IProps> = ({ onConfirm }) => {
  const classes = currencyChangedModalStyle();

  const { t } = useTranslation('purchaseOrders');

  return (
    <Modal open>
      <Paper className={classes.modalContent}>
        <h2 className={classes.title}>{t('currencyChangedModal.currencyChanged')}</h2>
        <p className={classes.description}>{t('currencyChangedModal.validateCostInUsdMessage')}</p>
        <div className={classes.actions}>
          <LuxButton className={classes.button} luxColor="secondary" variant="contained" onClick={onConfirm}>
            {t('currencyChangedModal.ok')}
          </LuxButton>
        </div>
      </Paper>
    </Modal>
  );
};

export default CurrencyChangedModal;
