import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const luxNumberInputStyles = makeStyles((theme: Theme) => {
  return createStyles({
    fontSize: {
      fontSize: '13px',
      color: theme.palette.secondary.main,
      letterSpacing: 0,
      lineHeight: '14px'
    },
    removeArrow: {
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        display: 'none'
      },
      width: '50px',
      height: '32px',
      marginTop: 0
    },
    label: {
      color: theme.palette.secondary.main,
      fontFamily: 'Nunito Sans',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '22px'
    },
    incrementIconButton: {
      backgroundColor: secondaryNeutral.light85,
      color: secondaryNeutral.light40,
      borderRadius: '4px',
      height: '39px',
      width: '39px',
      fontSize: '16px',
      flexGrow: 0,
      marginTop: '10px'
    },
    minus: {
      marginRight: '8px'
    },
    addition: {
      marginLeft: '8px'
    }
  });
});

export default luxNumberInputStyles;
