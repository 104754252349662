import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const uploadFilesStyles = makeStyles((theme: Theme) => {
  return createStyles({
    boxContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    dropzone: {
      display: 'table',
      width: '100%',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#F5F5F4',
      color: '#86867E',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      minHeight: '86px',
      border: '1px dashed #DDDDDA',
      borderRadius: '4px',
      marginBottom: '40px'
    },
    inlineText: {
      display: 'inline-block',
      margin: '8px'
    },
    cloudIcon: {
      marginRight: '16px',
      color: '#86867E',
      height: '40px',
      width: '40px',
      verticalAlign: 'middle'
    },
    tableCell: {
      fontSize: 14,
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '100%'
    },
    fileContainer: {
      display: 'table',
      boxSizing: 'border-box',
      height: '56px',
      width: '100%',
      border: '1px solid #DDDDDA',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      marginTop: '8px',
      marginBottom: '8px'
    },
    fileText: {
      marginLeft: '19.5px',
      marginRight: '19.5px',
      color: '#86867E',
      fontFamily: 'Nunito Sans',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '16px'
    },
    fileName: {
      color: theme.palette.secondary.main
    },
    imageIcon: {
      color: '#86867E',
      height: '24px',
      width: '24px'
    },
    actionButtons: {
      color: '#444442',
      height: '20px',
      width: '20px'
    },
    actionButtonsContainer: {
      height: '32px',
      width: '32px',
      borderRadius: 0
    }
  });
});

export default uploadFilesStyles;
