import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CountryGetListModel, EItemExtraFieldsKey } from '@inbound/api';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputBaseComponentProps,
  MaterialIcons,
  Radio,
  RadioGroup,
  TextField,
  Tooltip
} from '@luxclusif/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Carousel from 'components/Carousel/Carousel';
import ColorChips from 'components/ColorChips/ColorChips';
import AutocompleteInput from 'components/Input/AutocompleteInput/AutocompleteInput';
import CheckBoxChip from 'components/Input/CheckboxChip/CheckboxChip';
import DecimalInput from 'components/Input/DecimalInput/DecimalInput';
import { EInboundFlow, IInclusion } from 'models/warehouse';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import useGetUnit from 'pages/WarehouseList/hooks/useGetUnit';
import { defaultCheckInValues, ICheckinInputs } from 'pages/WarehouseList/schemas/checkInSchema';
import checkIsRegularFlow from 'pages/WarehouseList/utils/checkIsRegularFlow';

import foundStyles from './found.styles';

const ENABLE_KARAT_FIELD = process.env.REACT_APP_ENABLE_KARAT_FIELD === 'true';

const Found: React.FC = () => {
  const classes = foundStyles();
  const { t } = useTranslation(['common', 'warehouse']);
  const { countries, inclusions } = useWarehouseItemDetailsContext();
  const { warehouseForm } = useWarehouseItemDetailsContext();

  const [amountOfInclusions, setAmountOfInclusions] = useState(0);
  const getUnit = useGetUnit();

  const form = useMemo(() => warehouseForm as UseFormMethods<ICheckinInputs>, [warehouseForm]);

  const { isViewMode } = useWarehouseItemContext();

  const { control, errors, register } = form;

  useEffect(() => {
    if (form.getValues('none')) {
      [
        'box',
        'dustBag',
        'authenticityCard',
        'padlock',
        'key',
        'pouch',
        'nameTag',
        'charm',
        'mirror',
        'shoulderStrap'
      ].forEach(inclusion => {
        form.setValue(inclusion, false, { shouldDirty: true, shouldValidate: true });
      });
    }
  }, [form.watch('none')]);

  useEffect(() => {
    if (
      (form.getValues('box') ||
        form.getValues('dustBag') ||
        form.getValues('authenticityCard') ||
        form.getValues('padlock') ||
        form.getValues('key') ||
        form.getValues('pouch') ||
        form.getValues('nameTag') ||
        form.getValues('charm') ||
        form.getValues('mirror') ||
        form.getValues('shoulderStrap')) &&
      form.getValues('none')
    ) {
      form.setValue('none', false, { shouldDirty: true, shouldValidate: true });
    }

    getAmountOfInclusions();
  }, [
    form.watch('box'),
    form.watch('dustBag'),
    form.watch('authenticityCard'),
    form.watch('padlock'),
    form.watch('key'),
    form.watch('pouch'),
    form.watch('nameTag'),
    form.watch('charm'),
    form.watch('mirror'),
    form.watch('shoulderStrap')
  ]);

  const getAmountOfInclusions = useCallback(() => {
    const formInclusions = [
      form.getValues('box'),
      form.getValues('dustBag'),
      form.getValues('authenticityCard'),
      form.getValues('padlock'),
      form.getValues('key'),
      form.getValues('pouch'),
      form.getValues('nameTag'),
      form.getValues('charm'),
      form.getValues('mirror'),
      form.getValues('shoulderStrap')
    ];

    const inclusionsSelected = formInclusions.filter((el: boolean) => {
      return el === true;
    });

    setAmountOfInclusions(inclusionsSelected.length);
  }, [
    form.watch([
      'authenticityCard',
      'box',
      'charm',
      'dustBag',
      'key',
      'mirror',
      'nameTag',
      'padlock',
      'pouch',
      'shoulderStrap'
    ])
  ]);

  const isRegularFlow = useMemo(() => checkIsRegularFlow(form.watch('inboundFlow')), [form.watch('inboundFlow')]);
  const isRegularVLGFlow = useMemo(
    () => checkIsRegularFlow(form.getValues('inboundFlow'), [EInboundFlow.VLG]),
    [form.watch('inboundFlow')]
  );
  const isDisabled = useMemo(() => !isRegularFlow || isViewMode, [isRegularFlow, isViewMode]);
  const isMeasuresDisabled = useMemo(
    () => !checkIsRegularFlow(form.getValues('inboundFlow'), [EInboundFlow.VLG]) || isViewMode,
    [isViewMode, form.watch('inboundFlow')]
  );
  const inclusionsWithData = useMemo(
    (): IInclusion[] =>
      isRegularVLGFlow
        ? inclusions
        : inclusions.filter(inclusionOption =>
            form.watch('initialInclusions')?.find(initialInclusion => inclusionOption.id === initialInclusion.id)
          ),
    [form.watch('initialInclusions'), inclusions, isRegularVLGFlow]
  );

  return (
    <div className={classes.container}>
      <div className={classes.leftSection}>
        <div className={classes.formSection}>
          <h3 className={classes.inclusionLabelContainer}>
            {`${t('warehouse:inclusions')} (${amountOfInclusions})`}
            {!isRegularFlow && (
              <Tooltip
                arrow
                title={t('warehouse:checkinStation.inclusionInfo')}
                classes={{ popper: classes.tooltipContent }}
              >
                <MaterialIcons.InfoOutlined />
              </Tooltip>
            )}
          </h3>
          <div className={classes.chipCheckBoxContainer}>
            {/* TODO: Revamp the initialization of inclusionsData options and default value */}
            {inclusionsWithData.map(({ icon, id, key = '', name }) => (
              <Controller
                key={id}
                name={key}
                control={control}
                render={({ onChange, value }) => (
                  <CheckBoxChip
                    disabled={isViewMode}
                    icon={icon as string}
                    label={name}
                    onChange={newValue => {
                      onChange(newValue);
                    }}
                    value={value}
                    className={classes.chipCheckBox}
                  />
                )}
              />
            ))}
          </div>
        </div>

        <hr className={classes.divider} />

        {form.watch('securityTagRequired') && (
          <>
            <div className={classes.formSection}>
              <h3>{t('warehouse:securityTag')}</h3>
              <Controller
                name="hasSecurityTag"
                control={control}
                render={({ onChange, value }) => (
                  <FormControl component="fieldset" error={!!errors.hasSecurityTag} variant="standard">
                    <RadioGroup
                      row
                      className={classes.securityTagContainer}
                      value={JSON.stringify(value)}
                      onChange={(_, value) => onChange(JSON.parse(value))}
                    >
                      <FormControlLabel
                        disabled={isViewMode}
                        value="true"
                        control={<Radio />}
                        label={t('common:yes')}
                      />
                      <FormControlLabel
                        disabled={isViewMode}
                        value="false"
                        control={<Radio />}
                        label={t('common:no')}
                      />
                    </RadioGroup>
                    {!!errors.hasSecurityTag && <FormHelperText>{t('common:required')}</FormHelperText>}
                  </FormControl>
                )}
              />
            </div>
            <hr className={classes.divider} />
          </>
        )}

        <div className={classes.formSection}>
          <h3>{t('warehouse:measures')}</h3>
          <div className={classes.measurementsContainer}>
            {form.getValues('measures')?.map((measure, index) => (
              <Controller
                control={form.control}
                key={measure.key}
                name={`measures.${index}.value`}
                render={({ ref, ...controlProps }) => (
                  <TextField
                    {...controlProps}
                    disabled={isMeasuresDisabled}
                    defaultValue="0"
                    error={!!errors.measures?.[index]}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ inputComponent: DecimalInput as React.ElementType<InputBaseComponentProps> }}
                    label={`${t(`warehouse:measurements.${measure.labelKey}`)}${
                      measure.isRequired ? ' *' : ''
                    }${getUnit(measure.key)}`}
                  />
                )}
              />
            ))}
          </div>
        </div>

        <hr className={classes.divider} />

        <div className={classes.formSection}>
          <h3>{t('warehouse:serialNumber')}</h3>
          <div className={classes.serialNumberContainer}>
            <Controller
              name="hasSerialNumber"
              control={control}
              render={({ onChange, value }) => (
                <RadioGroup
                  row
                  className={classes.serialNumberRadioContainer}
                  value={JSON.stringify(value)}
                  onChange={(_, value) => onChange(JSON.parse(value))}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    disabled={isDisabled}
                    label={t('warehouse:checkinStation.withoutSerialNumber')}
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    disabled={isDisabled}
                    label={t('warehouse:checkinStation.withSerialNumber')}
                  />
                </RadioGroup>
              )}
            />
            {form.watch('hasSerialNumber') && (
              <TextField
                disabled={isDisabled}
                name="serialNumber"
                inputRef={register}
                error={!!errors.serialNumber}
                label={t('warehouse:serialNumber')}
                type="text"
                variant="outlined"
                helperText={errors.serialNumber ? t('common:required') : ''}
              />
            )}
          </div>
        </div>

        <hr className={classes.divider} />

        <div className={classes.formSection}>
          <h3>{t('warehouse:checkinStation.details')}</h3>
          <div className={classes.detailsContainer}>
            <AutocompleteInput<CountryGetListModel>
              className={classes.countriesOptions}
              control={control}
              disabled={isDisabled}
              errorText={errors['country']?.message}
              fullWidth={true}
              getOptionLabel={option => option.name || ''}
              label={t('warehouse:madeIn')}
              name="country"
              optionValue="countryId"
              options={countries}
              setValue={form.setValue}
              variant="outlined"
            />

            {ENABLE_KARAT_FIELD && (
              <Controller
                control={form.control}
                name="carat"
                render={({ ref, ...controlProps }) => (
                  <TextField
                    {...controlProps}
                    className={classes.caratInput}
                    disabled={isDisabled}
                    defaultValue="0"
                    error={!!errors.carat}
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ inputComponent: DecimalInput as React.ElementType<InputBaseComponentProps> }}
                    label={`${t('warehouse:measurements.carat')}${getUnit(EItemExtraFieldsKey.Carat)}`}
                  />
                )}
              />
            )}
          </div>
        </div>
      </div>

      <div className={classes.rightSection}>
        <div className={classes.imageContainer}>
          <Carousel images={form.watch('photos', [])} />
        </div>

        <div className={classes.itemDetailsContainer}>
          <div>
            <label className={classes.itemDetailLabel}>{t('warehouse:description')}</label>
            <span className={classes.itemDetailValue}>{form.watch('description')}</span>
          </div>
          <div>
            <label className={classes.itemDetailLabel}>{t('warehouse:colors')}</label>
            <ColorChips
              color={form.watch('color', defaultCheckInValues.color)?.englishName}
              colorLabel={form.watch('color', defaultCheckInValues.color).name}
            />
          </div>
          <div>
            <label className={classes.itemDetailLabel}>{t('warehouse:material')}</label>
            <span className={classes.itemDetailValue}>{form.watch('material')}</span>
          </div>
          <div>
            <label className={classes.itemDetailLabel}>{t('warehouse:size')}</label>
            <span className={classes.itemDetailValue}>{form.watch('size')}</span>
          </div>
          <div>
            <label className={classes.itemDetailLabel}>{t('common:warehouse')}</label>
            <span className={classes.itemDetailValue}>{form.watch('warehouse')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Found;
