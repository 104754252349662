import { RadioGroup } from '@lux-ds/radio';

import { css, styled } from 'utils/styles';

export const StyledRadioGroup = styled(RadioGroup)(
  ({ theme }) => css`
    flex-wrap: unset;
    gap: ${theme.space.xs};
  `
);
