import React from 'react';

import { Grid } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import { EMeasure } from 'models/warehouse';
import { IOnHoldItemInfo } from 'models/warehouse/onHold';

import reviewDetailsStyles from '../../onHold.styles';

interface IProps {
  measures: IOnHoldItemInfo['measures'];
}

const Measures: React.FC<IProps> = ({ measures }) => {
  const classes = reviewDetailsStyles();
  const { t } = useTranslation('warehouse');

  const getMeasurement = (id: EMeasure) => Number(measures.find(measure => measure.id === id)?.value || '0').toFixed(2);

  return (
    <>
      <h1 className={classes.title}>{t('measures')}</h1>

      <Grid container justifyContent="space-between" alignItems="center" direction="row">
        <Grid item lg={3}>
          <h3 className={classes.warehouseTextTitle}>{t('measurements.width')}</h3>
          <h3 className={classes.warehouseTextDetails}>{`${getMeasurement(EMeasure.Width)} ${t(
            'measureUnits.centimeters'
          )}`}</h3>
        </Grid>

        <Grid item lg={3}>
          <h3 className={classes.warehouseTextTitle}>{t('measurements.length')}</h3>
          <h3 className={classes.warehouseTextDetails}>{`${getMeasurement(EMeasure.Length)} ${t(
            'measureUnits.centimeters'
          )}`}</h3>
        </Grid>

        <Grid item lg={3}>
          <h3 className={classes.warehouseTextTitle}>{t('measurements.depth')}</h3>
          <h3 className={classes.warehouseTextDetails}>{`${getMeasurement(EMeasure.Depth)} ${t(
            'measureUnits.centimeters'
          )}`}</h3>
        </Grid>

        <Grid item lg={3}>
          <h3 className={classes.warehouseTextTitle}>{t('measurements.shoulderDrop')}</h3>
          <h3 className={classes.warehouseTextDetails}>
            {`${getMeasurement(EMeasure.ShoulderDrop)} ${t('measureUnits.centimeters')}`}
          </h3>
        </Grid>

        <Grid item lg={3}>
          <h3 className={classes.warehouseTextTitle}>{t('measurements.handleDrop')}</h3>
          <h3 className={classes.warehouseTextDetails}>
            {`${getMeasurement(EMeasure.HandleDrop)} ${t('measureUnits.centimeters')}`}
          </h3>
        </Grid>
      </Grid>
    </>
  );
};

export default Measures;
