import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const loadingSpinnerStyles = makeStyles((theme: Theme) => {
  return createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: theme.palette.primary.contrastText
    },
    spinner: {
      color: theme.palette.primary.contrastText
    }
  });
});

export default loadingSpinnerStyles;
