import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  CircularProgress,
  IconButton,
  LuxButton,
  MaterialIcons,
  MenuItem,
  Modal,
  Paper,
  TextField
} from '@luxclusif/material';
import moment from 'moment';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ITrackingNumber, TGetAllCouriers } from 'models/purchaseOrders';
import { IPurchaseInfo, muiDateFormat } from 'pages/PurchaseOrder/schema';

import { defaultTrackingNumber, defaultValues, ITrackingNumbersForm, trackingNumbersSchema } from './schema';

import trackingNumberModalStyles from './trackingNumberModal.styles';

interface IProps {
  couriers: TGetAllCouriers;
  isLoadingCouriers: boolean;
  onClose: () => void;
  onSubmit: (trackingNumbers: ITrackingNumber[]) => void;
  selectedItems: string[];
}

const TrackingNumberModal: React.FC<IProps> = ({ couriers, isLoadingCouriers, onClose, onSubmit, selectedItems }) => {
  const classes = trackingNumberModalStyles();
  const { t } = useTranslation(['common', 'purchaseOrders']);
  const { getValues } = useFormContext<IPurchaseInfo>();

  const currentItem =
    selectedItems.length === 1 ? getValues('items').find(({ itemId }) => itemId === selectedItems[0]) : undefined;

  const {
    control,
    errors,
    formState: { isSubmitted },
    handleSubmit,
    register,
    watch
  } = useForm<ITrackingNumbersForm>({
    defaultValues: defaultValues(currentItem),
    resolver: yupResolver(trackingNumbersSchema)
  });

  const { append, fields, remove } = useFieldArray<ITrackingNumber>({ control, name: 'trackingNumbers' });

  const trackingNumbers = watch('trackingNumbers', []) as ITrackingNumber[];

  const getDuplicateIndexes = () =>
    trackingNumbers.reduce((result: number[], { code, courier: { id } }, index) => {
      const hasDuplicate = trackingNumbers.some(
        (trackingNumber, duplicateIndex) =>
          trackingNumber.code &&
          trackingNumber.code === code &&
          trackingNumber.courier.id &&
          trackingNumber.courier.id === id &&
          duplicateIndex !== index
      );

      return hasDuplicate ? [...result, index] : result;
    }, []);

  const duplicateIndexes = isSubmitted ? getDuplicateIndexes() : [];

  const handleSave = ({ trackingNumbers }: ITrackingNumbersForm) =>
    !getDuplicateIndexes().length && onSubmit(trackingNumbers || []);

  return (
    <Modal onClose={onClose} open>
      <form onSubmit={handleSubmit(handleSave)}>
        <Paper className={classes.modalContent}>
          <h2 className={classes.title}>{t('purchaseOrders:poItems.assignTrackingNumber')}</h2>
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <MaterialIcons.Close />
          </IconButton>
          <span className={classes.description}>{t('purchaseOrders:poItems.trackingNumberModal.description')}</span>
          <b className={classes.subtitle}>
            {selectedItems.length > 1
              ? `${t('purchaseOrders:items')} (${selectedItems.length})`
              : `${t('common:item')} ${currentItem?.sku}`}
          </b>
          <div className={classes.trackingNumbers}>
            {!fields.length ? (
              <p className={classes.emptyTrackingNumbers}>
                {t('purchaseOrders:poItems.trackingNumberModal.noTrackingNumbersAssigned')}
              </p>
            ) : (
              fields.map(({ code, courier, estimatedDeliveryDate, id }, index) => (
                <div className={classes.trackingNumber} key={id}>
                  <div className={classes.inputs}>
                    <TextField
                      defaultValue={code}
                      error={!!(errors.trackingNumbers?.[index]?.code || duplicateIndexes.includes(index))}
                      helperText={
                        duplicateIndexes.includes(index) &&
                        t('purchaseOrders:poItems.trackingNumberModal.errorUniqueTrackingNumber')
                      }
                      inputRef={register()}
                      label={`${t('common:trackingNumber')} *`}
                      name={`trackingNumbers[${index}].code`}
                    />
                    <Controller
                      control={control}
                      defaultValue={courier}
                      name={`trackingNumbers[${index}].courier`}
                      render={({ onChange, ref, value: controlValue, ...controlProps }) => (
                        <TextField
                          {...controlProps}
                          error={!!(errors.trackingNumbers?.[index]?.courier || duplicateIndexes.includes(index))}
                          inputRef={ref}
                          label={`${t('purchaseOrders:carrier')} *`}
                          onChange={({ target: { value } }) => {
                            const selectedCourier = couriers.find(({ id }) => id === value) || null;

                            onChange(selectedCourier);
                          }}
                          select
                          SelectProps={{
                            IconComponent: isLoadingCouriers
                              ? props => <CircularProgress size={24} {...props} />
                              : undefined,
                            MenuProps: {
                              PaperProps: {
                                className: classes.selectMenu
                              },
                              anchorOrigin: {
                                horizontal: 'left',
                                vertical: 'bottom'
                              }
                            },
                            disabled: isLoadingCouriers
                          }}
                          value={controlValue?.id || ''}
                        >
                          {couriers.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <TextField
                      defaultValue={estimatedDeliveryDate && moment(estimatedDeliveryDate).format(muiDateFormat)}
                      error={!!errors.trackingNumbers?.[index]?.estimatedDeliveryDate}
                      InputLabelProps={{ shrink: true }}
                      inputRef={register()}
                      label={`${t('purchaseOrders:estimatedDeliveryDate')} *`}
                      name={`trackingNumbers[${index}].estimatedDeliveryDate`}
                      type="date"
                    />
                  </div>
                  <IconButton className={classes.deleteButton} onClick={() => remove(index)}>
                    <MaterialIcons.Delete />
                  </IconButton>
                </div>
              ))
            )}
          </div>
          <LuxButton
            className={classes.assignButton}
            startIcon={<MaterialIcons.Add />}
            onClick={() => append(defaultTrackingNumber)}
          >
            {t('purchaseOrders:poItems.trackingNumberModal.assignNewTrackingNumber')}
          </LuxButton>
          <div className={classes.actions}>
            <LuxButton className={classes.button} onClick={onClose} variant="outlined">
              {t('common:neverMind')}
            </LuxButton>
            <LuxButton className={classes.button} luxColor="secondary" type="submit" variant="contained">
              {t('common:saveChanges')}
            </LuxButton>
          </div>
        </Paper>
      </form>
    </Modal>
  );
};

export default TrackingNumberModal;
