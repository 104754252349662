import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const qualityCheckTableStyles = makeStyles(() => {
  return createStyles({
    container: {
      marginTop: '40px',
      marginBottom: '40px'
    },
    rowLabel: {
      fontSize: 16,
      fontWeight: 800
    },
    damageColumn: {
      width: 60
    },
    damageCount: {
      fontSize: 10
    },
    fixedCellsWidth: {
      '& > *:nth-child(2), & > *:nth-child(3)': {
        maxWidth: '220px'
      }
    },
    rowLabelCell: {
      width: '174px'
    },
    cellBorder: {
      fontSize: '12px',
      border: `1px solid ${secondaryNeutral.light75}`
    },
    cellGeneric: {
      display: 'flex',
      gap: '8px',
      paddingBottom: '32px',
      paddingTop: '32px'
    }
  });
});

export default qualityCheckTableStyles;
