import moment from 'moment';

import {
  EPurchaseOrderAllStatus,
  ICancelPurchaseOrder,
  ICancelReason,
  IGetAllPurchaseOrders,
  IGetPurchaseOrder,
  IGetSupplierPurchaseInfo,
  IPurchaseOrderFilters,
  ISplitPurchaseOrder,
  TGetAllBusinessModelOptions,
  TGetAllCouriers,
  TGetAllCurrencies,
  TGetAllFreightTerms,
  TGetAllPaymentMethods,
  TGetAllPaymentTerms,
  TGetAllStatus,
  TGetAllSupplierEntityOptions,
  TGetAllSupplierOptions
} from 'models/purchaseOrders';
import { EBusinessModelsId } from 'models/supplier';
import { IItemsInfoData, IPurchaseInfo } from 'pages/PurchaseOrder/schema';
import axios from 'utils/axios';

class PurchaseOrdersService {
  public async getPurchaseOrderById(id: string): Promise<IGetPurchaseOrder> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/${id}`;

    return await axios.get(url).then(response => response.data);
  }

  public async getAll(page: number, pageSize: number, filters?: IPurchaseOrderFilters): Promise<IGetAllPurchaseOrders> {
    let statusFilter = null;
    let purchaseDateRange = null;
    let deliveryDateRange = null;

    if (filters?.status && filters.status !== EPurchaseOrderAllStatus.AllItems) {
      const statusKey = filters.status;
      const pascalId = `${statusKey.charAt(0).toUpperCase()}${statusKey.slice(1)}`;

      statusFilter = pascalId;
    }

    if (filters?.purchaseDateRange?.startDate && filters?.purchaseDateRange?.endDate) {
      const { endDate, startDate } = filters.purchaseDateRange;

      purchaseDateRange = {
        endPurchaseOrderDate: moment(endDate).format('YYYY-MM-DD'),
        startPurchaseOrderDate: moment(startDate).format('YYYY-MM-DD')
      };
    }

    if (filters?.deliveryDateRange?.startDate && filters?.deliveryDateRange?.endDate) {
      const { endDate, startDate } = filters.deliveryDateRange;

      deliveryDateRange = {
        endExpectedDeliveryDate: moment(endDate).format('YYYY-MM-DD'),
        startExpectedDeliveryDate: moment(startDate).format('YYYY-MM-DD')
      };
    }

    const filtersParams = {
      ...(filters?.search && { freeTextSearch: filters.search }),
      ...(filters?.sortModel && { orderBy: filters.sortModel.field, orderDirection: filters.sortModel.sort }),
      ...(statusFilter && { status: statusFilter }),

      ...(filters?.supplier && { suppliersSearch: filters.supplier }),
      ...(filters?.businessModel && { businessModelsSearch: filters.businessModel }),
      ...(filters?.buyer && { buyersSearch: filters.buyer }),
      ...(filters?.warehouse && { warehousesSearch: filters.warehouse }),
      ...(purchaseDateRange && purchaseDateRange),
      ...(deliveryDateRange && deliveryDateRange)
    };

    const pageInfoParams = {
      pageIndex: page,
      pageSize: pageSize
    };

    const params = {
      ...pageInfoParams,
      ...filtersParams
    };

    const url = `${process.env.REACT_APP_API_URL}Purchases`;

    return await axios.get(url, { params }).then(response => response.data);
  }

  public async getAllSupplierOptions(supplierName?: string): Promise<TGetAllSupplierOptions> {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/suppliers`;
    const params = {
      ...(supplierName && { supplierName })
    };

    return await axios.get(url, { params }).then(response => response.data);
  }

  public async getAllSupplierEntityOptions(): Promise<TGetAllSupplierEntityOptions> {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/entity-companies`;

    return await axios.get(url).then(response => response.data);
  }

  public async getSupplierPurchaseInfo(supplierId: string, businessModel?: number): Promise<IGetSupplierPurchaseInfo> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/supplier-po-info`;

    const params = {
      SupplierId: supplierId,
      businessModel
    };

    return await axios.get(url, { params }).then(response => response.data);
  }

  public async getAllSupplierBusinessModelOptions(): Promise<TGetAllBusinessModelOptions> {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/business-models`;

    return await axios.get(url).then(response => response.data);
  }

  public async getAllCurrencyOptions(): Promise<TGetAllCurrencies> {
    const url = `${process.env.REACT_APP_API_URL}Currencies`;

    return await axios.get(url).then(response => response.data);
  }

  public async getAllPaymentTermOptions(): Promise<TGetAllPaymentTerms> {
    const url = `${process.env.REACT_APP_API_URL}Finances/payment-terms`;

    return await axios.get(url).then(response => response.data);
  }

  public async getAllPaymentMethodOptions(): Promise<TGetAllPaymentMethods> {
    const url = `${process.env.REACT_APP_API_URL}Finances/payment-methods`;

    return await axios.get(url).then(response => response.data);
  }

  public async getAllCourierOptions(): Promise<TGetAllCouriers> {
    const url = `${process.env.REACT_APP_API_URL}Couriers`;

    return await axios.get(url).then(response => response.data);
  }

  public async getAllFreightTermOptions(): Promise<TGetAllFreightTerms> {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/freight-terms`;

    return await axios.get(url).then(response => response.data);
  }

  public async getAllStatusOptions(): Promise<TGetAllStatus> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/purchase-statuses`;

    return await axios.get(url).then(response => response.data);
  }

  public async postFile(file: File, fileId: string): Promise<string> {
    const url = `${process.env.REACT_APP_API_URL}Files/upload-temp`;
    const formData = new FormData();

    formData.append('File', file);
    formData.append('FileId', fileId);

    const config = {
      headers: {
        'Content-Data': 'multipart/form-data'
      }
    };

    return await axios.post(url, formData, config).then(response => response.data);
  }

  public async importOrderItems(supplierId: string, businessModelId: string, file: File): Promise<IItemsInfoData> {
    const config = {
      headers: {
        'Content-Data': 'multipart/form-data'
      }
    };
    const url = `${process.env.REACT_APP_API_URL}Purchases/validate-items`;
    const formData = new FormData();

    formData.append('SupplierId', supplierId);
    formData.append('BusinessModelId', businessModelId);
    formData.append('File', file);

    return await axios.post(url, formData, config).then(response => response.data);
  }

  public async postPurchaseOrder(formInputs: IPurchaseInfo): Promise<string> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/create`;

    const formData = {
      ...formInputs,
      ...(formInputs.consignmentEndDate && { consignmentEndDate: new Date(formInputs.consignmentEndDate) }),
      ...(formInputs.consignmentStartDate && {
        consignmentStartDate: new Date(formInputs.consignmentStartDate)
      }),
      date: new Date(formInputs.date),
      estimatedDeliveryDate: new Date(formInputs.estimatedDeliveryDate),
      items: formInputs.items.map(({ brand: { id: brandId }, category: { id: categoryId }, ...item }) => ({
        ...item,
        brandId,
        categoryId
      }))
    };

    return await axios.post(url, formData).then(response => response.data);
  }

  public async putPurchaseOrder(id: string, formInputs: IPurchaseInfo): Promise<string> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/update/${id}`;

    delete formInputs.poNumber;
    delete formInputs.splitFrom;
    delete formInputs.supplierName;
    delete formInputs.trackingsDetail;

    if (formInputs.businessModelId !== EBusinessModelsId.PhysicalConsignment) {
      delete formInputs.consignmentEndDate;
      delete formInputs.consignmentStartDate;
    }

    const formData = {
      ...formInputs,
      date: new Date(formInputs.date),
      ...(formInputs.consignmentEndDate && {
        consignmentEndDate: new Date(formInputs.consignmentEndDate)
      }),
      ...(formInputs.consignmentStartDate && {
        consignmentStartDate: new Date(formInputs.consignmentStartDate)
      }),
      estimatedDeliveryDate: new Date(formInputs.estimatedDeliveryDate),
      items: formInputs.items.map(({ brand: { id: brandId }, category: { id: categoryId }, ...item }) => ({
        ...item,
        brandId,
        categoryId
      })),
      purchaseId: id
    };

    return await axios.put(url, formData).then(response => response.data);
  }

  public async removeOrderItems(purchaseId: string, itemIds: string[]): Promise<string> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/remove-items`;

    const body = {
      itemIds,
      purchaseId
    };

    return await axios.put(url, body).then(response => response.data);
  }

  public async splitOrderItems(purchaseId: string, items: string[]): Promise<ISplitPurchaseOrder> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/split`;

    const body = {
      items,
      purchaseId
    };

    return await axios.put(url, body).then(response => response.data);
  }

  public async getCancelReasons(): Promise<ICancelReason[]> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/cancel-reasons`;

    return await axios.get(url).then(response => response.data);
  }

  public async cancelOrderItems(
    purchaseId: string,
    itemIds: string[],
    reasonId: number
  ): Promise<ICancelPurchaseOrder> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/cancel-items`;

    const body = {
      itemIds,
      purchaseId,
      reasonId
    };

    return await axios.put(url, body).then(response => response.data);
  }

  public async getTemplateFile(): Promise<string> {
    const url = `${process.env.REACT_APP_API_URL}Purchases/template-file`;

    return await axios.get(url).then(response => response.data);
  }
}

const purchaseOrdersService = new PurchaseOrdersService();

export default purchaseOrdersService;
