export enum EBytesMeasure {
  B = 1,
  KB = 2,
  MB = 3
}

const convertBytes = (bytes: number, to: EBytesMeasure, from: EBytesMeasure = EBytesMeasure.B) => {
  const convertion = to - from;
  let result: number;

  if (convertion > 0) {
    result = bytes / Math.pow(1024, convertion);
  } else {
    result = bytes * Math.pow(1024, Math.abs(convertion));
  }

  return to === EBytesMeasure.MB ? Number(result.toFixed(2)) : Math.round(result);
};

export default convertBytes;
