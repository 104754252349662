import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { LayoutJay } from '@lux-ds/content-layouts';
import Tabs, { ITabsItem } from '@lux-ds/tabs';
import Typography from '@lux-ds/typography';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { EMatrixParent, IItemAuthenticationSchema } from 'pages/WarehouseList/schemas/authenticationSchema';

import { concernKeys, exteriorLocationKeys, interiorLocationKeys } from '../constants';
import AuthenticationMatrixTable from './components/AuthenticationMatrixTable';

const AuthenticationMatrix: React.FC = () => {
  const { t } = useTranslation('warehouse');
  const { warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IItemAuthenticationSchema>, [warehouseForm]);

  const [tab, setTab] = useState<EMatrixParent>(EMatrixParent.Exterior);

  const {
    formState: { dirtyFields, errors }
  } = form;

  const tabs = useMemo(
    (): ITabsItem[] => [
      {
        label: t('qualityCheckStation.exterior'),
        subLabel: exteriorLocationKeys
          .reduce(
            (result, key) => result + concernKeys.filter(concern => form.getValues(`${key}.${concern}`)).length,
            0
          )
          .toString(),
        value: EMatrixParent.Exterior
      },
      {
        label: t('qualityCheckStation.interior'),
        subLabel: interiorLocationKeys
          .reduce(
            (result, key) => result + concernKeys.filter(concern => form.getValues(`${key}.${concern}`)).length,
            0
          )
          .toString(),
        value: EMatrixParent.Interior
      }
    ],
    [form, form.watch([...exteriorLocationKeys, ...interiorLocationKeys]), t]
  );

  const handleSetTab = useCallback((_, tab: EMatrixParent) => setTab(tab), [setTab]);

  useEffect(() => {
    const values = form.getValues() || {};
    const locationKeys = [...exteriorLocationKeys, ...interiorLocationKeys];

    const hasMatrixConcern = locationKeys.some(key => Object.values(values[key] || {}).some(value => !!value));

    form.setValue('hasMatrixConcern', hasMatrixConcern, { shouldValidate: true });

    if (locationKeys.some(key => Object.keys(dirtyFields).includes(key))) {
      form.trigger('hasMatrixConcern');
    }
  }, [dirtyFields, form.watch([...exteriorLocationKeys, ...interiorLocationKeys]), form]);

  return (
    <LayoutJay gap="2xl">
      <Typography color={errors.hasMatrixConcern && 'error.main'} variant="h6">
        {`${t('authenticationStation.authenticationMatrix')} *`}
      </Typography>
      <LayoutJay gap="xxs">
        <Tabs items={tabs} onChange={handleSetTab} value={tab} />
        <AuthenticationMatrixTable tab={tab} />
      </LayoutJay>
    </LayoutJay>
  );
};

export default AuthenticationMatrix;
