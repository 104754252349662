import React, { createContext, useCallback, useEffect, useState } from 'react';

import {
  EAuthenticationStatusFilter,
  EInboundFlow,
  EItemProcessOrderBy,
  EQualityCheckStatusFilter,
  EStation,
  GetAllItemProcessesModelV2,
  ItemProcessGetListModelPagedListCounts
} from '@inbound/api';
import { IMultipleSelectOptionIdName, IMultipleSelectOptionKeyValue } from '@lux-ds/data-grid';
import { useNotification } from '@lux-ds/notification';
import { useTranslation } from 'react-i18next';

import useApi from 'hooks/useApi';
import useWarehouseContext from 'hooks/useWarehouseContext';
import isValidUUID from 'utils/isValidUUID';

interface IWarehouseListContext {
  counts: ItemProcessGetListModelPagedListCounts;
  getWarehouse: () => void;
  isLoading: boolean;
  isWarehouseIssuePanelOpen: boolean;
  items: GetAllItemProcessesModelV2[];
  onWarehouseIssuePanelClose: () => void;
  onWarehouseIssuePanelOpen: () => void;
  totalItems: number;
}

const countsDefaultValue: ItemProcessGetListModelPagedListCounts = {
  allItems: 0,
  authentication: 0,
  checkin: 0,
  photoAuthentication: 0,
  qualityControl: 0,
  storage: 0,
  toReview: 0,
  withIssues: 0
};

const WarehouseListContextV2 = createContext<IWarehouseListContext>({
  counts: countsDefaultValue,
  getWarehouse: () => void 0,
  isLoading: true,
  isWarehouseIssuePanelOpen: false,
  items: [],
  onWarehouseIssuePanelClose: () => void 0,
  onWarehouseIssuePanelOpen: () => void 0,
  totalItems: 0
});

const WarehouseListProviderV2: React.FC = ({ children }) => {
  const api = useApi();
  const { enqueueNotification } = useNotification();
  const { t } = useTranslation(['common', 'warehouse']);
  const {
    activeFilterTab,
    activeWarehouse,
    filters,
    getFilterOptions,
    getWarehouseOptions,
    includeProcessedItems,
    page,
    pageSize,
    resetWarehouseIssue,
    search,
    setPage,
    sortModel
  } = useWarehouseContext();

  const [counts, setCounts] = useState<IWarehouseListContext['counts']>(countsDefaultValue);
  const [isLoading, setIsLoading] = useState<IWarehouseListContext['isLoading']>(true);
  const [isWarehouseIssuePanelOpen, setIsWarehouseIssuePanelOpen] =
    useState<IWarehouseListContext['isWarehouseIssuePanelOpen']>(false);
  const [items, setItems] = useState<IWarehouseListContext['items']>([]);
  const [totalItems, setTotalItems] = useState<IWarehouseListContext['totalItems']>(0);

  const mapOrderBy = useCallback(
    (orderBy: string) => {
      switch (orderBy) {
        case 'authTimestamp':
          return EItemProcessOrderBy.AuthenticationUpdateAtUtc;
        case 'openProcessAtUtc':
          return EItemProcessOrderBy.OpenProcessAtUtc;
        case 'qcTimestamp':
          return EItemProcessOrderBy.QualityCheckUpdatedAtUtc;
        case 'currentStation':
          return EItemProcessOrderBy.CurrentStationName;
        case 'daysWithIssues':
          return EItemProcessOrderBy.FirstIssueReportedAtUtc;
        case 'daysInStation':
        default:
          return activeFilterTab === 'withIssues'
            ? EItemProcessOrderBy.FirstIssueReportedAtUtc
            : EItemProcessOrderBy.CurrentStationAtUtc;
      }
    },
    [activeFilterTab]
  );

  const mapStationId = useCallback(() => {
    switch (activeFilterTab) {
      case 'checkin':
        return EStation.Checkin;
      case 'photoAuthentication':
        return EStation.PhotoAuthentication;
      case 'authentication':
        return EStation.Authentication;
      case 'qualityControl':
        return EStation.QualityControl;
      case 'storage':
        return EStation.Storage;
      default:
        return null;
    }
  }, [activeFilterTab]);

  const mapCommonFilters = useCallback(
    (filters: IMultipleSelectOptionIdName[] | IMultipleSelectOptionKeyValue[]) =>
      filters.map(filter => ('id' in filter ? filter.id.toString() : filter.key.toString())),
    []
  );

  const getWarehouse = useCallback(() => {
    const getWarehouseCallback = async () => {
      try {
        setIsLoading(true);

        const inboundFlowFilters =
          (filters?.inboundFlow as IMultipleSelectOptionKeyValue[])?.map(
            ({ key }) => EInboundFlow[key as keyof typeof EInboundFlow]
          ) || null;

        const qcDecisionFilters =
          (filters?.qualityCheckDecision as IMultipleSelectOptionKeyValue[])?.map(
            ({ key }) => EQualityCheckStatusFilter[key as keyof typeof EQualityCheckStatusFilter]
          ) || null;

        const authResults =
          (filters?.authenticationResult as IMultipleSelectOptionKeyValue[])?.map(
            ({ key }) => EAuthenticationStatusFilter[key as keyof typeof EAuthenticationStatusFilter]
          ) || null;

        const { field: orderBy, sort: orderDirection } = sortModel?.[0] || { field: null, sort: null };
        const { counts, items, totalItems } = await api.warehouse_GetAllProcessesV2(
          page,
          pageSize,
          mapOrderBy(orderBy),
          orderBy ? orderDirection || null : 'desc',
          activeWarehouse || null,
          mapStationId(),
          activeFilterTab === 'withIssues', // With Issues
          includeProcessedItems,
          search,
          filters?.supplier ? mapCommonFilters(filters?.supplier as IMultipleSelectOptionKeyValue[]) : null,
          inboundFlowFilters,
          filters?.brand ? mapCommonFilters(filters?.brand as IMultipleSelectOptionIdName[]) : null,
          qcDecisionFilters,
          authResults
        );

        setCounts(counts || countsDefaultValue);
        setItems(items || []);
        setTotalItems(totalItems || 0);
      } catch {
        setItems([]);
        setPage(0);
        setTotalItems(0);
        enqueueNotification({ title: t('warehouse:errors.errorGetWarehouse') }, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    getWarehouseCallback();
  }, [
    activeFilterTab,
    activeWarehouse,
    api,
    enqueueNotification,
    filters,
    includeProcessedItems,
    mapCommonFilters,
    mapOrderBy,
    mapStationId,
    page,
    pageSize,
    search,
    setPage,
    sortModel,
    t
  ]);

  useEffect(() => {
    getFilterOptions();
    getWarehouseOptions();
  }, []);

  useEffect(() => {
    if (isValidUUID(activeWarehouse)) {
      getWarehouse();
    }
  }, [activeFilterTab, activeWarehouse, filters, includeProcessedItems, page, pageSize, search, sortModel]);

  const handleWarehouseIssuePanelClose = useCallback(() => {
    setIsWarehouseIssuePanelOpen(false);
    resetWarehouseIssue();
  }, []);
  const handleWarehouseIssuePanelOpen = useCallback(() => setIsWarehouseIssuePanelOpen(true), []);

  return (
    <WarehouseListContextV2.Provider
      value={{
        counts,
        getWarehouse,
        isLoading,
        isWarehouseIssuePanelOpen,
        items,
        onWarehouseIssuePanelClose: handleWarehouseIssuePanelClose,
        onWarehouseIssuePanelOpen: handleWarehouseIssuePanelOpen,
        totalItems
      }}
    >
      {children}
    </WarehouseListContextV2.Provider>
  );
};

export { WarehouseListProviderV2 };

export default WarehouseListContextV2;
