import { Profile } from 'oidc-client';

import axios from '../utils/axios';

class UserService {
  public async getUserClaims(): Promise<Profile | null> {
    const url = `${process.env.REACT_APP_STS_AUTHORITY}/connect/userinfo`;

    return await axios.get(url).then(response => response?.data);
  }
}

const userService = new UserService();

export default userService;
