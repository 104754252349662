import { createStyles, makeStyles } from '@luxclusif/material';

const multipleSelectStyles = makeStyles(() => {
  return createStyles({
    container: {
      position: 'relative'
    },

    dropDownToggler: {
      lineHeight: 1,
      fontSize: '14px',
      padding: '10px 12px 10px 16px',
      '& .MuiSvgIcon-root': {
        fontSize: '18px',
        marginLeft: '7px'
      }
    },
    dropDownTogglerInactive: {
      color: '#86867E',
      '& .MuiSvgIcon-root': {
        color: '#86867E'
      }
    },
    dropDownTogglerActive: {
      '& .MuiSvgIcon-root': {
        color: 'white'
      }
    },
    dropDownContainer: {
      zIndex: 1,
      boxShadow: '0 2px 4px 0 rgba(68,68,66,0.32)',
      marginTop: '8px',
      minWidth: '308px',
      position: 'absolute',
      padding: '16px',
      background: 'white'
    },
    dropDownTitle: {
      marginTop: 0,
      marginBottom: '24px',
      fontSize: '16px',
      lineHeight: 1
    },
    dropDownSearchInput: {
      width: '100%',
      marginBottom: '16px',
      '& .MuiInputBase-input': {
        padding: '16px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#DDDDDA'
      }
    },
    dropDownOptions: {
      maxHeight: '252px',
      overflow: 'auto',
      display: 'flex',
      flexFlow: 'column'
    },
    option: {
      width: '100%',
      marginRight: 0
    },
    dropDownButtons: {
      marginTop: '24px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '8px'
    }
  });
});

export default multipleSelectStyles;
