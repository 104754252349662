import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const dateRangeStyles = makeStyles((theme: Theme) => {
  return createStyles({
    rangeDatePickerContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    rangeDateDescription: {
      color: secondaryNeutral.light40,
      fontWeight: 600,
      backgroundColor: theme.palette.background.default,
      padding: '10px 0 10px 16px',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center'
    },
    rangeDateIcon: {
      color: secondaryNeutral.light40,
      height: '14px',
      width: '14px',
      marginLeft: '16px',
      '&:hover': {
        color: secondaryNeutral.light65,
        cursor: 'pointer'
      }
    },
    initialDatePicker: {
      borderRight: `1px solid ${secondaryNeutral.light75}`
    },
    finalDatePicker: {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px'
    },
    datePicker: {
      backgroundColor: theme.palette.primary.contrastText,
      borderBottom: 0,
      width: '120px',
      color: secondaryNeutral.normal
    },
    datePickerInput: {
      textAlign: 'center',
      fontSize: '14px',
      height: '16px',
      padding: '10px 16px',
      borderWidth: '0'
    }
  });
});

export default dateRangeStyles;
