import React from 'react';

import moment from 'moment';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ITrackingDetail } from 'models/purchaseOrders';

import purchaseOrderStyles from 'pages/PurchaseOrder/purchaseOrder.styles';

import trackingNumbersSectionStyles from './trackingNumbersSection.styles';

const TrackingNumbersSection: React.FC = () => {
  const watchTrackingsDetail = useWatch<ITrackingDetail[]>({ defaultValue: [], name: 'trackingsDetail' });

  const { t } = useTranslation(['common', 'purchaseOrders']);

  const commonClasses = purchaseOrderStyles();
  const classes = trackingNumbersSectionStyles();

  return (
    <>
      <h4 className={commonClasses.formSectionTitle}>
        {t('common:trackingNumbers')} ({watchTrackingsDetail.length})
      </h4>
      <div className={classes.container}>
        {watchTrackingsDetail.map(({ totalItems, trackingNumber }, index) => (
          <div key={index} className={classes.content}>
            <div className={classes.trackingNumber}>
              <div className={classes.label}>{t('common:trackingNumber')}</div>
              <div className={classes.value}>{trackingNumber.code}</div>
            </div>

            <div className={classes.carrier}>
              <div className={classes.label}>{t('purchaseOrders:carrier')}</div>
              <div className={classes.value}>{trackingNumber.courier.name}</div>
            </div>

            <div className={classes.totalItems}>
              <div className={classes.label}>{t('purchaseOrders:poInformation.totalItems')}</div>
              <div className={classes.value}>{totalItems}</div>
            </div>

            <div className={classes.deliveryDate}>
              <div className={classes.label}>{t('purchaseOrders:estimatedDeliveryDate')}</div>
              <div className={classes.value}>
                {trackingNumber.estimatedDeliveryDate
                  ? moment(trackingNumber.estimatedDeliveryDate).format('MM/DD/YYYY')
                  : '-'}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TrackingNumbersSection;
