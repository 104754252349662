import React from 'react';

import { EInboundFlow } from 'models/warehouse';
import pascalize from 'utils/pascalize';

import inboundFlowChipStyles, { inboundFlowColorsStyles } from './inboundFlowChip.styles';

interface IProps {
  label: string;
  subLabel?: string;
  type: EInboundFlow;
}

const InboundFlowChip: React.FC<IProps> = ({ label, subLabel, type }) => {
  const classes = inboundFlowChipStyles();
  const classesColors = inboundFlowColorsStyles();

  return (
    <div className={`${classes.container} ${classesColors[pascalize(type) as EInboundFlow]}`}>
      <p>{label}</p>
      {subLabel && <p className={classes.subLabel}>{subLabel}</p>}
    </div>
  );
};

export default InboundFlowChip;
