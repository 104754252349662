import { LayoutLyssa } from '@lux-ds/body-layouts';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import { LuxThemeType } from '@lux-ds/theming';
import { Cancel, CheckCircle, Warning } from '@mui/icons-material';

import { alpha, createStyles, css, makeStyles, styled } from 'utils/styles';

export const StyledLyssaV1 = styled(LayoutLyssa)`
  grid-template-rows: repeat(2, min-content) 1fr;
`;

export const StyledLyssa = styled(LayoutLyssa)(
  () => css`
    max-height: calc(100% - 115px);
    grid-template-rows: min-content 1fr;
    padding-top: unset;
  `
);

export const StyledLastStation = styled(LayoutHerodias)(
  ({ theme }) => css`
    gap: ${theme.space.xs};

    & svg {
      font-size: ${theme.space.s};
    }
  `
);

export const StyledHeaderJay = styled(LayoutJay)(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.primary.shade[100]};
    box-shadow: 0px 2px 4px rgba(68, 68, 66, 0.05);
    column-gap: ${theme.space.l};
    position: relative;
  `
);

export const StyledCheckCircleIcon = styled(CheckCircle)(
  ({ theme }) => css`
    color: ${theme.palette.success.main};
    font-size: ${theme.space.s};
  `
);

export const StyledCancelIcon = styled(Cancel)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    font-size: ${theme.space.s};
  `
);

export const StyledWarningIcon = styled(Warning)(
  ({ theme }) => css`
    color: ${theme.palette.warning.main};
    font-size: ${theme.space.s};
  `
);

export const warehouseListStyles = makeStyles((theme: LuxThemeType) => {
  return createStyles({
    highlighted: {
      '&.MuiDataGrid-row': {
        borderLeft: `3px solid ${theme.palette.citlali.main}`,

        '&:nth-of-type(odd)': {
          backgroundColor: `${theme.palette.citlali.light}`,

          '&:hover': {
            backgroundColor: `${alpha(theme.palette.citlali.main, 0.3)}`
          }
        },

        '&:nth-of-type(even)': {
          backgroundColor: `${alpha(theme.palette.citlali.main, 0.3)}`,

          '&:hover': {
            backgroundColor: `${alpha(theme.palette.citlali.main, 0.5)}`
          }
        }
      }
    },
    selected: {
      '&.MuiDataGrid-row': {
        backgroundColor: `${theme.palette.primary.shade[98]}`
      }
    }
  });
});
