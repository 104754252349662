import React, { useEffect, useState } from 'react';

import { CircularProgress, MenuItem, TextField } from '@luxclusif/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TGetAllBuyers } from 'models/purchaseOrders';
import { IPurchaseInfo } from 'pages/PurchaseOrder/schema';
import buyersService from 'services/Buyers.service';

import purchaseOrderStyles from 'pages/PurchaseOrder/purchaseOrder.styles';

import orderInformationStyles from '../../orderInformation.styles';

const SupplierSection: React.FC = () => {
  const { t } = useTranslation('purchaseOrders');
  const commonClasses = purchaseOrderStyles();
  const orderInformationClasses = orderInformationStyles();
  const {
    control,
    formState: { errors }
  } = useFormContext<IPurchaseInfo>();

  const [loadingBuyers, setLoadingBuyers] = useState<boolean>(true);
  const [buyersOptions, setBuyersOptions] = useState<TGetAllBuyers>([]);

  const getBuyers = async () => {
    try {
      const buyers = await buyersService.getBuyers();

      setBuyersOptions(buyers);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBuyers(false);
    }
  };

  useEffect(() => {
    getBuyers();
  }, []);

  return (
    <>
      <h4 className={commonClasses.formSectionTitle}>{t('poInformation.buyerIdentification')}</h4>
      <div className={orderInformationClasses.inputsContainer}>
        <Controller
          control={control}
          name="buyerId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.buyerId}
              inputRef={ref}
              label={`${t('buyer')} *`}
              select
              SelectProps={{
                IconComponent: loadingBuyers ? props => <CircularProgress size={16} {...props} /> : undefined,
                MenuProps: {
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom'
                  }
                },
                disabled: loadingBuyers
              }}
            >
              {buyersOptions.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div>
    </>
  );
};

export default SupplierSection;
