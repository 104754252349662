const isProd = process.env.NODE_ENV === 'production';

const downloadResource = (url: string, prefixName = '') => {
  fetch(url, { mode: isProd ? 'cors' : 'no-cors' })
    .then(response => response.blob())
    .then(blob => {
      const imageName = url.split('/').pop() || '';
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = prefixName ? `${prefixName}_${imageName}` : imageName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(e => console.error(e));
};

export default downloadResource;
