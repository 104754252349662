import React, { useEffect } from 'react';

import { AuthService } from '@luxclusif/material';

const Signin: React.FC = () => {
  useEffect(() => {
    AuthService.getUserManager()
      .signinRedirectCallback()
      .then(({ state }: { state?: { redirectUrl: string } }) => {
        window.location.href = state?.redirectUrl || '/';
      })
      .catch((e: any) => {
        console.error(e);
      });
  }, []);

  return null;
};

export default Signin;
