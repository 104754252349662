import React from 'react';

import { IconButton } from '@luxclusif/material';
import PhotoIcon from '@mui/icons-material/Photo';
import { useDrag } from 'react-dnd';
import { InView } from 'react-intersection-observer';

import imageFrameStyles from './imageFrame.styles';

interface IImageFrameAction {
  icon: React.ReactElement;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface IProps {
  actions?: IImageFrameAction[];
  canDrag?: boolean;
  dragItemInfo: unknown;
  dragType: string;
  label: React.ReactNode | string;
  src: string;
}

/**
 * @description this is based on lux-ds' @lux-ds/image and can be replaced with it once lux-ds is fully installed
 */
const ImageFrame: React.FC<IProps> = ({ actions, canDrag, dragItemInfo, dragType, label, src }) => {
  const classes = imageFrameStyles();

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      canDrag,
      collect: monitor => ({ isDragging: monitor.isDragging() }),
      item: dragItemInfo,
      type: dragType
    }),
    [dragItemInfo, dragType]
  );

  return (
    <div
      ref={dragRef}
      className={`${classes.container}${canDrag ? ` ${classes.containerDraggable}` : ''}${
        isDragging ? ` ${classes.containerDragging}` : ''
      }`}
    >
      <div className={classes.headerContainer}>
        <InView triggerOnce>
          {({ inView, ref }) => <img ref={ref} className={classes.headerImage} src={inView ? src : ''} />}
        </InView>
        {actions?.length && (
          <div className={classes.actionsContainer}>
            {actions.map((action, index) => (
              <IconButton key={index} className={classes.actionButton} onClick={action.onClick}>
                {action.icon}
              </IconButton>
            ))}
          </div>
        )}
      </div>

      <div className={classes.imageLabel}>
        <PhotoIcon />
        <span>{label}</span>
      </div>
      {isDragging && <div className={classes.placeHolderDragging} />}
    </div>
  );
};

export default ImageFrame;
