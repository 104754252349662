import * as yup from 'yup';

import { ITrackingNumber } from 'models/purchaseOrders';
import { IItemInfo, trackingNumberSchema } from 'pages/PurchaseOrder/schema';

export interface ITrackingNumbersForm {
  trackingNumbers?: ITrackingNumber[];
}

export const trackingNumbersSchema = yup.object({
  trackingNumbers: trackingNumberSchema
});

export const defaultTrackingNumber: ITrackingNumber = {
  code: '',
  courier: {
    id: '',
    name: ''
  },
  estimatedDeliveryDate: ''
};

export const defaultValues = (item?: IItemInfo): ITrackingNumbersForm => ({
  trackingNumbers: item?.trackingNumbers?.length
    ? item.trackingNumbers.map(({ code, courier, estimatedDeliveryDate }) => ({
        code,
        courier,
        estimatedDeliveryDate
      }))
    : [defaultTrackingNumber]
});
