import { BankDetailsGetModel } from '@inbound/api';
import * as yup from 'yup';

import {
  IBankDetails,
  IProfile,
  ISupplierBusinessModelTargetSettingsUpdate,
  ISupplierDetails,
  ISupplierFormDraftBusinessModel,
  ISupplierFormDraftWarehouse,
  ITargetSettings,
  IWarehouse
} from 'models/supplier';

import { supplierBankSchema } from './bankDetailSchema';

export const supplierSchema = yup.object().shape({
  address1: yup.string().required(),
  bankAccounts: yup.array().of(supplierBankSchema).min(0).notRequired(),
  businessModel: yup
    .array()
    .of(
      yup.object().shape({
        averageHTML: yup.string().notRequired(),
        averages: yup.string().notRequired(),
        businessModelId: yup.string().notRequired(),
        businessModelType: yup.string().required(),
        businessModelTypeHTML: yup.string().notRequired(),
        carrier: yup.string().required(),
        carrierHTML: yup.string().notRequired(),
        code: yup.string().notRequired(),
        commercialModel: yup.string().notRequired(),
        commercialModelHTML: yup.string().notRequired(),
        condition: yup.string().notRequired(),
        conditionHTML: yup.string().notRequired(),
        defaultFreightTerms: yup.string().required(),
        defaultFreightTermsHTML: yup.string().notRequired(),
        deliveryLeadTime: yup.number().integer().min(1).required(),
        deliverySLAType: yup.string().notRequired(),
        files: yup
          .object()
          .shape({
            files: yup.array().of(yup.mixed().notRequired()).notRequired(),
            filesToDelete: yup.array().of(yup.string().notRequired()).notRequired()
          })
          .notRequired(),
        financialPayment: yup.string().required(),
        financialPaymentHTML: yup.string().notRequired(),
        internalComments: yup.string().notRequired(),
        notes: yup.string().notRequired(),
        numberDays: yup.string().required(),
        numberDaysType: yup.string().notRequired(),
        paymentMethod: yup.string().required(),
        paymentMethodHTML: yup.string().notRequired(),
        photos: yup.string().required(),
        reception: yup.string().required(),
        receptionHTML: yup.string().notRequired(),
        responsible: yup.string().notRequired(),
        status: yup.string().required(),
        statusHTML: yup.string().notRequired(),
        targetSettings: yup.array().of(
          yup
            .object()
            .shape({
              endDate: yup
                .date()
                .when('startDate', (eventStartDate: any, schema: any) => eventStartDate && schema.min(eventStartDate))
                .required(),
              goal: yup.string().required(),
              poReference: yup.string().required(),
              startDate: yup.date().required(),
              targetSettingsId: yup.string().notRequired(),
              testItems: yup.string().required(),
              unit: yup.string().required(),
              unitHTML: yup.string().notRequired()
            })
            .notRequired()
        ),
        tier: yup.string().notRequired(),
        tierHTML: yup.string().notRequired(),
        warehouseFrom: yup.string().notRequired(),
        warehouseFromHTML: yup.string().notRequired()
      })
    )
    .min(1)
    .required(),
  city: yup.string().required(),
  cityHTML: yup.string().notRequired(),
  ...(process.env.REACT_APP_ENABLE_SUPPLIER_CLUSTER === 'true'
    ? { clusters: yup.array().of(yup.string()).min(1).required() }
    : {}),
  contactEmail: yup.string().notRequired(),
  contactName: yup.string().required(),
  contactPerson: yup.string().required(),
  contactTelephone: yup.string().notRequired(),
  country: yup.string().required(),
  countryHTML: yup.string().notRequired(),
  currency: yup.string().required(),
  currencyHTML: yup.string().notRequired(),
  emailAddress: yup.string().email().required(),
  entityCompany: yup.string().required(),
  entityCompanyHTML: yup.string().notRequired(),
  internalNotes: yup.string().notRequired(),
  logo: yup.string().notRequired(),
  name: yup.string().required(),
  postalCode: yup.string().required(),
  regionId: yup.string().required(),
  relevanceId: yup.string().required('Supplier Relevance is a required field'),
  state: yup.string().required(),
  stateHTML: yup.string().notRequired(),
  status: yup.string().notRequired(),
  supplierAddresses: yup
    .array()
    .of(
      yup.object().shape({
        supplierAddress: yup.string().required(),
        supplierCity: yup.string().required(),
        supplierCityHTML: yup.string().notRequired(),
        supplierCountry: yup.string().required(),
        supplierCountryHTML: yup.string().notRequired(),
        supplierPostalCode: yup.string().required(),
        supplierState: yup.string().required(),
        supplierStateHTML: yup.string().notRequired(),
        supplierWarehouseLocation: yup.string().required(),
        warehouseAddressId: yup.string().notRequired()
      })
    )
    .min(1)
    .required(),
  supplierId: yup.string().notRequired(),
  website: yup.string().notRequired()
});

export const defaultSupplierValues = () => {
  return {
    address1: '',
    banks: [],
    businessModel: [],
    city: '',
    cityHTML: '',
    clusters: [],
    contactEmail: '',
    contactName: '',
    contactPerson: '',
    contactTelephone: '',
    country: '',
    countryHTML: '',
    currency: '',
    currencyHTML: '',
    emailAddress: '',
    entityCompany: '',
    entityCompanyHTML: '',
    internalNotes: '',
    logo: '',
    name: '',
    postalCode: '',
    regionId: '',
    relevanceId: '',
    state: '',
    stateHTML: '',
    status: '',
    supplierAddresses: [],
    supplierId: '',
    website: ''
  };
};

export const supplierEditInitialize = (
  items: any,
  setValue: (
    name: string,
    value: unknown,
    config?:
      | Partial<{
          shouldDirty: boolean;
          shouldValidate: boolean;
        }>
      | undefined
  ) => void,
  reset: any
) => {
  const banks: IBankDetails[] = items.bankAccounts.map((value: BankDetailsGetModel) => ({
    bankAccountId: value.bank?.id || '',
    bankAccountNumber: value.accountNumberIban || '',
    bankAddress: value.address || '',
    bankCountry: value.countryId || '',
    bankEmail: value.email || '',
    bankName: value.bank?.name || '',
    beneficiaryName: value.beneficiaryName || '',
    swiftOrBic: value.swift || ''
  }));

  const warehouses: IWarehouse[] = items.warehouses.map((value: ISupplierFormDraftWarehouse) => ({
    supplierAddress: value.addressLine1 || '',
    supplierCity: value.cityId || '',
    supplierCityHTML: value.city || '',
    supplierCountry: value.countryId || '',
    supplierCountryHTML: value.country || '',
    supplierPostalCode: value.postalCode,
    supplierState: value.stateId || '',
    supplierStateHTML: value.state || '',
    supplierWarehouseLocation: value.location || '',
    warehouseAddressId: value.warehouseId || ''
  }));

  const supplierProfile: IProfile[] = items.profiles.map((value: ISupplierFormDraftBusinessModel) => {
    const targetSettingsObject: ITargetSettings[] | never[] = value.testingPeriodTargets.map(
      (data: ISupplierBusinessModelTargetSettingsUpdate) => ({
        endDate: data.endAtUtc || '',
        goal: data.value || '',
        poReference: data.poReference || '',
        startDate: data.startAtUtc || '',
        targetSettingsId: data.testingPeriodTargetId || '',
        testItems: data.numberOfItems || '',
        unit: data.unitId || ''
      })
    );

    return {
      averageHTML: '',
      averages: '',
      businessModelId: value.profileId || '',
      businessModelType: value.businessModelTypeId || '',
      businessModelTypeHTML: value.businessModelType || '',
      carrier: value.courierId || '',
      carrierHTML: value.courier || '',
      code: value.shortCode || '',
      commercialModel: value.commercialModelId || '',
      commercialModelHTML: value.commercialModel || '',
      condition: '',
      defaultFreightTerms: value.defaultFreightTermId || '',
      defaultFreightTermsHTML: value.defaultFreightTerm || '',
      deliveryLeadTime: value.deliverySLADays.toString(),
      deliverySLAType: value.deliverySLAIsWorkingDays ? 'Work' : 'Calendar',
      files: {
        files: value.files || '',
        filesToDelete: []
      },
      financialPayment: value.paymentTerms.length !== 0 ? value.paymentTerms[0].paymentTermId : '',
      internalComments: value.returnPolicyNotes || '',
      notes: value.technologicalNotes || '',
      numberDays: value.returnPolicyDays.toString() || 0,
      numberDaysType: value.returnPolicyIsWorkingDays ? 'Work' : 'Calendar',
      paymentMethod: value.paymentMethodId || '',
      paymentMethodHTML: value.paymentMethod || '',
      photos: value.useSupplierPhotos ? 'Yes' : 'No',
      reception: value.shippingWarehouses.length !== 0 ? value.shippingWarehouses[0].warehouseId : '',
      responsible: '',
      status: value.statusId || '',
      statusHTML: value.status || '',
      targetSettings: targetSettingsObject,
      tier: value.tierId || '',
      tierHTML: value.tier || '',
      warehouseFrom: '',
      warehouseFromHTML: ''
    };
  });

  const formItem: ISupplierDetails = {
    address1: items.addressLine1 || '',
    city: items.cityId || '',
    cityHTML: items.city || '',
    clusters: items.clusters || [],
    contactEmail: items.contactEmail || '',
    contactName: items.contactName || '',
    contactPerson: items.luxclusifContactName || '',
    contactTelephone: items.contactPhone || '',
    country: items.countryId || '',
    countryHTML: items.country || '',
    currency: items.billingCurrencyId || '',
    currencyHTML: items.billingCurrency || '',
    emailAddress: items.email || '',
    entityCompany: items.entityCompanyId || '',
    entityCompanyHTML: items.entityCompany || '',
    internalNotes: items.internalNotes || '',
    logo: items.logo || '',
    name: items.name || '',
    postalCode: items.postalCode || '',
    regionId: items.regionId || '',
    relevanceId: items.relevanceId || '',
    state: items.stateId || '',
    stateHTML: items.state || '',
    status: items.status || '',
    supplierId: items.supplierId || '',
    website: items.website || ''
  };

  reset(formItem);
  setValue('businessModel', supplierProfile);
  setValue('supplierAddresses', warehouses, { shouldValidate: true });
  setValue('supplierBankDetails', banks, { shouldValidate: true });
};
