import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { LuxButton, Grid, Modal, Paper, TableContainer } from '@luxclusif/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from 'components/Input/Input/Input';
import {
  SupplierReturnAndBboxSchema,
  defaultSupplierReturnAndBboxSchemaValues
} from 'pages/WarehouseList/schemas/utilitySchema/BBoxSchema';

import returnModalStyles from './returnModal.styles';

interface IProps {
  returnModalOpened: boolean;
  setReturnModalOpened: (value: boolean) => void;
}

const ReturnModal: React.FC<IProps> = ({ returnModalOpened, setReturnModalOpened }) => {
  const classes = returnModalStyles();
  const { t } = useTranslation(['common', 'warehouse']);

  const form = useForm({
    defaultValues: defaultSupplierReturnAndBboxSchemaValues(),
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(SupplierReturnAndBboxSchema),
    shouldUnregister: false
  });

  const {
    control,
    errors,
    formState: { isValid },
    handleSubmit
  } = form;

  const onSubmit = (data: any) => {
    alert(JSON.stringify(data));
  };

  return (
    <Modal open={returnModalOpened} onClose={() => setReturnModalOpened(false)}>
      <div className={classes.modalUpperContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TableContainer className={classes.tableContainerExit} component={Paper}>
            <h1 className={classes.title}>{t('warehouse:returnItemToSupplier')}</h1>
            <Input
              name="reason"
              control={control}
              errors={errors}
              label={t('common:reason')}
              type="text"
              variant="outlined"
              fullWidth={true}
              errorText={t('common:required')}
              rows={6}
            />

            <Grid
              className={classes.submitPanel}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <LuxButton
                className={`${classes.button} ${classes.cancelButton}`}
                onClick={() => setReturnModalOpened(false)}
              >
                {t('common:neverMind')}
              </LuxButton>

              <LuxButton
                variant="contained"
                luxColor="secondary"
                className={classes.button}
                disabled={!isValid}
                type="submit"
              >
                {t('warehouse:returnItem')}
              </LuxButton>
            </Grid>
          </TableContainer>
        </form>
      </div>
    </Modal>
  );
};

export default ReturnModal;
