import React, { useEffect, useState } from 'react';

import { IdNamePairGetModelOfGuid, KeyValuePairModelOfIntegerAndString } from '@inbound/api';
import Dropdown from '@lux-ds/dropdown';
import { Grid, MenuItem } from '@luxclusif/material';
import { StatusCodes } from 'http-status-codes';
import { Controller, useFormContext } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import AutocompleteInput from 'components/Input/AutocompleteInput/AutocompleteInput';
import Input from 'components/Input/Input/Input';
import SelectInput from 'components/Input/Select/Select';
import UploadImage from 'components/Input/UploadImage/UploadImage';
import { $hideLoading, $showLoading } from 'components/LoadingSpinner/LoadingSpinner';
import useApi from 'hooks/useApi';
import { ICity, ICountry, ICurrency, IEntityCompany, IState } from 'models/supplier';
import currencyService from 'services/Currency.service';
import geoService from 'services/Geo.service';
import suppliersService from 'services/Suppliers.service';
import BaseConfiguration from 'setup/BaseConfiguration';

import supplierDetailsStyles from '../../supplierDetails.styles';

interface IProps {
  status: string | null;
}

const SUPPLIER_CLUSTER_ENABLED = process.env.REACT_APP_ENABLE_SUPPLIER_CLUSTER === 'true';

const ProfileDetails: React.FC<IProps> = ({ status }) => {
  const api = useApi();

  const classes = supplierDetailsStyles();
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [clusters, setClusters] = useState<KeyValuePairModelOfIntegerAndString[]>([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [relevanceOptions, setRelevanceOptions] = useState([]);
  const [regions, setRegions] = useState([]);

  const [currency, setCurrency] = useState([]);
  const [entityCompany, setEntityCompany] = useState([]);
  const [showStatus, setShowStatus] = useState(true);

  const { control, errors, register, setValue, watch } = useFormContext();
  const country = watch('country');
  const state = watch('state');
  const city = watch('city');

  useEffect(() => {
    getCountries();
    getter('getCurrency', currencyService.getCurrency, setCurrency);
    getter('getBuyerEntities', suppliersService.getBuyerEntities, setEntityCompany);
    getter('getRelevanceOptions', suppliersService.getRelevanceOptions, setRelevanceOptions);
    getter('getRegions', suppliersService.getRegions, setRegions);

    if (!status) {
      setShowStatus(false);
    }
  }, []);

  useEffect(() => {
    setCities([]);
    getStates();
  }, [country]);

  useEffect(() => {
    getCities();
  }, [state]);

  useEffect(() => {
    if (states.length !== 0) {
      const found = states.some((key: IState) => key.stateId === state);

      if (!found) {
        setValue('state', '');
        setValue('city', '');
      }
    }
  }, [states]);

  useEffect(() => {
    if (cities.length !== 0) {
      const found = cities.some((key: ICity) => key.cityId === city);

      if (!found) {
        setValue('city', '');
      }
    }
  }, [cities]);

  const getter = (loading: string, service: any, setter: any) => {
    const loadingId = loading;

    $showLoading.next(loadingId);
    service()
      .then((data: any) => {
        setter(data);
      })
      .catch((response: any) => {
        const status = response.response ? response.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

        if (status === StatusCodes.UNAUTHORIZED) {
          setLoggedIn(false);
        }
      })
      .finally(() => {
        $hideLoading.next(loadingId);
      });
  };

  const getCountries = () => {
    const loadingId = 'getCountries';

    $showLoading.next(loadingId);
    geoService
      .getCountries()
      .then(data => {
        setCountries(data);
      })
      .catch(response => {
        const status = response.response ? response.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

        if (status === StatusCodes.UNAUTHORIZED) {
          setLoggedIn(false);
        }
      })
      .finally(() => {
        $hideLoading.next(loadingId);
      });
  };

  const getCities = () => {
    const loadingId = 'getCities';

    $showLoading.next(loadingId);

    if (state) {
      geoService
        .getCities(state)
        .then(data => {
          setCities(data);
        })
        .catch(response => {
          const status = response.response ? response.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

          if (status === StatusCodes.UNAUTHORIZED) {
            setLoggedIn(false);
          }
        })
        .finally(() => {
          $hideLoading.next(loadingId);
        });
    }
  };

  const getStates = () => {
    const loadingId = 'getStates';

    $showLoading.next(loadingId);

    if (country) {
      geoService
        .getStates(country)
        .then(data => {
          setStates(data);
        })
        .catch(response => {
          const status = response.response ? response.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

          if (status === StatusCodes.UNAUTHORIZED) {
            setLoggedIn(false);
          }
        })
        .finally(() => {
          $hideLoading.next(loadingId);
        });
    }
  };

  useEffect(() => {
    const getClustersCallback = async () => {
      const clusters = await api.suppliers_GetAllClusters();

      setClusters(clusters);
    };

    if (SUPPLIER_CLUSTER_ENABLED) {
      getClustersCallback();
    }
  }, [api]);

  return !isLoggedIn ? (
    <Navigate to="/logout" />
  ) : (
    <div>
      <div>
        <h1 className={classes.title}>Details</h1>
      </div>
      <Grid container>
        <Grid item md={8}>
          <Grid container spacing={4}>
            {/* <Grid item md={3}>
              <Input
                name="supplierId"
                control={control}
                errors={errors}
                label="Supplier ID"
                type="text"
                className={classes.input}
                disabled={true}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Grid> */}

            <Grid item md={6}>
              <Input
                name="name"
                control={control}
                errors={errors}
                label="Supplier Name*"
                type="text"
                className={classes.input}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Grid>
            <Grid item md={3}>
              <SelectInput
                label="Supplier Relevance*"
                className={classes.input}
                fullWidth={true}
                name="relevanceId"
                variant="outlined"
                control={control}
                errors={errors}
                setValue={setValue}
              >
                <MenuItem aria-label="None" value="" key={'none'} />
                {relevanceOptions.map((row: IdNamePairGetModelOfGuid) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item md={3}>
              {showStatus && (
                <Input
                  name="status"
                  control={control}
                  errors={errors}
                  label="Status"
                  type="text"
                  className={classes.input}
                  disabled={true}
                  variant="outlined"
                  fullWidth={true}
                  errorText="Required"
                />
              )}
            </Grid>

            <Grid item md={6}>
              <Input
                name="address1"
                control={control}
                errors={errors}
                label="Address*"
                type="text"
                className={classes.input}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Grid>

            <Grid item md={3}>
              <Input
                name="postalCode"
                control={control}
                errors={errors}
                label="Postal Code*"
                type="text"
                className={classes.input}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Grid>

            <Grid item md={3}>
              <SelectInput
                label="Country*"
                className={classes.input}
                fullWidth={true}
                name="country"
                variant="outlined"
                control={control}
                errors={errors}
                setValue={setValue}
              >
                <MenuItem aria-label="None" value="" key={'none'} />
                {countries.map((row: ICountry) => (
                  <MenuItem key={row.countryId} value={row.countryId}>
                    {row.name}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>

            <Grid item md={3}>
              <SelectInput
                label="State*"
                className={classes.input}
                fullWidth={true}
                name="state"
                variant="outlined"
                control={control}
                errors={errors}
                setValue={setValue}
              >
                <MenuItem aria-label="None" value="" key={'none'} />
                {states.map((row: IState) => (
                  <MenuItem key={row.stateId} value={row.stateId}>
                    {row.name}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>

            <Grid item md={3}>
              <SelectInput
                label="City*"
                className={classes.input}
                fullWidth={true}
                name="city"
                variant="outlined"
                control={control}
                errors={errors}
                setValue={setValue}
              >
                <MenuItem aria-label="None" value="" key={'none'} />
                {cities.map((row: ICity) => (
                  <MenuItem key={row.cityId} value={row.cityId}>
                    {row.name}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>

            <Grid item md={3}>
              <SelectInput
                label="Region*"
                className={classes.input}
                fullWidth={true}
                name="regionId"
                variant="outlined"
                control={control}
                errors={errors}
                setValue={setValue}
              >
                <MenuItem aria-label="None" value="" key={'none'} />
                {regions.map((row: IdNamePairGetModelOfGuid) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>

            <Grid item md={3}>
              <BaseConfiguration>
                <Controller
                  control={control}
                  name="clusters"
                  render={({ ref, value, ...props }) => (
                    <Dropdown
                      items={clusters.map(({ key, value }) => ({
                        key: key as number,
                        name: value as string,
                        value: value as string
                      }))}
                      label="Supplier Cluster*"
                      multiple
                      ref={ref}
                      sx={{ width: '100%' }}
                      value={value || []}
                      {...props}
                    />
                  )}
                />
              </BaseConfiguration>
            </Grid>

            <Grid item md={3}>
              <SelectInput
                label="Entity Company*"
                className={classes.input}
                fullWidth={true}
                name="entityCompany"
                variant="outlined"
                control={control}
                errors={errors}
                setValue={setValue}
              >
                <MenuItem aria-label="None" value="" key={''} />
                {entityCompany.map((row: IEntityCompany) => (
                  <MenuItem key={row.entityCompanyId} value={row.entityCompanyId}>
                    {row.name}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>

            <Grid item md={3}>
              <AutocompleteInput<ICurrency>
                className={classes.input}
                control={control}
                errorText={errors['currency']?.message}
                fullWidth={true}
                getOptionLabel={option => `${option.isoCode} - ${option.name}`}
                label="Billing Currency*"
                name="currency"
                optionValue="currencyId"
                optionValueHTML="name"
                options={currency}
                setValue={setValue}
                variant="outlined"
              />
            </Grid>

            <Grid item md={3}>
              <Input
                name="website"
                control={control}
                errors={errors}
                label="Website"
                type="text"
                className={classes.input}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Grid>

            <Grid item md={3}>
              <Input
                name="emailAddress"
                control={control}
                errors={errors}
                label="Email*"
                type="text"
                className={classes.input}
                variant="outlined"
                fullWidth={true}
                errorText="Required"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} className={classes.imageUploadContainer}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <UploadImage
              name="logo"
              label="Logo"
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
            />
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.line} />
    </div>
  );
};

export default ProfileDetails;
