/* eslint-disable react/display-name */
import React, { useCallback, useMemo, useState } from 'react';

import { EInboundFlow, GetAllItemProcessesModel, TrackingNumberModel } from '@inbound/api';
import { LayoutAriel, LayoutHerodias } from '@lux-ds/content-layouts';
import DataGrid, {
  DataGridDoubleCell,
  DataGridImage,
  DataGridToolbar,
  GridColumns,
  GridRenderCellParams,
  IDataGridToolbarProps
} from '@lux-ds/data-grid';
import Dropdown, { ISelectValue, SelectChangeEvent } from '@lux-ds/dropdown';
import StatusIndicator from '@lux-ds/status-indicator';
import Tabs, { ITabsItem } from '@lux-ds/tabs';
import { LuxTheme } from '@lux-ds/theming';
import Typography from '@lux-ds/typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import InboundFlowChip from 'components/InboundFlowChip/InboundFlowChip';
import GalleryModal from 'components/Warehouse/GalleryModal/GalleryModal';
import useWarehouseContext from 'hooks/useWarehouseContext';
import { EWarehouseName } from 'models/warehouse';
import EInboundRoute from 'navigation/models/EInboundRoute';
import { TItemIssuesTypeKeys } from 'pages/ItemIssues/constants';
import BaseConfiguration from 'setup/BaseConfiguration';
import camelize from 'utils/camelize';

import WarehouseIssue from './components/WarehouseIssue/WarehouseIssue';
import useWarehouseListContextV2 from './hooks/useWarehouseListContextV2';

import {
  StyledCancelIcon,
  StyledCheckCircleIcon,
  StyledHeaderJay,
  StyledLyssa,
  StyledWarningIcon,
  warehouseListStyles
} from './WarehouseList.styles';

type IImageDetails = {
  itemId: string;
  name: string;
};

type ICountLabelKeys =
  | 'allItems'
  | 'authentication'
  | 'checkin'
  | 'photoAuthentication'
  | 'qualityControl'
  | 'storage'
  | 'withIssues';

type TWarehouseKeys = 'authentication' | 'checkin' | 'photoAuthentication' | 'qC' | 'storage';

const WarehouseListV2: React.FC = () => {
  const {
    counts,
    getWarehouse,
    isLoading,
    isWarehouseIssuePanelOpen,
    items,
    onWarehouseIssuePanelClose,
    onWarehouseIssuePanelOpen,
    totalItems
  } = useWarehouseListContextV2();
  const {
    activeFilterTab,
    activeWarehouse,
    filterOptions,
    filters,
    getWarehouseIssueDetails,
    hasDefaultWarehouse,
    includeProcessedItems,
    page,
    pageSize,
    search,
    setActiveFilterTab,
    setActiveWarehouse,
    setFilters,
    setIncludeProcessedItems,
    setPage,
    setPageSize,
    setSearch,
    setSortModel,
    sortModel,
    warehouseIssueDetails,
    warehouseOptions
  } = useWarehouseContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'itemIssues', 'warehouse']);

  const [showImageDetails, setShowImageDetails] = useState<IImageDetails>();

  const classes = warehouseListStyles();

  const handleRowClick = useCallback(
    (row: GetAllItemProcessesModel) =>
      navigate(`${EInboundRoute.Warehouse}/${row.itemProcessId}/${row.isProcessed ? 'view' : 'edit'}`),
    [navigate]
  );

  const handleChangeActiveWarehouse = useCallback(
    (event: SelectChangeEvent<ISelectValue>) => setActiveWarehouse(event.target.value as string),
    [setActiveWarehouse]
  );

  const handleChangeIncludeProcessedItems = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setIncludeProcessedItems(checked),
    [setIncludeProcessedItems]
  );

  const handleRowHighlight = useCallback(
    ({ row }: { row: GetAllItemProcessesModel }) =>
      row.isProcessed ? classes.highlighted : row.itemId === warehouseIssueDetails.itemId ? classes.selected : '',
    [classes.highlighted, classes.selected, warehouseIssueDetails.itemId]
  );

  const handleSuccessResubmit = useCallback(() => {
    onWarehouseIssuePanelClose();
    getWarehouse();
  }, [getWarehouse, onWarehouseIssuePanelClose]);

  const handleIssuesClick = useCallback(
    ({ itemId, itemProcessId, sku }) => {
      onWarehouseIssuePanelOpen();
      getWarehouseIssueDetails(itemProcessId, itemId, sku);
    },
    [getWarehouseIssueDetails, onWarehouseIssuePanelOpen]
  );

  const handleCloseIssuePanel = useCallback(() => onWarehouseIssuePanelClose(), [onWarehouseIssuePanelClose]);

  const handleImageClick = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>, details: IImageDetails) => {
    ev.stopPropagation();
    setShowImageDetails(details);
  }, []);

  const handleFormatDays = useCallback(date => {
    const ms = moment().diff(moment.utc(date));
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));

    return `${days} day(s)`;
  }, []);

  const handleFormatDaysInStation = useCallback(date => {
    const ms = moment().diff(moment.utc(date));
    const hours = Math.floor(ms / (1000 * 60 * 60));

    if (hours < 72) {
      return `${hours} h`;
    }

    const days = hours / 24;
    const remainingHours = Math.floor(((days % 2) * 24) / 10);

    return `${Math.floor(days)}${remainingHours ? `.${remainingHours}` : ''} day(s)`;
  }, []);

  const isJapanWarehouse = useMemo(() => {
    const warehouse = warehouseOptions.find(({ name }) => name === EWarehouseName.Japan);

    return warehouse?.id === activeWarehouse;
  }, [activeWarehouse, warehouseOptions]);

  const commonColumns = useMemo(
    (): GridColumns => [
      {
        field: 'thumbnail',
        headerName: '',
        renderCell: ({ row, value }) => (
          <DataGridImage
            onClick={ev =>
              handleImageClick(ev, {
                itemId: row.itemId as string,
                name: row.name
              })
            }
            src={value}
          />
        ),
        sortable: false,
        width: 113
      },
      {
        field: 'sku',
        flex: 1,
        minWidth: 163,
        renderCell: Object.assign(
          ({ row, value }: GridRenderCellParams) => (
            <DataGridDoubleCell subTitle={row['itemProcessNumber']} title={value} type="row" />
          ),
          { displayName: 'skuCell' }
        ),
        renderHeader: Object.assign(
          () => (
            <DataGridDoubleCell subTitle={t('warehouse:list.processNumber')} title={t('common:sku')} type="header" />
          ),
          { displayName: 'skuHeader' }
        )
      },
      {
        field: 'supplier',
        flex: 1,
        minWidth: 200,
        renderCell: Object.assign(
          ({ row, value: { value } }: GridRenderCellParams) => (
            <DataGridDoubleCell subTitle={row['sellersCode']} title={value} type="row" />
          ),
          { displayName: 'supplierNameCell' }
        ),
        renderHeader: Object.assign(
          () => <DataGridDoubleCell subTitle={t('common:sellerCode')} title={t('common:supplier')} type="header" />,
          { displayName: 'supplierNameHader' }
        ),
        sortable: false
      },
      {
        field: 'brand',
        flex: 1,
        minWidth: 200,
        renderCell: Object.assign(
          ({ row, value: { value } }: GridRenderCellParams) => (
            <DataGridDoubleCell subTitle={row['name']} title={value} type="row" />
          ),
          { displayName: 'brandCell' }
        ),
        renderHeader: Object.assign(
          () => <DataGridDoubleCell subTitle={t('common:modelName')} title={t('common:brand')} type="header" />,
          { displayName: 'brandHeader' }
        ),
        sortable: false
      },
      {
        field: 'inboundFlow',
        flex: 1,
        minWidth: 182,
        renderCell: params => (
          <InboundFlowChip
            label={params.value.name}
            subLabel={params.value.businessModel}
            type={params.value.name as EInboundFlow}
          />
        ),
        renderHeader: Object.assign(
          () => (
            <DataGridDoubleCell subTitle={t('common:businessModel')} title={t('warehouse:inboundFlow')} type="header" />
          ),
          { displayName: 'inboundFlowHeader' }
        ),
        sortable: false
      }
    ],
    [handleImageClick, t]
  );

  const daysInStationColumn = useMemo(
    (): GridColumns => [
      {
        field: 'daysInStation',
        flex: 1,
        headerName: t('warehouse:list.daysInStation'),
        minWidth: 200,
        valueFormatter: ({ value }) => (value ? handleFormatDaysInStation(value) : '-'),
        valueGetter: ({ row }) => row.currentStation.stationAtUtc
      }
    ],
    [handleFormatDaysInStation, t]
  );

  const lastStationColumn = useMemo(
    (): GridColumns => [
      {
        field: 'lastStation',
        flex: 1,
        minWidth: 150,
        renderCell: Object.assign(
          ({ value }: GridRenderCellParams) =>
            value.user || value.name ? (
              <DataGridDoubleCell
                subTitle={value.user}
                title={t(`warehouse:list.${camelize(value.name) as TWarehouseKeys}`)}
                type="row"
              />
            ) : (
              '-'
            ),
          { displayName: 'lastStationCell' }
        ),
        renderHeader: Object.assign(
          () => (
            <DataGridDoubleCell
              subTitle={t('warehouse:list.lastUser')}
              title={t('warehouse:list.lastStation')}
              type="header"
            />
          ),
          { displayName: 'lastStationHeader' }
        ),
        sortable: false
      }
    ],
    [t]
  );

  const allItemsColumns = useMemo(
    (): GridColumns => [
      ...commonColumns,
      {
        field: 'openProcessAtUtc',
        flex: 1,
        headerName: t('warehouse:list.daysSinceOpenedProcess'),
        valueFormatter: ({ value }) => (value ? handleFormatDays(value) : '-'),
        width: 200
      },
      {
        field: 'currentStation',
        flex: 1,
        headerName: t('warehouse:list.currentStation'),
        valueFormatter: ({ value }) =>
          value.name ? t(`warehouse:list.${camelize(value.name) as TWarehouseKeys}`) : '-',
        width: 162
      },
      ...daysInStationColumn
    ],
    [commonColumns, daysInStationColumn, handleFormatDays, t]
  );

  const checkInColumns = useMemo(
    (): GridColumns => [
      ...commonColumns,
      ...lastStationColumn,
      {
        field: 'trackingNumber',
        flex: 1,
        headerName: t('common:trackingNumber'),
        minWidth: 138,
        renderCell: ({ row }) =>
          row['trackingNumbers'].length > 0
            ? row['trackingNumbers'].map(({ code }: TrackingNumberModel) => (
                <Typography key={code} variant="bodyTextSmall">
                  {code}
                </Typography>
              ))
            : '-',
        sortable: false
      },
      {
        field: 'carrier',
        flex: 1,
        headerName: t('warehouse:list.carrier'),
        minWidth: 100,
        renderCell: ({ row }) =>
          row['trackingNumbers'].length > 0
            ? row['trackingNumbers'].map(({ carrier }: TrackingNumberModel, i: number) => (
                <Typography key={i} variant="bodyTextSmall">
                  {carrier}
                </Typography>
              ))
            : '-',
        sortable: false
      },
      ...daysInStationColumn
    ],
    [commonColumns, daysInStationColumn, lastStationColumn, t]
  );

  const photoAuthenticationColumns = useMemo(
    (): GridColumns => [...commonColumns, ...lastStationColumn, ...daysInStationColumn],
    [commonColumns, daysInStationColumn, lastStationColumn]
  );

  const qualityControlColumns = useMemo(
    (): GridColumns => [
      ...photoAuthenticationColumns,
      {
        field: 'qcResult',
        flex: 1,
        headerName: t('common:result'),
        renderCell: ({ row }) => {
          const value = row.qualityControl.hasPassedQualityCheck;

          if (value === null) {
            return <Typography variant="bodyTextSmall">-</Typography>;
          }

          return (
            <LayoutHerodias gap="xxs">
              <Typography variant="bodyTextSmall" sx={{ textTransform: 'capitalize' }}>
                {value ? t('warehouse:qualityCheckPass') : t('warehouse:qualityCheckFail')}
              </Typography>
              {value ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
            </LayoutHerodias>
          );
        },
        sortable: false,
        width: 120
      },
      {
        field: 'qcTimestamp',
        flex: 1,
        headerName: t('warehouse:list.timestamp'),
        valueFormatter: ({ value }) => (value ? moment(value).format('DD MMM YYYY hh:mm A') : '-'),
        valueGetter: ({ row }) => row.qualityControl.qualityCheckUpdatedAtUtc,
        width: 200
      }
    ],
    [photoAuthenticationColumns, t]
  );

  const getAuthResultLabel = useCallback(
    (result: string) => {
      switch (result) {
        case 'Authentic':
          return t('warehouse:authenticationStation.authPass');
        case 'Fake':
          return t('warehouse:authenticationStation.authFail');
        case 'Inconclusive':
          return t('warehouse:authenticationStation.inconclusive');
        default:
          return '-';
      }
    },
    [t]
  );

  const getAuthResultIcon = useCallback((result: string) => {
    switch (result) {
      case 'Authentic':
        return <StyledCheckCircleIcon />;
      case 'Fake':
        return <StyledCancelIcon />;
      case 'Inconclusive':
        return <StyledWarningIcon />;
      default:
        return null;
    }
  }, []);

  const authenticationColumns = useMemo(
    (): GridColumns => [
      ...photoAuthenticationColumns,
      {
        field: 'authResult',
        flex: 1,
        headerName: t('common:result'),
        renderCell: ({ row }) => {
          const value = row.lastAuthentication.authenticationStatus;

          if (value === null) {
            return <Typography variant="bodyTextSmall">-</Typography>;
          }

          return (
            <LayoutHerodias gap="xxs">
              <Typography variant="bodyTextSmall" sx={{ textTransform: 'capitalize' }}>
                {getAuthResultLabel(value)}
              </Typography>
              {getAuthResultIcon(value)}
            </LayoutHerodias>
          );
        },
        sortable: false,
        width: 120
      },
      {
        field: 'authTimestamp',
        flex: 1,
        headerName: t('warehouse:list.timestamp'),
        valueFormatter: ({ value }) => (value ? moment(value).format('DD MMM YYYY hh:mm A') : '-'),
        valueGetter: ({ row }) => row.lastAuthentication.authenticationUpdateAtUtc,
        width: 200
      }
    ],
    [getAuthResultIcon, getAuthResultLabel, photoAuthenticationColumns, t]
  );

  const storageColumns = useMemo(
    (): GridColumns => [...commonColumns, ...daysInStationColumn],
    [commonColumns, daysInStationColumn]
  );

  const withIssuesColumns = useMemo(
    (): GridColumns => [
      ...commonColumns,
      {
        field: 'daysWithIssues',
        flex: 1,
        headerName: t('warehouse:list.daysWithIssues'),
        minWidth: 200,
        valueFormatter: ({ value }) => (value ? handleFormatDaysInStation(value) : '-'),
        valueGetter: ({ row }) => row.issues.firstIssueReportedAtUtc
      },
      {
        field: 'issues',
        flex: 1,
        headerName: t('warehouse:itemIssues.issueType'),
        minWidth: 200,
        renderCell: ({ row }) => {
          const issues = row.issues.activeIssuesType || [];

          return issues[0] ? (
            <StatusIndicator
              color={LuxTheme.palette.warning.main}
              label={`${t(`itemIssues:issueTypes.${camelize(issues[0]) as TItemIssuesTypeKeys}`)}${
                issues.length > 1 ? ` +${issues.length - 1}` : ''
              }`}
              onClick={e => {
                (e as unknown as React.MouseEvent).preventDefault();
                (e as unknown as React.MouseEvent).stopPropagation();

                handleIssuesClick(row);
              }}
            />
          ) : (
            '-'
          );
        }
      }
    ],
    [commonColumns, daysInStationColumn, handleFormatDaysInStation, handleIssuesClick, t]
  );

  const columns = useMemo((): GridColumns => {
    switch (activeFilterTab) {
      case 'authentication':
        return authenticationColumns;
      case 'checkin':
        return checkInColumns;
      case 'photoAuthentication':
        return photoAuthenticationColumns;
      case 'qualityControl':
        return qualityControlColumns;
      case 'storage':
        return storageColumns;
      case 'withIssues':
        return withIssuesColumns;
      case 'allItems':
      default:
        return allItemsColumns;
    }
  }, [
    activeFilterTab,
    allItemsColumns,
    authenticationColumns,
    checkInColumns,
    photoAuthenticationColumns,
    qualityControlColumns,
    storageColumns,
    withIssuesColumns
  ]);

  const toolbar = useMemo(
    (): IDataGridToolbarProps => ({
      filter: {
        collapseMountOnEnter: true,
        collapseUnmountOnExit: true,
        filters: [
          {
            id: 'supplier',
            label: t('common:suppliers'),
            options: filterOptions.suppliers,
            type: 'multipleSelect'
          },
          {
            id: 'inboundFlow',
            label: t('warehouse:inboundFlow'),
            options: filterOptions.inboundFlows,
            type: 'multipleSelect'
          },
          {
            id: 'brand',
            label: t('common:brand'),
            options: filterOptions.brands,
            type: 'multipleSelect'
          },
          {
            id: 'qualityCheckDecision',
            label: t('common:qualityControlResult'),
            options: filterOptions.qcDecisions,
            type: 'multipleSelect'
          },
          {
            id: 'authenticationResult',
            label: t('common:authenticationResult'),
            options: filterOptions.authResults,
            type: 'multipleSelect'
          }
        ],
        locale: {
          filtersLabel: t('common:filters')
        },
        onChange: setFilters,
        value: filters
      },
      search: {
        defaultValue: search,
        onSearch: setSearch,
        placeholder: t('warehouse:searchPlaceholder')
      },
      switchAction: {
        checked: !!includeProcessedItems,
        formControlLabelProps: {
          labelPlacement: 'start'
        },
        label: t('warehouse:list.includeProcessedItems'),
        onChange: handleChangeIncludeProcessedItems
      }
    }),
    [
      filterOptions.authResults,
      filterOptions.brands,
      filterOptions.inboundFlows,
      filterOptions.qcDecisions,
      filterOptions.suppliers,
      filters,
      handleChangeIncludeProcessedItems,
      includeProcessedItems,
      search,
      setFilters,
      setSearch,
      t
    ]
  );

  const tabs = useMemo(
    (): ITabsItem[] =>
      ['allItems', 'checkin', 'photoAuthentication', 'qualityControl', 'authentication', 'storage', 'withIssues']
        .map(tab => ({
          label: t(`warehouse:quickFilters.${tab as ICountLabelKeys}`),
          subLabel: counts[tab as ICountLabelKeys]?.toString(),
          value: tab
        }))
        .filter(({ value }) => (!isJapanWarehouse ? value !== 'authentication' : true)),
    [counts, isJapanWarehouse, t]
  );

  const handleActiveFilterTab = useCallback((_, tab: string) => setActiveFilterTab(tab), [setActiveFilterTab]);

  return (
    <BaseConfiguration>
      <StyledHeaderJay gap="m" paddingTop="s" paddingX="m">
        <LayoutHerodias sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" weight="semibold">
            Warehouse
          </Typography>
          <Dropdown
            disabled={hasDefaultWarehouse}
            items={warehouseOptions}
            label=""
            name="activeWarehouse"
            onChange={handleChangeActiveWarehouse}
            size="small"
            value={activeWarehouse}
          />
        </LayoutHerodias>
        <LayoutHerodias gap="l" gridColumn="2/3" sx={{ alignItems: 'flex-end' }}>
          <Tabs items={tabs} value={activeFilterTab} onChange={handleActiveFilterTab} />
        </LayoutHerodias>
      </StyledHeaderJay>
      <StyledLyssa>
        <DataGridToolbar {...toolbar} />
        <LayoutAriel
          gridTemplateColumns={`1fr ${isWarehouseIssuePanelOpen ? '315px' : ''}`}
          sx={{ overflow: 'hidden' }}
        >
          <DataGrid
            columns={columns}
            getRowClassName={handleRowHighlight}
            getRowId={({ itemProcessNumber }) => itemProcessNumber}
            headerHeight="m"
            loading={isLoading}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            onRowClick={params => handleRowClick(params.row)}
            onSortModelChange={setSortModel}
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            rowCount={totalItems}
            rowHeight="l"
            rows={items}
            sortModel={sortModel}
            sortingMode="server"
          />
          {isWarehouseIssuePanelOpen && (
            <WarehouseIssue onClose={handleCloseIssuePanel} onSuccess={handleSuccessResubmit} version="v2" />
          )}
        </LayoutAriel>
      </StyledLyssa>

      {!!Object.keys(showImageDetails || {}).length && (
        <GalleryModal
          handleClose={() => setShowImageDetails(undefined)}
          itemId={showImageDetails?.itemId as string}
          name={showImageDetails?.name as string}
        />
      )}
    </BaseConfiguration>
  );
};

export default WarehouseListV2;
