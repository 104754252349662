import { TGetAllBuyers } from 'models/purchaseOrders';

import axios from '../utils/axios';

class BuyersService {
  public async getBuyers(): Promise<TGetAllBuyers> {
    const url = `${process.env.REACT_APP_API_URL}Buyers`;

    return await axios.get(url).then(response => response.data);
  }
}

const buyersService = new BuyersService();

export default buyersService;
