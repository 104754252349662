import * as yup from 'yup';

export const businessModelSchema = yup.object().shape({
  averageHTML: yup.string().notRequired(),
  averages: yup.string().notRequired(),
  businessModelId: yup.string().notRequired(),
  businessModelType: yup.string().required(),
  businessModelTypeHTML: yup.string().notRequired(),
  carrier: yup.string().required(),
  carrierHTML: yup.string().notRequired(),
  code: yup.string().notRequired(),
  commercialModel: yup.string().notRequired(),
  commercialModelHTML: yup.string().notRequired(),
  condition: yup.string().notRequired(),
  conditionHTML: yup.string().notRequired(),
  defaultFreightTerms: yup.string().required(),
  defaultFreightTermsHTML: yup.string().notRequired(),
  deliveryLeadTime: yup.number().integer().min(1).required(),
  deliverySLAType: yup.string().notRequired(),
  files: yup
    .object()
    .shape({
      files: yup.array().of(yup.mixed().notRequired()).notRequired(),
      filesToDelete: yup.array().of(yup.string().notRequired()).notRequired()
    })
    .notRequired(),
  financialPayment: yup.string().required(),
  financialPaymentHTML: yup.string().notRequired(),
  internalComments: yup.string().notRequired(),
  notes: yup.string().notRequired(),
  numberDays: yup.string().required(),
  numberDaysType: yup.string().notRequired(),
  paymentMethod: yup.string().required(),
  paymentMethodHTML: yup.string().notRequired(),
  photos: yup.string().required(),
  reception: yup.string().required(),
  receptionHTML: yup.string().notRequired(),
  responsible: yup.string().notRequired(),
  status: yup.string().required(),
  statusHTML: yup.string().notRequired(),
  targetSettings: yup.array().of(
    yup
      .object()
      .shape({
        endDate: yup
          .date()
          .when('startDate', (eventStartDate: any, schema: any) => eventStartDate && schema.min(eventStartDate))
          .required(),
        goal: yup.string().required(),
        poReference: yup.string().required(),
        startDate: yup.date().required(),
        targetSettingsId: yup.string().notRequired(),
        testItems: yup.string().required(),
        unit: yup.string().required(),
        unitHTML: yup.string().notRequired()
      })
      .notRequired()
  ),
  tier: yup.string().notRequired(),
  tierHTML: yup.string().notRequired(),
  warehouseFrom: yup.string().notRequired(),
  warehouseFromHTML: yup.string().notRequired()
});

export const businessModelInitialize = () => {
  return {
    averageHTML: '',
    averages: '',
    businessModelId: '',
    businessModelType: '',
    businessModelTypeHTML: '',
    carrier: '',
    carrierHTML: '',
    code: '',
    commercialModel: '',
    commercialModelHTML: '',
    condition: '',
    conditionHTML: '',
    defaultFreightTerms: '',
    defaultFreightTermsHTML: '',
    deliveryLeadTime: '0',
    deliverySLAType: 'Work',
    files: {
      files: [],
      filesToDelete: []
    },
    financialPayment: '',
    financialPaymentHTML: '',
    internalComments: '',
    notes: '',
    numberDays: '0',
    numberDaysType: 'Work',
    paymentMethod: '',
    paymentMethodHTML: '',
    photos: '',
    reception: '',
    receptionHTML: '',
    responsible: '',
    status: '',
    statusHTML: '',
    targetSettings: [],
    tier: '',
    tierHTML: '',
    warehouseFrom: '',
    warehouseFromHTML: ''
  };
};
