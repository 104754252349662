import React, { PropsWithChildren, ReactElement } from 'react';

import { Autocomplete, TextField } from '@luxclusif/material';
import { Control, Controller } from 'react-hook-form';

import autocompleteInputStyles from './autocompleteInput.styles';

interface IProps<T> {
  className?: string;
  control?: Control;
  defaultValue?: T;
  disabled?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  getOptionLabel?: (option: T) => string;
  label: string;
  margin?: 'none' | 'normal' | 'dense';
  name: string;
  optionValue?: keyof T;
  optionValueHTML?: keyof T;
  options: T[];
  setValue?: (name: string, value: string) => void;
  variant?: 'filled' | 'outlined' | 'standard';
}

const AutocompleteInput = <T,>({
  className,
  control,
  defaultValue,
  disabled,
  errorText,
  fullWidth,
  getOptionLabel,
  label,
  margin,
  name,
  optionValue = 'id' as keyof T,
  optionValueHTML,
  options,
  setValue,
  variant
}: PropsWithChildren<IProps<T>>): ReactElement | null => {
  const classes = autocompleteInputStyles();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ onChange, value: controlValue, ...controlProps }) => (
        <Autocomplete<T>
          {...controlProps}
          classes={{
            endAdornment: classes.icons
          }}
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          onChange={(_, newValue) => {
            const finalValue = newValue?.[optionValue] || '';

            onChange(finalValue);

            setValue?.(
              `${name}HTML`,
              optionValueHTML ? (newValue?.[optionValueHTML] as unknown as string) || '' : (finalValue as string)
            );
          }}
          options={options}
          renderInput={({ InputLabelProps, InputProps, ...props }) => (
            <TextField
              {...props}
              className={className}
              error={!!errorText}
              fullWidth={fullWidth}
              helperText={errorText}
              InputLabelProps={{
                ...InputLabelProps,
                classes: { root: classes.label }
              }}
              InputProps={{
                ...InputProps,
                classes: { root: classes.input }
              }}
              label={label}
              margin={margin}
              variant={variant}
            />
          )}
          value={options.find(option => option[optionValue] === controlValue) || null}
        />
      )}
    />
  );
};

export default AutocompleteInput;
