import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const orderInformationStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    container: {
      padding: '48px 24px 0'
    },
    inputsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',
      marginTop: '24px',
      '& .MuiInputBase-input, .MuiSelect-select': {
        height: '16px',
        lineHeight: 1,
        minHeight: '16px',
        padding: '16px'
      },
      '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
        height: '16px',
        lineHeight: 1,
        minHeight: '16px'
      },
      '& .MuiFormControl-root': {
        minWidth: '204px'
      },
      '& .Mui-textarea-multiline': {
        width: '100%'
      },
      '& .MuiCircularProgress-root': {
        position: 'absolute',
        right: '11px',
        top: 'calc(50% - 8px)'
      }
    },
    inputSupplierName: {
      minWidth: '432px !important'
    },
    selectMenu: {
      maxHeight: '270px'
    },
    divider: {
      backgroundColor: secondaryNeutral.light75,
      border: 'none',
      height: '1px',
      margin: '48px 0'
    },

    // Dates Section
    budgetWeekContainer: {
      minWidth: '304px !important'
    },

    // Files Section
    dropZoneEnabled: {
      cursor: 'pointer'
    },
    dropZoneDisabled: {
      cursor: 'not-allowed'
    },
    dropZoneContainer: {
      background: secondaryNeutral.light85,
      border: `1px dashed ${secondaryNeutral.light75}`,
      borderRadius: '4px',
      marginTop: '32px'
    },
    dropZonePlaceholderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px 0'
    },
    dropZoneIcon: {
      color: secondaryNeutral.light40,
      fontSize: '40px',
      marginRight: '24px'
    },
    dropZoneLabel: {
      color: secondaryNeutral.light40,
      fontSize: '16px',
      fontWeight: 800,
      marginTop: 0,
      marginBottom: 0,
      marginRight: '8px'
    },
    dropZoneButton: {
      borderColor: secondaryNeutral.light75,
      fontSize: '14px',
      fontWeight: 800
    },
    filesContainer: {
      display: 'grid',
      gridGap: '8px',
      marginTop: '16px'
    },
    filesInfo: {
      alignItems: 'center',
      border: `1px solid ${secondaryNeutral.light75}`,
      borderRadius: '4px',
      display: 'flex',
      padding: '16px 24px'
    },
    fileInfoIcon: {
      fontSize: '24px',
      color: secondaryNeutral.light40,
      marginRight: '16px'
    },
    fileInfoName: {
      flex: 1,
      fontSize: '14px',
      marginTop: 0,
      marginBottom: 0,
      marginRight: '8px'
    },
    fileInfoSize: {
      fontSize: '14px',
      marginTop: 0,
      marginBottom: 0,
      marginRight: '40px'
    },
    fileInfoActionContainer: {
      minWidth: '50px'
    },
    fileInfoAction: {
      padding: 0,
      '&:first-child': {
        marginRight: '14px'
      },
      '& .MuiSvgIcon-root': {
        fontSize: '18px',
        color: palette.secondary.light
      }
    }
  })
);

export default orderInformationStyles;
