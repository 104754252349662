import React from 'react';

import { LocalizationProvider, AdapterDateFns } from '@lux-ds/date-picker';
import NotificationProvider from '@lux-ds/notification';
import { LuxTheme } from '@lux-ds/theming';
import CssBaseline from '@mui/material/CssBaseline';

import { GlobalStyles, ThemeProvider } from 'utils/styles';

const BaseConfiguration: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={LuxTheme}>
      <CssBaseline>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <NotificationProvider>{children}</NotificationProvider>
        </LocalizationProvider>
      </CssBaseline>
      <GlobalStyles />
    </ThemeProvider>
  );
};

export default BaseConfiguration;
