import * as yup from 'yup';

export const warehouseSchema = yup.object().shape({
  supplierAddress: yup.string().required(),
  supplierCity: yup.string().required(),
  supplierCityHTML: yup.string().notRequired(),
  supplierCountry: yup.string().required(),
  supplierCountryHTML: yup.string().notRequired(),
  supplierPostalCode: yup.string().required(),
  supplierState: yup.string().required(),
  supplierStateHTML: yup.string().notRequired(),
  supplierWarehouseLocation: yup.string().required(),
  warehouseAddressId: yup.string().notRequired()
});

export const SupplierWarehouseDefaultValues = () => {
  return {
    supplierAddress: '',
    supplierCity: '',
    supplierCityHTML: '',
    supplierCountry: '',
    supplierCountryHTML: '',
    supplierPostalCode: '',
    supplierState: '',
    supplierStateHTML: '',
    supplierWarehouseLocation: '',
    warehouseAddressId: ''
  };
};
