import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const bagLocationStyles = makeStyles(({ palette }: Theme) => {
  return createStyles({
    chipCheckBoxContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px'
    },
    checkboxChip: {
      border: `1px solid ${secondaryNeutral.light75}`,
      borderRadius: '4px',
      width: '73px',
      height: '40px',
      '&[aria-checked=false]': {
        backgroundColor: palette.common.white
      },
      '& > .MuiChip-label': {
        padding: '6px',
        textAlign: 'center',
        whiteSpace: 'normal',
        fontSize: '12px',
        lineHeight: '14px'
      }
    },

    title: {
      color: palette.secondary.main,
      fontsize: '14px',
      letterSpacing: 0,
      lineHeight: '14px'
    },
    container: {
      marginBottom: '12px !important',
      marginTop: '12px !important'
    },
    cellBorder: {
      padding: '16px',
      border: `1px solid ${secondaryNeutral.light75}`
    }
  });
});

export default bagLocationStyles;
