import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const imageContainerStyle = {
  borderRadius: '4px',
  height: '230px',
  width: '204px'
};

const uploadImagesStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px'
    },
    dropZoneContainer: {
      ...imageContainerStyle,
      backgroundColor: '#F8F7F8',
      border: '1px dashed #DFDCE0',
      padding: '49px 17px',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      lineHeight: 1,
      rowGap: '8px'
    },
    uploadImageError: {
      borderColor: palette.error.main,
      color: palette.error.main
    },
    dropZoneIcon: {
      color: '#7D717E',
      fontSize: '48px'
    },
    dropZoneLabel: {
      color: '#7D717E',
      textAlign: 'center'
    },
    dropZoneButton: {
      borderColor: '#DFDCE0',
      color: '#131113'
    },

    imageListContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px'
    },
    imageContainer: {
      ...imageContainerStyle,
      border: '1px solid #DFDCE0',
      overflow: 'hidden'
    },
    headerContainer: {
      height: '203px',
      borderBottom: `1px solid ${secondaryNeutral.light75}`,
      position: 'relative'
    },
    headerActions: {
      position: 'absolute',
      padding: '8px',
      display: 'flex',
      gap: '8px',
      top: 0,
      right: 0
    },
    actionButton: {
      backgroundColor: palette.common.white,
      color: palette.secondary.light,
      padding: '6px !important',
      '& .MuiSvgIcon-root': {
        color: 'inherit',
        fontSize: '20px'
      },
      '&:hover': {
        backgroundColor: secondaryNeutral.light85
      }
    },
    otherActionItem: {
      gap: '9px',
      minWidth: '173px',
      '& .MuiListItemIcon-root': {
        minWidth: '26px'
      },
      '& .MuiListItemText-primary': {
        fontSize: '14px'
      }
    },

    headerImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%'
    },
    imageLabel: {
      lineHeight: 1,
      padding: '7px 8px'
    },

    // Image Zoom modal
    modalImageContainer: {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      width: '42vw',
      minWidth: '507px',
      padding: '28px',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      '& img': {
        width: '100%',
        height: 'auto'
      }
    },
    closeZoomButton: {
      position: 'absolute',
      right: '4px',
      top: '4px',
      backgroundColor: palette.common.white,
      color: palette.secondary.light,
      padding: '4px',

      '& .MuiSvgIcon-root': {
        color: 'inherit',
        fontSize: '18px'
      }
    }
  })
);

export default uploadImagesStyles;
