import React, { useRef, useState } from 'react';

import { MaterialIcons } from '@luxclusif/material';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { TDateRangeState, IDateRangeProps } from 'models/component/luxDataTableInterface';

import dateRangeStyles from './dateRange.styles';

const DateRange: React.FC<IDateRangeProps> = ({ id, label, onChange, onOpen }) => {
  const classes = dateRangeStyles();
  const [dateRange, setDateRange] = useState<TDateRangeState>(null);
  const startDateRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);

  const onResetRange = () => {
    setDateRange(null);
    onChange(id, null);
  };

  const onClickIconCalendar = () => {
    if (startDateRef.current && !dateRange?.startDate) {
      startDateRef.current.click();
    } else if (endDateRef.current && !dateRange?.endDate) {
      endDateRef.current.click();
    }
  };

  const onStartDatePickerChange = (value: MaterialUiPickersDate) => {
    const valueUtc = value?.format() || null;
    let newDateRange: TDateRangeState;

    if (dateRange?.endDate) {
      newDateRange = { ...dateRange, startDate: valueUtc };
    } else {
      newDateRange = valueUtc ? { endDate: null, startDate: valueUtc } : null;
    }

    setDateRange(newDateRange);

    if (newDateRange?.endDate && newDateRange?.startDate) {
      onChange(id, newDateRange);
    }
  };

  const onEndDatePickerChange = (value: MaterialUiPickersDate) => {
    const valueUtc = value?.format() || null;
    let newDateRange: TDateRangeState;

    if (dateRange?.startDate) {
      newDateRange = { ...dateRange, endDate: valueUtc };
    } else {
      newDateRange = valueUtc ? { endDate: valueUtc, startDate: null } : null;
    }

    setDateRange(newDateRange);

    if (newDateRange?.endDate && newDateRange?.startDate) {
      onChange(id, newDateRange);
    }
  };

  const showResetIcon = !!dateRange?.startDate && !!dateRange?.endDate;

  return (
    <div className={classes.rangeDatePickerContainer}>
      <span className={classes.rangeDateDescription}>
        {label}
        {showResetIcon ? (
          <MaterialIcons.Close onClick={onResetRange} className={classes.rangeDateIcon} />
        ) : (
          <MaterialIcons.DateRange onClick={onClickIconCalendar} className={classes.rangeDateIcon} />
        )}
      </span>
      <DatePicker
        className={`${classes.datePicker} ${classes.initialDatePicker}`}
        inputVariant="standard"
        format="YYYY/MM/DD"
        placeholder="yyyy/mm/dd"
        InputProps={{ disableUnderline: true, ref: startDateRef }}
        inputProps={{ className: `${classes.datePickerInput}` }}
        onChange={onStartDatePickerChange}
        onOpen={() => onOpen(null)}
        value={dateRange?.startDate || null}
        {...(dateRange?.endDate && { maxDate: dateRange.endDate })}
      />

      <DatePicker
        className={`${classes.datePicker} ${classes.finalDatePicker}`}
        inputVariant="standard"
        format="YYYY/MM/DD"
        placeholder="yyyy/mm/dd"
        InputProps={{ disableUnderline: true, ref: endDateRef }}
        inputProps={{ className: classes.datePickerInput }}
        onChange={onEndDatePickerChange}
        onOpen={() => onOpen(null)}
        value={dateRange?.endDate || null}
        {...(dateRange?.startDate && { minDate: dateRange.startDate })}
      />
    </div>
  );
};

export default DateRange;
