import React from 'react';

import { EOrderItemStatus, TPurchaseOrderStatus } from 'models/purchaseOrders';

import statusIndicatorStyles, { statusTypeStyles } from './statusIndicator.styles';

interface IProps {
  label: string;
  // Add more types here with union("|").
  statusId: EOrderItemStatus | TPurchaseOrderStatus;
}

const StatusIndicator: React.FC<IProps> = ({ label, statusId }) => {
  const classes = statusIndicatorStyles();
  const statusTypeClasses = statusTypeStyles();
  const statusColorClass = statusTypeClasses[statusId] || classes.statusIndicatorColorDefault;

  return (
    <div className={classes.container}>
      <div className={`${classes.statusIndicator} ${statusColorClass}`} />
      <span className={classes.statusLabel}>{label}</span>
    </div>
  );
};

export default StatusIndicator;
