import React from 'react';

import { Alert, IconButton, LuxButton, MaterialIcons } from '@luxclusif/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import convertBytes, { EBytesMeasure } from 'utils/convertBytes';
import uuidGenerator from 'utils/uuidGenerator';

import uploadFilesStyles from './uploadFiles.styles';

export interface IFile {
  file: File;
  id: string;
}

interface IProps {
  accept: string;
  className?: string;
  files: IFile[];
  maxSize?: number;
  multiple?: boolean;
  setFiles: (files: IFile[]) => void;
  subtitle?: string;
}

const UploadFiles: React.FC<IProps> = ({
  accept,
  className,
  files,
  maxSize = 5120,
  multiple = false,
  setFiles,
  subtitle
}) => {
  const classes = uploadFilesStyles();
  const { t } = useTranslation();

  const onDropFile = (filesUploaded: File[]) => {
    const newFiles = filesUploaded.map(file => ({
      file,
      id: uuidGenerator()
    }));

    if (newFiles.length) {
      setFiles(multiple ? [...files, ...newFiles] : newFiles);
    }
  };

  const onRemoveFile = (fileId: string) => {
    const filesUploaded = files.filter(({ id }) => fileId !== id);

    setFiles(filesUploaded);
  };

  const { fileRejections, getInputProps, getRootProps, open } = useDropzone({
    accept,
    maxSize: convertBytes(maxSize, EBytesMeasure.B, EBytesMeasure.KB),
    multiple,
    noClick: true,
    onDrop: onDropFile
  });

  const renderErrors = () => {
    const [
      {
        errors,
        file: { name, size }
      }
    ] = fileRejections;
    const [{ code, message }] = errors;

    const errorMessage =
      code === 'too-many-files'
        ? message
        : `${name} - ${t('kiloBytes', { size: convertBytes(size, EBytesMeasure.KB) })}: ${message}`;

    return (
      <Alert className={classes.error} severity="error">
        {errorMessage}
      </Alert>
    );
  };

  return (
    <>
      <div
        {...getRootProps({
          className: `${classes.dropzoneContainer}${className ? ` ${className}` : ''}`
        })}
      >
        <input {...getInputProps()} />
        <div className={classes.dropzonePlaceholder}>
          <MaterialIcons.CloudUpload className={classes.dropzoneIcon} />
          <p className={classes.dropzoneLabel}>
            <b>{t('uploadFiles.dragDropToUpload')}</b>
            <span> {t('or')}</span>
          </p>
          <LuxButton className={classes.dropzoneButton} onClick={open} variant="outlined">
            {t('browseFiles')}
          </LuxButton>
        </div>
        {subtitle && <span className={classes.dropzoneSubtitle}>{subtitle}</span>}
      </div>
      {fileRejections.length > 0 && renderErrors()}
      {files.map(({ file: { name, size }, id }) => (
        <div key={id} className={classes.fileInfo}>
          <div className={classes.fileInfoLeft}>
            <MaterialIcons.FilePresent className={classes.fileInfoIcon} />
            <span className={classes.fileInfoName}>{name}</span>
          </div>
          <div className={classes.fileInfoRight}>
            <span className={classes.fileInfoSize}>
              {t('kiloBytes', { size: convertBytes(size, EBytesMeasure.KB) })}
            </span>
            <IconButton className={classes.fileInfoButton} onClick={() => onRemoveFile(id)}>
              <MaterialIcons.Delete />
            </IconButton>
          </div>
        </div>
      ))}
    </>
  );
};

export default UploadFiles;
