import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AuthService, CircularProgress } from '@luxclusif/material';
import { IModule } from '@luxclusif/material/dist/components/LuxSuperFrame/interfaces';
import { StatusCodes } from 'http-status-codes';
import { User } from 'oidc-client';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import PurchaseOrdersImage from 'assets/svg/welcome_purchase_orders.svg';
import SuppliersImage from 'assets/svg/welcome_suppliers.svg';
import WarehouseImage from 'assets/svg/welcome_warehouse.svg';
import Logout from 'components/Logout/Logout';
import { LUX_CORE_LOGOUT, LUX_CORE_ROUTE_CHANGE } from 'constants/Events';
import { ApiProvider } from 'contexts/ApiContext';
import { WarehouseProvider } from 'contexts/WarehouseContext';
import { defaultLanguage } from 'i18n';
import {
  EWarehouseName,
  LOCAL_STORAGE_USER_WAREHOUSE,
  LOCAL_STORAGE_USER_WAREHOUSE_WARNING_DATE,
  LOCAL_STORAGE_USER_MEASUREMENT_UNIT
} from 'models/warehouse';
import EInboundModule from 'navigation/models/EInboundModule';
import EInboundRole from 'navigation/models/EInboundRole';
import EInboundRoute from 'navigation/models/EInboundRoute';
import Error from 'pages/Error/Error';
import PurchaseOrder from 'pages/PurchaseOrder/PurchaseOrder';
import { PurchaseOrdersProvider } from 'pages/PurchaseOrders/contexts/PurchaseOrdersContext';
import PurchaseOrders from 'pages/PurchaseOrders/PurchaseOrders';
import Supplier from 'pages/Supplier/SupplierDetails';
import { SuppliersProvider } from 'pages/Suppliers/contexts/SuppliersContext';
import Suppliers from 'pages/Suppliers/Suppliers';
import WarehouseItem from 'pages/WarehouseItem/WarehouseItem';
import { measurementUnit } from 'pages/WarehouseList/constants';
import { WarehouseListProviderV2 } from 'pages/WarehouseList/contexts/WarehouseListContextV2';
import WarehouseListV2 from 'pages/WarehouseList/WarehouseListV2';
import Welcome from 'pages/Welcome/Welcome';
import userService from 'services/User.service';
import OldBaseConfiguration from 'setup/OldBaseConfiguration';
import { $logOut, $redirectToError } from 'utils/axios';

import ProviderWrapper from './ProviderWrapper';

import routerConfigStyles from './privateRouter.styles';

interface IAppRoute {
  component?: JSX.Element;
  id: EInboundModule;
  isNewDesign?: boolean;
  roles: EInboundRole[];
  url: string;
}

interface IModuleConfig extends IModule {
  component?: JSX.Element;
  description: string;
  id: EInboundModule;
  image: string;
  isNewDesign?: boolean;
  roles: EInboundRole[];
  subRoutes?: IAppRoute[];
}

interface IUserInfo {
  email: string;
  roles: string[];
}

const PrivateRouter: React.FC = () => {
  const classes = routerConfigStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n, ready, t } = useTranslation(['common', 'welcome']);

  const [isAppLoading, setIsAppLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    email: '',
    roles: []
  });

  const isWarehouseEnabled = useMemo(() => {
    const userWarehouse = localStorage.getItem(LOCAL_STORAGE_USER_WAREHOUSE);
    const enabledWarehouseList = process.env.REACT_APP_ENABLE_NEW_WAREHOUSE_LIST;

    return enabledWarehouseList?.split(',').includes(userWarehouse as string);
  }, []);

  const modules: IModuleConfig[] = [
    ...(process.env.REACT_APP_ENABLE_PURCHASE_ORDERS_MODULE === 'true'
      ? [
          {
            component: <PurchaseOrders />,
            description: t('welcome:purchaseOrdersDescription'),
            id: EInboundModule.PurchaseOrders,
            image: PurchaseOrdersImage,
            isNewDesign: true,
            name: t('common:purchaseOrders'),
            roles: [EInboundRole.Admin, EInboundRole.Tagger],
            subRoutes: [
              {
                component: <PurchaseOrder />,
                id: EInboundModule.PurchaseOrders,
                roles: [EInboundRole.Admin, EInboundRole.Tagger],
                url: `${EInboundRoute.PurchaseOrder}/:poId`
              },
              {
                component: <PurchaseOrder />,
                id: EInboundModule.PurchaseOrders,
                roles: [EInboundRole.Admin, EInboundRole.Tagger],
                url: EInboundRoute.PurchaseOrder
              }
            ],
            title: t('common:purchaseOrders'),
            url: EInboundRoute.PurchaseOrders
          }
        ]
      : []),
    {
      component: <Suppliers />,
      description: t('welcome:suppliersDescription'),
      id: EInboundModule.Suppliers,
      image: SuppliersImage,
      isNewDesign: true,
      name: t('common:suppliers'),
      roles: [EInboundRole.Admin],
      subRoutes: [
        {
          component: <Supplier />,
          id: EInboundModule.Suppliers,
          roles: [EInboundRole.Admin],
          url: `${EInboundRoute.Supplier}/:supplierId`
        },
        {
          component: <Supplier />,
          id: EInboundModule.Suppliers,
          roles: [EInboundRole.Admin],
          url: `${EInboundRoute.Supplier}`
        }
      ],
      title: t('common:suppliers'),
      url: EInboundRoute.Suppliers
    },
    ...(isWarehouseEnabled
      ? [
          {
            component: (
              <WarehouseListProviderV2>
                <WarehouseListV2 />
              </WarehouseListProviderV2>
            ),
            description: t('welcome:warehouseDescription'),
            id: EInboundModule.Others,
            image: WarehouseImage,
            isNewDesign: true,
            name: t('common:warehouse'),
            roles: [EInboundRole.Admin, EInboundRole.Warehouse],
            subRoutes: [
              {
                component: <WarehouseItem />,
                id: EInboundModule.Others,
                roles: [EInboundRole.Admin, EInboundRole.Warehouse],
                url: `${EInboundRoute.Warehouse}/:itemId/:action`
              }
            ],
            title: t('common:warehouse'),
            url: EInboundRoute.Warehouse
          }
        ]
      : [])
  ];

  useEffect(() => {
    const errorSubscription = $redirectToError.subscribe((status: StatusCodes) => {
      navigate(EInboundRoute.Error, { state: status });
    });

    const logoutSubscription = $logOut.subscribe(() => {
      navigate(EInboundRoute.Logout);
    });

    return () => {
      errorSubscription.unsubscribe();
      logoutSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isAppLoading && location.pathname !== EInboundRoute.Logout) {
      AuthService.isUserLoggedIn()
        .then((loggedIn: boolean) => {
          if (!loggedIn) {
            setIsLoggedIn(false);
          }
        })
        .catch(() => setIsLoggedIn(false));
    }
  }, [location]);

  useEffect(() => {
    AuthService.getUserManager()
      .getUser()
      .then(async (userTokenInformation: User | null) => {
        if (userTokenInformation) {
          const profile = await userService.getUserClaims();

          if (profile) {
            const { email, language, role, warehouse_name } = profile;
            const formattedWarehouseName = warehouse_name?.toUpperCase();

            if (language && language !== i18n.language) {
              await i18n.changeLanguage(language);
            } else if (!language && i18n.language !== defaultLanguage) {
              await i18n.changeLanguage(defaultLanguage);
            }

            if (formattedWarehouseName) {
              localStorage.setItem(LOCAL_STORAGE_USER_WAREHOUSE, formattedWarehouseName);
              localStorage.removeItem(LOCAL_STORAGE_USER_WAREHOUSE_WARNING_DATE);
            } else {
              localStorage.removeItem(LOCAL_STORAGE_USER_WAREHOUSE);
            }

            if (formattedWarehouseName === EWarehouseName.USA) {
              localStorage.setItem(LOCAL_STORAGE_USER_MEASUREMENT_UNIT, measurementUnit[0]);
            } else {
              localStorage.setItem(LOCAL_STORAGE_USER_MEASUREMENT_UNIT, measurementUnit[1]);
            }

            setUserInfo({
              email: email || '',
              roles: role && typeof role === 'string' ? [role] : role || []
            });
            setIsLoggedIn(true);
          }
        }

        setIsAppLoading(false);
      });
  }, []);

  const handleRouteChange = ((e: CustomEvent<{ path: string }>) => {
    navigate(e.detail.path);
  }) as EventListener;

  useEffect(() => {
    document.addEventListener(LUX_CORE_ROUTE_CHANGE, handleRouteChange);

    return () => {
      document.removeEventListener(LUX_CORE_ROUTE_CHANGE, handleRouteChange);
    };
  }, []);

  const handleLogout = () => AuthService.logout();

  useEffect(() => {
    document.addEventListener(LUX_CORE_LOGOUT, handleLogout);

    return () => {
      document.removeEventListener(LUX_CORE_LOGOUT, handleLogout);
    };
  }, []);

  const onVerifyRole = useCallback(
    ({ roles }: IModuleConfig | IAppRoute) => (roles ? roles.some(role => userInfo.roles.includes(role)) : true),
    [userInfo.roles]
  );

  const userModules = modules.filter(onVerifyRole);

  const userRoutes = useMemo(
    () =>
      userModules.reduce(
        (
          routes: Record<string, JSX.Element[]>,
          { component, id, isNewDesign, roles, subRoutes: moduleSubRoutes, url }
        ) => {
          const mainRoute = { component, id, isNewDesign, roles, url };
          const subRoutes: IAppRoute[] = moduleSubRoutes?.filter(onVerifyRole) || [];

          const moduleRoutes = [...subRoutes, mainRoute].map(({ component, isNewDesign, url }) =>
            isNewDesign ? (
              <Route element={component} key={url} path={url} />
            ) : (
              <Route element={<OldBaseConfiguration>{component}</OldBaseConfiguration>} key={url} path={url} />
            )
          );

          return { ...routes, [id as EInboundModule]: [...(routes[id as EInboundModule] || []), ...moduleRoutes] };
        },
        {}
      ),
    [onVerifyRole, userModules]
  );

  return isAppLoading || !ready ? (
    <div className={classes.loading}>
      <CircularProgress size={60} />
    </div>
  ) : isLoggedIn ? (
    <ApiProvider>
      <Routes>
        <Route element={<Logout />} path={EInboundRoute.Logout} />
        <Route element={<ProviderWrapper provider={PurchaseOrdersProvider} />}>
          {userRoutes[EInboundModule.PurchaseOrders]}
        </Route>
        <Route element={<ProviderWrapper provider={SuppliersProvider} />}>{userRoutes[EInboundModule.Suppliers]}</Route>
        <Route element={<ProviderWrapper provider={WarehouseProvider} />}>{userRoutes[EInboundModule.Others]}</Route>
        <Route
          element={
            <Routes>
              {userRoutes[EInboundModule.Others]}
              <Route
                element={
                  <OldBaseConfiguration>
                    <Error />
                  </OldBaseConfiguration>
                }
                path={EInboundRoute.Error}
              />
              <Route
                element={
                  <OldBaseConfiguration>
                    <Welcome email={userInfo.email} modules={userModules} />
                  </OldBaseConfiguration>
                }
                path={EInboundRoute.Root}
              />
              <Route element={<Navigate to={EInboundRoute.Root} />} path="*" />
            </Routes>
          }
          path="*"
        />
      </Routes>
    </ApiProvider>
  ) : (
    <Navigate state={{ redirectUrl: location.pathname }} to={EInboundRoute.Login} />
  );
};

export default PrivateRouter;
