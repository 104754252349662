import React from 'react';

import { TableCell } from '@luxclusif/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DamageSeverityTicker from 'components/Input/DamageSeverityTicker/DamageSeverityTicker';
import {
  EDamageSeverityLevel,
  HardwareDamageKeys,
  InteriorExteriorDamageOtherDamagesKeys,
  InteriorExteriorDamageStainedKeys
} from 'models/warehouse';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import {
  defaultHardwareDamages,
  defaultInteriorExteriorDamages,
  IDamage,
  IInteriorExteriorDamages,
  IQualityCheckFormInputs,
  THardwareDamages
} from 'pages/WarehouseList/schemas/qualityCheckSchema';

import bagLocationStyles from './bagLocation.styles';

interface IProps {
  form: UseFormMethods<IQualityCheckFormInputs>;
  hardware?: boolean;
  isRegularFlow: boolean;
  name: string;
  otherDamages?: boolean;
  stained?: boolean;
}

const BagLocation: React.FC<IProps> = ({ form, hardware, isRegularFlow, name, otherDamages, stained }) => {
  const classes = bagLocationStyles();
  const { t } = useTranslation('warehouse');
  const { isViewMode } = useWarehouseItemContext();

  const { control, watch } = form;

  const onTickerChange = (
    currentValue: IDamage,
    newValueSeverity: EDamageSeverityLevel | null,
    onChange: (newDamage: IDamage) => void
  ) => {
    onChange({
      ...currentValue,
      isAdditional: currentValue.isAdditional && currentValue.previousSeverityId === newValueSeverity ? false : true,
      isChecked: !!newValueSeverity,
      severityId: newValueSeverity
    });

    form.setValue('hasPassedQualityCheck', null);
  };

  if (hardware) {
    const attributes: THardwareDamages = watch(name, defaultHardwareDamages);

    return (
      <TableCell align="left" className={classes.cellBorder}>
        <div className={classes.chipCheckBoxContainer}>
          {(Object.keys(attributes) as HardwareDamageKeys[]).map(key => (
            <Controller
              key={key}
              control={control}
              name={`${name}.${key}`}
              render={({ onChange, value }) => (
                <DamageSeverityTicker
                  isDisabled={isViewMode}
                  isAdditional={value.isAdditional}
                  isRegularFlow={isRegularFlow}
                  label={t(`qualityCheckStation.damage.${key}`)}
                  onChange={newValueSeverity => onTickerChange(value, newValueSeverity, onChange)}
                  value={value?.severityId || null}
                />
              )}
            />
          ))}
        </div>
      </TableCell>
    );
  }

  const attributes: IInteriorExteriorDamages = watch(name, defaultInteriorExteriorDamages);

  return (
    <>
      {stained && (
        <TableCell align="left" className={classes.cellBorder} width={403}>
          <div className={classes.chipCheckBoxContainer}>
            {(Object.keys(attributes.stained) as InteriorExteriorDamageStainedKeys[]).map(key => (
              <Controller
                key={key}
                control={control}
                name={`${name}.stained.${key}`}
                render={({ onChange, value }) => (
                  <DamageSeverityTicker
                    isDisabled={isViewMode}
                    isAdditional={value.isAdditional}
                    isRegularFlow={isRegularFlow}
                    label={t(`qualityCheckStation.damage.${key}`)}
                    onChange={newValueSeverity => onTickerChange(value, newValueSeverity, onChange)}
                    value={value?.severityId || null}
                  />
                )}
              />
            ))}
          </div>
        </TableCell>
      )}
      {otherDamages && (
        <TableCell align="left" className={classes.cellBorder}>
          <div className={classes.chipCheckBoxContainer}>
            {(Object.keys(attributes.otherDamages) as InteriorExteriorDamageOtherDamagesKeys[]).map(key => (
              <Controller
                key={key}
                control={control}
                name={`${name}.otherDamages.${key}`}
                render={({ onChange, value }) => (
                  <DamageSeverityTicker
                    isDisabled={isViewMode}
                    isAdditional={value.isAdditional}
                    isRegularFlow={isRegularFlow}
                    label={t(`qualityCheckStation.damage.${key}`)}
                    onChange={newValueSeverity => onTickerChange(value, newValueSeverity, onChange)}
                    value={value?.severityId || null}
                  />
                )}
              />
            ))}
          </div>
        </TableCell>
      )}
    </>
  );
};

export default BagLocation;
