import { createStyles, makeStyles } from '@luxclusif/material';

const importItemsModalStyles = makeStyles(() =>
  createStyles({
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      padding: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '684px'
    },
    title: {
      fontSize: '20px',
      lineHeight: '24px',
      margin: 0
    },
    uploadFiles: {
      marginTop: '24px'
    },
    actions: {
      alignSelf: 'flex-end',
      display: 'flex',
      gap: '16px',
      marginTop: '40px'
    },
    button: {
      lineHeight: '19px',
      padding: '8px 24px'
    }
  })
);

export default importItemsModalStyles;
