import React, { useEffect, useState } from 'react';

import { Alert, CircularProgress, IconButton, LuxButton, MaterialIcons } from '@luxclusif/material';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { IPurchaseInfo } from 'pages/PurchaseOrder/schema';
import purchaseOrdersService from 'services/PurchaseOrders.service';
import convertBytes, { EBytesMeasure } from 'utils/convertBytes';
import uuidGenerator from 'utils/uuidGenerator';

import purchaseOrderStyles from 'pages/PurchaseOrder/purchaseOrder.styles';

import orderInformationStyles from '../../orderInformation.styles';

interface IFilesUploadedState {
  fileInfo: File;
  id: string;
  initial?: boolean;
  isPending: boolean;
  url: string;
}

const FilesSection: React.FC = () => {
  const { t } = useTranslation('common');
  const commonClasses = purchaseOrderStyles();
  const orderInformationClasses = orderInformationStyles();

  const { poId = '' } = useParams<{ poId: string }>();
  const form = useFormContext<IPurchaseInfo>();
  const { isDirty } = form.formState;
  const watchFiles = form.watch('files');

  const [filesUploaded, setFilesUploaded] = useState<IFilesUploadedState[]>([]);
  const [hasFiveMbFile, setHasFiveMbFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (poId && !isDirty) {
      form.register('filesToDelete');
    }
  }, [isDirty]);

  useEffect(() => {
    if (poId) {
      const newFilesUploaded = watchFiles.map(file => ({
        fileInfo: {
          name: file.name,
          size: file.fileSize,
          type: file.type
        },
        id: file.fileId,
        initial: true,
        isPending: false,
        url: file.fileUrl
      }));

      setFilesUploaded(newFilesUploaded as IFilesUploadedState[]);
    }
  }, []);

  const onDropFile = async (files: File[]) => {
    const hasInvalidSize = !!files.find(file => convertBytes(file.size, EBytesMeasure.MB) > 5);

    if (!hasInvalidSize) {
      setHasFiveMbFile(false);

      const formattedFiles: IFilesUploadedState[] = files.map(file => ({
        fileInfo: file,
        id: uuidGenerator(),
        isPending: true,
        url: ''
      }));

      const newFilesUploaded = [...filesUploaded, ...formattedFiles];

      setFilesUploaded(newFilesUploaded);

      // Upload Request Process
      setIsUploading(true);

      for (let index = 0; index < newFilesUploaded.length; index++) {
        const fileToUpload = newFilesUploaded[index];

        if (fileToUpload.isPending) {
          const url = await purchaseOrdersService.postFile(fileToUpload.fileInfo, fileToUpload.id);

          const newFormattedFile: IFilesUploadedState = {
            ...fileToUpload,
            isPending: false,
            url
          };

          const formattedFileIndex = newFilesUploaded.findIndex(
            fileUploaded => fileUploaded.id === newFormattedFile.id
          );

          newFilesUploaded[formattedFileIndex] = newFormattedFile;

          const fileFormFormat = newFilesUploaded.map(({ fileInfo, id, url }) => ({
            fileId: id,
            fileSize: fileInfo.size,
            fileUrl: url,
            name: fileInfo.name,
            originalName: fileInfo.name,
            type: fileInfo.name.match(/\.([0-9a-z]{1,5}$)/i)?.[1] || ''
          }));

          form.setValue('files', fileFormFormat, { shouldDirty: true, shouldValidate: true });
        }
      }

      setFilesUploaded(newFilesUploaded);
      setIsUploading(false);
    } else {
      setHasFiveMbFile(true);
    }
  };

  const onRemoveFile = (fileId: string) => {
    const shouldDelete = filesUploaded.find(({ id }) => id === fileId)?.initial || false;

    const newFilesUploaded = filesUploaded.filter(fileUploaded => fileUploaded.id !== fileId);

    setFilesUploaded(newFilesUploaded);

    const fileFormFormat = newFilesUploaded.map(({ fileInfo, id, url }) => ({
      fileId: id,
      fileSize: fileInfo.size,
      fileUrl: url,
      name: fileInfo.name,
      originalName: fileInfo.name,
      type: fileInfo.name.match(/\.([0-9a-z]{1,5}$)/i)?.[1] || ''
    }));

    form.setValue('files', fileFormFormat, { shouldDirty: true, shouldValidate: true });

    if (poId && shouldDelete) {
      const filesToDelete = form.getValues('filesToDelete') || [];

      form.setValue('filesToDelete', [...filesToDelete, fileId], { shouldDirty: true, shouldValidate: true });
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: '.pdf, .xls, .xlsx, .csv',
    disabled: isUploading,
    onDrop: onDropFile
  });

  return (
    <>
      <h4 className={commonClasses.formSectionTitle}>
        {t('files')} ({filesUploaded.length})
      </h4>
      <div
        {...getRootProps({
          className: `${orderInformationClasses.dropZoneContainer} ${
            orderInformationClasses[isUploading ? 'dropZoneDisabled' : 'dropZoneEnabled']
          }`
        })}
      >
        <input {...getInputProps()} {...(form.register('files') as any)} />
        <div className={orderInformationClasses.dropZonePlaceholderContainer}>
          <MaterialIcons.CloudUpload className={orderInformationClasses.dropZoneIcon} />
          <p className={orderInformationClasses.dropZoneLabel}>
            {t('uploadFiles.dragDropToUpload')} {t('or')}
          </p>
          <LuxButton disabled={isUploading} className={orderInformationClasses.dropZoneButton} variant="outlined">
            {t('browseFiles')}
          </LuxButton>
        </div>
      </div>
      <div className={orderInformationClasses.filesContainer}>
        {hasFiveMbFile && <Alert severity="error">{t('uploadFiles.errorFileSize', { fileSize: '5MB' })}</Alert>}
        {filesUploaded.map(({ fileInfo, id, isPending, url }) => (
          <div key={id} className={orderInformationClasses.filesInfo}>
            {isPending ? (
              <CircularProgress size={22} className={orderInformationClasses.fileInfoIcon} />
            ) : (
              <MaterialIcons.FilePresent className={orderInformationClasses.fileInfoIcon} />
            )}
            <p className={orderInformationClasses.fileInfoName}>{fileInfo.name}</p>
            <p className={orderInformationClasses.fileInfoSize}>
              {t('kiloBytes', { size: convertBytes(fileInfo.size, EBytesMeasure.KB) })}
            </p>
            <div className={orderInformationClasses.fileInfoActionContainer}>
              {!isPending && (
                <>
                  <IconButton className={orderInformationClasses.fileInfoAction} target="_blank" href={url}>
                    <MaterialIcons.Download />
                  </IconButton>
                  <IconButton
                    disabled={isUploading}
                    className={orderInformationClasses.fileInfoAction}
                    onClick={() => onRemoveFile(id)}
                  >
                    <MaterialIcons.Delete />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FilesSection;
