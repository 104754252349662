import React from 'react';

import { AppBar, MaterialIcons, Tab, Tabs } from '@luxclusif/material';

import formTabStyles from './formTabs.styles';

interface ITabObject {
  icon?: string;
  label: string;
  value: number;
}

interface ITabQuantity {
  quantity: number;
  tabValue: number;
}

interface IProps {
  changeTab: (val: number) => void;
  full?: boolean;
  fullWidth?: boolean;
  quantityPerTab?: Array<ITabQuantity>;
  tabObjArray: Array<ITabObject>;
  value: number;
}

const FormTabs: React.FC<IProps> = ({ changeTab, full, fullWidth, quantityPerTab, tabObjArray, value }) => {
  const classes = formTabStyles();

  const handleChange = (htmlComponent: React.SyntheticEvent, newValue: number) => changeTab(newValue);

  const getTabQuantity = (tabValue: number) => {
    const result = quantityPerTab?.filter(obj => {
      return obj.tabValue === tabValue;
    });

    return result ? result[0].quantity : 0;
  };

  const changeTabByIndex = (index: number, full = false) => {
    if (full) {
      return {
        'aria-controls': `full-width-tabpanel-${index}`,
        id: `full-width-tab-${index}`
      };
    }

    return {
      'aria-controls': `wrapped-tabpanel-${index}`,
      id: `wrapped-tab-${index}`
    };
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        indicatorColor="primary"
        className={full ? classes.fullTabs : classes.tabs}
        variant={fullWidth ? 'fullWidth' : undefined}
        data-testid="formTabs"
        TabIndicatorProps={{
          style: {
            display: full ? 'none' : ''
          }
        }}
      >
        {tabObjArray.map(tab => {
          let Icon = null;

          if (tab.icon) {
            Icon = (MaterialIcons as any)[tab.icon];
          }

          return (
            <Tab
              value={tab.value}
              key={tab.value}
              label={quantityPerTab ? `${tab.label} (${getTabQuantity(tab.value)})` : tab.label}
              {...changeTabByIndex(tab.value, full)}
              icon={Icon && <Icon />}
              className={full ? classes.fullTab : classes.tab}
              data-testid={`tab${tab.value}`}
            />
          );
        })}
      </Tabs>
    </AppBar>
  );
};

export default FormTabs;
