import { GridRowsProp } from '@mui/x-data-grid';
import moment from 'moment';
import * as yup from 'yup';

import {
  IFilePurchase,
  IOrderItemStatus,
  ISelectedEntity,
  ITrackingDetail,
  ITrackingNumber
} from 'models/purchaseOrders';
import { EBusinessModelsId } from 'models/supplier';

/**
 * This date format is based from M-UIv5 TextField Component
 * https://material-ui.com/components/text-fields/
 */
export const muiDateFormat = 'YYYY-MM-DD';

interface IPurchaseFormInputs {
  businessModelId: EBusinessModelsId;
  buyerId: string;
  consignmentEndDate?: string | null;
  consignmentStartDate?: string | null;
  courierId: string;
  curationType?: string;
  currencyId: string;
  date: string;
  entityCompanyId: string;
  estimatedDeliveryDate: string;
  files: IFilePurchase[];
  filesToDelete?: string[];
  freightTermId: string;
  items: IItemInfo[];
  notes?: string | null;
  paymentMethodId: string;
  paymentTermId: string;
  procurementType?: string;
  receptionWarehouseId: string;
  statusId: string;
  supplierClusterId: string;
  supplierId: string;
  title: string;
}

export interface IPurchaseInfo extends IPurchaseFormInputs {
  poNumber?: Readonly<string>;
  splitFrom?: Readonly<string | null>;
  supplierName?: Readonly<string>;
  trackingsDetail?: Readonly<ITrackingDetail[]>;
}

interface IDraftEntity {
  id: string | null;
  name: string;
}

interface IItemColor extends IDraftEntity {
  isMainColor: boolean;
}

export interface IItemInfo extends GridRowsProp {
  baseSellingPrice?: number | null;
  beltLength?: string | null;
  beltSize?: string | null;
  brand: ISelectedEntity;
  buckleLength?: string | null;
  buckleWidth?: string | null;
  carat?: number | null;
  category: ISelectedEntity;
  circumference?: string | null;
  colors?: IItemColor[];
  competitorPriceUSD?: number | null;
  costWithTax: number;
  costWithoutTax: number;
  countryOfOrigin?: string | null;
  depth?: string | null;
  diameter?: string | null;
  gender?: string | null;
  handleDrop?: string | null;
  inclusionIds?: number[];
  isFinalized?: boolean;
  itemId?: string;
  lengthOrHeight?: string | null;
  materials?: IDraftEntity[];
  midCompetitorPriceUSD?: string | null;
  modelNumber: string | null;
  name: string | null;
  photos: string[];
  productSellerUrl?: string | null;
  ringSize?: string | null;
  secondHandPriceUSD?: string | null;
  sellerCode: string | null;
  serialNumber: string | null;
  shoeSize?: string | null;
  shoulderDrop?: string | null;
  size?: string | null;
  sku?: string;
  status: IOrderItemStatus;
  strapLength?: string | null;
  tags?: string | null;
  thickness?: string | null;
  trackingNumbers?: ITrackingNumber[];
  usdCostPrice: number | null;
  width?: string | null;
}

export interface IItemErrorWarning {
  message: string;
  rowNumber: number;
}

export interface IItemsInfoData {
  data: IItemInfo[];
  errors: IItemErrorWarning[];
  failedItems: number;
  succededItems: number;
  totalItems: number;
  totalwarnings: number;
  warnings: IItemErrorWarning[];
}

export const trackingNumberSchema = yup.array().of(
  yup.object({
    code: yup.string().required(),
    courier: yup
      .object({
        id: yup.string().required(),
        name: yup.string().required()
      })
      .nullable()
      .required(),
    estimatedDeliveryDate: yup.string().required()
  })
);

export const purchaseOrdersSchema = yup
  .object()
  .shape({
    businessModelId: yup.string().required(),
    buyerId: yup.string().required(),
    consignmentEndDate: yup.string().when('businessModelId', {
      is: EBusinessModelsId.PhysicalConsignment,
      then: yup
        .string()
        .when('consignmentStartDate', (consignmentStartDate, schema: yup.BaseSchema) => {
          return schema.test({
            test: (consignmentEndDate: string) => new Date(consignmentStartDate) < new Date(consignmentEndDate)
          });
        })
        .required()
    }),
    consignmentStartDate: yup.string().when('businessModelId', {
      is: EBusinessModelsId.PhysicalConsignment,
      then: yup.string().required()
    }),
    courierId: yup.string().required(),
    curationType: yup.string().required(),
    currencyId: yup.string().required(),
    date: yup.string().required(),
    entityCompanyId: yup.string().required(),
    estimatedDeliveryDate: yup.string().required(),
    files: yup.array().of(
      yup.object().shape({
        fileId: yup.string(),
        fileSize: yup.number(),
        fileUrl: yup.string(),
        name: yup.string(),
        originalName: yup.string(),
        type: yup.string()
      })
    ),
    freightTermId: yup.string().required(),
    // As the 'items' are already validated by the BE and are mostly readonly, we will not validate any fields.
    items: yup.array().of(
      yup.object().shape({
        trackingNumbers: trackingNumberSchema
      })
    ),
    notes: yup.string(),
    paymentMethodId: yup.string().required(),
    paymentTermId: yup.string().required(),
    procurementType: yup.string().required(),
    receptionWarehouseId: yup.string().required(),
    statusId: yup.string().required(),
    ...(process.env.REACT_APP_ENABLE_SUPPLIER_CLUSTER === 'true'
      ? {
          supplierClusterId: yup.string().required()
        }
      : {}),
    supplierId: yup.string().required(),
    title: yup.string().required()
  })
  .required();

export const defaultValues: IPurchaseFormInputs = {
  businessModelId: '' as EBusinessModelsId,
  buyerId: '',
  consignmentEndDate: null,
  consignmentStartDate: null,
  courierId: '',
  curationType: '',
  currencyId: '',
  date: moment().format(muiDateFormat),
  entityCompanyId: '',
  estimatedDeliveryDate: '',
  files: [],
  freightTermId: '',
  items: [],
  notes: '',
  paymentMethodId: '',
  paymentTermId: '',
  procurementType: '',
  receptionWarehouseId: '',
  statusId: '',
  supplierClusterId: '',
  supplierId: '',
  title: ''
};
