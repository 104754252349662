import React, { useMemo } from 'react';

import { EInboundFlow, EStation } from '@inbound/api';
import Chip from '@lux-ds/chip';
import { AppBar, Button, MaterialIcons } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import InboundFlowChip from 'components/InboundFlowChip/InboundFlowChip';
import StationAlertChip from 'components/StationAlertChip/StationAlertChip';
import { EWarehouseName, TStationAlertKeys } from 'models/warehouse';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import WAREHOUSE_CONSTANTS from 'pages/WarehouseList/constants';
import BaseConfiguration from 'setup/BaseConfiguration';

import warehouseTitleSectionStyles from './warehouseTitleSection.styles';

interface IProps {
  setPrintDamageCardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPrintSkuBarcodeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WarehouseTitleSection: React.FC<IProps> = ({ setPrintDamageCardOpen, setPrintSkuBarcodeOpen }) => {
  const classes = warehouseTitleSectionStyles();
  const { t } = useTranslation(['common', 'warehouse']);
  const { activeStation, itemDetails } = useWarehouseItemDetailsContext();

  const alertTranslationKey = useMemo((): TStationAlertKeys => {
    const stationAlert = itemDetails.stationAlert?.toString();

    return `${stationAlert?.charAt(0).toLocaleLowerCase()}${stationAlert?.slice(1)}` as TStationAlertKeys;
  }, [itemDetails]);

  return (
    <AppBar position="relative" className={classes.formHeader} elevation={0}>
      <div className={classes.itemTitleContainer}>
        <span className={classes.itemTitle}>
          <b>{t('common:item')}</b> {itemDetails.sku}
        </span>
        <InboundFlowChip
          type={itemDetails.inboundFlow?.id as EInboundFlow}
          label={itemDetails.inboundFlow?.name || ''}
        />
        {itemDetails.stationAlert && (
          <StationAlertChip
            type={itemDetails.stationAlert}
            label={t(`warehouse:stationAlerts.${alertTranslationKey}`)}
          />
        )}
        {itemDetails.isAutoQC ? (
          <BaseConfiguration>
            <Chip color="citlali" label={t('warehouse:skipQC')} squared />
          </BaseConfiguration>
        ) : null}
      </div>
      <div className={classes.rightActionPanel}>
        {activeStation !== EStation.QualityControl && itemDetails.warehouse === EWarehouseName.Japan && (
          <Button
            variant="outlined"
            size="small"
            className={classes.rightActionPanelButtons}
            startIcon={<MaterialIcons.Print />}
            onClick={() => setPrintDamageCardOpen(true)}
          >
            {t('warehouse:details.printItemCard')}
          </Button>
        )}
        {activeStation === WAREHOUSE_CONSTANTS.STATIONS.QUALITY_CHECK && (
          <Button
            variant="outlined"
            size="small"
            className={classes.rightActionPanelButtons}
            startIcon={<MaterialIcons.Print />}
            onClick={() => setPrintDamageCardOpen(true)}
          >
            {t('warehouse:details.printDamageCard')}
          </Button>
        )}

        <Button
          variant="outlined"
          size="small"
          // className={classes.rightActionPanelButtons}
          startIcon={<MaterialIcons.Print />}
          onClick={() => setPrintSkuBarcodeOpen(true)}
        >
          {t('warehouse:details.printBarcode')}
        </Button>
      </div>
      {/* <LuxIconButton
        onClick={() => setIncidentModalOpened(true)}
        tooltipTitle={t('warehouse:details.reportIncident')}
        Icon={MaterialIcons.Error}
      /> */}
    </AppBar>
  );
};

export default WarehouseTitleSection;
