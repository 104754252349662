import React, { useState } from 'react';

import { CircularProgress, IconButton, LuxButton, MaterialIcons, Modal, Paper } from '@luxclusif/material';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import UploadedImage, { IDisabledUploadedImages } from './UploadedImage';

import uploadImagesStyles from './uploadImages.styles';

interface IDisabledProps extends IDisabledUploadedImages {
  input?: boolean;
}

export interface IImageValue {
  id: string;
  label?: React.ReactNode;
  url: string;
}

export interface IProps {
  className?: string;
  disabled?: IDisabledProps;
  error?: boolean;
  isUploading: boolean;
  multiple?: boolean;
  onDeletePhoto?: (photoId: string) => void;
  onUpload: DropzoneOptions['onDrop'];
  value: IImageValue[];
}

/**
 * @description This component will be the new input/placeholder for the images basing from the updated design system of IM
 *
 */
const UploadImages: React.FC<IProps> = ({
  className,
  disabled,
  error,
  isUploading,
  multiple,
  onDeletePhoto,
  onUpload,
  value
}) => {
  const { t } = useTranslation('common');

  const classes = uploadImagesStyles();

  const [zoomPhoto, setZoomPhoto] = useState<string>('');

  const onZoom = (url: string) => setZoomPhoto(url);

  const onCloseZoom = () => setZoomPhoto('');

  const { getInputProps, getRootProps, open } = useDropzone({
    accept: '.jpg, .jpeg, .png',
    disabled: isUploading || disabled?.input,
    multiple,
    noClick: true,
    onDrop: onUpload
  });

  const isSingleMethod = value.length !== 0 && !multiple;

  return (
    <>
      <div className={`${classes.container}${className ? ` ${className}` : ''}`}>
        {value.map(uploadedImage => (
          <UploadedImage
            key={uploadedImage.id}
            disabled={{
              delete: disabled?.delete || isUploading,
              download: disabled?.download
            }}
            id={uploadedImage.id}
            label={uploadedImage.label}
            onDelete={onDeletePhoto}
            onZoom={onZoom}
            url={uploadedImage.url}
          />
        ))}

        {!isSingleMethod && (
          <div
            {...getRootProps({
              className: `${classes.dropZoneContainer}${error ? ` ${classes.uploadImageError}` : ''}`
            })}
          >
            <input {...getInputProps()} />
            {isUploading ? (
              <CircularProgress />
            ) : (
              <>
                <MaterialIcons.CloudUpload className={classes.dropZoneIcon} />
                <b className={classes.dropZoneLabel}>{t('uploadImages.singleDropToUpload')}</b>
                <span className={classes.dropZoneLabel}>{t('or')}</span>
                <LuxButton
                  className={classes.dropZoneButton}
                  disabled={disabled?.input || isUploading}
                  onClick={open}
                  variant="outlined"
                >
                  {t('browseFiles')}
                </LuxButton>
              </>
            )}
          </div>
        )}
      </div>
      {zoomPhoto && (
        <Modal onClose={onCloseZoom} open>
          <Paper className={classes.modalImageContainer}>
            <IconButton className={classes.closeZoomButton} onClick={onCloseZoom}>
              <MaterialIcons.Close />
            </IconButton>
            <img src={zoomPhoto} />
          </Paper>
        </Modal>
      )}
    </>
  );
};

export default UploadImages;
