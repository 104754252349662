import { TFunction } from 'react-i18next';

import { EDamageSeverityLevel } from 'models/warehouse';

export const getSeverityLabels = (t: TFunction<'warehouse'>) => ({
  [EDamageSeverityLevel.High]: t('qualityCheckStation.damageSeverity.high'),
  [EDamageSeverityLevel.Low]: t('qualityCheckStation.damageSeverity.low'),
  [EDamageSeverityLevel.Medium]: t('qualityCheckStation.damageSeverity.medium'),
  null: t('qualityCheckStation.damageSeverity.none')
});
