import { createStyles, makeStyles } from '@luxclusif/material';

const actionsModalStyles = makeStyles(() =>
  createStyles({
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      maxWidth: '512px',
      padding: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    title: {
      fontSize: '20px',
      lineHeight: '24px',
      margin: 0
    },
    closeButton: {
      padding: '8px',
      position: 'absolute',
      right: '16px',
      top: '16px'
    },
    description: {
      fontSize: '16px',
      lineHeight: '22px',
      margin: '32px 0 0'
    },
    reasonsSelect: {
      marginTop: '24px',
      '& .MuiSelect-select': {
        height: '22px',
        lineHeight: '22px',
        minHeight: '22px',
        padding: '13px'
      }
    },
    reasonsSelectMenu: {
      maxHeight: '240px'
    },
    actions: {
      alignSelf: 'flex-end',
      display: 'flex',
      gap: '16px',
      marginTop: '32px'
    },
    button: {
      lineHeight: '19px',
      padding: '8px 24px',
      width: '140px'
    }
  })
);

export default actionsModalStyles;
