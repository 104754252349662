import React from 'react';

import { MaterialIcons, Paper } from '@luxclusif/material';
import { IModule } from '@luxclusif/material/dist/components/LuxSuperFrame/interfaces';
import { useTranslation } from 'react-i18next';

import WelcomeHeader from '../../assets/images/welcome_header.png';
import WelcomeCard from './components/WelcomeCard';

import welcomeStyles from './welcome.styles';

export interface IWelcomeModule extends Omit<IModule, 'name'> {
  description: string;
  image: string;
}

interface IProps {
  email: string;
  modules: IWelcomeModule[];
}

const Welcome: React.FC<IProps> = ({ email, modules }) => {
  const classes = welcomeStyles();
  const { t } = useTranslation(['welcome']);

  return (
    <Paper className={classes.welcomeContainer} elevation={0} variant="elevation">
      <img className={classes.header} src={WelcomeHeader} />
      <div className={classes.userThumbnailContainer}>
        <MaterialIcons.Person className={classes.userThumbnailPlaceholder} />
      </div>
      <span className={classes.welcomeMessage}>{t('welcome:welcomeInbound')}</span>
      <div className={classes.emailInformation}>
        <MaterialIcons.Email className={classes.emailIcon} />
        <span className={classes.email}>{email}</span>
      </div>
      <div className={classes.cardsContainer}>
        {modules.map(({ description, image, title, url }) => (
          <WelcomeCard key={url} description={description} image={image} title={title} url={url} />
        ))}
      </div>
    </Paper>
  );
};

export default Welcome;
