import React, { useCallback, useMemo, useState } from 'react';

import { LayoutLyssa } from '@lux-ds/body-layouts';
import Chip, { IChipProps } from '@lux-ds/chip';
import { LayoutMervin } from '@lux-ds/content-layouts';
import DataGrid, {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  IDataGridToolbarProps,
  IMultipleSelectValue
} from '@lux-ds/data-grid';
import { ButtonRadio, RadioGroup } from '@lux-ds/radio';
import StatusIndicator from '@lux-ds/status-indicator';
import Tooltip from '@lux-ds/tooltip';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Download from '@mui/icons-material/Download';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ImportItemsModal from 'components/PurchaseOrders/ImportItemsModal/ImportItemsModal';
import { businessModelColor, purchaseStatusColor } from 'constants/StatusColor';
import { EColumnFilterTypes } from 'models/component/luxDataTableInterface';
import { EPurchaseOrderFilterId, TPurchaseOrderStatus } from 'models/purchaseOrders';
import { EBusinessModelsIdNumber } from 'models/supplier';
import EInboundRoute from 'navigation/models/EInboundRoute';
import BaseConfiguration from 'setup/BaseConfiguration';
import capitalize from 'utils/capitalize';
import { currencyGridValueFormatter, dateGridValueFormatter } from 'utils/formatters';

import usePurchaseOrdersContext from './hooks/usePurchaseOrdersContext';

const PurchaseOrders: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'purchaseOrders']);

  const {
    downloadTemplateLoading,
    filterOptions,
    filters,
    filterStatus,
    handleUploadSupplierItems,
    isLoading,
    page,
    pageSize,
    purchaseOrders,
    quickFilter,
    rowCount,
    search,
    setFilters,
    setPage,
    setPageSize,
    setQuickFilter,
    setSearch,
    setSortModel,
    sortModel,
    templateUrl
  } = usePurchaseOrdersContext();

  const [openUploadSupplierItemsModal, setOpenUploadSupplierItemsModal] = useState<boolean>(false);

  const columns = useMemo(
    (): GridColumns => [
      {
        field: 'purchaseOrderNumber',
        headerName: t('purchaseOrders:poNumber'),
        width: 110
      },
      {
        field: 'supplierName',
        flex: 1,
        headerName: t('common:supplier'),
        minWidth: 135
      },
      {
        field: 'businessModelName',
        flex: 1,
        headerName: t('common:businessModel'),
        minWidth: 135,
        renderCell: Object.assign(
          ({ row: { businessModelId }, value: businessModelName }: GridRenderCellParams) => (
            <Tooltip title={businessModelName || ''}>
              <Chip
                color={businessModelColor[businessModelId as EBusinessModelsIdNumber] as IChipProps['color']}
                label={businessModelName as string}
                squared
              />
            </Tooltip>
          ),
          { displayName: 'purchaseOrderBusinessModel' }
        )
      },
      {
        field: 'purchaseTitleReference',
        flex: 1,
        headerName: t('purchaseOrders:poTitle'),
        minWidth: 135
      },
      {
        field: 'itemsQuantity',
        headerName: t('purchaseOrders:items'),
        sortable: false,
        width: 70
      },
      {
        field: 'totalCostUSD',
        headerName: t('purchaseOrders:list.totalAmountWithTax'),
        sortable: false,
        valueFormatter: currencyGridValueFormatter,
        width: 170
      },
      {
        field: 'warehouseName',
        flex: 1,
        headerName: t('common:warehouse'),
        minWidth: 135,
        valueFormatter: (valueFormatter: GridValueFormatterParams) =>
          capitalize((valueFormatter.value as string | null) || '')
      },
      {
        field: 'purchaseOrderDateUtc',
        headerName: t('purchaseOrders:purchaseDate'),
        sortable: false,
        valueFormatter: dateGridValueFormatter,
        width: 100
      },
      {
        field: 'expectedDeliveryDate',
        headerName: t('purchaseOrders:list.deliveryDate'),
        sortable: false,
        valueFormatter: dateGridValueFormatter,
        width: 100
      },
      {
        field: 'statusName',
        flex: 1,
        headerName: t('common:status'),
        minWidth: 135,
        renderCell: Object.assign(
          ({ row: { statusId }, value: statusName }: GridRenderCellParams) => (
            <StatusIndicator
              color={purchaseStatusColor[statusId as TPurchaseOrderStatus]}
              hint={statusName as string}
              label={statusName as string}
            />
          ),
          { displayName: 'purchaseOrderStatus' }
        ),
        sortable: false
      }
    ],
    [navigate]
  );

  const toolbar = useMemo(
    (): IDataGridToolbarProps => ({
      actions: [
        {
          icon: <PlaylistAdd />,
          label: t('purchaseOrders:list.addPurchaseOrder'),
          onClick: () => navigate(EInboundRoute.PurchaseOrder)
        }
      ],
      extraActions: [
        {
          Icon: <CloudUpload />,
          onClick: () => setOpenUploadSupplierItemsModal(true),
          text: t('purchaseOrders:list.uploadSupplierFile')
        },
        {
          Icon: <Download />,
          disabled: downloadTemplateLoading,
          onClick: () => (window.location.href = templateUrl),
          text: t('purchaseOrders:list.downloadTemplate')
        }
      ],
      filter: {
        collapseMountOnEnter: true,
        collapseUnmountOnExit: true,
        filters: [
          {
            id: EPurchaseOrderFilterId.Supplier,
            label: t('common:supplier'),
            options: filterOptions.supplier as IMultipleSelectValue[],
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: EPurchaseOrderFilterId.BusinessModel,
            label: t('common:poBusinessModel'),
            options: filterOptions.businessModel,
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: EPurchaseOrderFilterId.Warehouse,
            label: t('common:warehouse'),
            options: filterOptions.warehouse,
            type: EColumnFilterTypes.MultipleSelect
          },
          {
            id: EPurchaseOrderFilterId.PurchaseDateRange,
            label: t('purchaseOrders:purchaseDate'),
            type: EColumnFilterTypes.DateRange
          },
          {
            id: EPurchaseOrderFilterId.DeliveryDateRange,
            label: t('purchaseOrders:list.deliveryDate'),
            type: EColumnFilterTypes.DateRange
          },
          {
            id: EPurchaseOrderFilterId.Buyer,
            label: t('purchaseOrders:buyer'),
            options: filterOptions.buyers,
            type: EColumnFilterTypes.MultipleSelect
          }
        ],
        onChange: setFilters,
        value: filters
      },
      search: {
        defaultValue: search,
        onSearch: setSearch,
        placeholder: t('purchaseOrders:list.searchPONumber')
      }
    }),
    [downloadTemplateLoading, filterOptions, filters, navigate, search, setFilters, setSearch, t, templateUrl]
  );

  const handleRowClick = useCallback(({ id }) => navigate(`${EInboundRoute.PurchaseOrder}/${id}`), [navigate]);

  return (
    <BaseConfiguration>
      <LayoutLyssa>
        <RadioGroup onChange={setQuickFilter} value={quickFilter}>
          <LayoutMervin gap="xs">
            {filterStatus.map(({ count, id, label }) => (
              <ButtonRadio key={id} background="secondary" label={label} number={count} value={id} />
            ))}
          </LayoutMervin>
        </RadioGroup>
        <DataGrid
          columns={columns}
          getRowId={({ purchaseId }) => purchaseId}
          headerHeight="s"
          loading={isLoading}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onRowClick={handleRowClick}
          onSortModelChange={setSortModel}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={rowCount}
          rowHeight="s"
          rows={purchaseOrders}
          sortModel={sortModel}
          sortingMode="server"
          toolbar={toolbar}
        />
      </LayoutLyssa>
      {openUploadSupplierItemsModal && (
        <ImportItemsModal onClose={() => setOpenUploadSupplierItemsModal(false)} onSubmit={handleUploadSupplierItems} />
      )}
    </BaseConfiguration>
  );
};

export default PurchaseOrders;
