import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const foundStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',
      marginTop: '40px',
      marginBottom: '40px'
    },

    leftSection: {
      flex: 1
    },
    rightSection: {
      width: '432px'
    },

    imageContainer: {
      boxSizing: 'border-box',
      width: '100%',
      border: `1px solid ${secondaryNeutral.light75}`,
      borderRadius: '4px',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center'
    },

    itemDetailsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 50%)',
      gridTemplateRows: 'repeat(3, 0fr)',
      gridColumnGap: '4px',
      gridRowGap: '24px',
      marginTop: '32px',

      '& > :first-child': {
        gridArea: '1 / 1 / 2 / 3'
      }
    },
    itemDetailLabel: {
      display: 'block',
      color: secondaryNeutral.light40,
      fontSize: '12px',
      marginBottom: '8px'
    },
    itemDetailValue: {
      fontSize: '16px'
    },

    inclusionLabelContainer: {
      display: 'flex',
      gap: '12px',
      alignItems: 'center',

      '& .MuiSvgIcon-root': {
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 400
      }
    },

    tooltipContent: {
      '& .MuiTooltip-tooltip': {
        fontSize: '14px !important',
        boxShadow: '0px 3px 14px rgba(6, 5, 6, 0.12), 0px 5px 5px rgba(6, 5, 6, 0.2)',
        color: '#131113',
        backgroundColor: '#FFFFFF',
        padding: '16px 18px'
      },
      '& .MuiTooltip-arrow::before': {
        backgroundColor: '#FFFFFF'
      }
    },

    formSection: {
      '& h3': {
        color: theme.palette.secondary.main,
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0',
        lineHeight: '18px',
        marginTop: 0,
        marginBottom: '24px'
      }
    },
    chipCheckBoxContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
      rowGap: '16px'
    },
    chipCheckBox: {
      fontSize: '14px',
      '& > .MuiSvgIcon-root': {
        fontSize: '14px'
      }
    },
    securityTagContainer: {
      gap: '84px'
    },
    measurementsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',
      '& .MuiFormControl-root': {
        width: '135px',
        display: 'block'
      },
      '& .MuiInputBase-input': {
        padding: '13px 16px'
      }
    },
    serialNumberContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',
      '& .MuiInputBase-input': {
        padding: '13px 16px'
      }
    },
    serialNumberRadioContainer: {
      gap: '26px'
    },

    detailsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px'
    },
    countriesOptions: {
      width: '204px',
      '& .MuiInputBase-input': {
        padding: '0 !important'
      },
      '& .MuiOutlinedInput-root': {
        padding: '13px 65px 13px 13px !important'
      }
    },
    caratInput: {
      width: '204px',
      '& .MuiInputBase-input': {
        padding: '13px 16px'
      }
    },

    divider: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `1px solid ${secondaryNeutral.light75}`,
      marginBottom: '32px',
      marginTop: '32px',
      padding: '0'
    }
  });
});

export default foundStyles;
