import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const autocompleteInputStyles = makeStyles((theme: Theme) => {
  return createStyles({
    icons: {
      '& .MuiSvgIcon-root': {
        color: 'inherit'
      }
    },
    input: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    },
    label: {
      fontSize: 15,
      marginRight: 30,
      '&.Mui-focused': {
        color: theme.palette.primary.main
      }
    }
  });
});

export default autocompleteInputStyles;
