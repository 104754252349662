import { ProcessStorageGetModel, UpdateProcessStorageCommand } from '@inbound/api';
import * as yup from 'yup';

export type IItemStorageDetailsSchema = UpdateProcessStorageCommand;

export const storageSchema = yup.object().shape({
  storedAt: yup.string().required()
});

export const getDefaultItemStorageValues = (values: ProcessStorageGetModel): UpdateProcessStorageCommand => ({
  storedAt: values.storedAt
});
