import React, { useCallback, useMemo, useState } from 'react';

import Image, { IImageActionProps, IImageProps } from '@lux-ds/image';
import Menu, { IMenuItems } from '@lux-ds/menu';
import Delete from '@mui/icons-material/Delete';
import Download from '@mui/icons-material/Download';
import MoreVert from '@mui/icons-material/MoreVert';
import ZoomIn from '@mui/icons-material/ZoomIn';
import { useTranslation } from 'react-i18next';

import PhotoPreview from 'components/PhotoPreview/PhotoPreview';
import downloadResource from 'utils/downloadResource';

interface IUploadedPhotoProps extends IImageProps {
  id: string;
  onDelete?: (id: string) => void;
}

const UploadedPhoto: React.FC<IUploadedPhotoProps> = ({ id, onDelete, src, ...props }) => {
  const { t } = useTranslation('common');

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const handleCloseMoreOptions = useCallback(() => setAnchorEl(null), []);
  const handleOpenMoreOptions = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    []
  );

  const handleDownload = useCallback(() => {
    downloadResource(src);
    handleCloseMoreOptions();
  }, [handleCloseMoreOptions, src]);

  const handleDelete = useCallback(() => {
    onDelete?.(id);
    handleCloseMoreOptions();
  }, [handleCloseMoreOptions, id, onDelete]);

  const handleZoom = useCallback(() => setShowPreview(true), []);

  const actions = useMemo(
    (): IImageActionProps[] => [
      {
        icon: <ZoomIn />,
        onClick: handleZoom
      },
      ...(onDelete
        ? [
            {
              icon: <MoreVert />,
              onClick: handleOpenMoreOptions
            }
          ]
        : [
            {
              icon: <Download />,
              onClick: handleDownload
            }
          ])
    ],
    [handleDownload, handleOpenMoreOptions, handleZoom]
  );

  const extraActions: IMenuItems = useMemo(
    () => [
      {
        Icon: <Download fontSize="small" />,
        onClick: handleDownload,
        text: t('uploadImages.download')
      },
      {
        Icon: <Delete fontSize="small" />,
        onClick: handleDelete,
        text: t('delete')
      }
    ],
    [handleDelete, handleDownload, t]
  );

  return (
    <>
      <Image actions={actions} src={src} {...props} />
      <Menu anchorEl={anchorEl} items={extraActions} onClose={handleCloseMoreOptions} open={!!anchorEl} />
      {showPreview && <PhotoPreview onClose={() => setShowPreview(false)} src={src} />}
    </>
  );
};

export default UploadedPhoto;
