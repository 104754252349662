import { GridRowsProp, GridSortItem } from '@mui/x-data-grid';

import { TDateRangeState, TMultipleSelectState } from 'models/component/luxDataTableInterface';
import { EBusinessModelsId, EBusinessModelsIdNumber } from 'models/supplier';
import { IItemInfo } from 'pages/PurchaseOrder/schema';

export interface ISelectedEntity {
  id: string;
  name: string;
}

export enum EPurchaseOrderFilterId {
  BusinessModel = 'businessModel',
  Buyer = 'buyer',
  DeliveryDateRange = 'deliveryDateRange',
  PurchaseDateRange = 'purchaseDateRange',
  Supplier = 'supplier',
  Warehouse = 'warehouse'
}

export interface IPurchaseOrderColFilters {
  [EPurchaseOrderFilterId.BusinessModel]?: TMultipleSelectState;
  [EPurchaseOrderFilterId.Buyer]?: TMultipleSelectState;
  [EPurchaseOrderFilterId.DeliveryDateRange]?: TDateRangeState;
  [EPurchaseOrderFilterId.PurchaseDateRange]?: TDateRangeState;
  [EPurchaseOrderFilterId.Supplier]?: TMultipleSelectState;
  [EPurchaseOrderFilterId.Warehouse]?: TMultipleSelectState;
}

export interface IPurchaseOrderFilters extends IPurchaseOrderColFilters {
  search?: string;
  sortModel?: GridSortItem;
  status?: EPurchaseOrderAllStatus;
}

export interface IPurchaseOrderSupplier {
  supplierId: string;
  supplierName: string;
}

export interface IPurchaseOrderBusinessModel {
  businessModelId: EBusinessModelsIdNumber;
  businessModelName: string;
}

export interface IPurchaseOrderWarehouse {
  warehouseId: string;
  warehouseName: string;
}

export interface IPurchaseOrderStatus {
  id: TPurchaseOrderStatus;
  name: string;
}

export interface IPurchaseOrder extends GridRowsProp {
  businessModel: IPurchaseOrderBusinessModel;
  expectedDeliveryDate: string;
  itemsQuantity: number;
  purchaseId: string;
  purchaseOrderDateUtc: string;
  purchaseOrderNumber: number;
  purchaseTitleReference: string;
  status: IPurchaseOrderStatus;
  supplier: IPurchaseOrderSupplier;
  totalCostUSD: number;
  warehouse: IPurchaseOrderWarehouse;
}

// these keys are based from GET Purchases endpoint for `status` params but formatted to camelCase
export enum EPurchaseOrderAllStatus {
  AllItems = 'AllItems',
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Finalized = 'Finalized',
  RecentlyPurchased = 'RecentlyPurchased',
  Review = 'Review',
  WarehouseInbound = 'WarehouseInbound'
}

export enum EPurchaseOrderCurationTypes {
  HighCurated = 'HighCurated',
  LowCurated = 'LowCurated',
  Regular = 'Regular'
}

export enum EPurchaseOrderProcurementTypes {
  BuyingTrip = 'BuyingTrip',
  Remote = 'Remote',
  SoloTrip = 'SoloTrip'
}

export type TPurchaseOrderStatus = Exclude<EPurchaseOrderAllStatus, EPurchaseOrderAllStatus.AllItems>;

type TPurchaseOrderCounts = {
  [key in EPurchaseOrderAllStatus]: number;
};

export interface IGetAllPurchaseOrders {
  counts: TPurchaseOrderCounts;
  items: IPurchaseOrder[];
}

interface IGetAllOptions {
  id: string;
  name: string;
}

interface ISupplierCompanyEntityOption {
  entityCompanyEnum: string;
  entityCompanyId: string;
  name: string;
}

interface ICurrencyOption {
  currencyId: string;
  exchangeRate: number;
  isoCode: string;
  name: string;
  symbol: string;
}

interface IPaymentTermOption {
  name: string;
  paymentTermId: string;
}

interface IPaymentMethodOption {
  name: string;
  paymentMethodEnum: string;
  paymentMethodId: number;
}

interface IFreightTermOption {
  freightTermEnum: string;
  freightTermId: number;
  name: string;
}

interface IBusinessModelOption {
  businessModelEnum: EBusinessModelsId;
  id: number;
  name: string;
}

export type TGetAllSupplierEntityOptions = Array<ISupplierCompanyEntityOption>;

export type TGetAllSupplierOptions = Array<IGetAllOptions>;

export type TGetAllBusinessModelOptions = Array<IBusinessModelOption>;

export type TGetWarehouseOptions = Array<IGetAllOptions>;

export type TGetAllCurrencies = Array<ICurrencyOption>;

export type TGetAllPaymentTerms = Array<IPaymentTermOption>;

export type TGetAllPaymentMethods = Array<IPaymentMethodOption>;

export type TGetAllCouriers = Array<IGetAllOptions>;

export type TGetAllFreightTerms = Array<IFreightTermOption>;

export type TGetAllStatus = Array<IPurchaseOrderStatus>;

export type TGetAllBuyers = Array<IGetAllOptions>;

export interface IBusinessModelSupplierInfo {
  businessModelEnum: EBusinessModelsId;
  businessModelId: number;
  businessModelName: string;
}

export interface IGetSupplierPurchaseInfo {
  billingCurrencyId: string;
  businessModelList: IBusinessModelSupplierInfo[];
  clusters: Array<number>;
  courierId: string | null;
  entityCompany: string;
  freightTerm: string | null;
  paymentMethod: string | null;
  paymentTermsId: string | null;
  receptionWarehouseId: string;
  supplierId: string;
}

export interface IFilePurchase {
  fileId: string;
  fileSize: number;
  fileUrl: string;
  name: string;
  originalName: string;
  type: string;
  uploadDate?: string;
}

export interface ITrackingNumber {
  code: string;
  courier: ISelectedEntity;
  estimatedDeliveryDate: string;
}

export interface ITrackingDetail {
  totalItems: number;
  trackingNumber: ITrackingNumber;
}

export interface IGetPurchaseOrder {
  buyerId: string | null;
  datesDetail: {
    estimatedDeliveryDate: string;
    purchaseDate: string;
  };
  files: IFilePurchase[];
  financialDetail: {
    courierId: string;
    currencyId: string;
    freightterms: string;
    paymentMethodId: string;
    paymentTermId: string;
    warehouseId: string | null;
  };
  items: IItemInfo[];
  notes: string | null;
  purchaseDetail: {
    curationType: string;
    number: string;
    procurementType: string;
    purchaseId: string;
    splitFrom: string | null;
    statusId: EPurchaseOrderAllStatus;
    title: string;
  };
  supplierDetail: {
    businessModelId: EBusinessModelsId;
    consignmentEndDate: string | null;
    consignmentStartDate: string | null;
    entityCompanyId: string;
    name: string;
    supplierClusterId: string;
    supplierId: string;
  };
  trackingsDetail: ITrackingDetail[];
}

export interface ISplitPurchaseOrder {
  purchaseId: string;
  purchaseNumber: string;
}

export interface ICancelPurchaseOrder {
  errors: string[];
  success: string[];
}

export interface ICancelReason {
  id: number;
  reason: string;
}

export enum EOrderItemStatus {
  Available = 'Available',
  Canceled = 'Canceled',
  Consigned = 'Consigned',
  Delivered = 'Delivered',
  DisposedOf = 'DisposedOf',
  Draft = 'Draft',
  InTransit = 'InTransit',
  Marketplace = 'Marketplace',
  PendingProcessing = 'PendingProcessing',
  PendingReview = 'PendingReview',
  Processing = 'Processing',
  Published = 'Published',
  Reserved = 'Reserved',
  Sold = 'Sold',
  SoldBySupplier = 'SoldBySupplier',
  SupplierReturn = 'SupplierReturn',
  Unpublished = 'Unpublished'
}

export interface IOrderItemStatus {
  id: EOrderItemStatus;
  name: string;
}

export enum EOrderItemsFilterId {
  Brand = 'brand',
  Category = 'category',
  Status = 'status',
  Templated = 'templated',
  TrackingNumber = 'trackingNumber'
}

export interface IOrderItemsColFilters {
  [EOrderItemsFilterId.Brand]: TMultipleSelectState;
  [EOrderItemsFilterId.Category]: TMultipleSelectState;
  [EOrderItemsFilterId.Status]: TMultipleSelectState;
  [EOrderItemsFilterId.Templated]: TMultipleSelectState;
  [EOrderItemsFilterId.TrackingNumber]: TMultipleSelectState;
}
