import React, { useMemo } from 'react';

import CheckBox from '@lux-ds/checkbox';
import { LayoutJay } from '@lux-ds/content-layouts';
import Table, { ITableHeaderProps, TableBody, TableCell, TableRow } from '@lux-ds/table';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { EMatrixParent, IItemAuthenticationSchema } from 'pages/WarehouseList/schemas/authenticationSchema';

import { concernKeys, exteriorLocationKeys, interiorLocationKeys } from '../../constants';

interface IAuthenticationMatrixTableProps {
  tab: EMatrixParent;
}

const AuthenticationMatrixTable: React.FC<IAuthenticationMatrixTableProps> = ({ tab }) => {
  const { t } = useTranslation('warehouse');
  const { isViewMode } = useWarehouseItemContext();
  const { warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IItemAuthenticationSchema>, [warehouseForm]);

  const headers = useMemo(
    (): ITableHeaderProps[] => [
      { align: 'center', key: '', label: '' },
      ...concernKeys.map(key => {
        return {
          align: 'center',
          key,
          label: t(`authenticationStation.matrixConcern.${key}`)
        } as ITableHeaderProps;
      })
    ],
    [t]
  );

  const locationKeys = useMemo(
    () => (tab === EMatrixParent.Exterior ? exteriorLocationKeys : interiorLocationKeys),
    [tab]
  );

  return (
    <LayoutJay>
      <Table headers={headers} size="small" striped>
        <TableBody>
          {locationKeys.map(key => (
            <TableRow key={key}>
              <TableCell>{t(`authenticationStation.matrixLocation.${key}`)}</TableCell>
              {concernKeys.map(damageKey => (
                <TableCell align="center" key={damageKey}>
                  <Controller
                    key={damageKey}
                    control={form.control}
                    name={`${key}.${damageKey}`}
                    render={({ onChange, ref, ...props }) => (
                      <CheckBox
                        {...props}
                        checked={!!props.value}
                        disabled={isViewMode}
                        formControlLabelProps={{ label: '', ref, sx: { margin: 'unset' } }}
                        onChange={(e, data) => onChange(data)}
                      />
                    )}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </LayoutJay>
  );
};

export default AuthenticationMatrixTable;
