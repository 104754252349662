import { GetWarehouseQueryResponse } from '@inbound/api';
import { AuthService } from '@luxclusif/material';
import { AxiosRequestConfig } from 'axios';
import { Subject } from 'rxjs';

import { IInclusion, IItemPhotos, IUploadFormat } from 'models/warehouse';
import { IItemNotOkForm } from 'pages/WarehouseList/schemas/utilitySchema/BBoxSchema';
import axios from 'utils/axios';

class ProductsService {
  public static $productRowUpdated = new Subject<any>();

  public async getReviewDetailsById(itemId: string) {
    const url = `${process.env.REACT_APP_API_URL}Products/${itemId}/review`;

    return await axios.get(url).then(response => response.data);
  }

  public async reviewItem(form: any, itemId: string, stationStartDate?: Date) {
    const url = `${process.env.REACT_APP_API_URL}Products/${itemId}/review`;

    const body = {
      finalStationDateTimeUtc: new Date(),
      // TODO: delete "initialStationDateTime" once BE has been updated
      initialStationDateTime: stationStartDate,
      initialStationDateTimeUtc: stationStartDate,
      itemId,
      itemWithIssues: form.itemCheckupIssues,
      ratingId: form.itemRating ? form.itemRating : null,
      reviewComments: form.reviewComments,
      sendToBbox: form.sendToBboxCheckBox,
      sendToBboxReason: form.sendToBboxCheckBox ? form.sendToBboxReason : null,
      tags: form.tags
    };

    return await axios.put(url, body).then(response => response.data);
  }

  public async supplierReturn(form: IItemNotOkForm, itemId: string) {
    const files: IUploadFormat[] = [];

    form.files.files.forEach((value, index: number) => {
      const fileName = value.file.name;
      const fileParts = fileName.split('.');

      const [fileType] = fileParts.slice(-1);

      files[index] = {
        fileId: value.fileId,
        fileSize: value.file.size,
        name: fileName,
        originalName: fileName,
        type: fileType
      };
    });

    const body = {
      files: files,
      itemId: itemId,
      reason: form.reason
    };

    const url = `${process.env.REACT_APP_API_URL}Products/${itemId}/supplier-return`;

    return await axios.post(url, body).then(response => response.data);
  }

  public async uploadExcelProductsFile(file: any) {
    const url = `${process.env.REACT_APP_API_URL}files/upload-items`;

    const form = new FormData();

    form.append('file', file, file.name);

    const config: AxiosRequestConfig = {
      data: form,
      headers: {
        Authorization: AuthService.getAccessToken(),
        'Content-Type': undefined
      },
      method: 'post',
      url: url
    };

    return await axios(config).then(response => {
      return response.data;
    });
  }

  public async getInclusions(): Promise<IInclusion[]> {
    const url = `${process.env.REACT_APP_API_URL}Products/inclusions`;

    return await axios.get(url).then(response => response.data);
  }

  public async getProductPhotos(itemId: string): Promise<IItemPhotos[]> {
    const url = `${process.env.REACT_APP_API_URL}Products/${itemId}/photos`;

    return await axios.get(url).then(response => response.data);
  }

  public async getWarehouses(): Promise<GetWarehouseQueryResponse[]> {
    const url = `${process.env.REACT_APP_API_URL}Products/warehouses`;

    return await axios.get(url).then(response => response.data);
  }
}

const productsService = new ProductsService();

export default productsService;
