import React from 'react';

import { InputBaseComponentProps } from '@luxclusif/material';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface IProps {
  onChange: (e: any) => void;
}

const DecimalInput = React.forwardRef<React.ElementType<InputBaseComponentProps>, IProps>(
  ({ onChange, ...props }, ref) => (
    <NumberFormat
      {...props}
      type="text"
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      getInputRef={ref}
      onValueChange={(values: NumberFormatValues) => onChange(values.floatValue)}
    />
  )
);

DecimalInput.displayName = 'DecimalInput';

export default DecimalInput;
