import { createStyles, makeStyles, secondaryNeutral, secondaryPalette } from '@luxclusif/material';

const computeQCDecisionStyles = makeStyles(() => {
  return createStyles({
    container: {
      display: 'flex',
      justifyContent: 'end',
      border: '1px solid #DFDCE0',
      borderRadius: '4px',
      padding: '16px 64px',
      marginTop: '24px'
    },
    error: {
      alignSelf: 'center',
      color: secondaryPalette.error.main,
      marginRight: '8px'
    },

    divider: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '40px 0',
      padding: '0'
    },

    submitButton: {
      lineHeight: 1,
      padding: '8px 24px',
      height: '35px',
      alignSelf: 'center'
    },

    resultContainer: {
      borderLeft: '1px solid #DFDCE0',
      padding: '14px 0 14px 32px',
      marginLeft: '32px',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center'
    },
    resultIcon: {
      marginLeft: '26px',
      marginRight: '10px',
      fontSize: '20px'
    },
    resultIconFail: {
      color: '#6E0B28'
    },
    resultIconPass: {
      color: '#105914'
    },
    resultPlaceholder: {
      backgroundColor: '#ECEAEC',
      borderRadius: '8px',
      height: '8px',
      width: '91px',
      marginLeft: '24px'
    }
  });
});

export default computeQCDecisionStyles;
