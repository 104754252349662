import React, { useMemo } from 'react';

import { LayoutLyssa } from '@lux-ds/body-layouts';
import Chip, { IChipProps } from '@lux-ds/chip';
import { LayoutMervin } from '@lux-ds/content-layouts';
import DataGrid, {
  DataGridImage,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  IDataGridToolbarProps
} from '@lux-ds/data-grid';
import { ButtonRadio, RadioGroup } from '@lux-ds/radio';
import StatusIndicator from '@lux-ds/status-indicator';
import Typography from '@lux-ds/typography';
import Edit from '@mui/icons-material/Edit';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LuxFlagIcon from 'components/LuxFlagIcon/LuxFlagIcon';
import { businessModelColor, supplierStatusColor } from 'constants/StatusColor';
import { EBusinessModelsId, EBusinessModelsIdNumber } from 'models/supplier';
import { TSupplierStatus } from 'models/suppliers';
import EInboundRoute from 'navigation/models/EInboundRoute';
import BaseConfiguration from 'setup/BaseConfiguration';

import useSuppliersContext from './hooks/useSuppliersContext';

const Suppliers: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'supplier']);
  const {
    filterStatus,
    isLoading,
    page,
    pageSize,
    quickFilter,
    rowCount,
    search,
    setPage,
    setPageSize,
    setQuickFilter,
    setSearch,
    setSortModel,
    sortModel,
    suppliers
  } = useSuppliersContext();

  const columns = useMemo(
    (): GridColumns => [
      {
        field: 'photo',
        headerName: '',
        renderCell: Object.assign(({ value }: GridRenderCellParams) => <DataGridImage src={value} />, {
          displayName: 'purchaseOrderBusinessModel'
        }),
        sortable: false,
        width: 98
      },
      {
        field: 'supplierId',
        flex: 1,
        headerName: t('supplier:supplierId')
      },
      {
        field: 'name',
        flex: 1,
        headerName: t('supplier:supplierName')
      },
      {
        field: 'status',
        flex: 1,
        headerName: t('common:status'),
        renderCell: Object.assign(
          ({ row: { status }, value: statusName }: GridRenderCellParams) => (
            <StatusIndicator color={supplierStatusColor[status as TSupplierStatus]} label={statusName as string} />
          ),
          { displayName: 'supplierStatus' }
        )
      },
      {
        field: 'businessModelTypes',
        flex: 1,
        headerName: t('common:businessModel'),
        renderCell: Object.assign(
          ({ row: { businessModelTypes } }: GridRenderCellParams) => (
            <LayoutMervin gap="xxs" sx={{ alignItems: 'center' }}>
              {businessModelTypes.map((businessModel: string) => (
                <Chip
                  color={
                    businessModelColor[
                      EBusinessModelsIdNumber[EBusinessModelsId[businessModel.replaceAll(' ', '') as EBusinessModelsId]]
                    ] as IChipProps['color']
                  }
                  key={businessModel}
                  label={businessModel}
                  squared
                />
              ))}
            </LayoutMervin>
          ),
          { displayName: 'supplierBusinessModel' }
        ),
        sortable: false
      },
      {
        field: 'country',
        flex: 1,
        headerName: t('common:country'),
        renderCell: Object.assign(
          ({ row: { country } }: GridRenderCellParams) =>
            country && (
              <LayoutMervin gap="xxs" sx={{ alignItems: 'center' }}>
                <LuxFlagIcon icon={country.toLowerCase().replaceAll(' ', '-')} width="15" height="15" />
                <Typography variant="bodyTextSmall">{country}</Typography>
              </LayoutMervin>
            ),
          {
            displayName: 'supplierCountry'
          }
        )
      },
      {
        field: 'currency',
        flex: 1,
        headerName: t('supplier:currency')
      },
      {
        field: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            icon={<Edit />}
            key="Edit"
            label="Edit"
            onClick={() => navigate(`${EInboundRoute.Supplier}/${id}`)}
          />
        ],
        headerName: t('common:dataTable.action'),
        type: 'actions',
        width: 75
      }
    ],
    [history, t]
  );

  const toolbar = useMemo(
    (): IDataGridToolbarProps => ({
      actions: [
        {
          icon: <PlaylistAdd />,
          label: t('supplier:addSupplier'),
          onClick: () => navigate(EInboundRoute.Supplier)
        }
      ],
      search: {
        defaultValue: search,
        onSearch: setSearch,
        placeholder: t('supplier:searchSupplier')
      }
    }),
    [history, search, setSearch, t]
  );

  return (
    <BaseConfiguration>
      <LayoutLyssa>
        <RadioGroup onChange={setQuickFilter} value={quickFilter}>
          <LayoutMervin gap="xs">
            {filterStatus.map(({ count, id, label }) => (
              <ButtonRadio background="secondary" key={id} label={label} number={count} value={id} />
            ))}
          </LayoutMervin>
        </RadioGroup>
        <DataGrid
          columns={columns}
          getRowId={({ supplierId }) => supplierId}
          headerHeight="s"
          loading={isLoading}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onSortModelChange={setSortModel}
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={rowCount}
          rowHeight="l"
          rows={suppliers}
          sortingMode="server"
          sortModel={sortModel}
          toolbar={toolbar}
        />
      </LayoutLyssa>
    </BaseConfiguration>
  );
};

export default Suppliers;
