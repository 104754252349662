import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const securityTagStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '18px',
      marginTop: '48px',
      marginBottom: '24px'
    },
    securityTagValue: {
      color: theme.palette.secondary.main,
      display: 'block',
      fontSize: '16px',
      marginBottom: '48px'
    }
  })
);

export default securityTagStyles;
