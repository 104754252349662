import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  LuxButton,
  MaterialIcons,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TableContainer
} from '@luxclusif/material';
import { StatusCodes } from 'http-status-codes';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import Input from 'components/Input/Input/Input';
import SelectInput from 'components/Input/Select/Select';
import { $hideLoading, $showLoading } from 'components/LoadingSpinner/LoadingSpinner';
import { ICity, ICountry, IState } from 'models/supplier';
import { warehouseSchema } from 'pages/Suppliers/schemas/warehouseSchema';
import geoService from 'services/Geo.service';

import supplierDetailsStyles from '../../supplierDetails.styles';

const defaultValues = {
  supplierAddress: '',
  supplierCity: '',
  supplierCityHTML: '',
  supplierCountry: '',
  supplierCountryHTML: '',
  supplierPostalCode: '',
  supplierState: '',
  supplierStateHTML: '',
  supplierWarehouseLocation: '',
  warehouseAddressId: ''
};

interface IProps {
  countries?: ICountry[];
  mode: string;
}

const WarehouseAddress: React.FC<IProps> = ({ countries, mode }) => {
  const classes = supplierDetailsStyles();

  const [showFields, setShowFields] = useState('');
  const { control, getValues, setValue } = useFormContext();
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'supplierAddresses'
  });

  const warehouseForm = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(warehouseSchema),
    shouldUnregister: false
  });

  const [renderCreate, setRenderCreate] = useState(false);
  const [addUpdateModal, setAddUpdateModalOpen] = useState(false);
  const [toDelete, setToDelete] = useState(0);
  const [deleteModal, setDeleteModalOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoggedIn, setLoggedIn] = useState(true);

  const [deleteWarehouseNameMessage, setDeleteWarehouseNameMessage] = useState('');
  const supplierCountry = warehouseForm.watch('supplierCountry');
  const supplierState = warehouseForm.watch('supplierState');

  useEffect(() => {
    setCities([]);
    const getStates = () => {
      const loadingId = 'getStates';

      $showLoading.next(loadingId);

      if (supplierCountry) {
        geoService
          .getStates(supplierCountry)
          .then(data => {
            setStates(data);
          })
          .catch(response => {
            const status = response.response ? response.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

            if (status === StatusCodes.UNAUTHORIZED) {
              setLoggedIn(false);
            }
          })
          .finally(() => {
            $hideLoading.next(loadingId);
          });
      }
    };

    getStates();
  }, [supplierCountry]);

  useEffect(() => {
    setCities([]);
    const getCities = () => {
      const loadingId = 'getCities';

      $showLoading.next(loadingId);

      if (supplierState) {
        geoService
          .getCities(supplierState)
          .then(data => {
            setCities(data);
          })
          .catch(response => {
            const status = response.response ? response.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

            if (status === StatusCodes.UNAUTHORIZED) {
              setLoggedIn(false);
            }
          })
          .finally(() => {
            $hideLoading.next(loadingId);
          });
      }
    };

    getCities();
  }, [supplierState]);

  useEffect(() => {
    if (renderCreate) {
      setRenderCreate(false);
    }
  }, [renderCreate]);

  useEffect(() => {
    if (showFields !== '') {
      setRenderCreate(true);

      if (showFields !== 'create') {
        warehouseForm.reset(getValues().supplierAddresses[parseInt(showFields)]);
      } else {
        warehouseForm.reset(defaultValues);
      }
    }
  }, [showFields]);

  const addSupplierWarehouse = (data: Record<string, string>) => {
    setRenderCreate(true);

    if (showFields !== 'create') {
      setValue(`supplierAddresses[${showFields}]`, data);
      setAddUpdateModalOpen(true);
    } else {
      append(data);
      warehouseForm.reset(defaultValues);
    }
  };

  const openDeleteModal = (index: number, name: string) => {
    setDeleteModalOpen(true);
    setToDelete(index);
    setDeleteWarehouseNameMessage(name);
  };

  const deleteWarehouse = () => {
    remove(toDelete);
    setDeleteModalOpen(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowFields(event.target.value);
  };

  const id = warehouseForm.watch('id');

  const renderWarehouseFields = () => (
    <>
      <Grid item md={12} className={classes.warehouseTitlePadding}>
        <hr className={classes.subLine} />
      </Grid>

      <Grid item md={12} paddingRight="32px">
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Input
              name="supplierWarehouseLocation"
              control={warehouseForm.control}
              errors={warehouseForm.errors}
              label="Supplier Warehouse Location*"
              type="text"
              className={classes.input}
              variant="outlined"
              fullWidth={true}
              errorText="Required"
            />
          </Grid>
          <Grid item md={8} />
          <Grid item md={4}>
            <Input
              name="supplierAddress"
              control={warehouseForm.control}
              errors={warehouseForm.errors}
              label="Address*"
              type="text"
              className={classes.input}
              variant="outlined"
              fullWidth={true}
              errorText="Required"
            />
          </Grid>
          <Grid item md={2}>
            <Input
              name="supplierPostalCode"
              control={warehouseForm.control}
              errors={warehouseForm.errors}
              label="Postal Code*"
              type="text"
              className={classes.input}
              variant="outlined"
              fullWidth={true}
              errorText="Required"
            />
          </Grid>
          {mode === 'create' || showFields === 'create' || id === '' ? (
            <>
              <Grid item md={2}>
                <SelectInput
                  label="Country*"
                  className={classes.input}
                  fullWidth={true}
                  name="supplierCountry"
                  variant="outlined"
                  control={warehouseForm.control}
                  errors={warehouseForm.errors}
                  setValue={warehouseForm.setValue}
                >
                  <MenuItem aria-label="None" value="" key={'none'} />
                  {countries?.map((row: ICountry) => (
                    <MenuItem key={row.countryId} value={row.countryId}>
                      {row.name}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              <Grid item md={2}>
                <SelectInput
                  label="State*"
                  className={classes.input}
                  fullWidth={true}
                  name="supplierState"
                  variant="outlined"
                  control={warehouseForm.control}
                  errors={warehouseForm.errors}
                  setValue={warehouseForm.setValue}
                >
                  <MenuItem aria-label="None" value="" key={'none'} />
                  {states.map((row: IState) => (
                    <MenuItem key={row.stateId} value={row.stateId}>
                      {row.name}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              <Grid item md={2}>
                <SelectInput
                  label="City*"
                  className={classes.input}
                  fullWidth={true}
                  name="supplierCity"
                  variant="outlined"
                  control={warehouseForm.control}
                  errors={warehouseForm.errors}
                  setValue={warehouseForm.setValue}
                >
                  <MenuItem aria-label="None" value="" key={'none'} />
                  {cities.map((row: ICity) => (
                    <MenuItem key={row.cityId} value={row.cityId}>
                      {row.name}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={2}>
                <SelectInput
                  label="Country*"
                  className={classes.input}
                  fullWidth={true}
                  name="supplierCountry"
                  variant="outlined"
                  control={warehouseForm.control}
                  errors={warehouseForm.errors}
                  disabled={true}
                  setValue={warehouseForm.setValue}
                >
                  <MenuItem aria-label="None" value="" key={'none'} />
                  {countries?.map((row: ICountry) => (
                    <MenuItem key={row.countryId} value={row.countryId}>
                      {row.name}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              <Grid item md={2}>
                <SelectInput
                  label="State*"
                  className={classes.input}
                  fullWidth={true}
                  name="supplierState"
                  variant="outlined"
                  control={warehouseForm.control}
                  errors={warehouseForm.errors}
                  disabled={true}
                  setValue={warehouseForm.setValue}
                >
                  <MenuItem aria-label="None" value="" key={'none'} />
                  {states.map((row: IState) => (
                    <MenuItem key={row.stateId} value={row.stateId}>
                      {row.name}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              <Grid item md={2}>
                <SelectInput
                  label="City*"
                  className={classes.input}
                  fullWidth={true}
                  name="supplierCity"
                  variant="outlined"
                  control={warehouseForm.control}
                  errors={warehouseForm.errors}
                  disabled={true}
                  setValue={warehouseForm.setValue}
                >
                  <MenuItem aria-label="None" value="" key={'none'} />
                  {cities.map((row: ICity) => (
                    <MenuItem key={row.cityId} value={row.cityId}>
                      {row.name}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.warehouseButtonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={() => addSupplierWarehouse(warehouseForm.getValues())}
          disabled={!warehouseForm.formState.isValid}
        >
          {showFields === 'create' ? 'Add Warehouse' : 'Update Warehouse'}
        </Button>
      </Grid>
    </>
  );

  return !isLoggedIn ? (
    <Navigate to="/logout" />
  ) : renderCreate ? (
    <div></div>
  ) : (
    <>
      <div>
        <h1 className={classes.title}>Supplier Warehouse Details*</h1>

        <RadioGroup row aria-label="position" name="WarehouseName" value={showFields} onChange={handleRadioChange}>
          {fields.map((value: any, index: any) => {
            return (
              <Grid
                container
                spacing={4}
                className={classes.warehouseContainer}
                key={index}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item className={classes.warehouseTitlePadding}>
                  <FormControlLabel
                    className={classes.radioButtonText}
                    value={index.toString()}
                    control={<Radio color="default" />}
                    label={`${value.supplierWarehouseLocation}`}
                  />
                </Grid>
                <Grid item className={classes.warehouseTitlePadding}>
                  <IconButton
                    color="secondary"
                    component="button"
                    onClick={() => openDeleteModal(index, value.supplierWarehouseLocation)}
                  >
                    <MaterialIcons.Delete />
                  </IconButton>
                </Grid>
                {showFields === index.toString() ? renderWarehouseFields() : <></>}
              </Grid>
            );
          })}

          <Grid container spacing={4} className={classes.warehouseContainer}>
            <Grid item md={12} className={classes.warehouseTitlePadding}>
              <FormControlLabel
                className={classes.radioButtonText}
                value="create"
                control={<Radio color="default" />}
                label="Add New Warehouse"
              />
            </Grid>

            {showFields === 'create' ? renderWarehouseFields() : <></>}
          </Grid>
        </RadioGroup>

        <hr className={classes.line} />
      </div>

      <Modal open={addUpdateModal} onClose={() => setAddUpdateModalOpen(false)}>
        <div className={classes.modalUpperContainer}>
          <TableContainer className={classes.tableContainerUpdateModal} component={Paper}>
            <div>
              <h3 className={classes.deleteWarehouseDet}>Warehouse Added/Updated.</h3>
            </div>
            <Grid className={classes.grid} container justifyContent="flex-end">
              <Grid item>
                <LuxButton variant="contained" luxColor="secondary" onClick={() => setAddUpdateModalOpen(false)}>
                  Close
                </LuxButton>
              </Grid>
            </Grid>
          </TableContainer>
        </div>
      </Modal>

      <Modal open={deleteModal} onClose={() => setDeleteModalOpen(false)}>
        <div className={classes.modalUpperContainer}>
          <TableContainer className={classes.tableContainerDelete} /* spacing={5} */ component={Paper}>
            <div>
              <h3 className={classes.thisActionWillRem}>Delete warehouse details</h3>
              <p>
                This action will remove the details information about {deleteWarehouseNameMessage}. Do you really want
                to delete this record?
              </p>
            </div>
            <Grid className={classes.grid} container direction="row" justifyContent="flex-end" alignItems="center">
              <Button onClick={() => setDeleteModalOpen(false)} className={classes.button}>
                Never Mind
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => deleteWarehouse()}
              >
                Delete
              </Button>
            </Grid>
          </TableContainer>
        </div>
      </Modal>
    </>
  );
};

export default WarehouseAddress;
