import React, { useState } from 'react';

import { LuxButton, Modal, Paper } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import OldBaseConfiguration from 'setup/OldBaseConfiguration';

import UploadFiles, { IFile } from '../UploadFiles/UploadFiles';

import importItemsModalStyles from './importItemsModal.styles';

interface IProps {
  onClose: () => void;
  onSubmit: (file: File) => void;
}

const ImportItemsModal: React.FC<IProps> = ({ onClose, onSubmit }) => {
  const classes = importItemsModalStyles();
  const { t } = useTranslation(['common', 'purchaseOrders']);
  const [files, setFiles] = useState<IFile[]>([]);

  const acceptedExtensions = '.csv, .xls, .xlsx';
  const filesSupported = acceptedExtensions
    .toUpperCase()
    .replaceAll('.', '')
    .replace(/,(?=[^,]*$)/, ` ${t('common:or')}`);

  const handleSubmit = () => {
    onClose();
    onSubmit(files[0].file);
  };

  return (
    <OldBaseConfiguration>
      <Modal onClose={onClose} open>
        <Paper className={classes.modalContent}>
          <h2 className={classes.title}>{t('purchaseOrders:importItems')}</h2>
          <UploadFiles
            accept={acceptedExtensions}
            className={classes.uploadFiles}
            files={files}
            setFiles={setFiles}
            subtitle={t('common:uploadFiles.filesSupported', { filesSupported })}
          />
          <div className={classes.actions}>
            <LuxButton className={classes.button} onClick={onClose}>
              {t('common:neverMind')}
            </LuxButton>
            <LuxButton
              className={classes.button}
              disabled={!files.length}
              luxColor="secondary"
              onClick={handleSubmit}
              variant="contained"
            >
              {t('purchaseOrders:importItemsModal.updateItemList')}
            </LuxButton>
          </div>
        </Paper>
      </Modal>
    </OldBaseConfiguration>
  );
};

export default ImportItemsModal;
