import React, { useEffect, useState } from 'react';

import {
  CircularProgress,
  IconButton,
  LuxButton,
  MaterialIcons,
  MenuItem,
  Modal,
  Paper,
  TextField
} from '@luxclusif/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ICancelReason } from 'models/purchaseOrders';
import purchaseOrdersService from 'services/PurchaseOrders.service';

import actionsModalStyles from './actionsModal.styles';

export enum EOrderItemsAction {
  Cancel = 'cancel',
  Remove = 'remove',
  Split = 'split'
}

interface IProps {
  action: EOrderItemsAction;
  onClose: () => void;
  onSubmit: (cancelReason?: number) => void;
}

const ActionsModal: React.FC<IProps> = ({ action, onClose, onSubmit }) => {
  const classes = actionsModalStyles();
  const { t } = useTranslation(['common', 'purchaseOrders']);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReasonsLoading, setIsReasonsLoading] = useState<boolean>(true);
  const [cancelReason, setCancelReason] = useState<number | undefined>(undefined);
  const [cancelReasons, setCancelReasons] = useState<ICancelReason[]>([]);

  const getCancelReasons = async () => {
    try {
      const reasons = await purchaseOrdersService.getCancelReasons();

      setCancelReasons(reasons);
      setIsReasonsLoading(false);
    } catch {
      enqueueSnackbar(t('purchaseOrders:poItems.actionsModal.cancelReasonsError'), { variant: 'error' });
      onClose();
    }
  };

  useEffect(() => {
    if (action === EOrderItemsAction.Cancel) {
      getCancelReasons();
    }
  }, []);

  const handleSelectReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCancelReason(event.target.value as unknown as number);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    onSubmit(cancelReason);
  };

  const submitText =
    action === EOrderItemsAction.Cancel
      ? t(`purchaseOrders:poItems.actionsModal.${EOrderItemsAction.Cancel}Items`)
      : t(`purchaseOrders:poItems.${action}`);

  return (
    <Modal onClose={onClose} open>
      <Paper className={classes.modalContent}>
        <h2 className={classes.title}>{t(`purchaseOrders:poItems.actionsModal.${action}Items`)}</h2>
        {action === EOrderItemsAction.Cancel && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <MaterialIcons.Close />
          </IconButton>
        )}
        <p className={classes.description}>{t(`purchaseOrders:poItems.actionsModal.${action}Description`)}</p>
        {action === EOrderItemsAction.Cancel && (
          <TextField
            className={classes.reasonsSelect}
            label={`${t('common:reason')} *`}
            onChange={handleSelectReason}
            select
            SelectProps={{
              IconComponent: isReasonsLoading ? props => <CircularProgress size={24} {...props} /> : undefined,
              MenuProps: {
                PaperProps: {
                  className: classes.reasonsSelectMenu
                },
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom'
                }
              },
              disabled: isReasonsLoading
            }}
            value={cancelReason}
          >
            {cancelReasons.map(({ id, reason }) => (
              <MenuItem key={id} value={id}>
                {reason}
              </MenuItem>
            ))}
          </TextField>
        )}
        <div className={classes.actions}>
          <LuxButton className={classes.button} onClick={onClose}>
            {t('common:neverMind')}
          </LuxButton>
          <LuxButton
            className={classes.button}
            disabled={isLoading || (action === EOrderItemsAction.Cancel && !cancelReason)}
            luxColor="secondary"
            onClick={handleSubmit}
            variant="contained"
          >
            {isLoading ? <CircularProgress size={19} /> : submitText}
          </LuxButton>
        </div>
      </Paper>
    </Modal>
  );
};

export default ActionsModal;
