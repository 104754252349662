import React, { useCallback, useState } from 'react';

import { LoadingButton } from '@lux-ds/button';
import Chip from '@lux-ds/chip';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Divider from '@lux-ds/divider';
import Typography from '@lux-ds/typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SimpleDialog from 'components/SimpleDialog/SimpleDialog';
import useWarehouseContext from 'hooks/useWarehouseContext';
import EInboundRoute from 'navigation/models/EInboundRoute';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';

import { StyledHeader, StyledJay } from './WarehouseIssue.styles';

const WarehouseIssue: React.FC = () => {
  const navigate = useNavigate();
  const { isResubmitIssueLoading, isWarehouseIssueLoading, onResubmitWarehouseIssue, warehouseIssueDetails } =
    useWarehouseContext();
  const { isFormDirty, onErrorSave, onSaveChanges, warehouseForm } = useWarehouseItemDetailsContext();
  const { t } = useTranslation(['common', 'warehouse']);

  const [isShowWarningModal, setIsShowWarningModal] = useState<boolean>(false);

  const handleCloseWarningModal = useCallback(() => setIsShowWarningModal(false), []);

  const handleSaveChanges = useCallback(
    () =>
      warehouseForm.handleSubmit(
        () =>
          onSaveChanges(false, false, () => {
            warehouseForm.reset(warehouseForm.getValues());
            handleCloseWarningModal();
          }),
        onErrorSave
      )(),
    [handleCloseWarningModal, onErrorSave, onSaveChanges, warehouseForm]
  );

  const handleResubmit = useCallback(() => {
    if (!isFormDirty) {
      const handleResubmitCallback = () => navigate(EInboundRoute.Warehouse);

      onResubmitWarehouseIssue(handleResubmitCallback);
    } else {
      setIsShowWarningModal(true);
    }
  }, [isFormDirty, navigate, onResubmitWarehouseIssue]);

  return (
    <StyledJay>
      <StyledHeader gap="xxs">
        <Typography variant="bodyTextNormal" weight="semibold">
          {t('warehouse:list.issues')}
        </Typography>
      </StyledHeader>
      {!isWarehouseIssueLoading && !!warehouseIssueDetails.issues?.length && (
        <LayoutJay scroll="auto" paddingX="m" paddingBottom="m">
          {warehouseIssueDetails.issues?.map(({ comment, issueType, reasons }, index, list) => (
            <LayoutJay key={issueType}>
              <LayoutJay gap="xs">
                <Typography variant="caption">{t('warehouse:itemIssues.issueType')}</Typography>
                <Typography variant="bodyTextNormal">{issueType}</Typography>
              </LayoutJay>
              <LayoutJay gap="xs">
                <Typography variant="caption">{t('warehouse:itemIssues.reasons')}</Typography>
                <LayoutHerodias gap="xs" sx={{ flexWrap: 'wrap' }}>
                  {reasons?.map(reason => (
                    <Chip key={reason} label={reason} squared />
                  ))}
                </LayoutHerodias>
              </LayoutJay>
              <LayoutJay gap="xs">
                <Typography variant="caption">{t('warehouse:itemIssues.comments')}</Typography>
                <Typography variant="bodyTextNormal">{comment}</Typography>
              </LayoutJay>
              {list.length - 1 > index && <Divider />}
            </LayoutJay>
          ))}
          <LayoutHerodias paddingTop="m" sx={{ justifyContent: 'center' }}>
            <LoadingButton loading={isResubmitIssueLoading} onClick={handleResubmit} variant="contained">
              {t('common:resolve')}
            </LoadingButton>
          </LayoutHerodias>
        </LayoutJay>
      )}

      {isShowWarningModal && (
        <SimpleDialog
          action={{
            label: t('common:saveChanges'),
            onClick: handleSaveChanges
          }}
          hideClose
          onClose={handleCloseWarningModal}
          PaperProps={{
            sx: {
              width: '523px'
            }
          }}
          title={t('common:exitModal.unsavedChanges')}
        >
          <LayoutJay paddingTop="xs">
            <Typography variant="bodyTextNormal">{t('warehouse:itemIssues.resubmitUnsavedChanges')}</Typography>
          </LayoutJay>
        </SimpleDialog>
      )}
    </StyledJay>
  );
};

export default WarehouseIssue;
