import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, MaterialIcons } from '@luxclusif/material';

import accordionStyles from './accordion.styles';

// Changed this to Extend to `AccordionProps` types on ts config is fixed
// Remove these AccordionProps types(expand, defaultExpanded, onChange) once the ts config is fixed
interface IProps {
  children: JSX.Element;
  defaultExpanded?: boolean;
  expanded?: boolean;
  id: string;
  label: string;
  onChange?: (event: any, expanded: boolean) => void;
}

const LuxAccordion: React.FC<IProps> = props => {
  const classes = accordionStyles();
  const { children, id, label } = props;

  return (
    <Accordion {...props} className={classes.container}>
      <AccordionSummary
        expandIcon={<MaterialIcons.ExpandMore />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        className={classes.header}
      >
        <h3 className={classes.heading}>{label}</h3>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default LuxAccordion;
