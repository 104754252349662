import React from 'react';

import { useTranslation } from 'react-i18next';

import CheckBoxChip from 'components/Input/CheckboxChip/CheckboxChip';
import { IOnHoldItemInfo } from 'models/warehouse/onHold';
import { INCLUSIONS_DATA } from 'pages/WarehouseList/constants';

import inclusionsStyles from './inclusions.styles';

interface IProps {
  inclusions: IOnHoldItemInfo['inclusions'];
}

const Inclusions: React.FC<IProps> = ({ inclusions }) => {
  const classes = inclusionsStyles();
  const { t } = useTranslation('warehouse');

  const inclusionsArray = inclusions.map(inclusionInfo => ({ ...inclusionInfo, ...INCLUSIONS_DATA[inclusionInfo.id] }));

  const actualInclusions = inclusionsArray.filter(inclusion => !inclusion.isMissing);
  const missingInclusions = inclusionsArray.filter(inclusion => inclusion.isMissing);

  return (
    <>
      <h1 className={classes.title}>{t('inclusions')}</h1>
      <div className={classes.inclusionsContainer}>
        <div className={classes.inclusionType}>
          <label>{t('actualInclusions')}</label>
          <div className={classes.chipsContainer}>
            {actualInclusions.map(({ icon, id, name }) => (
              <CheckBoxChip key={id} value={true} icon={icon} label={name} />
            ))}
          </div>
        </div>
        <div className={classes.inclusionDivider} />
        <div className={classes.inclusionType}>
          <label>{t('missingInclusions')}</label>
          <div className={classes.chipsContainer}>
            {missingInclusions.map(({ icon, id, name }) => (
              <CheckBoxChip key={id} value={false} icon={icon} label={name} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Inclusions;
