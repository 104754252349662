import { Subject } from 'rxjs';

import axios from '../utils/axios';

class PaymentService {
  public static $paymentRowUpdated = new Subject<any>();

  public async getPaymentMethods() {
    const url = `${process.env.REACT_APP_API_URL}Finances/payment-methods`;

    return await axios.get(url).then(response => response.data);
  }

  public async getPaymentTerms() {
    const url = `${process.env.REACT_APP_API_URL}Finances/payment-terms`;

    return await axios.get(url).then(response => response.data);
  }
}

const paymentService = new PaymentService();

export default paymentService;
