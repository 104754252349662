import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const imageFrameStyles = makeStyles(theme =>
  createStyles({
    container: {
      borderRadius: '4px',
      height: '139px',
      width: '139px',
      border: '1px solid #DFDCE0',
      overflow: 'hidden',
      position: 'relative'
    },
    containerDraggable: {
      cursor: 'grab'
    },
    containerDragging: {
      cursor: 'grabbing'
    },
    headerContainer: {
      position: 'relative',
      width: '100%',
      height: '113px'
    },
    headerImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%'
    },
    actionsContainer: {
      position: 'absolute',
      top: 4,
      right: 4,
      display: 'flex',
      gap: '8px'
    },
    actionButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.light,
      padding: '6px !important',
      '& .MuiSvgIcon-root': {
        color: 'inherit',
        fontSize: '14px'
      },
      '&:hover': {
        backgroundColor: secondaryNeutral.light85
      }
    },
    imageLabel: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      padding: '4px 8px',
      '& > .MuiSvgIcon-root': {
        fontSize: '11px',
        color: '#968B97'
      },
      '& > span': {
        fontSize: '12px',
        color: '#968B97'
      }
    },
    placeHolderDragging: {
      position: 'absolute',
      backgroundColor: '#FBFAFB',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    }
  })
);

export default imageFrameStyles;
