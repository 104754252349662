import React, { useEffect, useState } from 'react';

import { Backdrop, CircularProgress } from '@luxclusif/material';
import { Subject } from 'rxjs';

import loadingSpinnerStyles from './loadingSpinner.styles';

export const $showLoading = new Subject<string>();
export const $hideLoading = new Subject<string>();

const loadingIdList: string[] = [];

const LoadingSpinner: React.FC = () => {
  const classes = loadingSpinnerStyles();
  const [isShowing, toggleIsShowing] = useState(false);

  const toggle = () => {
    const newState = loadingIdList.length > 0;

    setTimeout(() => {
      toggleIsShowing(newState);
    }, 0);
  };

  useEffect(() => {
    const showLoadingSubscription = $showLoading.subscribe(newId => {
      const isIdInserted = loadingIdList.some(id => id === newId);

      if (!isIdInserted) {
        loadingIdList.push(newId);
      }

      toggle();
    });

    const hideLoadingSubscription = $hideLoading.subscribe(newId => {
      const idIndex = loadingIdList.findIndex(id => id === newId);

      if (idIndex > -1) {
        loadingIdList.splice(idIndex, 1);
      }

      toggle();
    });

    return () => {
      showLoadingSubscription.unsubscribe();
      hideLoadingSubscription.unsubscribe();
    };
  }, []);

  return (
    <Backdrop open={isShowing} className={classes.backdrop} data-testid="loadingSpinner">
      <CircularProgress className={classes.spinner} />
    </Backdrop>
  );
};

export default LoadingSpinner;
