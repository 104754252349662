import { BankAccount, BankDetailsGetModel } from '@inbound/api';
import { AxiosRequestConfig } from 'axios';
import { Subject } from 'rxjs';

import SUPPLIER_CONSTANTS from 'pages/Suppliers/constants';
import { formatDate, getUtcDate } from 'utils/dateTimeHelper';
import uuidGenerator from 'utils/uuidGenerator';

import {
  IBankDetails,
  IProfile,
  ISupplierBusinessModelTargetSettingsCreate,
  ISupplierBusinessModelTargetSettingsUpdate,
  ISupplierFormBusinessModelCreate,
  ISupplierFormBusinessModelFile,
  ISupplierFormCreateWarehouse,
  ISupplierFormDraftBusinessModel,
  ISupplierFormDraftWarehouse,
  ISupplierFormUpdateBusinessModel,
  ISupplierFormUpdateWarehouse,
  ITargetSettings,
  IWarehouse
} from '../models/supplier';
import axios from '../utils/axios';

class SuppliersService {
  public static $supplierRowUpdated = new Subject<any>();

  public async getAll() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers?PageSize=500`;

    return await axios.get(url).then(response => response.data);
  }

  public async getByItemId(id: any) {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/${id}`;

    return await axios.get(url).then(response => response.data);
  }

  public async createSupplier(form: any) {
    let strImage = form.logo;

    strImage = strImage.replace(/^data:image\/[a-z]+;base64,/, SUPPLIER_CONSTANTS.EMPTY_STRING);

    const supplierBank: BankAccount[] = form.supplierBankDetails.map((value: IBankDetails) => ({
      accountNumberIban: value.bankAccountNumber,
      address: value.bankAddress,
      bankAccountId: value.bankAccountId,
      beneficiaryName: value.beneficiaryName,
      email: value.bankEmail,
      swift: value.swiftOrBic
    }));

    const supplierWarehouse: ISupplierFormCreateWarehouse[] = form.supplierAddresses.map((value: IWarehouse) => ({
      addressLine1: value.supplierAddress,
      addressLine2: SUPPLIER_CONSTANTS.EMPTY_STRING,
      cityId: value.supplierCity,
      deliveryNotes: SUPPLIER_CONSTANTS.EMPTY_STRING,
      location: value.supplierWarehouseLocation,
      postalCode: value.supplierPostalCode
    }));

    const supplierProfile: ISupplierFormBusinessModelCreate[] = form.businessModel.map((value: IProfile) => {
      const files: ISupplierFormBusinessModelFile[] = value.files.files.map((value: any) => {
        let fileType = SUPPLIER_CONSTANTS.EMPTY_STRING;
        const fileName = value.file ? value.file.name : value.name;
        const fileParts = fileName.split('.');

        [fileType] = fileParts.slice(-1);

        return {
          fileId: value.fileId,
          fileSize: value.fileSize,
          name: fileName,
          originalName: value.originalName ? value.originalName : value.file.name,
          type: fileType
        };
      });

      const testingPeriod: ISupplierBusinessModelTargetSettingsCreate[] = value.targetSettings.map(
        (value: ITargetSettings) => ({
          endAtUtc: value.endDate,
          numberOfItems: value.testItems,
          poReference: value.poReference,
          startAtUtc: value.startDate,
          unitId: value.unit,
          value: value.goal
        })
      );

      return {
        businessModel: value.businessModelType,
        commercialModel: value.commercialModel,
        defaultCourierId: value.carrier,
        defaultFreightTerm: value.defaultFreightTerms,
        defaultPaymentMethod: value.paymentMethod,
        deliverySLADays: value.deliveryLeadTime,
        deliverySLAIsWorkingDays: value.deliverySLAType === 'Work' ? true : false,
        files: files, // soon to be fixed
        paymentTerms: [value.financialPayment],
        returnPolicyDays: value.numberDays,
        returnPolicyIsWorkingDays: value.numberDaysType === 'Work' ? true : false,
        returnPolicyNotes: value.internalComments,
        shippingWarehouses: [value.reception],
        status: value.status,
        technologicalNotes: value.notes,
        testingPeriodTargets: testingPeriod, // soon to be fixed
        tier: value.tier,
        useSupplierPhotos: value.photos === 'Yes' ? true : false
      };
    });

    const body = {
      addressLine1: form.address1,
      addressLine2: SUPPLIER_CONSTANTS.EMPTY_STRING,
      bankAccounts: supplierBank,
      billingCurrencyId: form.currency,
      cityId: form.city,
      ...(process.env.REACT_APP_ENABLE_SUPPLIER_CLUSTER === 'true' ? { clusters: form.clusters } : {}),
      contactEmail: form.contactEmail,
      contactName: form.contactName,
      contactPhone: form.contactTelephone,
      email: form.emailAddress,
      entityCompanyId: form.entityCompany,
      internalNotes: form.internalNotes,
      logo: strImage,
      luxclusifContactName: form.contactPerson,
      name: form.name,
      postalCode: form.postalCode,
      profiles: supplierProfile,
      regionId: form.regionId,
      relevanceId: form.relevanceId,
      supplierId: form.supplierId ? form.supplierId : null,
      warehouses: supplierWarehouse,
      website: form.website
    };

    const cleanProfiles: any[] = [];

    body.profiles.forEach(p => {
      const cleanedObject = this.CleanNullables(p);

      cleanProfiles.push(cleanedObject);
    });

    body.profiles = cleanProfiles;

    const url = `${process.env.REACT_APP_API_URL}Suppliers`;

    return await axios
      .post(url, body)
      .then(response => response.data)
      .catch(err => console.log(err));
  }

  public async updateSupplier(form: any, supplierId: string) {
    let strImage = form.logo;

    strImage = strImage.replace(/^data:image\/[a-z]+;base64,/, SUPPLIER_CONSTANTS.EMPTY_STRING);

    const supplierBank: BankAccount[] = form.supplierBankDetails.map((value: IBankDetails) => ({
      accountNumberIban: value.bankAccountNumber,
      address: value.bankAddress,
      bankAccountId: value.bankAccountId,
      beneficiaryName: value.beneficiaryName,
      email: value.bankEmail,
      swift: value.swiftOrBic
    }));

    const supplierWarehouse: ISupplierFormUpdateWarehouse[] = form.supplierAddresses.map((value: IWarehouse) => ({
      addressLine1: value.supplierAddress,
      addressLine2: SUPPLIER_CONSTANTS.EMPTY_STRING,
      cityId: value.supplierCity,
      deliveryNotes: SUPPLIER_CONSTANTS.EMPTY_STRING,
      location: value.supplierWarehouseLocation,
      postalCode: value.supplierPostalCode,
      warehouseId:
        value.warehouseAddressId === SUPPLIER_CONSTANTS.EMPTY_STRING ? uuidGenerator() : value.warehouseAddressId
    }));

    const supplierProfile: ISupplierFormUpdateBusinessModel[] = form.businessModel.map((value: IProfile) => {
      const files: ISupplierFormBusinessModelFile[] = value.files.files.map((value: any) => {
        let fileType = SUPPLIER_CONSTANTS.EMPTY_STRING;
        const fileName = value.file ? value.file.name : value.name;
        const fileParts = fileName.split('.');

        [fileType] = fileParts.slice(-1);

        return {
          fileId: value.fileId,
          fileSize: value.file ? value.file.size : value.size,
          name: fileName,
          originalName: value.file ? value.file.name : value.originalName,
          type: fileType
        };
      });

      const testingPeriod: ISupplierBusinessModelTargetSettingsUpdate[] = value.targetSettings.map(
        (value: ITargetSettings) => ({
          endAtUtc: value.endDate,
          numberOfItems: value.testItems,
          poReference: value.poReference,
          startAtUtc: value.startDate,
          testingPeriodTargetId:
            value.targetSettingsId === SUPPLIER_CONSTANTS.EMPTY_STRING ? uuidGenerator() : value.targetSettingsId,
          unitId: value.unit,
          value: value.goal
        })
      );

      return {
        businessModel: value.businessModelType,
        commercialModel: value.commercialModel === SUPPLIER_CONSTANTS.EMPTY_STRING ? null : value.commercialModel,
        defaultCourierId: value.carrier,
        defaultFreightTerm: value.defaultFreightTerms,
        defaultPaymentMethod: value.paymentMethod,
        deliverySLADays: value.deliveryLeadTime,
        deliverySLAIsWorkingDays: value.deliverySLAType === 'Work' ? true : false,
        files: files,
        filesToDelete: value.files.filesToDelete,
        paymentTerms: [value.financialPayment],
        profileId: value.businessModelId === SUPPLIER_CONSTANTS.EMPTY_STRING ? uuidGenerator() : value.businessModelId,
        returnPolicyDays: value.numberDays,
        returnPolicyIsWorkingDays: value.numberDaysType === 'Work' ? true : false,
        returnPolicyNotes: value.internalComments,
        shippingWarehouseIds: [value.reception],
        status: value.status,
        technologicalNotes: value.notes,
        testingPeriodTargets: testingPeriod,
        tier: value.tier,
        useSupplierPhotos: value.photos === 'Yes' ? true : false
      };
    });

    const body = {
      addressLine1: form.address1,
      addressLine2: SUPPLIER_CONSTANTS.EMPTY_STRING,
      bankAccounts: supplierBank,
      billingCurrencyId: form.currency,
      cityId: form.city,
      ...(process.env.REACT_APP_ENABLE_SUPPLIER_CLUSTER === 'true' ? { clusters: form.clusters } : {}),
      contactEmail: form.contactEmail,
      contactName: form.contactName,
      contactPhone: form.contactTelephone,
      email: form.emailAddress,
      entityCompanyId: form.entityCompany,
      internalNotes: form.internalNotes,
      logo: strImage,
      luxclusifContactName: form.contactPerson,
      name: form.name,
      postalCode: form.postalCode,
      profiles: supplierProfile,
      regionId: form.regionId,
      relevanceId: form.relevanceId,
      supplierId: supplierId,
      warehouses: supplierWarehouse,
      website: form.website
    };

    const cleanProfiles: any[] = [];

    body.profiles.forEach(p => {
      const cleanedObject = this.CleanNullables(p);

      cleanProfiles.push(cleanedObject);
    });

    body.profiles = cleanProfiles;

    const url = `${process.env.REACT_APP_API_URL}Suppliers/${supplierId}`;

    return await axios
      .put(url, body)
      .then(response => response.data)
      .catch(err => console.log(err));
  }

  public async saveDraft(form: any, supplierId: string) {
    const newSupplierId = uuidGenerator();

    let strImage = form.logo;

    strImage = strImage.replace(/^data:image\/[a-z]+;base64,/, SUPPLIER_CONSTANTS.EMPTY_STRING);

    const supplierBank: BankDetailsGetModel[] = form.supplierBankDetails.map((value: IBankDetails) => ({
      accountNumberIban: value.bankAccountNumber,
      address: value.bankAddress,
      bank: {
        id: value.bankAccountId,
        name: value.bankName
      },
      beneficiaryName: value.beneficiaryName,
      countryId: value.bankCountry,
      email: value.bankEmail,
      swift: value.swiftOrBic
    }));

    const supplierWarehouse: ISupplierFormDraftWarehouse[] = form.supplierAddresses.map((value: IWarehouse) => ({
      addressLine1: value.supplierAddress,
      addressLine2: SUPPLIER_CONSTANTS.EMPTY_STRING,
      city: value.supplierCityHTML,
      cityId: value.supplierCity,
      country: value.supplierCountryHTML,
      countryId: value.supplierCountry,
      deliveryNotes: SUPPLIER_CONSTANTS.EMPTY_STRING,
      location: value.supplierWarehouseLocation,
      postalCode: value.supplierPostalCode,
      state: value.supplierStateHTML,
      stateId: value.supplierState,
      warehouseId:
        value.warehouseAddressId === SUPPLIER_CONSTANTS.EMPTY_STRING ? uuidGenerator() : value.warehouseAddressId
    }));

    const supplierProfile: ISupplierFormDraftBusinessModel[] = form.businessModel.map((value: IProfile) => {
      const files: any[] = value.files.files.map((value: any) => {
        let fileType = SUPPLIER_CONSTANTS.EMPTY_STRING;
        const fileName = value.file ? value.file.name : value.name;
        const fileParts = fileName.split('.');

        [fileType] = fileParts.slice(-1);

        return {
          fileId: value.fileId,
          fileSize: value.file ? value.file.size : value.size,
          fileUrl: `${process.env.REACT_APP_FILES_STORAGE}${SUPPLIER_CONSTANTS.BLOB_RELATIVE_PATH}${value.fileId}.${fileType}`,
          name: fileName,
          originalName: fileName,
          type: fileType,
          uploadDate: (value.uploadDate || value.file?.uploadDate) ?? formatDate(getUtcDate())
        };
      });

      const testingPeriod: ISupplierBusinessModelTargetSettingsUpdate[] = value.targetSettings.map(
        (value: ITargetSettings) => ({
          endAtUtc: value.endDate,
          numberOfItems: value.testItems,
          poReference: value.poReference,
          startAtUtc: value.startDate,
          testingPeriodTargetId:
            value.targetSettingsId === SUPPLIER_CONSTANTS.EMPTY_STRING ? uuidGenerator() : value.targetSettingsId,
          unitId: value.unit ? value.unit : SUPPLIER_CONSTANTS.STRING_ZERO_DRAFT,
          value: value.goal
        })
      );

      return {
        businessModel: value.businessModelType,
        businessModelType: value.businessModelTypeHTML,
        businessModelTypeId: value.businessModelType,
        commercialModel: value.commercialModel === SUPPLIER_CONSTANTS.EMPTY_STRING ? null : value.commercialModel,
        commercialModelId: value.commercialModel === SUPPLIER_CONSTANTS.EMPTY_STRING ? null : value.commercialModel,
        courierId: value.carrier,
        defaultCourierId: value.carrier,
        defaultFreightTerm: value.defaultFreightTerms,
        defaultFreightTermId: value.defaultFreightTerms,
        defaultPaymentMethod: value.paymentMethodHTML,
        deliverySLADays: value.deliveryLeadTime,
        deliverySLAIsWorkingDays: value.deliverySLAType === SUPPLIER_CONSTANTS.WORKING_DAYS ? true : false,
        files: files,
        filesToDelete: value.files.filesToDelete,
        paymentMethodId: value.paymentMethod,
        paymentTerms: [
          {
            name: SUPPLIER_CONSTANTS.JSON_SERIALIZATION_STRING,
            paymentTermId: value.financialPayment ? value.financialPayment : SUPPLIER_CONSTANTS.DEFAULT_GUID_VALUE
          }
        ],
        profileId: value.businessModelId === SUPPLIER_CONSTANTS.EMPTY_STRING ? uuidGenerator() : value.businessModelId,
        returnPolicyDays: value.numberDays,
        returnPolicyIsWorkingDays: value.numberDaysType === SUPPLIER_CONSTANTS.WORKING_DAYS ? true : false,
        returnPolicyNotes: value.internalComments,
        shippingWarehouseIds: [value.reception],
        shippingWarehouses: [
          {
            addressLine1: SUPPLIER_CONSTANTS.JSON_SERIALIZATION_STRING,
            addressLine2: SUPPLIER_CONSTANTS.JSON_SERIALIZATION_STRING,
            city: SUPPLIER_CONSTANTS.JSON_SERIALIZATION_STRING,
            cityId: uuidGenerator(),
            country: SUPPLIER_CONSTANTS.JSON_SERIALIZATION_STRING,
            countryId: uuidGenerator(),
            location: SUPPLIER_CONSTANTS.JSON_SERIALIZATION_STRING,
            postalCode: SUPPLIER_CONSTANTS.JSON_SERIALIZATION_STRING,
            state: SUPPLIER_CONSTANTS.JSON_SERIALIZATION_STRING,
            stateId: uuidGenerator(),
            warehouseId: value.reception === SUPPLIER_CONSTANTS.EMPTY_STRING ? uuidGenerator() : value.reception
          }
        ],
        shortCode: SUPPLIER_CONSTANTS.STILL_IN_DRAFT,
        status: value.statusHTML,
        statusId: value.status === SUPPLIER_CONSTANTS.EMPTY_STRING ? '0' : value.status,
        technologicalNotes: value.notes,
        testingPeriodTargets: testingPeriod,
        tier: value.tierHTML,
        tierId: value.tier,
        useSupplierPhotos: value.photos === SUPPLIER_CONSTANTS.YES ? true : false
      };
    });

    const body = {
      addressLine1: form.address1,
      addressLine2: SUPPLIER_CONSTANTS.EMPTY_STRING,
      bankAccounts: supplierBank,
      billingCurrency: form.currencyHTML ? form.currencyHTML : SUPPLIER_CONSTANTS.EMPTY_STRING,
      billingCurrencyId: form.currency,
      businessModelTypes: supplierProfile.map(p => {
        return p.businessModelType;
      }),
      city: form.cityHTML ? form.cityHTML : SUPPLIER_CONSTANTS.EMPTY_STRING,
      cityId: form.city ? form.city : SUPPLIER_CONSTANTS.DEFAULT_GUID_VALUE,
      ...(process.env.REACT_APP_ENABLE_SUPPLIER_CLUSTER === 'true' ? { clusters: form.clusters } : {}),
      contactEmail: form.contactEmail,
      contactName: form.contactName,
      contactPhone: form.contactTelephone,
      country: form.countryHTML ? form.countryHTML : SUPPLIER_CONSTANTS.EMPTY_STRING,
      countryId: form.country ? form.country : SUPPLIER_CONSTANTS.DEFAULT_GUID_VALUE,
      currency: form.currencyHTML ? form.currencyHTML : SUPPLIER_CONSTANTS.EMPTY_STRING,
      email: form.emailAddress,
      entityCompany: form.entityCompanyHTML,
      entityCompanyId: form.entityCompany,
      internalNotes: form.internalNotes,
      logo: strImage,
      luxclusifContactName: form.contactPerson,
      name: form.name,
      postalCode: form.postalCode,
      profiles: supplierProfile,
      regionId: form.regionId,
      relevanceId: form.relevanceId,
      state: form.stateHTML ? form.stateHTML : SUPPLIER_CONSTANTS.EMPTY_STRING,
      stateId: form.state ? form.state : SUPPLIER_CONSTANTS.DEFAULT_GUID_VALUE,
      status: SUPPLIER_CONSTANTS.DRAFT_STATUS_NAME,
      supplierId: supplierId || newSupplierId,
      warehouses: supplierWarehouse,
      website: form.website
    };

    const requestBody = {
      jsonPayload: JSON.stringify(body),
      supplierId: supplierId || newSupplierId
    };

    const cleanProfiles: any[] = [];

    body.profiles.forEach(p => {
      const cleanedObject = this.CleanNullables(p);

      cleanProfiles.push(cleanedObject);
    });

    body.profiles = cleanProfiles;

    const url = `${process.env.REACT_APP_API_URL}Suppliers/draft/${supplierId || newSupplierId}`;

    return await axios
      .post(url, requestBody)
      .then(response => response.data)
      .catch(err => console.log(err));
  }

  private CleanNullables(supplierProfile: any) {
    const newObject: any = {};

    Object.keys(supplierProfile).forEach(key => {
      if (supplierProfile[key]) {
        newObject[key] = supplierProfile[key];
      }
    });

    return newObject;
  }

  public async getStatus() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/profile-status`;

    return await axios.get(url).then(response => response.data);
  }

  public async getBuyerEntities() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/entity-companies`;

    return await axios.get(url).then(response => response.data);
  }

  public async getRelevanceOptions() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/supplier-relevance`;

    return await axios.get(url).then(response => response.data);
  }

  public async getRegions() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/supplier-region`;

    return await axios.get(url).then(response => response.data);
  }

  public async getFreightTerms() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/freight-terms`;

    return await axios.get(url).then(response => response.data);
  }

  public async getTargetSettings() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/target-units`;

    return await axios.get(url).then(response => response.data);
  }

  public async getTiers() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/tiers`;

    return await axios.get(url).then(response => response.data);
  }

  public async getCommercialModels() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/commercial-models`;

    return await axios.get(url).then(response => response.data);
  }

  public async getTargetUnits() {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/target-units`;

    return await axios.get(url).then(response => response.data);
  }

  public async uploadFile(id: any, file: any) {
    const url = `${process.env.REACT_APP_API_URL}Suppliers/upload-file`;
    const form = new FormData();

    form.append('file', file, file.name);
    form.append('fileId', id);

    const config: AxiosRequestConfig = {
      data: form,
      headers: {
        'Content-Type': undefined
      },
      method: 'post',
      url: url
    };

    return await axios(config).then(response => {
      console.log(JSON.stringify(response.data));
    });
  }
}

const suppliersService = new SuppliersService();

export default suppliersService;
