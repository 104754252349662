import { createStyles, makeStyles, secondaryPalette } from '@luxclusif/material';

import { EInboundFlow } from 'models/warehouse';

const inboundFlowChipStyles = makeStyles(() => {
  return createStyles({
    container: {
      borderRadius: '4px',
      display: 'inline-block',
      fontSize: '14px',
      lineHeight: 1,
      padding: '8px',
      whiteSpace: 'nowrap',

      '& > p': {
        margin: 0
      }
    },
    subLabel: {
      color: '#7D717E',
      fontSize: '10px',
      lineHeight: '14px'
    }
  });
});

export const inboundFlowColorsStyles = makeStyles(() =>
  createStyles({
    [EInboundFlow.ClientReturn]: {
      backgroundColor: '#FAEBE7',
      color: secondaryPalette.error.dark
    },
    [EInboundFlow.ConsignmentReturn]: {
      backgroundColor: '#F6EBED',
      color: '#7B3444'
    },
    [EInboundFlow.Hybrid]: {
      backgroundColor: '#EEEAF1',
      color: '#665073'
    },
    [EInboundFlow.Regular]: {
      backgroundColor: '#E7F7FB',
      color: secondaryPalette.success.dark
    },
    [EInboundFlow.TransferNotProcessed]: {
      backgroundColor: '#ECE8E4',
      color: '#6B5B4D'
    },
    [EInboundFlow.TransferProcessed]: {
      backgroundColor: '#E5E9F3',
      color: '#3C5483'
    },
    [EInboundFlow.VLG]: {
      backgroundColor: '#DFECEA',
      color: '#38615A'
    }
  })
);

export default inboundFlowChipStyles;
