import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, Modal, Paper, TableContainer } from '@luxclusif/material';
import { StatusCodes } from 'http-status-codes';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import ExitModal from 'components/ExitModal/ExitModal';
import { EInboundFlow, IInboundFlow } from 'models/warehouse';
import { IOnHoldItemInfo } from 'models/warehouse/onHold';
import { defaultOnHold, IOnHoldInputs, onHoldSchema } from 'pages/WarehouseList/schemas/onHoldSchema';
import warehouseService from 'services/Warehouse.service';

import WarehouseAppBar from '../../../components/Warehouse/WarehouseAppBar/WarehouseAppBar';
import Header from './Header/Header';
import Colors from './sections/Colors/Colors';
import DecisionForm from './sections/DecisionForm/DecisionForm';
import Inclusions from './sections/Inclusions/Inclusions';
import ItemDetails from './sections/ItemDetails/ItemDetails';
import Measures from './sections/Measures/Measures';
import QualityCheck from './sections/QualityCheck/QualityCheck';
import SecurityTag from './sections/SecurityTag/SecurityTag';

import onHoldStyles from './onHold.styles';

const defaultItemInfo: IOnHoldItemInfo = {
  actualQCDamagesDescription: '',
  actualQCDecision: false,
  additionalDamagePhotos: [],
  brand: '',
  category: '',
  colors: [],
  description: '',
  hasSecurityTag: null,
  inclusions: [],
  madeInCountry: '',
  materials: [],
  measures: [],
  name: '',
  parentCategory: '',
  previousQCDamagesDescription: '',
  sellerCode: '',
  serialNumber: '',
  size: '',
  sku: '',
  warehouse: ''
};

interface IProps {
  closeDetailModal?: () => void;
}

const OnHold: React.FC<IProps> = ({ closeDetailModal }) => {
  const { itemProcessId = '' } = useParams<{ itemProcessId: string }>();
  const classes = onHoldStyles();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('warehouse');

  const [exitModalOpened, setExitModalOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [itemInfo, setItemInfo] = useState<IOnHoldItemInfo>(defaultItemInfo);

  const inboundFlow: IInboundFlow = useMemo(() => {
    // Replace and get inboundFlowId and inboundFlowName from getById
    const search = new URLSearchParams(location.search);

    return {
      id: (search.get('inboundFlowId') as EInboundFlow) || EInboundFlow.Regular,
      name: search.get('inboundFlowName') || EInboundFlow.Regular
    };
  }, [location.search]);

  const onHoldForm = useForm<IOnHoldInputs>({
    defaultValues: defaultOnHold,
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(onHoldSchema)
  });

  const {
    formState: { isDirty },
    reset
  } = onHoldForm;

  useEffect(() => {
    warehouseService
      .getOnHoldItem(itemProcessId)
      .then(data => {
        setItemInfo(data);
        setIsLoading(false);
      })
      .catch(response => {
        const status = response.response ? response.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

        if (status === StatusCodes.NOT_FOUND) {
          discardChangesAndCloseModalDetails();
        }
      });
  }, []);

  const discardChangesAndCloseModalDetailsPrompt = () => {
    if (isDirty) {
      setExitModalOpened(true);
    } else {
      discardChangesAndCloseModalDetails();
    }
  };

  const discardChangesAndCloseModalDetails = () => {
    if (exitModalOpened) {
      setExitModalOpened(false);
    }

    reset();
    closeDetailModal?.();
  };

  const onCloseModal = () => {
    setExitModalOpened(false);
  };

  const handleSubmit: SubmitHandler<IOnHoldInputs> = formData => {
    setIsSubmitting(true);

    warehouseService
      .postOnHoldDecision(itemProcessId, formData.decision as boolean)
      .then(() => {
        enqueueSnackbar(t('details.itemSaveSuccess', { sku: itemInfo.sku }), { variant: 'success' });
        closeDetailModal?.();
      })
      .catch(() => enqueueSnackbar(t('details.errorOccurred'), { variant: 'error' }))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Modal open onClose={closeDetailModal}>
        <Paper className={classes.modalRoot}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <WarehouseAppBar sku={itemInfo.sku} mode="update" onClose={discardChangesAndCloseModalDetailsPrompt} />
              <div className={classes.contentContainer}>
                <TableContainer className={classes.itemDetails} component={Paper}>
                  <Header inboundFlow={inboundFlow} sku={itemInfo.sku} />
                  <hr className={classes.line} />
                  <div className={classes.itemContent}>
                    <ItemDetails
                      brand={itemInfo.brand}
                      category={itemInfo.category}
                      description={itemInfo.description}
                      madeInCountry={itemInfo.madeInCountry}
                      materials={itemInfo.materials}
                      name={itemInfo.name}
                      parentCategory={itemInfo.parentCategory}
                      sellerCode={itemInfo.sellerCode}
                      serialNumber={itemInfo.serialNumber}
                      size={itemInfo.size}
                      sku={itemInfo.sku}
                      warehouse={itemInfo.warehouse}
                    />
                    <Grid container justifyContent="center" alignItems="flex-start" direction="row">
                      <Grid item lg={6}>
                        <Measures measures={itemInfo.measures} />
                      </Grid>
                      <Grid item lg={6}>
                        <Colors colors={itemInfo.colors} />
                      </Grid>
                    </Grid>
                    <Inclusions inclusions={itemInfo.inclusions} />
                    <SecurityTag hasSecurityTag={itemInfo.hasSecurityTag} />
                    <hr className={classes.line} />
                    <QualityCheck
                      actualQCDamagesDescription={itemInfo.actualQCDamagesDescription}
                      actualQCDecision={itemInfo.actualQCDecision}
                      additionalDamagePhotos={itemInfo.additionalDamagePhotos}
                      previousQCDamagesDescription={itemInfo.previousQCDamagesDescription}
                    />
                  </div>
                </TableContainer>
                <DecisionForm form={onHoldForm} isSubmitting={isSubmitting} onSubmit={handleSubmit} />
              </div>
            </>
          )}
        </Paper>
      </Modal>

      {exitModalOpened && (
        <ExitModal
          onClose={onCloseModal}
          onDiscard={discardChangesAndCloseModalDetails}
          onSave={onHoldForm.handleSubmit(handleSubmit)}
        />
      )}
    </>
  );
};

export default OnHold;
