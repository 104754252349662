import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const uploadImagesStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    dropZoneContainer: {
      borderRadius: '4px',
      height: '139px',
      width: '150px',
      backgroundColor: '#F8F7F8',
      border: '1px dashed #DFDCE0',
      padding: '12px 8px',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      lineHeight: 1,
      rowGap: '2px'
    },
    uploadImageError: {
      borderColor: palette.error.main,
      color: palette.error.main
    },
    dropZoneIcon: {
      color: '#7D717E',
      fontSize: '24px'
    },
    dropZoneLabel: {
      color: '#7D717E',
      textAlign: 'center'
    },
    dropZoneButton: {
      fontSize: '14px',
      lineHeight: '16px',
      padding: '6px 12px',
      borderColor: '#DFDCE0',
      color: '#131113'
    }
  })
);

export default uploadImagesStyles;
