import React, { useEffect } from 'react';

import Skeleton from '@lux-ds/skeleton';
import { Box, Modal, Paper, Typography } from '@luxclusif/material';
import Barcode from 'react-barcode';

import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';

import printBarcodeModalStyles from './printBarcodeModal.styles';

interface IProps {
  isOpen: boolean;
  itemDetails: Record<string, any>;
  toggleModal: () => void;
}

const PrintBarcodeModal: React.FC<IProps> = ({ isOpen, itemDetails, toggleModal }) => {
  const classes = printBarcodeModalStyles();
  const { barcodeDetails, getBarcodeDetails, isBarcodeDetailsLoading } = useWarehouseItemDetailsContext();

  useEffect(() => {
    getBarcodeDetails();
  }, []);

  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <Box className={classes.modalContainer}>
        <Paper className={classes.modalContent}>
          <Box className={classes.itemInfoContainer}>
            <Typography className={classes.itemLabel}>Brand</Typography>
            {isBarcodeDetailsLoading ? (
              <Skeleton width={100} sx={{ transform: 'scale(1, 0.8)' }} />
            ) : (
              <Typography className={classes.itemValue}>{barcodeDetails?.brand?.value}</Typography>
            )}
          </Box>
          <Box className={classes.itemInfoContainer}>
            <Typography className={classes.itemLabel}>Category</Typography>
            {isBarcodeDetailsLoading ? (
              <Skeleton width={100} sx={{ transform: 'scale(1, 0.8)' }} />
            ) : (
              <Typography className={classes.itemValue}>{barcodeDetails?.category?.value}</Typography>
            )}
          </Box>
          <Box className={classes.itemInfoContainer}>
            <Typography className={classes.itemLabel}>Serial Number</Typography>
            {isBarcodeDetailsLoading ? (
              <Skeleton width={100} sx={{ transform: 'scale(1, 0.8)' }} />
            ) : (
              <Typography className={classes.itemValue}>{barcodeDetails?.serialNumber}</Typography>
            )}
          </Box>
          <Box className={classes.barcodeContainer}>
            <Barcode value={itemDetails.sku} width={5} height={150} displayValue={false} />
            <Typography className={classes.barcodeLabel}>{itemDetails.sku}</Typography>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default PrintBarcodeModal;
