import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const warehouseTitleSectionStyles = makeStyles((theme: Theme) => {
  return createStyles({
    upperDivContainer: {
      width: '100%',
      padding: '24px',
      flexGrow: 1
    },
    modalRoot: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100vw',
      height: '100vh',
      overflowY: 'auto',
      backgroundColor: secondaryNeutral.light85,
      '&:focus': {
        outline: 'none'
      }
    },
    formHeader: {
      height: '64px',
      backgroundColor: theme.palette.primary.contrastText,
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 24px'
    },
    itemTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
    },
    itemTitle: {
      fontWeight: 400,
      fontSize: '18px',
      color: theme.palette.secondary.main,
      '& b': {
        fontWeight: 600
      }
    },

    closeIcon: {
      marginLeft: 'auto',
      cursor: 'pointer'
    },
    lineHeader: {
      boxSizing: 'border-box',
      height: '2px',
      width: '100%',
      border: `1px solid ${secondaryNeutral.light75}`
    },
    rightIconMargin: {
      marginRight: '8px',
      cursor: 'pointer',
      boxSizing: 'border-box',
      height: '32px',
      width: '32px',
      border: `1px solid ${secondaryNeutral.light75}`,
      borderRadius: '4px'
    },
    BBoxIcon: {
      width: '20px',
      height: '6px'
    },
    rightActionPanel: {
      marginLeft: 'auto'
    },
    rightActionPanelButtons: {
      marginRight: '8px'
    }
  });
});

export default warehouseTitleSectionStyles;
