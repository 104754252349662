import React from 'react';

import { AppBar } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import InboundFlowChip from 'components/InboundFlowChip/InboundFlowChip';
import { IInboundFlow } from 'models/warehouse';

import headerStyles from './header.styles';

interface IProps {
  inboundFlow: IInboundFlow;
  sku: string;
}

const Header: React.FC<IProps> = ({ inboundFlow, sku }) => {
  const classes = headerStyles();
  const { t } = useTranslation();

  return (
    <AppBar position="relative" className={classes.formHeader} elevation={0}>
      <div className={classes.itemTitle}>
        <b>{t('item')}</b>
        <span>{sku}</span>
        <InboundFlowChip type={inboundFlow.id} label={inboundFlow.name} />
      </div>
    </AppBar>
  );
};

export default Header;
