import React from 'react';

import { Box, FormControlLabel, Grid, MenuItem, Radio } from '@luxclusif/material';
import { UseFormMethods } from 'react-hook-form';

import LuxToggleButton from 'components/Input/LuxToggleButton/LuxToggleButton';
import LuxNumberInput from 'components/Input/NumberInput/LuxNumberInput';
import SelectInput from 'components/Input/Select/Select';
import { ICouriers, IFreightTerms, ILuxWarehouses } from 'models/supplier';

import supplierDetailsStyles from '../../supplierDetails.styles';

interface IProps {
  businessModelForm: UseFormMethods<Record<string, any>>;
  couriers: ICouriers[];
  freightTerms: IFreightTerms[]; // will fix this
  luxWarehouses: ILuxWarehouses[];
}

const Operations: React.FC<IProps> = ({ businessModelForm, couriers, freightTerms, luxWarehouses }) => {
  const classes = supplierDetailsStyles();

  const { control, errors, setValue, watch } = businessModelForm;

  return (
    <div>
      <h1 className={classes.title}>Operations</h1>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <SelectInput
            label="Reception Warehouse*"
            className={classes.input}
            fullWidth={true}
            name="reception"
            variant="outlined"
            control={control}
            errors={errors}
            setValue={setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            {luxWarehouses.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Grid item md={3}>
          <SelectInput
            label="Default Courier*"
            className={classes.input}
            fullWidth={true}
            name="carrier"
            variant="outlined"
            control={control}
            errors={errors}
            setValue={setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            {couriers.map((row: ICouriers) => (
              <MenuItem key={row.id} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>

        <Grid item md={3}>
          <SelectInput
            label="Default Freight Terms*"
            className={classes.input}
            fullWidth={true}
            name="defaultFreightTerms"
            variant="outlined"
            control={control}
            errors={errors}
            setValue={setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            {freightTerms.map((row: IFreightTerms) => (
              <MenuItem key={row.freightTermId} value={row.freightTermId}>
                {row.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>

        <Grid item md={3}></Grid>
      </Grid>
      <Box display="flex" flexWrap="wrap" alignItems="flex-end">
        <Box p={1} className={classes.boxPadding}>
          <LuxNumberInput
            label="Delivery SLA Days*"
            className={classes.input}
            fullWidth={true}
            name="deliveryLeadTime"
            variant="outlined"
            control={control}
            errors={errors}
            inputProps={{
              className: classes.numberInputProps,
              min: '0',
              step: '1'
            }}
            watch={watch}
            setValue={setValue}
          />
        </Box>
        <Box p={1}>
          <LuxToggleButton name="deliverySLAType" control={control} errors={errors}>
            <Grid container spacing={0}>
              <Grid item>
                <FormControlLabel
                  className={
                    watch('deliverySLAType') === 'Work' ? classes.daysButtonSelected : classes.daysButtonNotSelected
                  }
                  value="Work"
                  control={<Radio className={classes.radioDaysButton} />}
                  label="Work Days"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  className={
                    watch('deliverySLAType') === 'Calendar' ? classes.daysButtonSelected : classes.daysButtonNotSelected
                  }
                  value="Calendar"
                  control={<Radio className={classes.radioDaysButton} />}
                  label="Calendar Days"
                />
              </Grid>
            </Grid>
          </LuxToggleButton>
        </Box>
      </Box>
      <hr className={classes.line} />
    </div>
  );
};

export default Operations;
