import axios from '../utils/axios';

class FinancesService {
  public async getBanks(countryId: string, name: string) {
    const url = `${process.env.REACT_APP_API_URL}BankAccounts?countryId=${countryId}&name=${name}`;

    return await axios.get(url).then(response => response.data);
  }
}

const financesService = new FinancesService();

export default financesService;
