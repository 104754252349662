export type TBusinessModelsKeys = 'buyback' | 'hybrid' | 'ownedGoods' | 'physicalConsignment' | 'virtualConsignment';

// Based from BE's hard-coded enum
export enum EBusinessModelsId {
  Buyback = 'Buyback',
  Hybrid = 'Hybrid',
  OwnedGoods = 'OwnedGoods',
  PhysicalConsignment = 'PhysicalConsignment',
  VirtualConsignment = 'VirtualConsignment'
}

// Based from end-point of /v1/Suppliers/business-models
export enum EBusinessModelsIdNumber {
  Buyback = 4,
  Hybrid = 5,
  OwnedGoods = 1,
  PhysicalConsignment = 2,
  VirtualConsignment = 3
}

export interface ISupplierBusinessModelTargetSettingsCreate {
  endAtUtc: string;
  numberOfItems: string;
  poReference: string;
  startAtUtc: string;
  unitId: string;
  value: string;
}

export interface ISupplierBusinessModelTargetSettingsUpdate {
  endAtUtc: string;
  numberOfItems: string;
  poReference: string;
  startAtUtc: string;
  testingPeriodTargetId: string;
  unitId: string;
  value: string;
}

export interface ISupplierFormBusinessModelById {
  businessModelType: string; // htmltext
  businessModelTypeId: string;
  commercialModelId: string;
  courierId: string;
  defaultFreightTermId: string;
  deliverySLADays: string | number;
  deliverySLAIsWorkingDays: boolean;
  files: Array<any>;
  financialPayment: string | number;
  paymentMethodId: string;
  paymentTerms: Array<any>;
  profileId: string;
  returnPolicyDays: string | number;
  returnPolicyIsWorkingDays: boolean;
  returnPolicyNotes: string;
  shippingWarehouses: Array<any>;
  shortCode: string;
  status: string | boolean | number; // to do: fix this one
  statusId: string;
  technologicalNotes: string;
  testingPeriodTargets: Array<any>;
  tierId: string; // htmltext
  useSupplierPhotos: boolean;
}

export interface ISupplierFormBusinessModelCreate {
  businessModel: string;
  commercialModel: string;
  defaultCourierId: string;
  defaultFreightTerm: string;
  defaultPaymentMethod: string;
  deliverySLADays: string | number;
  deliverySLAIsWorkingDays: boolean;
  files: Array<ISupplierFormBusinessModelFile>;
  paymentTerms: Array<string>;
  returnPolicyDays: string | number;
  returnPolicyIsWorkingDays: boolean;
  returnPolicyNotes: string;
  shippingWarehouses: Array<string>;
  status: string;
  technologicalNotes: string;
  testingPeriodTargets: Array<ISupplierBusinessModelTargetSettingsCreate>;
  tier: string;
  useSupplierPhotos: boolean;
}

export interface ISupplierFormBusinessModelFile {
  fileId: string;
  fileSize: number;
  name: string;
  originalName: string;
  type: string;
}

export interface ISupplierFormUpdateBusinessModel {
  businessModel: string;
  commercialModel: string | null;
  defaultCourierId: string;
  defaultFreightTerm: string;
  defaultPaymentMethod: string;
  deliverySLADays: string | number;
  deliverySLAIsWorkingDays: boolean;
  files: Array<ISupplierFormBusinessModelFile>;
  filesToDelete: Array<string>;
  paymentTerms: Array<string>;
  profileId: string;
  returnPolicyDays: string | number;
  returnPolicyIsWorkingDays: boolean;
  returnPolicyNotes: string;
  shippingWarehouseIds: Array<string>;
  status: string;
  technologicalNotes: string;
  testingPeriodTargets: Array<ISupplierBusinessModelTargetSettingsUpdate>;
  tier: string;
  useSupplierPhotos: boolean;
}

export interface ISupplierFormDraftBusinessModel {
  businessModel?: string;
  businessModelType?: string;
  businessModelTypeId?: string;
  commercialModel?: string | null;
  commercialModelId?: string | null;
  courier?: string;
  courierId?: string;
  defaultCourierId?: string | null;
  defaultFreightTerm?: string;
  defaultFreightTermId?: string;
  defaultPaymentMethod: string;
  deliverySLADays: string;
  deliverySLAIsWorkingDays: boolean;
  files: Array<any>; // will fix this once file input is available
  filesToDelete?: any;
  paymentMethod?: string;
  paymentMethodId?: string;
  paymentTerms: Array<any>;
  profileId?: string;
  returnPolicyDays: string | number;
  returnPolicyIsWorkingDays: boolean;
  returnPolicyNotes: string;
  shippingWarehouseIds?: Array<any>;
  shippingWarehouses: Array<any>;
  shortCode?: string;
  status?: string;
  statusId?: string;
  technologicalNotes: string;
  testingPeriodTargets: Array<any>;
  tier: string;
  tierId?: string;
  useSupplierPhotos: boolean;
}
