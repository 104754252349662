import React, { FC, useCallback } from 'react';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { EWarehouseAction } from 'models/warehouse';
import EInboundRoute from 'navigation/models/EInboundRoute';
import OnHold from 'pages/WarehouseItem/OnHold/OnHold';
import WarehouseDetails from 'pages/WarehouseItem/WarehouseDetails/WarehouseDetails';

import { WarehouseItemProvider } from './contexts/WarehouseItemContext';
import { WarehouseItemDetailsProvider } from './contexts/WarehouseItemDetailsContext';

const WarehouseItem: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action = '' } = useParams<{ action: string }>();

  const handleClose = useCallback(
    () => navigate({ pathname: EInboundRoute.Warehouse, search: location.search }),
    [location.search, navigate]
  );

  const renderDetails = useCallback(() => {
    if (action === EWarehouseAction.ON_HOLD) {
      return <OnHold closeDetailModal={handleClose} />;
    } else {
      return (
        <WarehouseItemDetailsProvider>
          <WarehouseDetails />
        </WarehouseItemDetailsProvider>
      );
    }
  }, [action, handleClose]);

  return <WarehouseItemProvider>{renderDetails()}</WarehouseItemProvider>;
};

export default WarehouseItem;
