import { createStyles, makeStyles } from '@luxclusif/material';

const errorStyles = makeStyles(() => {
  return createStyles({
    container: {
      backgroundColor: 'white',
      borderRadius: '8px',
      height: 'calc(100% - 48px)',
      margin: '24px'
    }
  });
});

export default errorStyles;
