import { EItemExtraFieldsKey } from '@inbound/api';
import { useTranslation } from 'react-i18next';

import { LOCAL_STORAGE_USER_MEASUREMENT_UNIT } from 'models/warehouse';
import { measurementUnit } from 'pages/WarehouseList/constants';

const useGetUnit = () => {
  const { t } = useTranslation('warehouse');

  const getUnit = (measureKey: EItemExtraFieldsKey) => {
    if (
      measureKey === EItemExtraFieldsKey.Width ||
      measureKey === EItemExtraFieldsKey.Length ||
      measureKey === EItemExtraFieldsKey.Depth ||
      measureKey === EItemExtraFieldsKey.ShoulderDrop ||
      measureKey === EItemExtraFieldsKey.HandleDrop ||
      measureKey === EItemExtraFieldsKey.Height
    ) {
      const isMetric = localStorage.getItem(LOCAL_STORAGE_USER_MEASUREMENT_UNIT) === measurementUnit[1];

      return ` (${isMetric ? t('measureUnits.centimeters') : t('measureUnits.inches')})`;
    } else if (measureKey === EItemExtraFieldsKey.Carat) {
      return ` (${t('measureUnits.carat')})`;
    }

    return '';
  };

  return getUnit;
};

export default useGetUnit;
