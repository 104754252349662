import { Subject } from 'rxjs';

import axios from '../utils/axios';

class CouriersService {
  public static $couriersRowUpdated = new Subject<any>();

  public async getCouriers() {
    const url = `${process.env.REACT_APP_API_URL}operations/couriers`;

    return await axios.get(url).then(response => response.data);
  }
}

const couriersService = new CouriersService();

export default couriersService;
