import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GetReturnToSupplierReasonsQueryResponse } from '@inbound/api';
import { CircularProgress } from '@luxclusif/material';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormTabs from 'components/FormTabs/FormTabs';
import TabPanel from 'components/TabPanel/TabPanel';
import { EWarehouseName } from 'models/warehouse';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import {
  defaultGenericDamages,
  defaultHardwareDamages,
  defaultInteriorExteriorDamages,
  IQualityCheckFormInputs,
  qualityCheckInitialize
} from 'pages/WarehouseList/schemas/qualityCheckSchema';
import checkIsRegularFlow from 'pages/WarehouseList/utils/checkIsRegularFlow';
import { getAllDamagesCount, getSumOfDamagesCount } from 'pages/WarehouseList/utils/getCountHelper';
import warehouseService from 'services/Warehouse.service';
import BaseConfiguration from 'setup/BaseConfiguration';

import ComputeQCDecision from './components/ComputeQCDecision/ComputeQCDecision';
import ManualQCDecision from './components/ManualQCDecision/ManualQCDecision';
import QCSupplierReturn from './components/QCSupplierReturn';
import ReturnToSupplier from './components/ReturnToSupplier/ReturnToSupplier';
import UploadDamagePhotos from './components/UploadDamagePhotos/UploadDamagePhotos';
import Exterior from './sections/Exterior/Exterior';
import Hardware from './sections/Hardware/Hardware';
import Interior from './sections/Interior/Interior';

import qualityCheckStyles from './qualityCheck.styles';

const ENABLE_RETURN_TO_SUPPLIER_NEW = process.env.REACT_APP_ENABLE_RETURN_TO_SUPPLIER_NEW === 'true';

interface IProps {
  isCalculatedDecision: boolean;
  setIsCalculatedDecision: (value: boolean) => void;
}

const QualityCheck: React.FC<IProps> = ({ isCalculatedDecision, setIsCalculatedDecision }) => {
  const classes = qualityCheckStyles();
  const { t } = useTranslation('warehouse');
  const { isViewMode } = useWarehouseItemContext();
  const {
    itemDetails: { inboundFlow, itemProcessId, warehouse },
    warehouseForm
  } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IQualityCheckFormInputs>, [warehouseForm]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState<number>(0);
  const [supplierReturnReasons, setSupplierReturnReasons] = useState<GetReturnToSupplierReasonsQueryResponse[]>([]);

  const { reset, watch } = form;

  const showSupplierReturn = useMemo(
    (): boolean =>
      warehouse === EWarehouseName.Japan
        ? ENABLE_RETURN_TO_SUPPLIER_NEW
          ? form.getValues('hasPassedQualityCheck') === false
          : true
        : false,
    [form.watch('hasPassedQualityCheck'), warehouse]
  );

  useEffect(() => {
    if (ENABLE_RETURN_TO_SUPPLIER_NEW) {
      form.setValue('supplierReturnReason', undefined, { shouldDirty: true, shouldValidate: true });
    }
  }, [form.watch('hasPassedQualityCheck')]);

  const QUALITY_CONTROL_TABS_CONST = [
    {
      icon: 'Folder',
      label: t('qualityCheckStation.exterior'),
      value: 0
    },
    {
      icon: 'FolderOpen',
      label: t('qualityCheckStation.interior'),
      value: 1
    },
    {
      icon: 'FolderSpecial',
      label: t('qualityCheckStation.hardware'),
      value: 2
    }
  ];

  const handleChangeTab = (newValue: number) => {
    setTabValue(newValue);
  };

  const loadQualityCheckStation = useCallback(async () => {
    try {
      const [getQualityControlItem, getSupplierReturnReasons] = await Promise.allSettled([
        warehouseService.getQualityControlItem(itemProcessId as string),
        showSupplierReturn ? warehouseService.getSupplierReturnReasons() : []
      ]);

      if (showSupplierReturn && getSupplierReturnReasons.status === 'fulfilled') {
        setSupplierReturnReasons(getSupplierReturnReasons.value as GetReturnToSupplierReasonsQueryResponse[]);
      }

      if (getQualityControlItem.status === 'fulfilled') {
        setIsCalculatedDecision(getQualityControlItem.value.calculatedDecision);
        qualityCheckInitialize(getQualityControlItem.value, reset, {
          inboundFlow,
          itemId: getQualityControlItem.value.itemId,
          itemProcessId: itemProcessId as string
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [inboundFlow, itemProcessId, reset, setIsCalculatedDecision, showSupplierReturn]);

  useEffect(() => {
    if (itemProcessId) {
      loadQualityCheckStation();
    }
  }, [itemProcessId]);

  const exteriorCount = {
    back: getAllDamagesCount(watch('back', defaultInteriorExteriorDamages)),
    bottom: getAllDamagesCount(watch('bottom', defaultInteriorExteriorDamages)),
    corners: getAllDamagesCount(watch('corners', defaultInteriorExteriorDamages)),
    front: getAllDamagesCount(watch('front', defaultInteriorExteriorDamages)),
    handleOrStrap: getAllDamagesCount(watch('handleOrStrap', defaultInteriorExteriorDamages)),
    pocket: getAllDamagesCount(watch('pocket', defaultInteriorExteriorDamages)),
    side: getAllDamagesCount(watch('side', defaultInteriorExteriorDamages)),
    smell: getAllDamagesCount(watch('smell', defaultGenericDamages), false, true),
    top: getAllDamagesCount(watch('top', defaultInteriorExteriorDamages))
  };

  const interiorCount = {
    interiorPocket: getAllDamagesCount(watch('interiorPocket', defaultInteriorExteriorDamages)),
    lining: getAllDamagesCount(watch('lining', defaultInteriorExteriorDamages))
  };

  const hardwareCount = {
    closure: getAllDamagesCount(watch('closure', defaultHardwareDamages), true),
    embellishment: getAllDamagesCount(watch('embellishment', defaultHardwareDamages), true),
    key: getAllDamagesCount(watch('key', defaultHardwareDamages), true),
    practicalAttachment: getAllDamagesCount(watch('practicalAttachment', defaultHardwareDamages), true),
    zipper: getAllDamagesCount(watch('zipper', defaultHardwareDamages), true)
  };

  const damagesQuantity = [
    { quantity: getSumOfDamagesCount(exteriorCount, 'isChecked'), tabValue: 0 },
    { quantity: getSumOfDamagesCount(interiorCount, 'isChecked'), tabValue: 1 },
    { quantity: getSumOfDamagesCount(hardwareCount, 'isChecked'), tabValue: 2 }
  ];

  const isRegularFlow = checkIsRegularFlow(inboundFlow);

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <h1 className={classes.headerTitle}>{t('qualityControl')}</h1>
      <label className={classes.commentSupplierLabel}>{t('qualityCheckStation.commentsSupplier')}</label>
      <p className={classes.commentSupplierValue}>{watch('supplierComments')}</p>

      <hr className={classes.divider} />

      <h2 className={classes.title}>{t('qualityControl')}</h2>
      <h3 className={classes.subTitle}>{t('damageRecognition')}</h3>

      <FormTabs
        changeTab={handleChangeTab}
        value={tabValue}
        tabObjArray={QUALITY_CONTROL_TABS_CONST}
        full={true}
        fullWidth={true}
        quantityPerTab={damagesQuantity}
      />

      <TabPanel value={tabValue} index={0}>
        <Exterior form={form} count={exteriorCount} isRegularFlow={isRegularFlow} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Interior form={form} count={interiorCount} isRegularFlow={isRegularFlow} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Hardware form={form} count={hardwareCount} isRegularFlow={isRegularFlow} />
      </TabPanel>

      {isCalculatedDecision && <ComputeQCDecision form={form} />}

      {!isRegularFlow && !isViewMode && (
        <UploadDamagePhotos
          exteriorCount={exteriorCount}
          hardwareCount={hardwareCount}
          interiorCount={interiorCount}
          form={form}
        />
      )}

      {!isCalculatedDecision && !isViewMode && <ManualQCDecision form={form} />}

      {showSupplierReturn &&
        !isViewMode &&
        (ENABLE_RETURN_TO_SUPPLIER_NEW ? (
          <BaseConfiguration>
            <QCSupplierReturn />
          </BaseConfiguration>
        ) : (
          <ReturnToSupplier form={form} supplierReturnReasons={supplierReturnReasons} />
        ))}
    </div>
  );
};

export default QualityCheck;
