import { createStyles, makeStyles } from '@luxclusif/material';

const routerConfigStyles = makeStyles(() => {
  return createStyles({
    loading: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center'
    }
  });
});

export default routerConfigStyles;
