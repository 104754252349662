import React, { useMemo, useState } from 'react';

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@luxclusif/material';
import Delete from '@mui/icons-material/Delete';
import Download from '@mui/icons-material/Download';
import MoreVert from '@mui/icons-material/MoreVert';
import ZoomIn from '@mui/icons-material/ZoomIn';
import moment from 'moment';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ImageFrame from 'components/ImageFrame/ImageFrame';
import { EDnDPhotoAuth } from 'models/warehouse';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { IItemPhotoAuthenticationDetailsSchema } from 'pages/WarehouseList/schemas/photoAuthenticationSchema';
import downloadResource from 'utils/downloadResource';

import uploadedPhotosStyles from './uploadedPhotos.styles';

const ENABLE_RA_INTEGRATION_MODULE = process.env.REACT_APP_ENABLE_RA_INTEGRATION_MODULE === 'true';

interface IProps {
  onDelete: (photoId: string) => void;
  onZoomPhoto: (url?: string) => void;
}

const UploadedPhotos: React.FC<IProps> = ({ onDelete, onZoomPhoto }) => {
  const classes = uploadedPhotosStyles();
  const { t } = useTranslation('common');
  const { isViewMode } = useWarehouseItemContext();
  const {
    itemDetails: { sku },
    warehouseForm
  } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IItemPhotoAuthenticationDetailsSchema>, [warehouseForm]);

  const [anchorEl, setAnchorEl] = useState<Record<string, HTMLElement> | null>(null);

  const photos = useMemo(() => form.watch('uploadedPhotos', []), [form.watch('uploadedPhotos')]);

  return (
    <>
      {photos.map((image, index) => (
        <div key={`${image.url}_${index}`} className={classes.container}>
          <ImageFrame
            actions={[
              {
                icon: <ZoomIn />,
                onClick: () => onZoomPhoto(image.url)
              },
              isViewMode
                ? {
                    icon: <Download />,
                    onClick: () => downloadResource(image.url as string, sku)
                  }
                : {
                    icon: <MoreVert />,
                    onClick: (event: React.MouseEvent<HTMLButtonElement>) =>
                      setAnchorEl({ [image.fileId as string]: event.currentTarget })
                  }
            ]}
            canDrag={!form.getValues('hasAuthenticationComplete') && ENABLE_RA_INTEGRATION_MODULE}
            dragItemInfo={{
              fileId: image.fileId,
              photoFrameId: image.photoFrameId,
              uploadDate: image?.uploadDate || new Date(),
              url: image.url
            }}
            dragType={EDnDPhotoAuth.ForUpload}
            label={moment(image.uploadDate || '').format('MM/DD/YYYY')}
            src={image.url || ''}
          />
          {anchorEl?.[image.fileId as string] && (
            <Menu
              open
              anchorEl={anchorEl[image.fileId as string]}
              onClose={() => setAnchorEl(null)}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem className={classes.otherActionItem} onClick={() => onDelete(image.fileId as string)}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText>{t('delete')}</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.otherActionItem}
                onClick={() => {
                  downloadResource(image.url as string, sku);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <Download />
                </ListItemIcon>
                <ListItemText>{t('uploadImages.download')}</ListItemText>
              </MenuItem>
            </Menu>
          )}
        </div>
      ))}
    </>
  );
};

export default UploadedPhotos;
