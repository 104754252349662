import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import qs from 'qs';
import { Subject } from 'rxjs';

interface IErrorMessage {
  errorCode: number;
  message: string;
}

export interface IRequestError {
  errors: IErrorMessage[];
  status: number;
  title?: string;
}

export const $logOut = new Subject<void>();

export const $redirectToError = new Subject<StatusCodes>();

axios.interceptors.request.use(async request => {
  request.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });

  return request;
});

axios.interceptors.request.use(async request => {
  const userSecurityDataStorageKey = `oidc.user:${process.env.REACT_APP_STS_AUTHORITY}:${process.env.REACT_APP_STS_CLIENT_ID}`;

  const userSecurityData = sessionStorage.getItem(userSecurityDataStorageKey);

  if (userSecurityData) {
    const { access_token } = JSON.parse(userSecurityData);

    if (access_token) {
      request.headers.Authorization = `Bearer ${access_token}`;
    }
  }

  const language = localStorage.getItem('language');

  if (language) {
    request.headers['content-language'] = language;
  }

  return request;
});

axios.interceptors.response.use(
  response => {
    if (!response) {
      $redirectToError.next(StatusCodes.INTERNAL_SERVER_ERROR);
    }

    // we made the <html verification because of another edge case that came along in the meantime
    // there are requests that deliver strings that are not actually html
    if (typeof response.data === 'string' && response.data.includes('<html')) {
      $redirectToError.next(StatusCodes.INTERNAL_SERVER_ERROR);
    }

    // FIXME: Code commented because it was conflicting with getSellability Model when there was
    // no data persisted on DB
    //
    // if (response.config.method === 'get' && !response.data) {
    // 	$redirectToError.next(StatusCodes.INTERNAL_SERVER_ERROR);
    // }

    return Promise.resolve(response);
  },
  error => {
    const requestError: IRequestError = error.response?.data || error.response;

    const status = requestError ? requestError.status : StatusCodes.INTERNAL_SERVER_ERROR;

    switch (status) {
      case StatusCodes.UNAUTHORIZED:
        return $logOut.next();

      case StatusCodes.INTERNAL_SERVER_ERROR:
      case StatusCodes.FORBIDDEN:
      case StatusCodes.NOT_FOUND:
      case StatusCodes.SERVICE_UNAVAILABLE:
        return $redirectToError.next(status);

      default:
        return Promise.reject(requestError);
    }
  }
);

export default axios;
