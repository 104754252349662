import React from 'react';

import Button from '@lux-ds/button';
import Typography from '@lux-ds/typography';
import { CircularProgress } from '@luxclusif/material';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { StyledCloudUpload, StyledJay } from './imageUpload.styles';

interface IImageUploadProps {
  disabled?: boolean;
  error?: boolean;
  isUploading: boolean;
  onUpload?: DropzoneOptions['onDrop'];
}

// New ImageUpload reusable component using new design system
const ImageUpload: React.FC<IImageUploadProps> = ({ disabled, error = false, isUploading, onUpload }) => {
  const { t } = useTranslation('common');

  const { getInputProps, getRootProps, open } = useDropzone({
    accept: '.jpg, .jpeg, .png',
    disabled: disabled || isUploading,
    multiple: true,
    noClick: true,
    onDrop: onUpload
  });

  return (
    <StyledJay
      {...getRootProps({
        $hasError: error
      })}
    >
      {isUploading ? (
        <CircularProgress />
      ) : (
        <>
          <input {...getInputProps()} />
          <StyledCloudUpload />
          <Typography color={error ? 'error.main' : ''} textAlign="center" variant="bodyTextSmall">
            <b>{t('uploadImages.dropToUpload')}*</b>
            <br />
            {t('or')}
          </Typography>
          <Button disabled={disabled} onClick={open} variant="outlined">
            {t('browseFiles')}
          </Button>
        </>
      )}
    </StyledJay>
  );
};

export default ImageUpload;
