import React from 'react';

import { Box, FormControlLabel, Grid, Radio } from '@luxclusif/material';
import { UseFormMethods } from 'react-hook-form';

import Input from 'components/Input/Input/Input';
import LuxToggleButton from 'components/Input/LuxToggleButton/LuxToggleButton';
import LuxNumberInput from 'components/Input/NumberInput/LuxNumberInput';

import supplierDetailsStyles from '../../supplierDetails.styles';

interface IProps {
  businessModelForm: UseFormMethods<Record<string, any>>;
}

const ReturnPolicy: React.FC<IProps> = ({ businessModelForm }) => {
  const classes = supplierDetailsStyles();

  const { control, errors, setValue, watch } = businessModelForm;

  return (
    <div>
      <h1 className={classes.title}>Return Policy</h1>
      <Grid item md={12}>
        <Box display="flex" flexWrap="wrap" alignItems="flex-end">
          <Box p={1} className={classes.boxPadding}>
            <LuxNumberInput
              label="Number of Days"
              className={classes.input}
              fullWidth={true}
              name="numberDays"
              variant="outlined"
              control={control}
              errors={errors}
              inputProps={{
                className: classes.numberInputProps,
                min: '0',
                step: '1'
              }}
              watch={watch}
              setValue={setValue}
            />
          </Box>
          <Box p={1}>
            <LuxToggleButton name="numberDaysType" control={control} errors={errors}>
              <Grid container spacing={0}>
                <Grid item>
                  <FormControlLabel
                    className={
                      watch('numberDaysType') === 'Work' ? classes.daysButtonSelected : classes.daysButtonNotSelected
                    }
                    value="Work"
                    control={<Radio className={classes.radioDaysButton} />}
                    label="Work Days"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    className={
                      watch('numberDaysType') === 'Calendar'
                        ? classes.daysButtonSelected
                        : classes.daysButtonNotSelected
                    }
                    value="Calendar"
                    control={<Radio className={classes.radioDaysButton} />}
                    label="Calendar Days"
                  />
                </Grid>
              </Grid>
            </LuxToggleButton>
          </Box>
        </Box>

        <Grid container className={classes.paddingInput}>
          <Grid item md={12}>
            <Input
              name="internalComments"
              control={control}
              errors={errors}
              label="Notes"
              type="text"
              className={classes.input}
              variant="outlined"
              fullWidth={true}
              errorText="Required"
              rows={4}
            />
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.line} />
    </div>
  );
};

export default ReturnPolicy;
