import React, { useCallback, useMemo } from 'react';

import { EPhotoFrame } from '@inbound/api';
import { LinearProgress, Typography } from '@luxclusif/material';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EDnDPhotoAuth, IAuthUploadedPhoto, TPhotoAuthFrameLabelKeys, TPhotoFrameNameKeys } from 'models/warehouse';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { additionalPhotoFrame } from 'pages/WarehouseList/constants';
import { ICheckinInputs } from 'pages/WarehouseList/schemas/checkInSchema';
import camelize from 'utils/camelize';

import PhotoFrameInput, { IDeleteInfo } from './components/PhotoFrameInput/PhotoFrameInput';

import forAuthenticationStyles from './forAuthentication.styles';

interface IProps {
  setZoomPhoto: React.Dispatch<React.SetStateAction<string>>;
}

const ForAuthentication: React.FC<IProps> = ({ setZoomPhoto }) => {
  const classes = forAuthenticationStyles();
  const { t } = useTranslation('warehouse');
  const { warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<ICheckinInputs>, [warehouseForm]);

  const {
    formState: { errors }
  } = form;

  const photoAdditionalFrames = form.watch('photoAdditionalFrames', []);
  const photoAuthFrames = form.watch('photoAuthFrames', []);

  const filledFrameCount = [...photoAdditionalFrames, ...photoAuthFrames].filter(({ url }) => url).length;

  const totalDefault = { filledRequiredTotal: 0, requiredTotal: 0 };
  const { filledRequiredTotal, requiredTotal } = !form.getValues('hasAuthenticationComplete')
    ? photoAuthFrames.reduce(
        (finalValue, { isRequired, url }) =>
          isRequired
            ? {
                filledRequiredTotal: url ? finalValue.filledRequiredTotal + 1 : finalValue.filledRequiredTotal,
                requiredTotal: finalValue.requiredTotal + 1
              }
            : finalValue,
        totalDefault
      )
    : totalDefault;
  const progress = requiredTotal !== 0 ? Math.round((filledRequiredTotal / requiredTotal) * 100) : 100;

  const getFrameLabel = useCallback(
    (photoTypeName: TPhotoFrameNameKeys, suffix?: string) => {
      const photoFrameLabelKey = camelize(photoTypeName) as TPhotoAuthFrameLabelKeys;

      return `${t(`checkinStation.photoAuthFrames.${photoFrameLabelKey}`)}${suffix || ''}`;
    },
    [t]
  );

  const onDeletePhoto = ({ fileId, photoFrameId }: IDeleteInfo) => {
    const isAdditional = photoFrameId === EPhotoFrame.Additional;
    const photoFrameField = isAdditional ? 'photoAdditionalFrames' : 'photoAuthFrames';
    const newValues = form.getValues(photoFrameField);
    const frameIndex = newValues.findIndex(photo =>
      isAdditional ? photo.fileId === fileId : photo.photoFrameId === photoFrameId
    );

    if (isAdditional) {
      newValues.splice(frameIndex, 1);

      if (newValues.length === 0) {
        newValues.push(additionalPhotoFrame);
      }
    } else {
      newValues[frameIndex].fileId = '';
      newValues[frameIndex].uploadDate = undefined;
      newValues[frameIndex].url = '';
    }

    form.setValue(photoFrameField, newValues);
  };

  const onDrop = (draggedType: EDnDPhotoAuth | null, photoFrame: EPhotoFrame, item: IAuthUploadedPhoto) => {
    const isForAdditional = photoFrame === EPhotoFrame.Additional;
    const photoFrameField = isForAdditional ? 'photoAdditionalFrames' : 'photoAuthFrames';
    const currentFrames = form.getValues(photoFrameField);
    const newFrameIndex = currentFrames.findIndex(currentFrame =>
      isForAdditional ? !currentFrame.url : currentFrame.photoFrameId === photoFrame
    );

    if (newFrameIndex !== -1) {
      const newFrames = [...currentFrames];

      if (draggedType === EDnDPhotoAuth.ForUpload) {
        const newUploadedPhotos = form
          .getValues('uploadedPhotos')
          .filter(uploadedPhoto => uploadedPhoto.fileId !== item.fileId);

        newFrames[newFrameIndex] = {
          ...newFrames[newFrameIndex],
          ...item,
          photoFrameId: photoFrame
        };
        form.setValue('uploadedPhotos', newUploadedPhotos, { shouldDirty: true, shouldValidate: true });
      } else if (draggedType === EDnDPhotoAuth.ForAuthentication) {
        if (isForAdditional) {
          const photoAuthFrames = form.getValues('photoAuthFrames');
          const fromAdditionalFrameIndex = currentFrames.findIndex(({ fileId }) => fileId === item.fileId);
          const fromAuthFrameIndex = photoAuthFrames.findIndex(
            ({ photoFrameId }) => photoFrameId === item.photoFrameId
          );

          if (fromAuthFrameIndex !== -1 && fromAdditionalFrameIndex === -1) {
            newFrames[newFrameIndex].fileId = item.fileId;
            newFrames[newFrameIndex].uploadDate = item.uploadDate;
            newFrames[newFrameIndex].url = item.url;

            photoAuthFrames[fromAuthFrameIndex].fileId = '';
            photoAuthFrames[fromAuthFrameIndex].uploadDate = undefined;
            photoAuthFrames[fromAuthFrameIndex].url = '';

            form.setValue('photoAuthFrames', photoAuthFrames);
          } else {
            newFrames[fromAdditionalFrameIndex].fileId = '';
            newFrames[fromAdditionalFrameIndex].uploadDate = undefined;
            newFrames[fromAdditionalFrameIndex].url = '';

            newFrames[newFrameIndex].fileId = item.fileId;
            newFrames[newFrameIndex].uploadDate = item.uploadDate;
            newFrames[newFrameIndex].url = item.url;
          }
        } else {
          const photoAdditionalFrames = form.getValues('photoAdditionalFrames');
          const fromAuthFrameIndex = currentFrames.findIndex(
            currentFrames => currentFrames.photoFrameId === item.photoFrameId
          );
          const fromAdditionalFrameIndex = photoAdditionalFrames.findIndex(({ fileId }) => fileId === item.fileId);

          if (fromAuthFrameIndex !== -1 && fromAdditionalFrameIndex === -1) {
            newFrames[fromAuthFrameIndex] = {
              ...newFrames[fromAuthFrameIndex],
              fileId: '',
              uploadDate: undefined,
              url: ''
            };

            newFrames[newFrameIndex] = {
              ...newFrames[newFrameIndex],
              ...item,
              photoFrameId: photoFrame
            };
          } else {
            newFrames[newFrameIndex].fileId = item.fileId;
            newFrames[newFrameIndex].uploadDate = item.uploadDate;
            newFrames[newFrameIndex].url = item.url;

            photoAdditionalFrames.splice(fromAdditionalFrameIndex, 1);

            form.setValue('photoAdditionalFrames', photoAdditionalFrames);
          }
        }
      }

      if (isForAdditional && newFrames.every(({ url }) => url)) {
        newFrames.push({ ...additionalPhotoFrame });
      }

      form.setValue(photoFrameField, newFrames);

      if (errors?.photoAuthFrames?.length) {
        form.trigger('photoAuthFrames');
      }
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title} fontWeight="600">
        {t('checkinStation.forAuthentication')} ({filledFrameCount})
      </Typography>
      {requiredTotal !== 0 && (
        <div className={classes.progressContainer}>
          <LinearProgress className={classes.progress} variant="determinate" value={progress} />
          <Typography className={classes.progressLabel}>
            {progress}% {t('checkinStation.complete')}
          </Typography>
        </div>
      )}
      <div className={classes.imagesContainer}>
        {[...photoAuthFrames, ...photoAdditionalFrames].map(
          ({ fileId, photoFrameId, photoFrameName, ...restAuthFrame }, index) => (
            <PhotoFrameInput
              {...restAuthFrame}
              key={`${photoFrameName}_${fileId || index}`}
              error={photoFrameId !== EPhotoFrame.Additional && !!errors?.photoAuthFrames?.[index]}
              isAuthComplete={form.getValues('hasAuthenticationComplete')}
              onDelete={onDeletePhoto}
              onDrop={onDrop}
              fileId={fileId}
              photoFrameId={photoFrameId}
              setZoomPhoto={setZoomPhoto}
              label={getFrameLabel(
                photoFrameName,
                photoFrameId === EPhotoFrame.Additional ? ` ${Math.abs(photoAuthFrames.length - index - 1)}` : ''
              )}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ForAuthentication;
