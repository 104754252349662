import React, { createContext, useCallback, useEffect, useState } from 'react';

import { GridSortModel } from '@lux-ds/data-grid';
import { useNotification } from '@lux-ds/notification';
import { useTranslation } from 'react-i18next';

import { IStatusFilterOption } from 'models/common';
import { ESupplierStatus, ISupplier } from 'models/suppliers';
import suppliersService from 'services/Suppliers.service';

interface ISuppliersContext {
  filterStatus: IStatusFilterOption[];
  isLoading: boolean;
  page: number;
  pageSize: number;
  quickFilter: string;
  rowCount: number;
  search: string;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setQuickFilter: (event: React.SyntheticEvent<Element, Event>, quickFilter: string) => void;
  setSearch: (search: string) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  sortModel: GridSortModel;
  suppliers: ISupplier[];
}

const SuppliersContext = createContext<ISuppliersContext>({
  filterStatus: [],
  isLoading: true,
  page: 0,
  pageSize: 25,
  quickFilter: '',
  rowCount: 0,
  search: '',
  setPage: () => void 0,
  setPageSize: () => void 0,
  setQuickFilter: () => void 0,
  setSearch: () => void 0,
  setSortModel: () => void 0,
  sortModel: [],
  suppliers: []
});

const SuppliersProvider: React.FC = ({ children }) => {
  const { enqueueNotification } = useNotification();
  const { t } = useTranslation(['common', 'supplier']);

  const [isLoading, setIsLoading] = useState<ISuppliersContext['isLoading']>(true);
  const [page, setPage] = useState<ISuppliersContext['page']>(0);
  const [pageSize, setPageSize] = useState<ISuppliersContext['pageSize']>(25);
  const [quickFilter, setQuickFilter] = useState<ISuppliersContext['quickFilter']>(ESupplierStatus.Supplier);
  const [rowCount, setRowCount] = useState<ISuppliersContext['rowCount']>(0);
  const [search, setSearch] = useState<ISuppliersContext['search']>('');
  const [sortModel, setSortModel] = useState<ISuppliersContext['sortModel']>([]);
  const [suppliers, setSuppliers] = useState<ISuppliersContext['suppliers']>([]);

  const [filterStatus, setFilterStatus] = useState<ISuppliersContext['filterStatus']>([
    { count: 0, id: ESupplierStatus.Supplier, label: t('supplier:supplier') },
    { count: 0, id: ESupplierStatus.Active, label: t('supplier:active') },
    { count: 0, id: ESupplierStatus.Inactive, label: t('supplier:inactive') },
    { count: 0, id: ESupplierStatus.Draft, label: t('supplier:draft') }
  ]);

  const getSuppliers = useCallback(() => {
    setIsLoading(true);

    suppliersService
      .getAll()
      .then(data => {
        let suppliersResult = data.items;
        const pageIndex = page * pageSize;

        if (search && search.length >= 3) {
          const value = search.toLowerCase().trim();

          suppliersResult = suppliersResult.filter((item: any) =>
            Object.keys(item).some(column => item[column]?.toString().toLowerCase().includes(value))
          );
        }

        const statusCounts = filterStatus.map(item => {
          if (item.id === ESupplierStatus.Supplier) {
            return { ...item, count: suppliersResult.length };
          }

          const count = suppliersResult.filter((supplier: ISupplier) => supplier.status === item.id).length;

          return { ...item, count };
        });

        if (quickFilter !== ESupplierStatus.Supplier) {
          suppliersResult = suppliersResult.filter((supplier: ISupplier) => supplier.status === quickFilter);
        }

        if (sortModel.length) {
          const [{ field, sort }] = sortModel;

          suppliersResult = suppliersResult.sort((a: any, b: any) =>
            sort === 'asc' ? (a[field] < b[field] ? -1 : 1) : a[field] < b[field] ? 1 : -1
          );
        }

        setRowCount(suppliersResult.length);

        suppliersResult = suppliersResult.slice(pageIndex, pageIndex + pageSize);

        setFilterStatus(statusCounts);
        setSuppliers(suppliersResult);
      })
      .catch(() => enqueueNotification({ title: t('supplier:errorGetSuppliers') }, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [page, pageSize, quickFilter, search, sortModel]);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers, page, pageSize, quickFilter, search, sortModel]);

  const handleSetPage = useCallback((page: number) => setPage(page), []);

  const handleSetPageSize = useCallback((pageSize: number) => setPageSize(pageSize), []);

  const handleSetQuickFilter = useCallback(
    (_, quickFilter: string) => setQuickFilter(quickFilter as ESupplierStatus),
    []
  );

  const handleSetSearch = useCallback((search: string) => setSearch(search), []);

  const handleSetSortModel = useCallback((sortModel: GridSortModel) => setSortModel(sortModel), []);

  return (
    <SuppliersContext.Provider
      value={{
        filterStatus,
        isLoading,
        page,
        pageSize,
        quickFilter,
        rowCount,
        search,
        setPage: handleSetPage,
        setPageSize: handleSetPageSize,
        setQuickFilter: handleSetQuickFilter,
        setSearch: handleSetSearch,
        setSortModel: handleSetSortModel,
        sortModel,
        suppliers
      }}
    >
      {children}
    </SuppliersContext.Provider>
  );
};

const SupplierConsumer = SuppliersContext.Consumer;

export { SupplierConsumer, SuppliersProvider };

export default SuppliersContext;
