import React from 'react';

import { Button, CircularProgress, IconButton, MaterialIcons, Modal, Paper } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import exitModalStyles from './exitModal.styles';

interface IProps {
  isLoading?: boolean;
  isValid?: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSave: () => void;
}

const ExitModal: React.FC<IProps> = ({ isLoading = false, isValid = true, onClose, onDiscard, onSave }) => {
  const classes = exitModalStyles();
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} open>
      <Paper className={classes.modalContent}>
        <h2 className={classes.title}>{t('exitModal.unsavedChanges')}</h2>
        <IconButton className={classes.closeButton} disabled={isLoading} onClick={onClose}>
          <MaterialIcons.Close />
        </IconButton>
        <p className={classes.description}>{t(`exitModal.areYouSure`)}</p>
        <div className={classes.actions}>
          <Button className={classes.button} disabled={isLoading} onClick={onDiscard}>
            {isLoading ? <CircularProgress size={16} color="inherit" /> : t('discardChanges')}
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            disabled={!isValid || isLoading}
            onClick={onSave}
            variant="contained"
          >
            {isLoading ? <CircularProgress size={16} color="inherit" /> : t('saveChanges')}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default ExitModal;
