import React from 'react';

import { Box } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import colorChipsStyles from './colorChips.styles';

interface IProps {
  color: string;
  colorLabel: string;
  title?: string;
}

const ColorChips: React.FC<IProps> = ({ color, colorLabel, title }) => {
  const classes = colorChipsStyles();
  const { t } = useTranslation();

  return (
    <>
      {title && <h3 className={classes.chipTitle}>{title}</h3>}
      <Box className={classes.coloredChipsContainer}>
        <Box m={0.2}>
          <div className={classes.coloredChips} style={{ backgroundColor: color }} />
        </Box>
        <Box m={1}>{colorLabel || t('none')}</Box>
      </Box>
    </>
  );
};

export default ColorChips;
