import React from 'react';

import { Grid } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import ColorChips from 'components/ColorChips/ColorChips';
import { IColorType } from 'models/warehouse';
import { IOnHoldItemInfo } from 'models/warehouse/onHold';

import reviewDetailsStyles from '../../onHold.styles';

interface IProps {
  colors: IOnHoldItemInfo['colors'];
}

const Colors: React.FC<IProps> = ({ colors }) => {
  const classes = reviewDetailsStyles();
  const { t } = useTranslation('warehouse');

  return (
    <>
      <h1 className={classes.title}>{t('colors')}</h1>
      <Grid container spacing={3}>
        {colors.map((el: IColorType) => {
          return (
            <Grid className={classes.colorChip} item key={el.id}>
              <ColorChips
                color={el.englishName}
                colorLabel={el.name}
                title={el.isMainColor ? t('mainColor') : undefined}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Colors;
