import React, { useEffect, useState } from 'react';

import { Modal, Paper } from '@luxclusif/material';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { useNavigate } from 'react-router-dom';

import LuxLogoFull from 'assets/svg/luxclusif_full_logo.svg';
import EInboundRoute from 'navigation/models/EInboundRoute';
import { formatMeasures, IMeasure } from 'pages/WarehouseList/schemas/checkInSchema';
import geoService, { GetCountryResponse } from 'services/Geo.service';
import warehouseService from 'services/Warehouse.service';

import { DamageRecognition } from './common';
import DamageRecognitionList from './sections/DamageRecognitionList';
import ItemDetails from './sections/ItemDetails';

import printDamageCardModalStyles from './printDamageCardModal.styles';

interface IProps {
  damageRecognition: DamageRecognition;
  isOpen: boolean;
  itemDetails: Record<string, any>;
  showDamages: boolean;
  toggleModal: () => void;
}

const PrintDamageCardModal: React.FC<IProps> = ({
  damageRecognition,
  isOpen,
  itemDetails,
  showDamages,
  toggleModal
}) => {
  const classes = printDamageCardModalStyles();
  const navigate = useNavigate();
  const [countryIds, setCountryIds] = useState<GetCountryResponse>([]);

  const itemDetailsDefaultValue = {
    ...itemDetails,
    country: '',
    inclusions: [],
    measures: [] as IMeasure[],
    summaryPhotos: []
  };

  const [updatedItemDetails, setUpdatedItemDetails] = useState(itemDetailsDefaultValue);

  const onErrorResponse = (err: AxiosError) => {
    const status = err.response ? err.response.status : StatusCodes.INTERNAL_SERVER_ERROR;

    if (status === StatusCodes.UNAUTHORIZED) {
      navigate(EInboundRoute.Logout);
    }

    if (status === StatusCodes.NOT_FOUND) {
      toggleModal();
    }
  };

  const loadMoreItemInfo = () => {
    warehouseService
      .getByItemId('check-in', itemDetails.itemProcessId)
      // Todo: add a specific typing for getByItemId response from WarehouseService()
      .then((data: any) => {
        setUpdatedItemDetails({
          ...updatedItemDetails,
          country: countryIds.find(country => country.countryId === data.madeInCountryId)?.name || '',
          inclusions: data.inclusions || [],
          measures: formatMeasures(data.measures),
          summaryPhotos: data.photos || []
        });
      })
      .catch(onErrorResponse);
  };

  const getCountryIds = () => {
    geoService
      .getCountries()
      .then((data: GetCountryResponse) => {
        setCountryIds(data);
      })
      .catch(onErrorResponse);
  };

  useEffect(() => {
    getCountryIds();
  }, []);

  useEffect(() => {
    if (countryIds.length) {
      loadMoreItemInfo();
    }
  }, [countryIds]);

  return (
    <Modal open={isOpen} onClose={toggleModal} className={classes.modalOverlay}>
      <div className={classes.modalContainer}>
        <Paper className={classes.modalContent} sx={{ minHeight: showDamages ? '842px' : 'max-content' }}>
          <ItemDetails itemDetails={updatedItemDetails} />
          {showDamages && <DamageRecognitionList damages={damageRecognition} />}
          <div className={classes.contentFooter}>
            <img src={LuxLogoFull} />
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

export default PrintDamageCardModal;
