import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const onHoldStyles = makeStyles(({ palette }: Theme) => {
  return createStyles({
    // Parent Modal Stylings
    itemDetails: {
      flexGrow: 1,
      margin: '24px',
      width: '75%'
    },
    modalRoot: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100vw',
      height: '100vh',
      overflowY: 'auto',
      backgroundColor: secondaryNeutral.light85,
      '&:focus': {
        outline: 'none'
      }
    },
    itemContent: {
      width: '100%',
      padding: '24px'
    },
    line: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `1px solid ${secondaryNeutral.light75}`,
      margin: '0',
      padding: '0'
    },

    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '48px'
    },

    contentContainer: {
      display: 'flex'
    },

    // Item Description Sections Stylings
    title: {
      color: palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '18px',
      paddingBottom: '10px',
      paddingTop: '10px'
    },
    warehouseTextTitle: {
      color: secondaryNeutral.light40,
      fontFamily: 'Nunito Sans',
      fontSize: '12px',
      margin: '0 0 8px !important',
      letterSpacing: 0
    },
    warehouseTextDetails: {
      wordBreak: 'break-word',
      color: palette.secondary.main,
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: 0,
      fontWeight: 400,
      marginTop: '0 !important',
      marginBottom: '24px !important'
    },
    colorChip: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    }
  });
});

export default onHoldStyles;
