import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, LuxButton, Modal, Paper, Tab, Tabs, Tooltip } from '@luxclusif/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ExitModal from 'components/ExitModal/ExitModal';
import TabPanel from 'components/TabPanel/TabPanel';
import { EPurchaseOrderAllStatus, TGetAllCouriers, TGetAllCurrencies, TGetAllStatus } from 'models/purchaseOrders';
import EInboundRoute from 'navigation/models/EInboundRoute';
import purchaseOrdersService from 'services/PurchaseOrders.service';
import BaseConfiguration from 'setup/BaseConfiguration';
import { IRequestError } from 'utils/axios';

import CurrencyChangedModal from './components/CurrencyChangedModal/CurrencyChangedModal';
import Header from './components/Header/Header';
import OrderInformation from './components/OrderInformation/OrderInformation';
import OrderItems from './components/OrderItems/OrderItems';
import VLGMissingPhotoModal from './components/VLGMissingPhotoModal/VLGMissingPhotoModal';
import { defaultValues, IPurchaseInfo, muiDateFormat, purchaseOrdersSchema } from './schema';

import purchaseOrderStyles from './purchaseOrder.styles';

const PurchaseOrder: React.FC = () => {
  const form = useForm<IPurchaseInfo>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(purchaseOrdersSchema)
  });
  const { formState, handleSubmit, watch } = form;
  const { dirtyFields } = formState;
  const isDirty = Object.keys(dirtyFields).length;

  const businessModelId = watch('businessModelId');
  const currencyId = watch('currencyId');
  const orderItems = watch('items');
  const supplierId = watch('supplierId');

  const classes = purchaseOrderStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { poId = '' } = useParams<{ poId: string }>();
  const { t } = useTranslation(['common', 'purchaseOrders']);

  const [isExitModalOpen, setIsExitModalOpen] = useState<boolean>(false);
  const [isCurrencyChangedOpen, setIsCurrencyChangedOpen] = useState<boolean>(false);
  const [hasMissingOrInvalidPhotos, setHasMissingOrInvalidPhotos] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [currencyOptions, setCurrencyOptions] = useState<TGetAllCurrencies>([]);
  const [courierOptions, setCourierOptions] = useState<TGetAllCouriers>([]);
  const [statusOptions, setStatusOptions] = useState<TGetAllStatus>([]);
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true);
  const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
  const [formDefaultValue, setFormDefaultValue] = useState<IPurchaseInfo>(defaultValues);
  const [currentStatus, setCurrentStatus] = useState<EPurchaseOrderAllStatus | undefined>(undefined);

  const loadOptions = async () => {
    const [getAllCourierOptions, getAllCurrencyOptions, getAllStatusOptions] = await Promise.allSettled([
      purchaseOrdersService.getAllCourierOptions(),
      purchaseOrdersService.getAllCurrencyOptions(),
      purchaseOrdersService.getAllStatusOptions()
    ]);

    if (getAllCourierOptions.status === 'fulfilled') {
      setCourierOptions(getAllCourierOptions.value as TGetAllCouriers);
    } else {
      setCourierOptions([]);
    }

    if (getAllCurrencyOptions.status === 'fulfilled') {
      setCurrencyOptions(getAllCurrencyOptions.value);
    } else {
      setCurrencyOptions([]);
    }

    if (getAllStatusOptions.status === 'fulfilled') {
      setStatusOptions(getAllStatusOptions.value);
    } else {
      setStatusOptions([]);
    }

    setIsOptionsLoading(false);
  };

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    if (dirtyFields.supplierId) {
      form.setValue(
        'items',
        orderItems.filter(({ sku }) => !!sku)
      );
    }
  }, [supplierId]);

  useEffect(() => {
    if (currentStatus === EPurchaseOrderAllStatus.Draft && dirtyFields.currencyId) {
      setIsCurrencyChangedOpen(true);
    }
  }, [currencyId, currentStatus]);

  const onGetPurchaseInfo = async (purchaseId: string) => {
    setIsLoadingInfo(true);

    const {
      buyerId,
      datesDetail,
      files,
      financialDetail,
      items,
      notes,
      purchaseDetail,
      supplierDetail,
      trackingsDetail
    } = await purchaseOrdersService.getPurchaseOrderById(purchaseId);

    const newFormValues = {
      businessModelId: supplierDetail.businessModelId,
      buyerId: buyerId || defaultValues.buyerId,
      consignmentEndDate: moment(supplierDetail.consignmentEndDate).format(muiDateFormat),
      consignmentStartDate: moment(supplierDetail.consignmentStartDate).format(muiDateFormat),
      courierId: financialDetail.courierId,
      currencyId: financialDetail.currencyId,
      date: moment(datesDetail.purchaseDate).format(muiDateFormat),
      entityCompanyId: supplierDetail.entityCompanyId,
      estimatedDeliveryDate: moment(datesDetail.estimatedDeliveryDate).format(muiDateFormat),
      files: files.map(({ fileId, fileSize, fileUrl, name, originalName, type }) => ({
        fileId,
        fileSize,
        fileUrl,
        name,
        originalName,
        type
      })),
      filesToDelete: [],
      freightTermId: financialDetail.freightterms,
      items,
      notes: notes || defaultValues.notes,
      paymentMethodId: financialDetail.paymentMethodId,
      paymentTermId: financialDetail.paymentTermId,
      poNumber: purchaseDetail.number, // Read only
      receptionWarehouseId: financialDetail.warehouseId || defaultValues.receptionWarehouseId,
      splitFrom: purchaseDetail.splitFrom, // Read only
      statusId: purchaseDetail.statusId,
      supplierClusterId: supplierDetail.supplierClusterId,
      supplierId: supplierDetail.supplierId,
      supplierName: supplierDetail.name, // Read only
      title: purchaseDetail.title,
      trackingsDetail, // Read only
      ...(process.env.REACT_APP_ENABLE_PO_CURPRO === 'true'
        ? { curationType: purchaseDetail.curationType, procurementType: purchaseDetail.procurementType }
        : {})
    };

    setCurrentStatus(purchaseDetail.statusId);
    setFormDefaultValue(newFormValues);
    form.reset(newFormValues);
    form.trigger();

    setIsLoadingInfo(false);
  };

  useEffect(() => {
    if (poId) {
      onGetPurchaseInfo(poId);
    }
  }, []);

  const handleClose = () => {
    if (isDirty) {
      setIsExitModalOpen(true);
    } else {
      navigate(EInboundRoute.PurchaseOrders);
    }
  };

  const onSubmit = async (form: IPurchaseInfo) => {
    try {
      !poId
        ? await purchaseOrdersService.postPurchaseOrder(form)
        : await purchaseOrdersService.putPurchaseOrder(poId, form);

      navigate(EInboundRoute.PurchaseOrders);
    } catch (error) {
      const { errors, title } = error as IRequestError;

      if (title) {
        enqueueSnackbar(title, {
          variant: 'error'
        });
      } else {
        errors.forEach(({ message }) => {
          if (
            (['VLG item/s must have at least 3 photos.', 'VLG item/s must have atleast 3 photos.'].includes(message) ||
              message.includes('VLG item must have atleast 3 photos.')) &&
            activeTab === 0
          ) {
            setHasMissingOrInvalidPhotos(true);

            return;
          } else {
            enqueueSnackbar(message, {
              variant: 'error'
            });
          }
        });
      }
    }
  };

  const onError = (errors: FieldErrors<IPurchaseInfo>) => {
    const errorKeys = Object.keys(errors);

    if (errorKeys.length === 1 && errors.items) {
      setActiveTab(1);
      const itemsWithErrorSkus = errors.items
        .reduce((result: string[], item, index) => (item ? [...result, orderItems[index].sku as string] : result), [])
        .join(', ')
        .concat('.');

      enqueueSnackbar(`${t('purchaseOrders:poItems.trackingNumberError')} ${itemsWithErrorSkus}`, {
        autoHideDuration: 20000,
        variant: 'error'
      });
    } else {
      setActiveTab(0);
    }
  };

  const onDiscardChanges = () => {
    form.reset(formDefaultValue);
  };

  const handleExitSave = () => {
    setIsExitModalOpen(false);
    handleSubmit(onSubmit, onError)();
  };

  const onConfirmCurrencyChanged = () => {
    setIsCurrencyChangedOpen(false);
    setActiveTab(1);
  };

  const handleConfirmVlgBusinessModelUpdate = useCallback(() => {
    setHasMissingOrInvalidPhotos(false);
    setActiveTab(1);
  }, []);

  return (
    <>
      <Modal open>
        <Paper className={classes.container}>
          <FormProvider {...form}>
            <Header isLoading={isLoadingInfo} onClose={handleClose} statuses={statusOptions} />

            <div className={classes.tabFormContainer}>
              {isLoadingInfo ? (
                <div className={classes.loadingContainer}>
                  <CircularProgress size={60} />
                </div>
              ) : (
                <>
                  <div className={classes.tabFormHeader}>
                    <Tabs value={activeTab} onChange={(_, newTab) => setActiveTab(newTab)}>
                      <Tab label={t('purchaseOrders:orderInformation')} />
                      <Tab
                        className={classes.tabWithTooltip}
                        disabled={!supplierId || !businessModelId}
                        label={
                          <Tooltip title={!supplierId || !businessModelId ? t('purchaseOrders:orderItemsTooltip') : ''}>
                            <span>{t('purchaseOrders:orderItems')}</span>
                          </Tooltip>
                        }
                      />
                    </Tabs>
                  </div>

                  <TabPanel index={0} value={activeTab}>
                    <OrderInformation
                      couriers={courierOptions}
                      currencies={currencyOptions}
                      isLoadingOptions={isOptionsLoading}
                      status={currentStatus}
                      statuses={statusOptions}
                    />
                  </TabPanel>
                  <TabPanel index={1} className={classes.orderItemsTab} value={activeTab}>
                    <OrderItems
                      couriers={courierOptions}
                      currencies={currencyOptions}
                      isLoadingCouriers={isOptionsLoading}
                      status={currentStatus}
                    />
                  </TabPanel>

                  <div className={classes.formActionButtons}>
                    <LuxButton disabled={formState.isSubmitting || !isDirty} onClick={onDiscardChanges}>
                      {t('common:discardChanges')}
                    </LuxButton>
                    <LuxButton
                      disabled={formState.isSubmitting || !isDirty}
                      luxColor="secondary"
                      onClick={handleSubmit(onSubmit, onError)}
                      variant="contained"
                    >
                      {t('common:saveChanges')}
                    </LuxButton>
                  </div>
                </>
              )}
            </div>
          </FormProvider>
        </Paper>
      </Modal>
      {isExitModalOpen && (
        <ExitModal
          onClose={() => setIsExitModalOpen(false)}
          onDiscard={() => navigate(EInboundRoute.PurchaseOrders)}
          onSave={handleExitSave}
        />
      )}

      {isCurrencyChangedOpen && <CurrencyChangedModal onConfirm={onConfirmCurrencyChanged} />}
      {hasMissingOrInvalidPhotos ? (
        <BaseConfiguration>
          <VLGMissingPhotoModal
            onClose={() => setHasMissingOrInvalidPhotos(false)}
            onConfirm={handleConfirmVlgBusinessModelUpdate}
          />
        </BaseConfiguration>
      ) : null}
    </>
  );
};

export default PurchaseOrder;
