import React from 'react';

import { CssBaseline, LuxTheme, StyledEngineProvider, ThemeProvider } from '@luxclusif/material';

const OldBaseConfiguration: React.FC = ({ children }) => {
  const CustomLuxTheme = { ...LuxTheme };

  CustomLuxTheme.shadows[16] =
    '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)';

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={CustomLuxTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default OldBaseConfiguration;
