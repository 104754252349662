import { AuthPhotoGetModel, EItemExtraFieldsKey, InboundFlowGetModel2, ItemExtraFieldsGetModel } from '@inbound/api';
import { UseFormMethods } from 'react-hook-form';
import * as yup from 'yup';

import {
  EInclusion,
  IAuthUploadedPhoto,
  IColorType,
  IInclusion,
  TMeasurementsKeys,
  TPhotoFrameNameKeys
} from 'models/warehouse';
import camelize from 'utils/camelize';

import checkIsRegularFlow from '../utils/checkIsRegularFlow';
import { convertToMeasurementUnit } from '../utils/measurementConversion';
import { getInclusion } from './newCheckInSchema';

const ENABLE_DYNAMIC_MEASURES = process.env.REACT_APP_ENABLE_DYNAMIC_MEASURES === 'true';

interface IOtherCheckinInputs {
  inboundFlow?: Readonly<InboundFlowGetModel2>;
  itemId: Readonly<string>;
  itemProcessId: Readonly<string>;
  photoAdditionalFrames: IPhotoAuthFrame[];
  photoAuthFrames: IPhotoAuthFrame[];
  uploadedPhotos: IAuthUploadedPhoto[];
}

export interface IPhotoAuthFrame extends IAuthUploadedPhoto {
  isRequired: boolean;
  photoFrameName: TPhotoFrameNameKeys;
}

export interface IGalleryPhoto {
  imgPath: string;
}

export interface IMeasure {
  isRequired: boolean;
  key: EItemExtraFieldsKey;
  labelKey: TMeasurementsKeys;
  value: number;
}

export interface ICheckinInputs extends IOtherCheckinInputs {
  authenticityCard: boolean;
  box: boolean;
  carat: number;
  charm: boolean;
  color: Readonly<IColorType>;
  country: string | undefined;
  description: Readonly<string>;
  dustBag: boolean;
  hasAuthenticationComplete: boolean;
  hasSecurityTag: boolean | undefined;
  hasSerialNumber: boolean;
  initialInclusions: Readonly<IInclusion[]>;
  key: boolean;
  material: Readonly<string>;
  measures: IMeasure[];
  mirror: boolean;
  nameTag: boolean;
  none: boolean;
  padlock: boolean;
  photos: IGalleryPhoto[];
  pouch: boolean;
  securityTagRequired: boolean;
  serialNumber: string | undefined;
  shoulderStrap: boolean;
  size: Readonly<string>;
  warehouse: Readonly<string>;
}

export const countryDefaultValue = 'none';

const oldMeasuresDefault: ItemExtraFieldsGetModel[] = [
  { extraFieldKey: EItemExtraFieldsKey.Width, extraFieldValue: '0.00', order: 1, required: false },
  { extraFieldKey: EItemExtraFieldsKey.Length, extraFieldValue: '0.00', order: 2, required: false },
  { extraFieldKey: EItemExtraFieldsKey.Depth, extraFieldValue: '0.00', order: 3, required: false },
  { extraFieldKey: EItemExtraFieldsKey.ShoulderDrop, extraFieldValue: '0.00', order: 4, required: false },
  { extraFieldKey: EItemExtraFieldsKey.HandleDrop, extraFieldValue: '0.00', order: 5, required: false }
];

const inclusionSchema = yup.boolean().when('none', {
  is: false,
  then: yup.boolean().required()
});

const photoFramesSchema = yup.array().when('hasAuthenticationComplete', {
  is: false,
  then: yup.array().of(
    yup.object().shape({
      isRequired: yup.boolean().required(),
      photoFrameId: yup.string().required(),
      photoFrameName: yup.string().required(),
      url: yup.string().when('isRequired', { is: true, then: yup.string().required() })
    })
  )
});

export const checkInSchema = yup.object().shape({
  authenticityCard: inclusionSchema,
  box: inclusionSchema,
  carat: yup.number().notRequired(),
  charm: inclusionSchema,
  country: yup.string().nullable().notRequired(),
  dustBag: inclusionSchema,
  hasAuthenticationComplete: yup.boolean().notRequired(),
  hasSecurityTag: yup.boolean().nullable().when('securityTagRequired', {
    is: true,
    then: yup.boolean().required()
  }),
  hasSerialNumber: yup.boolean().required(),
  key: inclusionSchema,
  measures: yup.array().of(
    yup.object().shape({
      isRequired: yup.boolean().required(),
      key: yup.number().required(),
      labelKey: yup.string().required(),
      value: yup.number().when('isRequired', {
        is: true,
        then: yup.number().positive().required({ message: 'warehouse:checkinStation.measureMessage' })
      })
    })
  ),
  mirror: inclusionSchema,
  nameTag: inclusionSchema,
  none: yup.boolean().notRequired(),
  padlock: inclusionSchema,
  photoAdditionalFrames: photoFramesSchema,
  photoAuthFrames: photoFramesSchema,
  photos: yup.array().required(), // will be removed once photo auth has been revamped
  pouch: inclusionSchema,
  securityTagRequired: yup.boolean().required(),
  serialNumber: yup
    .string()
    .nullable()
    .when(['hasSerialNumber', '$inboundFlow'], {
      is: (hasSerialNumber: boolean, inboundFlow: InboundFlowGetModel2) =>
        hasSerialNumber && checkIsRegularFlow(inboundFlow),
      then: yup.string().required()
    }),
  shoulderStrap: inclusionSchema,
  uploadedPhotos: yup.array().of(
    yup.object().shape({
      photoFrameId: yup.string(),
      uploadDate: yup.date(),
      url: yup.string()
    })
  )
});

export const defaultCheckInValues: ICheckinInputs = {
  authenticityCard: false,
  box: false,
  carat: 0,
  charm: false,
  color: {
    englishName: '',
    id: '',
    isMainColor: false,
    name: ''
  },
  country: countryDefaultValue,
  description: '',
  dustBag: false,
  hasAuthenticationComplete: false,
  hasSecurityTag: undefined,
  hasSerialNumber: false,
  inboundFlow: undefined,
  initialInclusions: [],
  itemId: '',
  itemProcessId: '',
  key: false,
  material: '',
  measures: [],
  mirror: false,
  nameTag: false,
  none: false,
  padlock: false,
  photoAdditionalFrames: [],
  photoAuthFrames: [],
  photos: [],
  pouch: false,
  securityTagRequired: false,
  serialNumber: '',
  shoulderStrap: false,
  size: '',
  uploadedPhotos: [],
  warehouse: ''
};

// TODO: Update implementation and cleanup
export const checkInInitialize = (
  items: any,
  reset: UseFormMethods<ICheckinInputs>['reset'],
  otherDefaults: IOtherCheckinInputs
) => {
  let defaultInclusions: IInclusion[] = [...items.inclusions];
  const noNoneInclusion = defaultInclusions.findIndex(inclusion => inclusion.id === EInclusion.None) === -1;

  if (noNoneInclusion) {
    defaultInclusions = [
      {
        id: EInclusion.None,
        isMissing: false
      },
      ...defaultInclusions
    ];
  }

  const measureValues = ENABLE_DYNAMIC_MEASURES
    ? items.measures
    : oldMeasuresDefault.map(({ extraFieldKey, ...restMeasures }) => {
        const { extraFieldValue: defaultValue } = items.measures.find(
          (measure: ItemExtraFieldsGetModel) => measure.extraFieldKey === extraFieldKey
        ) || { extraFieldValue: '0.00' };

        return { ...restMeasures, extraFieldKey, extraFieldValue: defaultValue };
      });

  const formItem = {
    ...otherDefaults,
    authenticityCard: getInclusion(EInclusion.AuthenticityCard, items.inclusions, otherDefaults.inboundFlow),
    box: getInclusion(EInclusion.Box, items.inclusions, otherDefaults.inboundFlow),
    carat: Number(items.carat),
    charm: getInclusion(EInclusion.Charm, items.inclusions, otherDefaults.inboundFlow),
    color: items.colors[0] || defaultCheckInValues.color,
    country: !items.madeInCountryId ? countryDefaultValue : items.madeInCountryId,
    description: items.description,
    dustBag: getInclusion(EInclusion.DustBag, items.inclusions, otherDefaults.inboundFlow),
    hasAuthenticationComplete: items.hasAuthenticationComplete,
    hasSecurityTag: items.hasSecurityTag,
    hasSerialNumber: items.serialNumber === undefined ? false : true,
    initialInclusions: defaultInclusions,
    key: getInclusion(EInclusion.Key, items.inclusions, otherDefaults.inboundFlow),
    material: items.materials[0]?.name || defaultCheckInValues.material,
    measures: formatMeasures(measureValues),
    mirror: getInclusion(EInclusion.Mirror, items.inclusions, otherDefaults.inboundFlow),
    nameTag: getInclusion(EInclusion.NameTag, items.inclusions, otherDefaults.inboundFlow),
    none: items.inclusions.length === 0 || getInclusion(EInclusion.None, items.inclusions, otherDefaults.inboundFlow),
    padlock: getInclusion(EInclusion.Padlock, items.inclusions, otherDefaults.inboundFlow),
    photos: items.photos.map((photo: AuthPhotoGetModel) => ({ imgPath: photo.url })) || [],
    pouch: getInclusion(EInclusion.Pouch, items.inclusions, otherDefaults.inboundFlow),
    securityTagRequired: items.securityTagRequired,
    serialNumber: items.serialNumber,
    shoulderStrap: getInclusion(EInclusion.ShoulderStrap, items.inclusions, otherDefaults.inboundFlow),
    size: items.size,
    warehouse: items.warehouse
  };

  reset(formItem, { isDirty: false });
};

export const formatMeasures = (measures: ItemExtraFieldsGetModel[]): IMeasure[] =>
  measures
    .sort((a, b) => ((a?.order || 0) > (b?.order || 0) ? 1 : -1))
    .map(({ extraFieldKey = '', extraFieldValue = '', required = false }) => {
      const [extraFieldKeyPascal] = Object.entries(EItemExtraFieldsKey).find(
        ([, fieldEnumValue]) => fieldEnumValue === extraFieldKey
      ) || [''];

      return {
        isRequired: required,
        key: extraFieldKey as EItemExtraFieldsKey,
        labelKey: camelize(extraFieldKeyPascal) as TMeasurementsKeys,
        value: convertToMeasurementUnit(Number(extraFieldValue || 0))
      };
    });
