import {
  AuthenticityPhotoConfigurationGetModel,
  AuthPhotoGetModel,
  EPhotoFrame,
  GetPhotoAuthenticationQueryResponse,
  UpdatePhotoAuthenticationRequest
} from '@inbound/api';
import * as yup from 'yup';

import { additionalPhotoFrame } from '../constants';
import { IPhotoAuthFrame } from './checkInSchema';

export interface IItemPhotoAuthenticationDetailsSchema
  extends Pick<GetPhotoAuthenticationQueryResponse, 'hasAuthenticationComplete'> {
  photoAdditionalFrames: IPhotoAuthFrame[];
  photoAuthFrames: IPhotoAuthFrame[];
  uploadedPhotos: AuthPhotoGetModel[];
}

const photoFramesSchema = yup.array().when('hasAuthenticationComplete', {
  is: false,
  then: yup.array().of(
    yup.object().shape({
      isRequired: yup.boolean().required(),
      photoFrameId: yup.string().required(),
      photoFrameName: yup.string().required(),
      url: yup.string().when('isRequired', { is: true, then: yup.string().required() })
    })
  )
});

export const photoAuthenticationSchema = yup.object().shape({
  photoAdditionalFrames: photoFramesSchema,
  photoFramesSchema: photoFramesSchema,
  uploadedPhotos: yup.array().of(
    yup.object().shape({
      photoFrameId: yup.string(),
      uploadDate: yup.date(),
      url: yup.string()
    })
  )
});

export const getDefaultItemPhotoAuthenticationValues = (
  values: GetPhotoAuthenticationQueryResponse,
  authConfig: AuthenticityPhotoConfigurationGetModel[]
): IItemPhotoAuthenticationDetailsSchema => {
  const defaultPhotoValues = {
    photoAdditionalFrames: [additionalPhotoFrame],
    photoAuthFrames: (authConfig as IPhotoAuthFrame[]).sort(a => (a?.isRequired ? -1 : 1)),
    uploadedPhotos: []
  };

  const { photoAdditionalFrames, photoAuthFrames, uploadedPhotos } =
    values.photos?.reduce(
      (
        {
          photoAdditionalFrames: newPhotoAdditionalFrames,
          photoAuthFrames: newPhotoAuthFrames,
          uploadedPhotos: prevUploadedPhotos
        }: {
          photoAdditionalFrames: Partial<IPhotoAuthFrame>[];
          photoAuthFrames: Partial<IPhotoAuthFrame>[];
          uploadedPhotos: AuthPhotoGetModel[];
        },
        uploadedPhoto: AuthPhotoGetModel
      ) => {
        const hasNoFrame = uploadedPhoto.photoFrameId === EPhotoFrame.Undefined;

        if (!hasNoFrame) {
          if (uploadedPhoto.photoFrameId === EPhotoFrame.Additional) {
            const newAdditionalFrame = {
              ...additionalPhotoFrame,
              fileId: uploadedPhoto.fileId,
              uploadDate: uploadedPhoto.uploadDate,
              url: uploadedPhoto.url
            };

            newPhotoAdditionalFrames.splice(newPhotoAdditionalFrames.length - 1, 0, newAdditionalFrame);
          } else {
            const [photoFrameNameEnum] = Object.entries(EPhotoFrame).find(
              ([, enumFrameValue]) => uploadedPhoto.photoFrameId === enumFrameValue
            ) || [''];

            const photoAuthFrameIndex = newPhotoAuthFrames.findIndex(
              ({ photoFrameName }) => photoFrameNameEnum === photoFrameName
            );

            if (photoAuthFrameIndex !== -1) {
              newPhotoAuthFrames[photoAuthFrameIndex].fileId = uploadedPhoto.fileId;
              newPhotoAuthFrames[photoAuthFrameIndex].photoFrameId = uploadedPhoto.photoFrameId;
              newPhotoAuthFrames[photoAuthFrameIndex].uploadDate = uploadedPhoto.uploadDate;
              newPhotoAuthFrames[photoAuthFrameIndex].url = uploadedPhoto.url;
            }
          }
        }

        return {
          photoAdditionalFrames: newPhotoAdditionalFrames,
          photoAuthFrames: newPhotoAuthFrames,
          uploadedPhotos: hasNoFrame ? [...prevUploadedPhotos, uploadedPhoto] : prevUploadedPhotos
        };
      },
      defaultPhotoValues
    ) || defaultPhotoValues;

  return {
    hasAuthenticationComplete: values.hasAuthenticationComplete,
    photoAdditionalFrames: photoAdditionalFrames as IPhotoAuthFrame[],
    photoAuthFrames: photoAuthFrames as IPhotoAuthFrame[],
    uploadedPhotos
  };
};

export const formatItemPhotoAuthenticationValues = (
  values: IItemPhotoAuthenticationDetailsSchema,
  initialStationDateTimeUtc: Date,
  isSaveAndGoNextStation?: boolean
): UpdatePhotoAuthenticationRequest => ({
  finalStationDateTimeUtc: new Date(),
  initialStationDateTimeUtc,
  isSaveAndGoNextStation,
  photos: [...values.uploadedPhotos, ...values.photoAuthFrames, ...values.photoAdditionalFrames]
    .filter(({ url }) => url)
    .map(photo => ({
      fileId: photo.fileId?.includes('new_') ? undefined : photo.fileId,
      photoFrameId: photo.photoFrameId,
      photoUrl: photo.url
    }))
});
