import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const colorChipsStyles = makeStyles(() => {
  return createStyles({
    chipTitle: {
      color: secondaryNeutral.light40,
      fontFamily: 'Nunito Sans',
      fontSize: '12px',
      margin: '0 0 8px !important',
      letterSpacing: 0
    },
    coloredChipsContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      border: `1px solid ${secondaryNeutral.light75}`,
      borderRadius: '18px'
    },
    coloredChips: {
      width: '30px',

      color: 'white',
      borderRadius: '50%',
      height: '30px'
    }
  });
});

export default colorChipsStyles;
