import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const welcomeStyles = makeStyles((theme: Theme) => {
  return createStyles({
    welcomeContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      margin: '24px',
      minHeight: 'calc(100% - 48px)',
      position: 'relative'
    },
    header: {
      borderRadius: '5px 5px 0 0',
      height: '150px',
      objectFit: 'cover',
      position: 'absolute',
      width: '100%'
    },
    userThumbnailContainer: {
      borderRadius: '50%',
      height: '128px',
      marginTop: '102px',
      overflow: 'hidden',
      position: 'relative',
      width: '128px'
    },
    userThumbnailPlaceholder: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      height: '100%',
      padding: '16px',
      width: '100%'
    },
    welcomeMessage: {
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '48px',
      margin: '16px 0'
    },
    emailInformation: {
      alignItems: 'center',
      display: 'flex'
    },
    emailIcon: {
      height: '24px',
      width: '24px'
    },
    email: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
      marginLeft: '8px'
    },
    cardsContainer: {
      alignSelf: 'stretch',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 512px)',
      gap: '32px',
      justifyContent: 'center',
      margin: '48px 32px 32px',
      overflow: 'auto'
    }
  });
});

export default welcomeStyles;
