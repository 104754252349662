import { createStyles, makeStyles } from '@luxclusif/material';

const uploadedPhotosStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative'
    },
    otherActionItem: {
      gap: '9px',
      minWidth: '173px',
      '& .MuiListItemIcon-root': {
        minWidth: '26px'
      },
      '& .MuiListItemText-primary': {
        fontSize: '14px'
      }
    }
  })
);

export default uploadedPhotosStyles;
