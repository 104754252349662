import React from 'react';

import { Grid } from '@luxclusif/material';
import { UseFormMethods } from 'react-hook-form';

import Input from 'components/Input/Input/Input';

import supplierDetailsStyles from '../../supplierDetails.styles';

interface IProps {
  businessModelForm: UseFormMethods<Record<string, any>>;
}

const TechNotes: React.FC<IProps> = ({ businessModelForm }) => {
  const classes = supplierDetailsStyles();

  const { control, errors } = businessModelForm;

  return (
    <div>
      <h1 className={classes.title}>Tech Notes</h1>
      <Grid item md={12}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Input
              name="notes"
              control={control}
              errors={errors}
              label="Notes"
              type="text"
              className={classes.input}
              variant="outlined"
              fullWidth={true}
              errorText="Required"
              rows={4}
            />
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.line} />
    </div>
  );
};

export default TechNotes;
