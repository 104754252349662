import React, { useMemo, useState } from 'react';

import { EPhotoFrame, EPhotoType2 } from '@inbound/api';
import { useSnackbar } from 'notistack';
import { useDrop } from 'react-dnd';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EDnDPhotoAuth, IAuthUploadedPhoto } from 'models/warehouse';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { IPhotoAuthFrame } from 'pages/WarehouseList/schemas/checkInSchema';
import { IItemPhotoAuthenticationDetailsSchema } from 'pages/WarehouseList/schemas/photoAuthenticationSchema';
import warehouseService from 'services/Warehouse.service';
import chunk from 'utils/chunk';
import uuidGenerator from 'utils/uuidGenerator';

import UploadedPhotos from './components/UploadedPhotos/UploadedPhotos';
import UploadImages from './components/UploadImages/UploadImages';

import photoUploadStyles from './photoUpload.styles';

const ENABLE_RA_INTEGRATION_MODULE = process.env.REACT_APP_ENABLE_RA_INTEGRATION_MODULE === 'true';

interface IPhotoUploadProps {
  handleZoomPhoto: (url?: string) => void;
}

const PhotoUpload: React.FC<IPhotoUploadProps> = ({ handleZoomPhoto }) => {
  const classes = photoUploadStyles();
  const { t } = useTranslation(['common', 'warehouse']);

  const {
    itemDetails: { itemProcessId },
    warehouseForm
  } = useWarehouseItemDetailsContext();
  const { isViewMode } = useWarehouseItemContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IItemPhotoAuthenticationDetailsSchema>, [warehouseForm]);

  const { enqueueSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: EDnDPhotoAuth.ForAuthentication,
    collect: monitor => ({
      isOver: monitor.isOver()
    }),
    drop: (item: IPhotoAuthFrame) => {
      const isFromAdditional = item.photoFrameId === EPhotoFrame.Additional;
      const photoFrameField = isFromAdditional ? 'photoAdditionalFrames' : 'photoAuthFrames';
      const currentUploadedPhotos = form.getValues('uploadedPhotos');
      const newPhotoFrames = form.getValues(photoFrameField);
      const photoFrameIndex = newPhotoFrames.findIndex(photoAuthFrame =>
        isFromAdditional ? photoAuthFrame.fileId === item.fileId : photoAuthFrame.photoFrameId === item.photoFrameId
      );

      if (photoFrameIndex !== -1) {
        if (isFromAdditional && newPhotoFrames.length !== 2) {
          newPhotoFrames.splice(photoFrameIndex, 1);
        } else {
          newPhotoFrames[photoFrameIndex].url = '';
          newPhotoFrames[photoFrameIndex].fileId = '';
          newPhotoFrames[photoFrameIndex].uploadDate = undefined;
        }

        form.setValue(photoFrameField, newPhotoFrames);
        form.setValue('uploadedPhotos', [...currentUploadedPhotos, { ...item, photoFrameId: EPhotoFrame.Undefined }]);
      }
    }
  }));

  const onDeletePhoto = (photoId: string) => {
    const newValues = form.getValues('uploadedPhotos').filter(photo => photo.fileId !== photoId);

    form.setValue('uploadedPhotos', newValues);
  };

  const onUploadImages = async (files: File[]) => {
    try {
      setIsUploading(true);

      let newValues: IAuthUploadedPhoto[] = [];
      const currentValues = form.getValues('uploadedPhotos');

      const filesUploadChunks = chunk(
        files.map(file => warehouseService.uploadStationPhoto(itemProcessId as string, EPhotoType2.Authenticity, file)),
        4
      );

      for (let index = 0; index < filesUploadChunks.length; index++) {
        const fileChunks = filesUploadChunks[index];
        const photoUrls = await Promise.all(fileChunks);

        const formattedValues = photoUrls.map(photoUrl => ({
          fileId: `new_${uuidGenerator()}`,
          photoFrameId: EPhotoFrame.Undefined,
          uploadDate: new Date(),
          url: photoUrl
        }));

        newValues = [...newValues, ...formattedValues];
      }

      form.setValue('uploadedPhotos', [...currentValues, ...newValues]);
    } catch (err) {
      enqueueSnackbar(t('common:uploadImages.uploadFail'), {
        variant: 'error'
      });
    } finally {
      setIsUploading(false);
    }
  };

  const photos = useMemo(() => form.watch('uploadedPhotos', []), [form.watch('uploadedPhotos')]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{`${t('warehouse:checkinStation.photoUpload')} (${photos.length})`}</h1>
      {ENABLE_RA_INTEGRATION_MODULE && !form.getValues('hasAuthenticationComplete') && (
        <p className={classes.instructions}>{t('warehouse:checkinStation.photoAuthInstructions')}</p>
      )}
      <div ref={dropRef} className={classes.uploadSection}>
        <UploadedPhotos onDelete={onDeletePhoto} onZoomPhoto={handleZoomPhoto} />
        {isOver && <div className={classes.uploadedPlaceholder} />}
        {!isViewMode && <UploadImages isUploading={isUploading} onUpload={onUploadImages} />}
      </div>
    </div>
  );
};

export default PhotoUpload;
