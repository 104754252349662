import * as yup from 'yup';

import { IFileUpload } from 'components/Input/UploadFiles/UploadFiles';

export interface IItemNotOkForm {
  files: {
    files: IFileUpload[];
    filesToDelete: string[];
  };
  reason: string;
}

export const SupplierReturnAndBboxSchema = yup.object().shape({
  files: yup
    .object()
    .shape({
      files: yup.array().of(yup.mixed().notRequired()).notRequired(),
      filesToDelete: yup.array().of(yup.string().notRequired()).notRequired()
    })
    .notRequired(),
  reason: yup.string().trim().required('common:required')
});

export const defaultSupplierReturnAndBboxSchemaValues = (): IItemNotOkForm => {
  return {
    files: {
      files: [],
      filesToDelete: []
    },
    reason: ''
  };
};

export const SupplierReturnAndBboxSchemaInitialize = (
  items: any,
  reset: (
    values?: any,
    omitResetState?:
      | Partial<{
          dirtyFields: boolean;
          errors: boolean;
          isDirty: boolean;
          isSubmitted: boolean;
          isValid: boolean;
          submitCount: boolean;
          touched: boolean;
        }>
      | undefined
  ) => void
) => {
  const formItem: IItemNotOkForm = {
    files: {
      files: items.files ? items.files : [],
      filesToDelete: []
    },
    reason: ''
  };

  reset(formItem);
};
