import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { PhotoGetModel2, ProductsPhotosGetModel } from '@inbound/api';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Dialog from '@lux-ds/dialog';
import IconButton from '@lux-ds/icon-button';
import Image from '@lux-ds/image';
import { useNotification } from '@lux-ds/notification';
import Typography from '@lux-ds/typography';
import { CircularProgress } from '@luxclusif/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import ImageSlider from 'components/ImageSlider/ImageSlider';
import useApi from 'hooks/useApi';

import {
  StyledHeader,
  StyledJay,
  StyledThumbnailItem,
  StyledThumbnailContainer,
  StyledSliderContainer,
  StyledLoaderContainer
} from './galleryModal.styles';

interface IItemPhoto extends ProductsPhotosGetModel {
  label: string;
}

interface IGalleryModalProps {
  handleClose: () => void;
  itemId: string;
  name: string;
}

const GalleryModal: React.FC<IGalleryModalProps> = ({ handleClose, itemId, name }) => {
  const api = useApi();
  const { enqueueNotification } = useNotification();
  const { t } = useTranslation('warehouse');

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [photos, setPhotos] = useState<IItemPhoto[]>([]);
  const [selectedType, setSelectedType] = useState<string>('All');

  const selectedPhotos = useMemo(
    () => photos.find(({ type }) => type === selectedType)?.photos || [],
    [photos, selectedType]
  );

  const fetchPhotos = useCallback(async () => {
    try {
      setIsFetching(true);
      const data = await api.photos_GetPhotosByItemId(itemId);

      const allPhotos = {
        label: t('all'),
        photos: data.map(({ photos }) => photos as PhotoGetModel2[]).flat(),
        type: 'All'
      };
      const itemPhotos = data.map(item => ({ ...item, label: item.type as string }));

      setPhotos([allPhotos, ...itemPhotos]);
    } catch {
      enqueueNotification({ title: t('errors.errorGetPhotos') }, { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  }, [api, enqueueNotification, itemId, setPhotos, t]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        const currentIndex = photos.findIndex(item => item.type === selectedType);

        if (currentIndex !== -1) {
          const nextIndex =
            (event.key === 'ArrowDown' ? currentIndex + 1 : currentIndex + photos.length - 1) % photos.length;

          setSelectedType(photos[nextIndex].type as string);
        }
      }
    },
    [photos, selectedType, setSelectedType]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (itemId) {
      fetchPhotos();
    }
  }, [itemId]);

  return (
    <Dialog onClose={handleClose} open>
      <StyledJay gap="none" scroll="hidden">
        <StyledHeader>
          <Typography variant="h6">{name}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledHeader>
        <LayoutHerodias gap="none" sx={{ overflow: 'hidden' }}>
          <StyledThumbnailContainer scroll="scroll">
            {isFetching ? (
              <StyledLoaderContainer>
                <CircularProgress size={40} />
              </StyledLoaderContainer>
            ) : (
              photos.map(item => (
                <StyledThumbnailItem
                  key={item.type}
                  onClick={() => setSelectedType(item.type as string)}
                  $selected={item.type === selectedType}
                >
                  <Image height="auto" src={item.photos?.[0].url || ''} width="auto" />
                  <LayoutJay gap="none">
                    <Typography variant="caption">{item.label}</Typography>
                    <Typography variant="overline">{`${item.photos?.length || 0} ${t('images')}`}</Typography>
                  </LayoutJay>
                </StyledThumbnailItem>
              ))
            )}
          </StyledThumbnailContainer>

          <StyledSliderContainer>
            {isFetching ? (
              <StyledLoaderContainer>
                <CircularProgress size={40} />
              </StyledLoaderContainer>
            ) : (
              <ImageSlider images={selectedPhotos} />
            )}
          </StyledSliderContainer>
        </LayoutHerodias>
      </StyledJay>
    </Dialog>
  );
};

export default GalleryModal;
