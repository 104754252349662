import React from 'react';

import { AppBar, MaterialIcons } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import warehouseAppBarStyles from './warehouseAppBar.styles';

interface IProps {
  mode?: string;
  onClose: () => void;
  sku?: string;
}

const WarehouseAppBar: React.FC<IProps> = ({ mode, onClose, sku }) => {
  const classes = warehouseAppBarStyles();
  const { t } = useTranslation();

  return (
    <AppBar position="relative" className={classes.header}>
      <span className={classes.clickableTitle} onClick={onClose}>
        {t('inbound')}
      </span>
      <MaterialIcons.ChevronRight color="primary"></MaterialIcons.ChevronRight>
      <span className={classes.clickableTitle} onClick={onClose}>
        {t('warehouse')}
      </span>

      {mode === 'update' ? (
        <>
          <MaterialIcons.ChevronRight color="primary" />
          <span className={classes.nonClickableTitle}>{sku}</span>
        </>
      ) : (
        <MaterialIcons.ChevronRight color="primary" />
      )}

      <MaterialIcons.Close className={classes.closeIcon} color="secondary" onClick={onClose}></MaterialIcons.Close>
    </AppBar>
  );
};

export default WarehouseAppBar;
