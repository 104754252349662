import React from 'react';

import { LayoutHerodias } from '@lux-ds/content-layouts';
import { CircularProgress } from '@mui/material';

const LoadingContainer: React.FC = () => (
  <LayoutHerodias align="center">
    <CircularProgress />
  </LayoutHerodias>
);

export default LoadingContainer;
