import React from 'react';

import { useTranslation } from 'react-i18next';

import { IOnHoldItemInfo } from 'models/warehouse/onHold';

import securityTagStyles from './securityTag.styles';

interface IProps {
  hasSecurityTag: IOnHoldItemInfo['hasSecurityTag'];
}

const SecurityTag: React.FC<IProps> = ({ hasSecurityTag }) => {
  const classes = securityTagStyles();
  const { t } = useTranslation('warehouse');

  return (
    <>
      <h1 className={classes.title}>{t('securityTag')}</h1>
      <label className={classes.securityTagValue}>{t(hasSecurityTag ? 'withSecurityTag' : 'withoutSecurityTag')}</label>
    </>
  );
};

export default SecurityTag;
