import React, { useEffect } from 'react';

import { AuthService } from '@luxclusif/material';

const Logout: React.FC = () => {
  useEffect(() => {
    AuthService.logout();
  }, []);

  return null;
};

export default Logout;
