import React from 'react';

import { CircularProgress } from '@luxclusif/material';

import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';

import Found from './sections/Found/Found';

import checkInStyles from './newCheckIn.styles';

const NewCheckIn: React.FC = () => {
  const classes = checkInStyles();
  const { isStationLoading } = useWarehouseItemDetailsContext();

  return isStationLoading ? (
    <div className={classes.loadingContainer}>
      <CircularProgress size={60} />
    </div>
  ) : (
    <Found />
  );
};

export default NewCheckIn;
