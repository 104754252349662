import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const bankDetailsStyles = makeStyles((theme: Theme) => {
  return createStyles({
    buttonContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: '0 32px 32px'
    },
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '4px',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      margin: '10px'
    },
    containerTitle: {
      paddingBottom: '2px !important',
      paddingRight: '32px',
      paddingTop: '2px !important'
    },
    dialogActions: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end'
    },
    dialogContainer: {
      padding: '20px'
    },
    dialogMessage: {
      color: theme.palette.secondary.main,
      fontSize: '20px'
    },
    dialogTitle: {
      fontWeight: 'bold',
      marginBottom: '16px'
    },
    divider: {
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '4em 0'
    },
    radioButtonText: {
      flex: 1,
      width: '100%',

      '& > span.MuiFormControlLabel-label': {
        color: theme.palette.secondary.main,
        fontFamily: 'Nunito Sans',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '16px'
      }
    },
    title: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '18px',
      paddingBottom: '10px',
      paddingTop: '10px'
    },
    titleDivider: {
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '0.5em 0',
      padding: '0'
    }
  });
});

export default bankDetailsStyles;
