import React, { createContext, useCallback, useEffect, useState } from 'react';

import { GetIssuesByProcessQueryResponse, ItemProcessGetListModelPagedListCounts } from '@inbound/api';
import {
  GridSortModel,
  IFilterValue,
  IMultipleSelectOptionIdName,
  IMultipleSelectOptionKeyValue
} from '@lux-ds/data-grid';
import { ISelectOptionIdName } from '@lux-ds/dropdown';
import { useNotification } from '@lux-ds/notification';
import { useTranslation } from 'react-i18next';

import useApi from 'hooks/useApi';
import { EWarehouseName, LOCAL_STORAGE_USER_WAREHOUSE } from 'models/warehouse';
import isValidUUID from 'utils/isValidUUID';

type IFilterOptions = {
  authResults: IMultipleSelectOptionKeyValue[];
  brands: IMultipleSelectOptionIdName[];
  inboundFlows: IMultipleSelectOptionKeyValue[];
  qcDecisions: IMultipleSelectOptionKeyValue[];
  suppliers: IMultipleSelectOptionKeyValue[];
};

type IQuickFilter = keyof ItemProcessGetListModelPagedListCounts | '';

interface IWarehouseIssueDetails extends GetIssuesByProcessQueryResponse {
  itemId: string;
  itemProcessId: string;
  sku?: string;
}

interface IWarehouseContext {
  activeFilterTab?: string;
  activeWarehouse?: string;
  filterOptions: IFilterOptions;
  filters: IFilterValue;
  getFilterOptions: () => void;
  getWarehouseIssueDetails: (id: string, itemId: string, sku?: string) => void;
  getWarehouseOptions: () => void;
  hasDefaultWarehouse: boolean;
  includeProcessedItems: boolean;
  isResubmitIssueLoading: boolean;
  isWarehouseIssueLoading: boolean;
  onResubmitWarehouseIssue: (onSuccess?: () => void) => void;
  page: number;
  pageSize: number;
  quickFilter: IQuickFilter; // TODO: Remove once Warehouse 2.0 released
  resetWarehouseIssue: () => void;
  search: string;
  setActiveFilterTab: (activeFilterTab: string) => void;
  setActiveWarehouse: (warehouse: string) => void;
  setFilters: (filters: IFilterValue) => void;
  setIncludeProcessedItems: (includeProcessedItems: boolean) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setQuickFilter: (event: React.SyntheticEvent<Element, Event>, quickFilter: string) => void; // TODO: Remove once Warehouse 2.0 released
  setSearch: (search: string) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  sortModel: GridSortModel;
  warehouseIssueDetails: IWarehouseIssueDetails;
  warehouseOptions: ISelectOptionIdName[];
}

const filterOptionsDefaultValue: IFilterOptions = {
  authResults: [],
  brands: [],
  inboundFlows: [],
  qcDecisions: [],
  suppliers: []
};

const WarehouseContext = createContext<IWarehouseContext>({
  activeFilterTab: 'allItems',
  activeWarehouse: '',
  filterOptions: filterOptionsDefaultValue,
  filters: {},
  getFilterOptions: () => void 0,
  getWarehouseIssueDetails: () => void 0,
  getWarehouseOptions: () => void 0,
  hasDefaultWarehouse: false,
  includeProcessedItems: false,
  isResubmitIssueLoading: false,
  isWarehouseIssueLoading: true,
  onResubmitWarehouseIssue: () => void 0,
  page: 0,
  pageSize: 25,
  quickFilter: 'allItems', // TODO: Remove once Warehouse 2.0 released
  resetWarehouseIssue: () => void 0,
  search: '',
  setActiveFilterTab: () => void 0,
  setActiveWarehouse: () => void 0,
  setFilters: () => void 0,
  setIncludeProcessedItems: () => void 0,
  setPage: () => void 0,
  setPageSize: () => void 0,
  setQuickFilter: () => void 0, // TODO: Remove once Warehouse 2.0 released
  setSearch: () => void 0,
  setSortModel: () => void 0,
  sortModel: [],
  warehouseIssueDetails: {} as IWarehouseIssueDetails,
  warehouseOptions: []
});

const WarehouseProvider: React.FC = ({ children }) => {
  const api = useApi();
  const { enqueueNotification } = useNotification();
  const { t } = useTranslation(['common', 'warehouse']);

  const [activeFilterTab, setActiveFilterTab] = useState<IWarehouseContext['activeFilterTab']>('allItems');
  const [activeWarehouse, setActiveWarehouse] = useState<IWarehouseContext['activeWarehouse']>('');
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>(filterOptionsDefaultValue);
  const [filters, setFilters] = useState<IWarehouseContext['filters']>({});
  const [hasDefaultWarehouse, setHasDefaultWarehouse] = useState<IWarehouseContext['hasDefaultWarehouse']>(false);
  const [includeProcessedItems, setIncludeProcessedItems] = useState<IWarehouseContext['includeProcessedItems']>(false);
  const [isResubmitIssueLoading, setIsResubmitIssueLoading] =
    useState<IWarehouseContext['isResubmitIssueLoading']>(false);
  const [isWarehouseIssueLoading, setIsWarehouseIssueLoading] =
    useState<IWarehouseContext['isWarehouseIssueLoading']>(false);
  const [page, setPage] = useState<IWarehouseContext['page']>(0);
  const [pageSize, setPageSize] = useState<IWarehouseContext['pageSize']>(25);
  const [quickFilter, setQuickFilter] = useState<IWarehouseContext['quickFilter']>('');
  const [search, setSearch] = useState<IWarehouseContext['search']>('');
  const [sortModel, setSortModel] = useState<IWarehouseContext['sortModel']>([]);
  const [warehouseIssueDetails, setWarehouseIssueDetails] = useState<IWarehouseContext['warehouseIssueDetails']>(
    {} as IWarehouseIssueDetails
  );
  const [warehouseOptions, setWarehouseOptions] = useState<IWarehouseContext['warehouseOptions']>([]);

  const handleSetActiveFilterTab = useCallback((activeFilterTab: string) => setActiveFilterTab(activeFilterTab), []);
  const handleSetActiveWarehouse = useCallback((warehouse: string) => setActiveWarehouse(warehouse), []);
  const handleSetFilters = useCallback((filters: IFilterValue) => setFilters(filters), []);
  const handleSetIncludeProcessedItems = useCallback(
    (includeProcessedItems: boolean) => setIncludeProcessedItems(includeProcessedItems),
    []
  );
  const handleSetPage = useCallback((page: number) => setPage(page), []);
  const handleSetPageSize = useCallback((pageSize: number) => setPageSize(pageSize), []);
  const handleSetQuickFilter = useCallback((_, quickFilter: string) => setQuickFilter(quickFilter as IQuickFilter), []);
  const handleSetSearch = useCallback((search: string) => setSearch(search), []);
  const handleSetSortModel = useCallback((sortModel: GridSortModel) => setSortModel(sortModel), []);

  const handleGetWarehouseOptions = useCallback(() => {
    const getWarehouseOptionsCallback = async () => {
      try {
        const filters = await api.warehouse_GetAll();

        const options: ISelectOptionIdName[] = filters
          .filter(({ isActive }) => isActive)
          .map(option => ({ id: option.id as string, name: option.name as string }));

        setWarehouseOptions(options);
      } catch {
        enqueueNotification({ title: t('warehouse:errors.errorGetWarehouseFilters') }, { variant: 'error' });
      }
    };

    getWarehouseOptionsCallback();
  }, [api, enqueueNotification, t]);

  const handleGetFilterOptions = useCallback(() => {
    const handleGetFilterOptionsCallback = async () => {
      try {
        const [authResults, brands, inboundFlows, qcDecisions, suppliers] = await Promise.all([
          api.authenticity_GetAuthStatusFilters(),
          api.productBrands_GetAll(),
          api.warehouse_GetInboundFlowFilters(),
          api.qualityControl_GetQCStatusFilters(),
          api.products_GetSuppliers()
        ]);

        const filters: IFilterOptions = {
          authResults,
          brands,
          inboundFlows,
          qcDecisions,
          suppliers
        } as IFilterOptions;

        setFilterOptions(filters);
      } catch {
        enqueueNotification({ title: t('common:errors.errorGetFilters') }, { variant: 'error' });
      }
    };

    if (process.env.REACT_APP_ENABLE_WAREHOUSE_V2 === 'true') {
      handleGetFilterOptionsCallback();
    }
  }, [api, enqueueNotification, t]);

  const handleGetWarehouseIssueDetails = useCallback(
    (id: string, itemId: string, sku?: string) => {
      const handleGetWarehouseIssueDetailsCallback = async () => {
        try {
          setIsWarehouseIssueLoading(true);
          const details = await api.issues_GetIssuesByProcess(id);

          console.log({ details });

          setWarehouseIssueDetails({ ...details, itemId, itemProcessId: id, sku });
        } catch {
          enqueueNotification(
            { title: t('warehouse:messages.errors.errorGetWarehouseIssueDetails') },
            { variant: 'error' }
          );
        } finally {
          setIsWarehouseIssueLoading(false);
        }
      };

      handleGetWarehouseIssueDetailsCallback();
    },
    [api, enqueueNotification, t]
  );

  const handleResetWarehouseIssue = useCallback(() => setWarehouseIssueDetails({} as IWarehouseIssueDetails), []);

  const handleResubmitWarehouseIssue = useCallback(
    (onSuccess?: () => void) => {
      const handleResubmitWarehouseIssueCallback = async () => {
        try {
          setIsResubmitIssueLoading(true);
          const { itemId, itemProcessId, sku } = warehouseIssueDetails;

          await api.warehouse_ResubmitItemToReviewObsolete(itemProcessId, itemId as string);
          enqueueNotification(
            { title: t('warehouse:messages.success.successResubmitWarehouseIssue', { sku }) },
            { variant: 'success' }
          );

          onSuccess?.();
        } catch {
          enqueueNotification(
            { title: t('warehouse:messages.errors.errorResubmitWarehouseIssue') },
            { variant: 'error' }
          );
        } finally {
          setIsResubmitIssueLoading(false);
        }
      };

      handleResubmitWarehouseIssueCallback();
    },
    [api, enqueueNotification, t, warehouseIssueDetails]
  );

  useEffect(() => {
    const getActiveWarehouseCallback = () => {
      const userWarehouse = localStorage.getItem(LOCAL_STORAGE_USER_WAREHOUSE);

      if (userWarehouse) {
        let warehouseId = userWarehouse;

        if (!isValidUUID(userWarehouse)) {
          const warehouse = warehouseOptions.find(({ name }) => name.toLowerCase() === userWarehouse.toLowerCase());

          warehouseId = warehouse?.id as string;
        }

        setActiveWarehouse(warehouseId);
      } else {
        const warehouse = warehouseOptions.find(({ name }) => name.toLowerCase() === EWarehouseName.USA.toLowerCase());

        setActiveWarehouse(warehouse?.id as string);
      }

      setHasDefaultWarehouse(!!userWarehouse);
    };

    if (warehouseOptions.length > 0 && !activeWarehouse) {
      getActiveWarehouseCallback();
    }
  }, [activeWarehouse, warehouseOptions]);

  return (
    <WarehouseContext.Provider
      value={{
        activeFilterTab,
        activeWarehouse,
        filterOptions,
        filters,
        getFilterOptions: handleGetFilterOptions,
        getWarehouseIssueDetails: handleGetWarehouseIssueDetails,
        getWarehouseOptions: handleGetWarehouseOptions,
        hasDefaultWarehouse,
        includeProcessedItems,
        isResubmitIssueLoading,
        isWarehouseIssueLoading,
        onResubmitWarehouseIssue: handleResubmitWarehouseIssue,
        page,
        pageSize,
        quickFilter, // TODO: Remove once Warehouse 2.0 released
        resetWarehouseIssue: handleResetWarehouseIssue,
        search,
        setActiveFilterTab: handleSetActiveFilterTab,
        setActiveWarehouse: handleSetActiveWarehouse,
        setFilters: handleSetFilters,
        setIncludeProcessedItems: handleSetIncludeProcessedItems,
        setPage: handleSetPage,
        setPageSize: handleSetPageSize,
        setQuickFilter: handleSetQuickFilter, // TODO: Remove once Warehouse 2.0 released
        setSearch: handleSetSearch,
        setSortModel: handleSetSortModel,
        sortModel,
        warehouseIssueDetails,
        warehouseOptions
      }}
    >
      {children}
    </WarehouseContext.Provider>
  );
};

export { WarehouseProvider };

export default WarehouseContext;
