import React, { useCallback, useEffect, useState } from 'react';

import { GetWarehouseQueryResponse } from '@inbound/api';
import { Button, Grid, IconButton, MaterialIcons, Modal, Paper, Tab, TableContainer, Tabs } from '@luxclusif/material';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { $hideLoading, $showLoading } from 'components/LoadingSpinner/LoadingSpinner';
import TabPanel from 'components/TabPanel/TabPanel';
import { ILuxWarehouses, IStatus } from 'models/supplier';
import SUPPLIER_CONSTANTS from 'pages/Suppliers/constants';
import { businessModelInitialize } from 'pages/Suppliers/schemas/businessModelSchema';
import couriersService from 'services/Couriers.service';
import paymentService from 'services/Payment.service';
import productsService from 'services/Products.service';
import suppliersService from 'services/Suppliers.service';

import BusinessModelDetails from '../BusinessModelDetails/BusinessModelDetails';
import Files from '../Files/Files';
import Financial from '../Financial/Financial';
import Operations from '../Operations/Operations';
import ReturnPolicy from '../ReturnPolicy/ReturnPolicy';
import TargetSettings from '../TargetSettings/TargetSettings';
import TechNotes from '../TechNotes/TechNotes';

import supplierDetailsStyles from '../../supplierDetails.styles';

interface IProps {
  businessModelForm: any;
  exit: (newValue: number) => void;
  formTouched: boolean;
  isValid: boolean;
  mode: string;
  setFormTouched: (newValue: boolean) => void;
  status: string | null;
  statusList: IStatus[];
}

const SupplierProfile: React.FC<IProps> = ({
  businessModelForm,
  exit,
  formTouched,
  isValid,
  mode,
  setFormTouched,
  status,
  statusList
}) => {
  const classes = supplierDetailsStyles();
  const [showBusinessModel, setShowBusinessModel] = useState(mode === 'create' ? 0 : 1);

  const [renderCreate, setRenderCreate] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [tiers, setTiers] = useState([]);
  const [, /* targetSettings */ setTargetSettings] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [freightTerms, setFreightTerms] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [deleteModal, setDeleteModalOpen] = useState(false);
  const [businessModelModalValidation, setBusinessModelModalValidation] = useState(false);
  const [commercialModels, setCommercialModels] = useState([]);
  const [luxWarehouses, setLuxWarehouses] = useState<ILuxWarehouses[]>([]);
  const [targetUnits, setTargetUnits] = useState([]);
  const [onExitWarningModal, setOnExitWarningModal] = useState(false);

  const { control, getValues, setValue, watch } = useFormContext();

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'businessModel'
  });

  const businessModel = watch('businessModel');

  const handleSetLuxWarehouses = useCallback(
    (luxWarehouses: GetWarehouseQueryResponse[]) => {
      const warehouseId = businessModelForm.getValues('reception');

      const newLuxWarehouses = luxWarehouses.reduce(
        (result: ILuxWarehouses[], { id, isActive, name }) => [
          ...result,
          ...(isActive || id === warehouseId
            ? [
                {
                  id: id as string,
                  name: name as string
                }
              ]
            : [])
        ],
        []
      );

      setLuxWarehouses(newLuxWarehouses);
    },
    [businessModelForm]
  );

  useEffect(() => {
    getter('getTiers', suppliersService.getTiers, setTiers);

    getter('getTargetSettings', suppliersService.getTargetSettings, setTargetSettings);

    getter('getCommercialModels', suppliersService.getCommercialModels, setCommercialModels);

    getter('getLuxWarehouses', suppliersService.getTargetUnits, setTargetUnits);

    getter('getPaymentMethods', paymentService.getPaymentMethods, setPaymentMethods);
    getter('getPaymentTerms', paymentService.getPaymentTerms, setPaymentTerms);

    getter('getFreightTerms', suppliersService.getFreightTerms, setFreightTerms);

    getter('getCouriers', couriersService.getCouriers, setCouriers);

    getter('getLuxWarehouses', productsService.getWarehouses, handleSetLuxWarehouses);
  }, []);

  useEffect(() => {
    if (formTouched) {
      setOnExitWarningModal(true);
    }
  }, [formTouched]);

  const getter = (loading: string, service: any, setter: any) => {
    const loadingId = loading;

    $showLoading.next(loadingId);
    service()
      .then((data: any) => {
        setter(data);
      })
      .finally(() => {
        $hideLoading.next(loadingId);
      });
  };

  useEffect(() => {
    if (renderCreate) {
      setRenderCreate(false);
    }
  }, [renderCreate]);

  useEffect(() => {
    setRenderCreate(true);

    if (showBusinessModel !== 0 && businessModel.length > 0) {
      businessModelForm.reset(getValues().businessModel[showBusinessModel - 1]);
    } else {
      businessModelForm.reset(businessModelInitialize());
    }
  }, [showBusinessModel]);

  const addUpdateBusinessModel = () => {
    setRenderCreate(true);
    businessModelForm.trigger();

    if (isValid) {
      if (showBusinessModel !== 0) {
        setValue(`businessModel[${showBusinessModel - 1}]`, businessModelForm.getValues());

        // just to re invoke isValid property
        remove(10000);
        setUpdateModal(true);
      } else {
        append(businessModelForm.getValues());
        businessModelForm.reset(businessModelInitialize());
        const toTop = document.querySelector('.makeStyles-modalRoot-40') as HTMLElement;

        if (toTop) {
          toTop.scrollTo({
            behavior: 'smooth',
            top: 0
          });
        }
      }
    }
  };

  const deleteBusinessModel = () => {
    remove(showBusinessModel - 1);
    setDeleteModalOpen(false);
    setShowBusinessModel(0);
  };

  const handleChange = (htmlComponent: React.SyntheticEvent, newValue: number) => {
    setShowBusinessModel(newValue);
  };

  const handleUnsaveChangesSubmit = () => {
    addUpdateBusinessModel();

    if (isValid) {
      exit(0);
    }

    setFormTouched(false);
    setOnExitWarningModal(false);
  };

  const changeTabByIndex = (index: number) => ({
    'aria-controls': `vertical-tabpanel-${index}`,
    id: `vertical-tab-${index}`
  });

  const renderBusinessModelName = (value: number) => {
    switch (value) {
      case 1:
        return 'Owned Goods';
      case 2:
        return 'Physical Consignment';
      case 3:
        return 'Virtual Consignment';
      case 4:
        return 'Buyback';
      case 5:
        return 'Hybrid';
      default:
        return;
    }
  };

  const renderBusinessModel = () => {
    return renderCreate ? (
      <div></div>
    ) : (
      <div className={classes.flexGrow}>
        <Grid container spacing={4} justifyContent="space-between" alignItems="center">
          <Grid item>
            {showBusinessModel !== 0 ? (
              <h1 className={classes.businessModelTitle}>
                {renderBusinessModelName(+businessModelForm.watch('businessModelType'))} Details
              </h1>
            ) : (
              <h1 className={classes.businessModelTitle}>Create New Business Model</h1>
            )}
          </Grid>
          <Grid item>
            {showBusinessModel !== 0 && (!status || status === SUPPLIER_CONSTANTS.DRAFT_STATUS_NAME) && (
              <IconButton color="secondary" component="button" onClick={() => setDeleteModalOpen(true)}>
                <MaterialIcons.Delete />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <BusinessModelDetails
          status={statusList}
          commercialModels={commercialModels}
          tiers={tiers}
          showBusinessModel={showBusinessModel}
          businessModelForm={businessModelForm}
        />
        <Financial paymentTerms={paymentTerms} paymentMethods={paymentMethods} businessModelForm={businessModelForm} />
        <Operations
          freightTerms={freightTerms}
          couriers={couriers}
          luxWarehouses={luxWarehouses}
          businessModelForm={businessModelForm}
        />
        <ReturnPolicy businessModelForm={businessModelForm} />
        <TargetSettings businessModelForm={businessModelForm} targetUnits={targetUnits} />
        <TechNotes businessModelForm={businessModelForm} />
        <Files businessModelForm={businessModelForm} />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            className={classes.AddSupplierProfile}
            variant="contained"
            color="secondary"
            onClick={addUpdateBusinessModel}
          >
            {showBusinessModel !== 0 ? 'Update Business Model' : 'Create Business Model'}
          </Button>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <Tabs
          value={showBusinessModel}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          orientation="vertical"
          variant="scrollable"
          className={classes.tabs}
          textColor="primary"
          TabIndicatorProps={{ className: classes.tabIndicatorProps }}
        >
          <Tab disabled label="Business Models" className={classes.titleSideTab} />
          {fields.map((value: any, index: number) => {
            return (
              <Tab
                value={index + 1}
                key={index + 1}
                label={renderBusinessModelName(+value.businessModelType)}
                {...changeTabByIndex(index + 1)}
                className={classes.alignment}
                icon={<MaterialIcons.Business />}
              />
            );
          })}

          <Tab
            value={0}
            key={0}
            label="Add Business Model"
            {...changeTabByIndex(0)}
            className={classes.alignment}
            icon={<MaterialIcons.Add />}
          />
        </Tabs>

        {fields.map((value: any, index: number) => {
          return (
            <TabPanel value={showBusinessModel} index={index + 1} key={index + 1} className={classes.tabPanel}>
              {showBusinessModel === index + 1 ? renderBusinessModel() : <></>}
            </TabPanel>
          );
        })}
        <TabPanel value={showBusinessModel} index={0} className={classes.tabPanel}>
          {showBusinessModel === 0 || !status ? renderBusinessModel() : <></>}
        </TabPanel>
      </div>

      <Modal open={deleteModal} onClose={() => setDeleteModalOpen(false)}>
        <div className={classes.modalUpperContainer}>
          <TableContainer className={classes.tableContainerDelete} /* spacing={5} */ component={Paper}>
            <div>
              <h3 className={classes.center}>Delete Business Model details</h3>
              <p className={classes.center}>
                This action will remove the details information. Do you really want to delete the record?
              </p>
            </div>
            <Grid className={classes.grid} container direction="row" justifyContent="flex-end" alignItems="center">
              <Button onClick={() => setDeleteModalOpen(false)} className={classes.button}>
                Never Mind
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => deleteBusinessModel()}
              >
                Delete
              </Button>
            </Grid>
          </TableContainer>
        </div>
      </Modal>

      <Modal open={updateModal} onClose={() => setUpdateModal(false)}>
        <div className={classes.modalUpperContainer}>
          <TableContainer className={classes.tableContainerUpdateModal} component={Paper}>
            <div>
              <h3 className={classes.deleteWarehouseDet}>Business Model Updated</h3>
            </div>
            <Grid className={classes.grid} container direction="row" justifyContent="flex-end" alignItems="center">
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => setUpdateModal(false)}
              >
                close
              </Button>
            </Grid>
          </TableContainer>
        </div>
      </Modal>

      <Modal open={businessModelModalValidation} onClose={() => setBusinessModelModalValidation(false)}>
        <div className={classes.modalUpperContainer}>
          <TableContainer className={classes.tableContainerDelete} /* spacing={5} */ component={Paper}>
            <div>
              <h3 className={classes.deleteWarehouseDet}>Error</h3>
              <p className={classes.thisActionWillRem}>
                The business model type you are trying to create is already in the supplier profile
              </p>
            </div>
            <Grid className={classes.grid} container direction="row" justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setBusinessModelModalValidation(false)}
                className={classes.button}
              >
                Close
              </Button>
            </Grid>
          </TableContainer>
        </div>
      </Modal>

      <Modal open={onExitWarningModal} onClose={() => setOnExitWarningModal(false)}>
        <div className={classes.modalUpperContainer}>
          <TableContainer className={classes.tableContainerExit} component={Paper}>
            <div>
              <h3>Unsaved changes</h3>
              <p>You have unsaved changes. Are you sure you want to continue and lose the information?</p>
            </div>
            <Grid className={classes.grid} container direction="row" justifyContent="center" alignItems="center">
              <Button
                className={classes.button}
                onClick={() => {
                  setFormTouched(false);
                  exit(0);
                  setOnExitWarningModal(false);
                }}
              >
                Discard Changes
              </Button>

              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={handleUnsaveChangesSubmit}
              >
                Save
              </Button>
            </Grid>
          </TableContainer>
        </div>
      </Modal>
    </>
  );
};

export default SupplierProfile;
