import React, { createContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { EWarehouseAction } from 'models/warehouse';

interface IWarehouseItemContext {
  isViewMode: boolean;
}

const WarehouseItemContext = createContext<IWarehouseItemContext>({
  isViewMode: false
});

const WarehouseItemProvider: React.FC = ({ children }) => {
  const { action = '' } = useParams<{ action: string }>();

  const [isViewMode, setIsViewMode] = useState<IWarehouseItemContext['isViewMode']>(false);

  useEffect(() => {
    setIsViewMode(action === EWarehouseAction.VIEW);
  }, [action]);

  return <WarehouseItemContext.Provider value={{ isViewMode }}>{children}</WarehouseItemContext.Provider>;
};

const WarehouseItemConsumer = WarehouseItemContext.Consumer;

export { WarehouseItemConsumer, WarehouseItemProvider };

export default WarehouseItemContext;
