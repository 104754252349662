import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const purchaseOrderStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      backgroundColor: secondaryNeutral.light85,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      left: '50%',
      overflowY: 'auto',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100vw',
      '&:focus': {
        outline: 'none'
      }
    },
    closeForm: {
      padding: 0
    },
    breadcrumbContainer: {
      backgroundColor: palette.common.white,
      boxShadow: '0 2px 4px 0 rgba(68,68,66,0.05)',
      display: 'flex',
      padding: '24px',
      justifyContent: 'space-between',
      '& .MuiBreadcrumbs-separator > .MuiSvgIcon-root': {
        color: palette.primary.main
      },
      '& .MuiBreadcrumbs-li': {
        fontSize: '18px'
      },
      '& .MuiBreadcrumbs-li .MuiLink-root:hover': {
        cursor: 'pointer',
        textDecoration: 'none'
      }
    },
    breadcrumbInactive: {
      color: secondaryNeutral.light40
    },
    breadcrumbActive: {
      color: palette.secondary.main,
      '& b': {
        fontWeight: 600
      },
      cursor: 'default'
    },

    editHeaderActive: {
      alignItems: 'center',
      display: 'flex',
      gap: '16px'
    },

    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minHeight: '50vh',
      width: '100%'
    },
    tabFormContainer: {
      backgroundColor: palette.common.white,
      borderRadius: '4px',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '24px'
    },
    tabFormHeader: {
      borderBottom: '1px solid #DDDDDA',
      padding: '0 24px',
      '& .MuiButtonBase-root': {
        lineHeight: 1,
        minHeight: '64px',
        padding: '0 16px',
        '&.Mui-selected': {
          color: palette.secondary.main
        }
      },
      '& .MuiTab-wrapper': {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1,
        textTransform: 'capitalize'
      },
      '& .MuiTabs-indicator': {
        height: '4px'
      }
    },
    formSectionTitle: {
      fontSize: '18px',
      fontWeight: 600,
      margin: 0
    },
    formActionButtons: {
      borderTop: '1px solid #DDDDDA',
      display: 'flex',
      gap: '16px',
      justifyContent: 'flex-end',
      padding: '32px 24px',
      marginTop: '32px'
    },
    tabWithTooltip: {
      pointerEvents: 'auto !important' as 'auto'
    },
    orderItemsTab: {
      flex: 1
    }
  })
);

export default purchaseOrderStyles;
