import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';

import { css, styled } from 'utils/styles';

export const StyledJay = styled(LayoutJay)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    border: 1px solid ${theme.palette.primary.shade[85]};
    border-radius: ${theme.space.xxs};
    overflow: hidden;
    padding: ${theme.space.m};
    width: 315px;
  `
);

export const StyledHeader = styled(LayoutHerodias)(() => css``);
