import { LayoutJay } from '@lux-ds/content-layouts';
import Stepper from '@lux-ds/stepper';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

import { css, styled } from 'utils/styles';

export const StyledJay = styled(LayoutJay)(
  ({ theme }) => css`
    border-right: 1px solid ${theme.palette.primary.shade[70]};
    padding: ${theme.space.l};
    width: 230px;
  `
);

export const StyledStepper = styled(Stepper)`
  & .MuiStepLabel-root {
    & .MuiStepLabel-label {
      font-weight: unset;
    }

    &.current {
      & .MuiStepLabel-label {
        font-weight: bold;
      }
    }
  }
`;

export const StyledIconContainer = styled('div', {
  shouldForwardProp: prop => prop !== '$active'
})<{ $active: boolean }>(
  ({ $active, theme }) => css`
    align-items: center;
    background-color: ${$active ? theme.palette.primary.shade[5] : theme.palette.primary.shade[85]};
    border-radius: 50%;
    display: flex;
    height: ${theme.space.m};
    justify-content: center;
    width: ${theme.space.m};

    > .MuiSvgIcon-root {
      color: ${theme.palette.common.white};
      height: 12px;
      width: 12px;
    }
  `
);

export const StyledCheckIcon = styled(CheckCircleIcon)(
  ({ theme }) => css`
    color: ${theme.palette.success.main};
    font-size: ${theme.space.s};
  `
);

export const StyledCancelIcon = styled(CancelIcon)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    font-size: ${theme.space.s};
  `
);

export const StyledWarningIcon = styled(WarningIcon)(
  ({ theme }) => css`
    color: ${theme.palette.warning.main};
    font-size: ${theme.space.s};
  `
);
