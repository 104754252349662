import { useContext } from 'react';

import WarehouseContext from 'contexts/WarehouseContext';

const useWarehouseContext = () => {
  const context = useContext(WarehouseContext);

  if (context === undefined) {
    throw new Error('useWarehouseContext must be used within WarehouseProvider');
  }

  return context;
};

export default useWarehouseContext;
