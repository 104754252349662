import React, { useEffect, useState } from 'react';

import { MaterialIcons } from '@luxclusif/material';

import SUPPLIER_CONSTANTS from 'pages/Suppliers/constants';
import compressImage from 'utils/compressImage';

import uploadImageStyles from './uploadImage.styles';

interface IProps {
  errors: any;
  label?: string | undefined;
  name: string;
  register: any;
  setValue: (name: string, value: any) => void;
  watch: (name: string, defaultValues?: string) => string;
}

const UploadImage: React.FC<IProps> = ({ errors, name, setValue, watch }) => {
  const classes = uploadImageStyles();
  const [image, setImage] = useState<string>('');
  const uploadImage = watch(name);

  useEffect(() => {
    if (uploadImage) {
      setImage(uploadImage);
    }
  }, [uploadImage]);

  const changeHandler = (event: any) => {
    const reader = new FileReader();
    const [file] = event.target.files;

    if (event.target.files[0]) {
      reader.onloadend = () => {
        compressImage(file, 300, 300, 'WEBP', 40).then(data => {
          setValue(name, data);
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const getTheImageUrl = (image: string) => {
    let result = '';

    if (image !== SUPPLIER_CONSTANTS.EMPTY_STRING && image.startsWith(SUPPLIER_CONSTANTS.IMAGECONDITIONFORBASE64)) {
      result = image;
    } else {
      result = SUPPLIER_CONSTANTS.BASE64IMAGE + image;
    }

    return result;
  };

  return (
    <div className={`${classes.textAlignCenter} ${errors[name] && classes.formControlInvalid} `}>
      <div
        className={classes.photos}
        style={{
          backgroundImage: `url(${getTheImageUrl(image)})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain'
        }}
      >
        <div className={classes.logoContainer}>
          <MaterialIcons.CameraAlt
            className={classes.logo}
            onClick={() => {
              setValue(name, '');
              setImage('');
            }}
            style={{ color: 'black' }}
          />
        </div>
        <input
          name={name}
          title=""
          type="file"
          onChange={changeHandler}
          className={classes.inputPhotos}
          accept="image/x-png,image/gif,image/jpeg"
        />
      </div>
    </div>
  );
};

export default UploadImage;
