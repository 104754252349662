import { createStyles, makeStyles } from '@luxclusif/material';

const trackingNumbersSectionStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '32px'
    },
    content: {
      border: '1px solid #DDDDDA',
      borderRadius: '4px',
      display: 'flex',
      gap: '24px',
      marginBottom: '16px',
      padding: '16px 24px',
      width: '780px'
    },
    label: {
      color: '#86867E',
      fontSize: '12px',
      marginBottom: '8px'
    },
    value: {
      fontSize: '16px'
    },

    trackingNumber: {
      width: '192px'
    },
    carrier: {
      width: '192px'
    },
    totalItems: {
      flex: 1
    },
    deliveryDate: {
      width: '192px'
    }
  })
);

export default trackingNumbersSectionStyles;
