import { LayoutJay } from '@lux-ds/content-layouts';
import CloudUpload from '@mui/icons-material/CloudUpload';

import { styled } from 'utils/styles';

export const StyledJay = styled(LayoutJay, {
  shouldForwardProp: prop => prop !== '$hasError'
})<{ $hasError: boolean }>(({ $hasError, theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.shade[90],
  border: `1px dashed ${$hasError ? theme.palette.error.main : theme.palette.primary.shade[80]}`,
  borderRadius: theme.space.xxs,
  gap: theme.space.xs,
  height: '230px',
  justifyContent: 'center',
  width: '204px'
}));

export const StyledCloudUpload = styled(CloudUpload)(({ theme }) => ({
  color: theme.palette.primary.shade[30],
  fontSize: theme.space['2xl']
}));
