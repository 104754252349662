import React, { createContext } from 'react';

import { Client, IConfig } from '@inbound/api';
import { useNavigate } from 'react-router-dom';

import EInboundRoute from 'navigation/models/EInboundRoute';

const ApiContext = createContext(new Client({} as IConfig));

const ApiProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const errorHandler = (response: Response) => {
    if (response.status === 401) {
      navigate(EInboundRoute.Logout);
    }
  };

  const getHeaders = () => {
    let authToken = '';
    const contentLanguage = localStorage.getItem('language') || '';

    const userSecurityDataStorageKey = `oidc.user:${process.env.REACT_APP_STS_AUTHORITY}:${process.env.REACT_APP_STS_CLIENT_ID}`;
    const userSecurityData = sessionStorage.getItem(userSecurityDataStorageKey);

    if (userSecurityData) {
      const { access_token } = JSON.parse(userSecurityData);

      authToken = access_token ? `Bearer ${access_token}` : '';
    }

    return {
      ...(authToken && { Authorization: authToken }),
      ...(contentLanguage && { ['Content-Language']: contentLanguage })
    };
  };

  const api = new Client({ errorHandler, getHeaders }, process.env.REACT_APP_API_URL_ROOT);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

const ApiConsumer = ApiContext.Consumer;

export { ApiConsumer, ApiProvider };

export default ApiContext;
