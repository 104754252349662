import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const warehouseAppBarStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      height: '64px',
      backgroundColor: theme.palette.primary.contrastText,
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 24px',
      boxShadow: '0 2px 4px 0 rgba(68, 68, 66, 0.05)',
      borderBottom: `2px solid ${secondaryNeutral.light75}`
    },

    clickableTitle: {
      color: secondaryNeutral.light40,
      fontSize: '18px',
      fontWeight: 600,
      cursor: 'pointer'
    },
    nonClickableTitle: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600
    },

    statusNonClickable: {
      color: theme.palette.secondary.main,
      fontSize: '14px'
    },
    closeIcon: {
      marginLeft: 'auto',
      cursor: 'pointer'
    }
  });
});

export default warehouseAppBarStyles;
