import { LayoutHerodias } from '@lux-ds/content-layouts';
import DangerousIcon from '@mui/icons-material/Dangerous';

import { css, styled } from 'utils/styles';

export const StyledDangerousIcon = styled(DangerousIcon)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    height: ${theme.space.s};
    margin-top: 2px;
    width: ${theme.space.s};
  `
);

export const StyledHerodias = styled(LayoutHerodias)(
  ({ theme }) => css`
    align-items: flex-start;
    background-color: ${theme.palette.error.light};
    border-radius: ${theme.space.xxs};
    gap: ${theme.space.xs};
    padding: ${theme.space.xs} 12px;
    width: 434px;
  `
);
