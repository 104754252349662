import React from 'react';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@luxclusif/material';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TInteriorDamageLocationCounts } from 'models/warehouse';
import { interiorDamageLocationKeys } from 'pages/WarehouseList/constants';
import { IQualityCheckFormInputs } from 'pages/WarehouseList/schemas/qualityCheckSchema';

import BagLocation from '../../components/BagLocation/BagLocation';

import qualityCheckTableStyles from '../../qualityCheckTable.styles';

interface IProps {
  count: TInteriorDamageLocationCounts;
  form: UseFormMethods<IQualityCheckFormInputs>;
  isRegularFlow: boolean;
}

const Interior: React.FC<IProps> = ({ count, form, isRegularFlow }) => {
  const { t } = useTranslation('warehouse');
  const classes = qualityCheckTableStyles();

  const headers = [
    {
      key: 'parts',
      label: t('qualityCheckStation.parts')
    },
    {
      key: 'stained',
      label: t('qualityCheckStation.damageCategory.stained')
    },
    {
      key: 'otherDamages',
      label: t('qualityCheckStation.damageCategory.otherDamages')
    }
  ];

  const getTableHeaders = () =>
    headers.map(({ key, label }) => (
      <TableCell key={key} align="left" className={classes.cellBorder}>
        <b>{label}</b>
      </TableCell>
    ));

  const getTableRows = () =>
    interiorDamageLocationKeys.map(key => (
      <TableRow key={key}>
        <TableCell component="th" scope="row" className={`${classes.cellBorder} ${classes.rowLabelCell}`}>
          <div className={classes.damageColumn}>
            <span className={classes.rowLabel}>{t(`qualityCheckStation.damageLocation.${key}`)}</span>
            <br />
            <span className={classes.damageCount}>{`${t('qualityCheckStation.damages')}: ${
              count[key].isChecked
            }`}</span>
          </div>
        </TableCell>

        <BagLocation name={key} stained otherDamages form={form} isRegularFlow={isRegularFlow} />
      </TableRow>
    ));

  return (
    <div className={classes.container}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item md={12}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>{getTableHeaders()}</TableRow>
              </TableHead>
              <TableBody>{getTableRows()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Interior;
