import React, { useEffect } from 'react';

import { AuthService } from '@luxclusif/material';

const SilentRenew: React.FC = () => {
  useEffect(() => {
    AuthService.getUserManager()
      .signinSilentCallback()
      .catch(e => {
        console.error(e);
      });
  }, []);

  return null;
};

export default SilentRenew;
