import React, { useEffect, useState } from 'react';

import { ESupplierCluster, KeyValuePairModelOfIntegerAndString } from '@inbound/api';
import { Autocomplete, CircularProgress, MenuItem, SelectProps, TextField } from '@luxclusif/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useDebounce from 'hooks/useDebounce';
import {
  IBusinessModelSupplierInfo,
  TGetAllSupplierEntityOptions,
  TGetAllSupplierOptions
} from 'models/purchaseOrders';
import { EBusinessModelsId } from 'models/supplier';
import { defaultValues } from 'pages/PurchaseOrder/schema';
import purchaseOrdersService from 'services/PurchaseOrders.service';

import purchaseOrderStyles from 'pages/PurchaseOrder/purchaseOrder.styles';

import orderInformationStyles from '../../orderInformation.styles';

const SUPPLIER_CLUSTER_ENABLED = process.env.REACT_APP_ENABLE_SUPPLIER_CLUSTER === 'true';

const SupplierSection: React.FC = () => {
  const form = useFormContext();
  const { errors, isDirty } = form.formState;

  const watchSupplierClusterId = useWatch<string>({ defaultValue: '', name: 'supplierClusterId' });
  const watchSupplierId = useWatch<string>({ defaultValue: '', name: 'supplierId' });
  const watchSupplierName = useWatch<string>({ defaultValue: '', name: 'supplierName' });
  const watchEntityCompanyId = useWatch<string>({ defaultValue: '', name: 'entityCompanyId' });
  const watchBusinessModelId = useWatch<string>({ defaultValue: '', name: 'businessModelId' });
  const watchConsignmentStartDate = useWatch<string>({ defaultValue: '', name: 'consignmentStartDate' });

  const { t } = useTranslation(['common', 'purchaseOrders']);

  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [supplierSearch, setSupplierSearch] = useState('');
  const [supplierOptions, setSupplierOptions] = useState<TGetAllSupplierOptions>([]);

  const [loadingEntities, setLoadingEntities] = useState(false);
  const [loadingSupplierInfo, setLoadingSupplierInfo] = useState(false);

  const [supplierEntityOptions, setSupplierEntityOptions] = useState<TGetAllSupplierEntityOptions>([]);
  const [businessModelOptions, setBusinessModelOptions] = useState<IBusinessModelSupplierInfo[]>([]);

  const [clusters, setClusters] = useState<KeyValuePairModelOfIntegerAndString[]>([]);

  const commonClasses = purchaseOrderStyles();
  const orderInformationClasses = orderInformationStyles();

  const debouncedSupplierSearch = useDebounce(supplierSearch);

  const getSupplier = async () => {
    setLoadingSuppliers(true);

    const suppliers = await purchaseOrdersService.getAllSupplierOptions(debouncedSupplierSearch);

    setSupplierOptions(suppliers);
    setLoadingSuppliers(false);
  };

  const getEntityOptions = async () => {
    setLoadingEntities(true);
    const newSupplierEntityOptions = await purchaseOrdersService.getAllSupplierEntityOptions();

    setSupplierEntityOptions(newSupplierEntityOptions);
    setLoadingEntities(false);
  };

  const getSupplierInfo = async () => {
    setLoadingSupplierInfo(true);

    const {
      businessModelList,
      clusters = [],
      entityCompany
    } = await purchaseOrdersService.getSupplierPurchaseInfo(watchSupplierId);

    setBusinessModelOptions(businessModelList);

    if (SUPPLIER_CLUSTER_ENABLED) {
      setClusters(clusters.map(cluster => ({ key: cluster, value: ESupplierCluster[cluster] })));
    }

    if ((isDirty && watchSupplierId) || !watchEntityCompanyId) {
      form.setValue('entityCompanyId', entityCompany);
    }

    setLoadingSupplierInfo(false);
  };

  useEffect(() => {
    if (watchBusinessModelId !== EBusinessModelsId.PhysicalConsignment) {
      form.setValue('consignmentEndDate', defaultValues.consignmentEndDate);
      form.setValue('consignmentStartDate', defaultValues.consignmentStartDate);
    }
  }, [watchBusinessModelId]);

  useEffect(() => {
    if (watchSupplierId && watchSupplierName && !supplierOptions.length) {
      setSupplierOptions([
        {
          id: watchSupplierId,
          name: watchSupplierName
        }
      ]);
    }
  }, [watchSupplierId, watchSupplierName]);

  useEffect(() => {
    if (watchSupplierId) {
      if (isDirty) {
        form.setValue('businessModelId', defaultValues.businessModelId);
      }

      getSupplierInfo();
    } else if (isDirty && !watchSupplierId) {
      form.setValue('entityCompanyId', defaultValues.entityCompanyId);
      form.setValue('businessModelId', defaultValues.businessModelId);

      if (SUPPLIER_CLUSTER_ENABLED) {
        form.setValue('supplierClusterId', defaultValues.supplierClusterId);
      }
    }
  }, [watchSupplierId]);

  useEffect(() => {
    if (debouncedSupplierSearch.trim() === supplierSearch && debouncedSupplierSearch.length >= 3) {
      getSupplier();
    }
  }, [debouncedSupplierSearch]);

  useEffect(() => {
    if (SUPPLIER_CLUSTER_ENABLED) {
      const clusterId = clusters.find(cluster => cluster?.key === parseInt(watchSupplierClusterId));

      if (!clusterId && isDirty) {
        form.setValue('supplierClusterId', defaultValues.supplierClusterId);
      }
    }
  }, [clusters, isDirty, watchSupplierClusterId]);

  useEffect(() => {
    getEntityOptions();
  }, []);

  /* Generate and enable options once supplier has been chosen */
  const otherOptionsProps: Partial<SelectProps> = {
    IconComponent:
      loadingEntities && loadingSupplierInfo ? props => <CircularProgress size={16} {...props} /> : undefined,
    MenuProps: {
      PaperProps: {
        className: orderInformationClasses.selectMenu
      },
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom'
      }
    },
    disabled: !watchSupplierId || loadingEntities || loadingSupplierInfo
  };

  return (
    <>
      <h4 className={commonClasses.formSectionTitle}>{t('common:supplier')}</h4>
      <div className={orderInformationClasses.inputsContainer}>
        <Controller
          control={form.control}
          name="supplierId"
          render={({ ref, ...controlProps }) => (
            <Autocomplete
              {...controlProps}
              filterOptions={x => x}
              getOptionLabel={option => option?.name || ''}
              loading={loadingSuppliers}
              loadingText={`${t('purchaseOrders:loading')}...`}
              noOptionsText={t('purchaseOrders:searchSupplierName')}
              value={
                supplierSearch.length
                  ? {
                      id: 'search',
                      name: supplierSearch
                    }
                  : supplierOptions.find(option => option.id === controlProps.value) || null
              }
              onChange={(_, newValue) => {
                setSupplierSearch(newValue?.name || '');
                controlProps.onChange(newValue?.id || defaultValues.supplierId);
              }}
              options={supplierOptions}
              popupIcon={null}
              renderInput={inputParams => (
                <TextField
                  {...inputParams}
                  className={orderInformationClasses.inputSupplierName}
                  error={!!errors.supplierId}
                  inputRef={ref}
                  label={`${t('purchaseOrders:poInformation.supplierName')} *`}
                  onChange={({ target }) => setSupplierSearch(target.value)}
                  value={supplierSearch}
                />
              )}
            />
          )}
        />

        <Controller
          control={form.control}
          name="entityCompanyId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.entityCompanyId}
              inputRef={ref}
              label={`${t('purchaseOrders:poInformation.entityCompany')} *`}
              select
              SelectProps={otherOptionsProps}
            >
              {supplierEntityOptions.length ? (
                supplierEntityOptions.map(supplier => (
                  <MenuItem key={supplier.entityCompanyEnum} value={supplier.entityCompanyEnum}>
                    {supplier.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">{t('purchaseOrders:poInformation.entityNotFound')}</MenuItem>
              )}
            </TextField>
          )}
        />

        <Controller
          control={form.control}
          name="businessModelId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors?.businessModelId}
              inputRef={ref}
              label={`${t('common:businessModel')} *`}
              select
              SelectProps={otherOptionsProps}
            >
              {businessModelOptions.length ? (
                businessModelOptions.map(businessModel => (
                  <MenuItem key={businessModel.businessModelId} value={businessModel.businessModelEnum}>
                    {businessModel.businessModelName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">{t('purchaseOrders:poInformation.businessModelNotFound')}</MenuItem>
              )}
            </TextField>
          )}
        />

        {SUPPLIER_CLUSTER_ENABLED && (
          <Controller
            control={form.control}
            name="supplierClusterId"
            render={({ ref, ...controlProps }) => (
              <TextField
                {...controlProps}
                error={!!errors?.supplierClusterId}
                inputRef={ref}
                label={`${t('common:supplierCluster')} *`}
                select
                SelectProps={otherOptionsProps}
              >
                {clusters.map(({ key, value }) => (
                  <MenuItem key={key as number} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        )}

        {watchBusinessModelId === EBusinessModelsId.PhysicalConsignment && !SUPPLIER_CLUSTER_ENABLED && (
          <>
            <TextField
              error={!!errors?.consignmentStartDate}
              inputRef={form.register}
              label={`${t('purchaseOrders:poInformation.startDate')} *`}
              name="consignmentStartDate"
              InputLabelProps={{ shrink: true }}
              type="date"
            />
            <TextField
              disabled={!watchConsignmentStartDate}
              error={!!errors?.consignmentEndDate}
              inputRef={form.register}
              label={`${t('purchaseOrders:poInformation.endDate')} *`}
              name="consignmentEndDate"
              InputLabelProps={{ shrink: true }}
              type="date"
            />
          </>
        )}
      </div>

      {watchBusinessModelId === EBusinessModelsId.PhysicalConsignment && SUPPLIER_CLUSTER_ENABLED && (
        <div className={orderInformationClasses.inputsContainer}>
          <TextField
            error={!!errors?.consignmentStartDate}
            inputRef={form.register}
            label={`${t('purchaseOrders:poInformation.startDate')} *`}
            name="consignmentStartDate"
            InputLabelProps={{ shrink: true }}
            type="date"
          />
          <TextField
            disabled={!watchConsignmentStartDate}
            error={!!errors?.consignmentEndDate}
            inputRef={form.register}
            label={`${t('purchaseOrders:poInformation.endDate')} *`}
            name="consignmentEndDate"
            InputLabelProps={{ shrink: true }}
            type="date"
          />
        </div>
      )}
    </>
  );
};

export default SupplierSection;
