import { Subject } from 'rxjs';

import axios from '../utils/axios';

export type GetCountryResponse = Array<{ countryId: string; name: string }>;

class GeoService {
  public static $geoRowUpdated = new Subject<any>();

  public async getCountries() {
    const url = `${process.env.REACT_APP_API_URL}Geo/country`;

    return await axios.get(url).then(response => response.data);
  }

  public async getStates(id: string) {
    const url = `${process.env.REACT_APP_API_URL}Geo/country/${id}/state`;

    return await axios.get(url).then(response => response.data);
  }

  public async getCities(id: string) {
    const url = `${process.env.REACT_APP_API_URL}Geo/state/${id}/city`;

    return await axios.get(url).then(response => response.data);
  }
}

const geoService = new GeoService();

export default geoService;
