import { createStyles, makeStyles } from '@luxclusif/material';

const returnToSupplierStyles = makeStyles(() => {
  return createStyles({
    title: {
      fontSize: '18px',
      fontWeight: 600
    },
    isSupplierReturnInput: {
      display: 'block',
      marginLeft: 0,
      marginTop: '24px'
    },
    returnReasonInput: {
      marginTop: '24px',
      width: '536px',

      '& .MuiSelect-root': {
        padding: '13px'
      }
    }
  });
});

export default returnToSupplierStyles;
