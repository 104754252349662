import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Dialog from '@lux-ds/dialog';

import { css, styled } from 'utils/styles';

export const PreviewDialog = styled(Dialog)(
  ({ theme }) => css`
    .MuiPaper-root {
      height: calc(100vh - ${theme.space['4xl']});
      max-height: 665px;
      max-width: 919px;
      overflow: hidden;
      width: calc(100vw - ${theme.space['4xl']});
    }
  `
);

export const PreviewHeader = styled(LayoutHerodias)(
  ({ theme }) => css`
    box-shadow: 0px 2px 4px rgba(68, 68, 66, 0.05);
    height: unset;
    justify-content: flex-end;
    padding: ${theme.space.m};
  `
);

export const PreviewContent = styled(LayoutJay)(
  ({ theme }) => css`
    align-items: center;
    height: calc(100% - ${theme.space['5xl']});
  `
);
