import React from 'react';

import { Grid, FormControlLabel, Radio, FormHelperText, FormControl, RadioGroup } from '@luxclusif/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Accept from 'assets/svg/accept.svg';
import Decline from 'assets/svg/decline.svg';
import { IQualityCheckFormInputs } from 'pages/WarehouseList/schemas/qualityCheckSchema';

import manualQCDecisionStyles from './manualQCDecision.styles';

interface IProps {
  form: UseFormMethods<IQualityCheckFormInputs>;
}

type TPassedQualityCheckString = 'true' | 'false';

const ManualQCDecision: React.FC<IProps> = ({ form }) => {
  const classes = manualQCDecisionStyles();
  const { t } = useTranslation('warehouse');

  return (
    <>
      <hr className={classes.divider} />
      <h2 className={classes.title}>{t('qualityCheckDecision')}</h2>
      <Controller
        name="hasPassedQualityCheck"
        control={form.control}
        defaultValue=""
        render={({ onBlur, onChange: onChangeController, value: valueController }) => {
          const passedQualityFormatted = JSON.stringify(valueController) as TPassedQualityCheckString;

          return (
            <FormControl error={!!form.errors?.hasPassedQualityCheck}>
              <RadioGroup
                value={passedQualityFormatted}
                onBlur={onBlur}
                onChange={({ target }) => onChangeController(JSON.parse(target.value) as boolean)}
              >
                <Grid container className={classes.decisionContainer} spacing={0}>
                  <Grid item>
                    <FormControlLabel
                      aria-checked={passedQualityFormatted === 'true'}
                      className={classes.boxContainer}
                      value="true"
                      control={<Radio className={classes.radioButton} />}
                      label={
                        <div className={classes.label}>
                          <img src={Accept} alt="accept" className={classes.acceptNotAcceptIcon} />
                          <span>{t('itemOk')}</span>
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      aria-checked={passedQualityFormatted === 'false'}
                      className={classes.boxContainer}
                      value="false"
                      control={<Radio className={classes.radioButton} />}
                      label={
                        <div className={classes.label}>
                          <img src={Decline} alt="decline" className={classes.acceptNotAcceptIcon} />
                          <span>{t('itemNotOk')}</span>
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <FormHelperText>
                {form.errors?.hasPassedQualityCheck ? t('reviewStation.itemCheckupRequired') : ''}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
      <hr className={classes.divider} />
    </>
  );
};

export default ManualQCDecision;
