import React, { useMemo } from 'react';

import { Button, IconButton, MaterialIcons } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import { EDamageSeverityLevel } from 'models/warehouse';

import { getSeverityLabels } from './utils';

import damageSeverityTickerStyles from './damageSeverityTicker.styles';

enum EChangeType {
  Decrease = 'Decrease',
  Increase = 'Increase'
}

interface IProps {
  isAdditional: boolean;
  isDisabled?: boolean;
  isRegularFlow: boolean;
  label: string;
  onChange: (newLevel: EDamageSeverityLevel | null) => void;
  value: EDamageSeverityLevel | null;
}

const DamageSeverityTicker: React.FC<IProps> = ({
  isAdditional,
  isDisabled,
  isRegularFlow,
  label,
  onChange,
  value
}) => {
  const classes = damageSeverityTickerStyles();

  const isAdditionalDefault = !isRegularFlow && !isAdditional && value;
  const isAllowDecrease = useMemo<boolean>(() => (isAdditionalDefault ? false : true), []);
  const initialValue = useMemo<EDamageSeverityLevel | null>(() => (isAdditionalDefault ? value : null), []);

  const { t } = useTranslation('warehouse');

  const valueStringified = !value ? 'null' : value;
  const severityLabels = getSeverityLabels(t);

  const severityLevels = [null, EDamageSeverityLevel.Low, EDamageSeverityLevel.Medium, EDamageSeverityLevel.High];
  const currentValueIndex = severityLevels.findIndex(severityLevel => value === severityLevel);
  const severityLastIndex = severityLevels.length - 1;

  const isDefaultDecreaseLimit = !isAllowDecrease && initialValue === value;
  const containerColorClass = isDefaultDecreaseLimit
    ? classes.containerDefaultLevelColor
    : classes.containerLevelColors;
  const tickerColorClass = isDefaultDecreaseLimit ? classes.tickerDefaultColor : classes.tickerColor;
  const tickerDecreaseClass = isDefaultDecreaseLimit ? classes.buttonTickerDecreaseDefault : '';
  const mainLabelColorClass = isDefaultDecreaseLimit ? classes.labelDefaultColor : classes.mainLabelColors;
  const subLabelColorClass = isDefaultDecreaseLimit ? classes.labelDefaultColor : classes.subLabelColors;

  const onChangeSeverity = (type: EChangeType) => {
    if (type === EChangeType.Increase) {
      onChange(severityLevels[currentValueIndex + 1]);
    } else if (type === EChangeType.Decrease) {
      onChange(severityLevels[currentValueIndex - 1]);
    }
  };

  return (
    <Button
      aria-value-now={valueStringified}
      className={`${classes.container} ${containerColorClass}`}
      disabled={isDisabled}
      onClick={() => (currentValueIndex === 0 ? onChangeSeverity(EChangeType.Increase) : null)}
    >
      {value && (
        <IconButton
          aria-value-now={valueStringified}
          disabled={isDefaultDecreaseLimit}
          className={`${classes.buttonTicker} ${tickerColorClass} ${tickerDecreaseClass}`}
          onClick={() => onChangeSeverity(EChangeType.Decrease)}
        >
          <MaterialIcons.Remove />
        </IconButton>
      )}
      <div className={classes.label}>
        <div aria-value-now={valueStringified} className={`${classes.mainLabel} ${mainLabelColorClass}`}>
          {label}
        </div>
        <div aria-value-now={valueStringified} className={`${classes.subLabel} ${subLabelColorClass}`}>
          {severityLabels[valueStringified]}
        </div>
      </div>
      {value && (
        <IconButton
          aria-value-now={valueStringified}
          className={`${classes.buttonTicker} ${tickerColorClass} ${classes.buttonTickerIncrease}`}
          disabled={currentValueIndex === severityLastIndex}
          onClick={() => onChangeSeverity(EChangeType.Increase)}
        >
          <MaterialIcons.Add />
        </IconButton>
      )}
    </Button>
  );
};

export default DamageSeverityTicker;
