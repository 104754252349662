import React, { useMemo } from 'react';

import { LayoutAriel, LayoutJay } from '@lux-ds/content-layouts';
import Input from '@lux-ds/input';
import Typography from '@lux-ds/typography';
import { generateDataCy } from '@lux-ds/utils-functions';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { IItemStorageDetailsSchema } from 'pages/WarehouseList/schemas/storageSchema';

import LoadingContainer from '../common/LoadingContainer';

import { StyledHerodias, StyledInfoIcon } from './storage.styles';

const Storage: React.FC = () => {
  const { t } = useTranslation(['common', 'warehouse']);
  const { isViewMode } = useWarehouseItemContext();
  const { isDoneAutomatically, isStationLoading, warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IItemStorageDetailsSchema>, [warehouseForm]);

  const { errors } = form;

  return (
    <LayoutJay gap="l" paddingBottom="5xl">
      {isStationLoading ? (
        <LoadingContainer />
      ) : (
        <>
          <Typography data-cy={generateDataCy('stationTitle')} variant="h5">
            {t('warehouse:storageStation.storageLocation')}
          </Typography>
          <LayoutAriel elements={3}>
            <Controller
              control={form.control}
              name="storedAt"
              render={({ ref, ...props }) => (
                <Input
                  {...props}
                  error={!!errors.storedAt}
                  inputRef={ref}
                  disabled={isViewMode}
                  label={t('warehouse:storageStation.coordinates')}
                />
              )}
            />
          </LayoutAriel>
          {isDoneAutomatically && (
            <StyledHerodias>
              <StyledInfoIcon />
              <Typography variant="bodyTextSmall">
                {t('warehouse:storageStation.storageAutomaticDescription')}
              </Typography>
            </StyledHerodias>
          )}
        </>
      )}
    </LayoutJay>
  );
};

export default Storage;
