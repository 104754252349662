import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const decisionFormStyles = makeStyles(({ palette }) => {
  return createStyles({
    container: {
      background: palette.common.white,
      width: '25%'
    },
    title: {
      borderBottom: `1px solid ${secondaryNeutral.light75}`,
      fontWeight: 600,
      fontSize: '18px',
      margin: 0,
      padding: '24px'
    },
    formContainer: {
      padding: '31px 23px'
    },
    formLabel: {
      color: secondaryNeutral.normal,
      fontSize: '16px',
      lineHeight: 1,
      marginBottom: '19px',
      '& .MuiSvgIcon-root': {
        fontSize: '14px',
        marginLeft: '10px',
        cursor: 'pointer'
      }
    },
    formSubmitButton: {
      marginTop: '28px'
    },

    // Helper Modal
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      width: '601px',
      maxHeight: '100vh',
      overflowY: 'auto',
      padding: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    decisionHelperTitle: {
      color: '#131113',
      fontSize: '20px',
      fontWeight: 700,
      marginBottom: '24px',
      marginTop: 0,
      lineHeight: 1
    },
    closeButton: {
      padding: '4px',
      position: 'absolute',
      right: '16px',
      top: '20px'
    },
    decisionHelperQuestion: {
      color: '#131113',
      fontSize: '16px',
      fontWeight: 700,
      margin: 0
    },

    inboundFlowList: {
      padding: '32px 24px 0'
    },
    inboundFlowContainer: {
      marginBottom: '41px',
      '&:last-child': {
        marginBottom: 0
      }
    },
    inboundFlowLabel: {
      color: '#7D717E',
      fontWeight: 400,
      marginTop: 0,
      marginBottom: '8px'
    },
    inboundFlow: {
      color: '#131113',
      fontWeight: 400,
      marginTop: 0,
      marginBottom: '16px'
    },
    divider: {
      borderTop: `1px ${secondaryNeutral.light75} solid`,
      border: 0,
      margin: 0
    },
    inboundInstructions: {
      marginTop: '16px',
      marginLeft: '14px',
      display: 'grid',
      gridTemplateColumns: '1fr 300px',
      gridTemplateRows: 'repeat(2, auto)',
      gridColumnGap: '8px',
      gridRowGap: '16px'
    },
    inboundAction: {
      alignSelf: 'center',
      color: '#131113',
      fontWeight: 400,
      margin: 0
    },
    inboundActionDescription: {
      color: '#484148',
      fontSize: '12px',
      margin: 0
    }
  });
});

export default decisionFormStyles;
