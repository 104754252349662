import React from 'react';

import { Box, IconButton, MaterialIcons, TextField } from '@luxclusif/material';
import { Controller, SetValueConfig } from 'react-hook-form';

import luxNumberInputStyles from './luxNumberInput.styles';

interface IProps {
  addIconClassName?: string;
  className?: any | undefined;
  control: any;
  disabled?: boolean;
  errors: any;
  fullWidth?: boolean | undefined;
  inputClassName?: string;
  inputProps?: any | undefined;
  label: string | undefined;
  minusIconClassName?: string;
  name: string;
  setValue: (name: string, value: any, config?: SetValueConfig) => void;
  size?: 'small' | 'medium' | undefined;
  variant: 'filled' | 'outlined' | 'standard' | undefined;
  watch: (name: string) => any;
}

const LuxNumberInput: React.FC<IProps> = ({
  addIconClassName,
  className,
  control,
  disabled,
  errors,
  fullWidth,
  inputClassName,
  inputProps,
  label,
  minusIconClassName,
  name,
  setValue,
  size,
  variant,
  watch
}) => {
  const classes = luxNumberInputStyles();

  const increaseHandler = (value: string) => {
    setValue(name, parseInt(value) + 1, { shouldDirty: true, shouldValidate: true });
  };

  const decreaseHandler = (value: string) => {
    if (parseInt(value) !== 0) {
      setValue(name, parseInt(value) - 1, { shouldDirty: true, shouldValidate: true });
    }
  };

  return (
    <div className={className}>
      <h1 className={classes.label}>{label}</h1>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Box>
          <IconButton
            color="secondary"
            className={`${addIconClassName} ${classes.minus} ${classes.incrementIconButton}`}
            component="button"
            onClick={() => decreaseHandler(watch(name))}
          >
            <MaterialIcons.Remove />
          </IconButton>
        </Box>
        <Box>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ onBlur, onChange, value }) => (
              <TextField
                type="number"
                value={value}
                className={`${inputClassName} ${classes.removeArrow}`}
                style={{ height: 32 }}
                onChange={onChange}
                onBlur={onBlur}
                InputLabelProps={{
                  classes: {
                    root: classes.fontSize
                  }
                }}
                inputProps={inputProps ? inputProps : {}}
                variant={variant}
                fullWidth={fullWidth}
                error={errors[name] ? true : false}
                disabled={disabled}
                size={size ? size : 'small'}
              />
            )}
          />
        </Box>
        <Box>
          <IconButton
            color="secondary"
            className={`${minusIconClassName}
          ${classes.addition} ${classes.incrementIconButton}`}
            component="button"
            onClick={() => increaseHandler(watch(name))}
          >
            <MaterialIcons.Add />
          </IconButton>
        </Box>
      </Box>
    </div>
  );
};

export default LuxNumberInput;
