import { createStyles, makeStyles } from '@luxclusif/material';

const damagePhotosStyles = makeStyles(({ palette }) => {
  return createStyles({
    hasPhotoLabel: {
      color: '#7D717E',
      display: 'block',
      fontSize: '11px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    errorPhotoLabel: {
      color: palette.error.main,
      fontSize: '12px'
    },
    noPhotoLabel: {
      display: 'block',
      fontSize: '12px',
      marginTop: '16px'
    }
  });
});

export default damagePhotosStyles;
