import { createStyles, makeStyles } from '@luxclusif/material';

const welcomeCardStyles = makeStyles(() => {
  return createStyles({
    card: {
      display: 'flex',
      minHeight: '256px',
      padding: '32px'
    },
    leftColumn: {
      alignItems: 'flex-start',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginRight: '32px'
    },
    cardInfo: {
      display: 'flex',
      flexDirection: 'column'
    },
    cardTitle: {
      fontSize: '28px',
      fontWeight: 600,
      lineHeight: '34px'
    },
    cardDescription: {
      fontSize: '16px',
      lineHeight: '22px',
      marginTop: '8px'
    },
    cardLink: {
      marginTop: '24px',
      textDecoration: 'none'
    },
    cardButton: {
      paddingLeft: '16px',
      paddingRight: '16px'
    },
    cardImage: {
      alignSelf: 'center',
      height: '120px',
      width: '180px'
    }
  });
});

export default welcomeCardStyles;
