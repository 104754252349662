import { createStyles, makeStyles, Theme, secondaryNeutral } from '@luxclusif/material';

const inclusionsStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '18px',
      paddingBottom: '10px',
      paddingTop: '10px'
    },
    inclusionsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1px 1fr',
      gap: '24px'
    },
    inclusionType: {
      '& label': {
        fontSize: '12px',
        color: secondaryNeutral.light40,
        display: 'block',
        marginBottom: '8px'
      }
    },
    inclusionDivider: {
      backgroundColor: secondaryNeutral.light75,
      width: '1px'
    },

    chipsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px'
    }
  });
});

export default inclusionsStyles;
