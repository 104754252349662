import React from 'react';

import { Grid } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import { IOnHoldItemInfo } from 'models/warehouse/onHold';

import reviewDetailsStyles from '../../onHold.styles';

interface IProps {
  brand: IOnHoldItemInfo['brand'];
  category: IOnHoldItemInfo['category'];
  description: IOnHoldItemInfo['description'];
  madeInCountry: IOnHoldItemInfo['madeInCountry'];
  materials: IOnHoldItemInfo['materials'];
  name: IOnHoldItemInfo['name'];
  parentCategory: IOnHoldItemInfo['parentCategory'];
  sellerCode: IOnHoldItemInfo['sellerCode'];
  serialNumber: IOnHoldItemInfo['serialNumber'];
  size: IOnHoldItemInfo['size'];
  sku: IOnHoldItemInfo['sku'];
  warehouse: IOnHoldItemInfo['warehouse'];
}

const ItemDetails: React.FC<IProps> = ({
  brand,
  category,
  description,
  madeInCountry,
  materials,
  name,
  parentCategory,
  sellerCode,
  serialNumber,
  size,
  sku,
  warehouse
}) => {
  const classes = reviewDetailsStyles();
  const { t } = useTranslation(['common', 'warehouse']);

  return (
    <>
      <h1 className={classes.title}>{t('warehouse:itemDetails')}</h1>
      <Grid container spacing={1}>
        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('common:brand')}</h3>
          <h3 className={classes.warehouseTextDetails}>{brand}</h3>
        </Grid>

        <Grid item md={4}>
          <h3 className={classes.warehouseTextTitle}>{t('common:name')}</h3>
          <h3 className={classes.warehouseTextDetails}>{name}</h3>
        </Grid>

        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('common:sellerCode')}</h3>
          <h3 className={classes.warehouseTextDetails}>{sellerCode}</h3>
        </Grid>

        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('common:sku')}</h3>
          <h3 className={classes.warehouseTextDetails}>{sku}</h3>
        </Grid>

        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('warehouse:serialNumber')}</h3>
          <h3 className={classes.warehouseTextDetails}>{serialNumber}</h3>
        </Grid>

        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('common:category')}</h3>
          <h3 className={classes.warehouseTextDetails}>{category}</h3>
        </Grid>

        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('warehouse:parentCategory')}</h3>
          <h3 className={classes.warehouseTextDetails}>{parentCategory}</h3>
        </Grid>

        <Grid item md={4}>
          <h3 className={classes.warehouseTextTitle}>{t('warehouse:material')}</h3>
          <h3 className={classes.warehouseTextDetails}>{materials[0]?.name}</h3>
        </Grid>

        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('warehouse:size')}</h3>
          <h3 className={classes.warehouseTextDetails}>{size}</h3>
        </Grid>

        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('warehouse:madeIn')}</h3>
          <h3 className={classes.warehouseTextDetails}>{madeInCountry}</h3>
        </Grid>

        <Grid item md={2}>
          <h3 className={classes.warehouseTextTitle}>{t('common:warehouse')}</h3>
          <h3 className={classes.warehouseTextDetails}>{warehouse}</h3>
        </Grid>
        <Grid item md={10}>
          <h3 className={classes.warehouseTextTitle}>{t('warehouse:description')}</h3>
          <h3 className={classes.warehouseTextDetails}>{description}</h3>
        </Grid>
      </Grid>
    </>
  );
};

export default ItemDetails;
