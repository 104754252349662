import { createStyles, makeStyles } from '@luxclusif/material';

const selectStyles = makeStyles(() => {
  return createStyles({
    resize: {
      fontSize: 15,
      marginRight: 30
    }
  });
});

export default selectStyles;
