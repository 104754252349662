import React, { useState } from 'react';

import { EPhotoType2 } from '@inbound/api';
import { useSnackbar } from 'notistack';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import UploadImages from 'components/Input/UploadImagesNew/UploadImages';
import {
  DamageLocationKeys,
  HardwareDamageKeys,
  InteriorExteriorDamageOtherDamagesKeys,
  InteriorExteriorDamageStainedKeys
} from 'models/warehouse';
import {
  defaultDamage,
  IDamage,
  IInteriorExteriorDamages,
  defaultHardwareDamages,
  defaultInteriorExteriorDamages,
  THardwareDamages,
  IQualityCheckFormInputs
} from 'pages/WarehouseList/schemas/qualityCheckSchema';
import warehouseService from 'services/Warehouse.service';

import damagePhotosStyles from './damagePhotos.styles';

interface IProps {
  form: UseFormMethods<IQualityCheckFormInputs>;
  hardware?: boolean;
  name: DamageLocationKeys;
  otherDamages?: boolean;
  stained?: boolean;
}

const DamagePhotos: React.FC<IProps> = ({ form, hardware, name, otherDamages, stained }) => {
  const classes = damagePhotosStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { t } = useTranslation(['common', 'warehouse']);
  const { formState, getValues, setValue, watch } = form;
  const { errors } = formState;

  const renderStained = (key: InteriorExteriorDamageStainedKeys) => {
    const nameKey = `${name}.stained.${key}`;
    const formValue = watch(nameKey, defaultDamage) as IDamage;
    const isError = !!(errors?.[name] as IInteriorExteriorDamages)?.stained?.[key];

    const damageCategoryLabel = t('warehouse:qualityCheckStation.damageCategory.stained');
    const damageLabel = t(`warehouse:qualityCheckStation.damage.${key}`);
    const locationLabel = t(`warehouse:qualityCheckStation.damageLocation.${name}`);
    const mainLabel = `${locationLabel} - ${damageCategoryLabel} - ${damageLabel}`;

    const showUploadImage = formValue.isChecked && formValue.isAdditional;
    const imageValue = formValue.photoUrl
      ? [
          {
            id: formValue.photoUrl,
            label: <label className={classes.hasPhotoLabel}>{mainLabel}</label>,
            url: formValue.photoUrl
          }
        ]
      : [];

    return (
      showUploadImage && (
        <div key={nameKey}>
          <UploadImages
            error={isError}
            isUploading={isUploading}
            onDeletePhoto={() =>
              setValue(nameKey, { ...formValue, photoUrl: null }, { shouldDirty: true, shouldValidate: true })
            }
            onUpload={async (files: File[]) => {
              try {
                setIsUploading(true);

                const photoUrl = await warehouseService.uploadStationPhoto(
                  getValues('itemProcessId'),
                  EPhotoType2.QC,
                  files[0]
                );

                setValue(nameKey, { ...formValue, photoUrl }, { shouldDirty: true, shouldValidate: true });
              } catch (err) {
                enqueueSnackbar(t('common:uploadImages.uploadFail'), {
                  variant: 'error'
                });
              } finally {
                setIsUploading(false);
              }
            }}
            value={imageValue}
          />
          {imageValue.length === 0 && (
            <>
              <label className={`${classes.noPhotoLabel}${isError ? ` ${classes.errorPhotoLabel}` : ''}`}>
                {mainLabel}
              </label>
              {isError && <label className={classes.errorPhotoLabel}>*{t('common:required')}</label>}
            </>
          )}
        </div>
      )
    );
  };

  const renderOtherDamages = (key: InteriorExteriorDamageOtherDamagesKeys) => {
    const nameKey = `${name}.otherDamages.${key}`;
    const formValue = watch(nameKey, defaultDamage) as IDamage;
    const isError = !!(errors?.[name] as IInteriorExteriorDamages)?.otherDamages?.[key];

    const damageCategoryLabel = t('warehouse:qualityCheckStation.damageCategory.otherDamages');
    const damageLabel = t(`warehouse:qualityCheckStation.damage.${key}`);
    const locationLabel = t(`warehouse:qualityCheckStation.damageLocation.${name}`);
    const mainLabel = `${locationLabel} - ${damageCategoryLabel} - ${damageLabel}`;

    const showUploadImage = formValue.isChecked && formValue.isAdditional;
    const imageValue = formValue.photoUrl
      ? [
          {
            id: formValue.photoUrl,
            label: <label className={classes.hasPhotoLabel}>{mainLabel}</label>,
            url: formValue.photoUrl
          }
        ]
      : [];

    return (
      showUploadImage && (
        <div key={nameKey}>
          <UploadImages
            error={isError}
            isUploading={isUploading}
            onDeletePhoto={() =>
              setValue(nameKey, { ...formValue, photoUrl: null }, { shouldDirty: true, shouldValidate: true })
            }
            onUpload={async (files: File[]) => {
              try {
                setIsUploading(true);

                const photoUrl = await warehouseService.uploadStationPhoto(
                  getValues('itemProcessId'),
                  EPhotoType2.QC,
                  files[0]
                );

                setValue(nameKey, { ...formValue, photoUrl }, { shouldDirty: true, shouldValidate: true });
              } catch (err) {
                enqueueSnackbar(t('common:uploadImages.uploadFail'), {
                  variant: 'error'
                });
              } finally {
                setIsUploading(false);
              }
            }}
            value={imageValue}
          />

          {imageValue.length === 0 && (
            <>
              <label className={`${classes.noPhotoLabel}${isError ? ` ${classes.errorPhotoLabel}` : ''}`}>
                {mainLabel}
              </label>
              {isError && <label className={classes.errorPhotoLabel}>*{t('common:required')}</label>}
            </>
          )}
        </div>
      )
    );
  };

  const renderHardwareDamages = (key: HardwareDamageKeys) => {
    const nameKey = `${name}.${key}`;
    const formValue = watch(nameKey, defaultDamage) as IDamage;
    const isError = !!(errors?.[name] as THardwareDamages)?.[key];

    const damageCategoryLabel = t('warehouse:qualityCheckStation.hardware');
    const damageLabel = t(`warehouse:qualityCheckStation.damage.${key}`);
    const locationLabel = t(`warehouse:qualityCheckStation.damageLocation.${name}`);
    const mainLabel = `${locationLabel} - ${damageCategoryLabel} - ${damageLabel}`;

    const showUploadImage = formValue.isChecked && formValue.isAdditional;
    const imageValue = formValue.photoUrl
      ? [
          {
            id: formValue.photoUrl,
            label: <label className={classes.hasPhotoLabel}>{mainLabel}</label>,
            url: formValue.photoUrl
          }
        ]
      : [];

    return (
      showUploadImage && (
        <div>
          <UploadImages
            error={isError}
            isUploading={isUploading}
            onDeletePhoto={() =>
              setValue(nameKey, { ...formValue, photoUrl: null }, { shouldDirty: true, shouldValidate: true })
            }
            onUpload={async (files: File[]) => {
              try {
                setIsUploading(true);

                const photoUrl = await warehouseService.uploadStationPhoto(
                  getValues('itemProcessId'),
                  EPhotoType2.QC,
                  files[0]
                );

                setValue(nameKey, { ...formValue, photoUrl }, { shouldDirty: true, shouldValidate: true });
              } catch (err) {
                enqueueSnackbar(t('common:uploadImages.uploadFail'), {
                  variant: 'error'
                });
              } finally {
                setIsUploading(false);
              }
            }}
            value={imageValue}
          />
          {imageValue.length === 0 && (
            <>
              <label className={`${classes.noPhotoLabel}${isError ? ` ${classes.errorPhotoLabel}` : ''}`}>
                {mainLabel}
              </label>
              {isError && <label className={classes.errorPhotoLabel}>*{t('common:required')}</label>}
            </>
          )}
        </div>
      )
    );
  };

  if (hardware) {
    const attributes = watch(name, defaultHardwareDamages) as THardwareDamages;

    return <>{(Object.keys(attributes) as HardwareDamageKeys[]).map(renderHardwareDamages)}</>;
  }

  const attributes = watch(name, defaultInteriorExteriorDamages) as IInteriorExteriorDamages;

  return (
    <>
      {stained && (Object.keys(attributes.stained) as InteriorExteriorDamageStainedKeys[]).map(renderStained)}
      {otherDamages &&
        (Object.keys(attributes.otherDamages) as InteriorExteriorDamageOtherDamagesKeys[]).map(renderOtherDamages)}
    </>
  );
};

export default DamagePhotos;
