import React from 'react';

import { Outlet } from 'react-router-dom';

import BaseConfiguration from 'setup/BaseConfiguration';

interface IProviderWrapperProps {
  provider: React.ComponentType;
}

const ProviderWrapper: React.FC<IProviderWrapperProps> = ({ provider: Provider }) => {
  return (
    <BaseConfiguration>
      <Provider>
        <Outlet />
      </Provider>
    </BaseConfiguration>
  );
};

export default ProviderWrapper;
