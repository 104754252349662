import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const manualQCDecisionStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: 1,
      marginTop: '8px',
      marginBottom: '32px'
    },
    decisionContainer: {
      gap: '24px',
      '& a:hover, a:link, a:visited ': {
        textDecoration: 'none',
        color: 'black'
      }
    },
    divider: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '40px 0',
      padding: '0'
    },
    boxContainer: {
      justifyContent: 'center',
      border: `2px solid ${secondaryNeutral.light75}`,
      borderRadius: '4px',
      height: '72px',
      padding: '20px',
      width: '236px',
      margin: 0,
      '&[aria-checked=true]': {
        border: `2px solid ${theme.palette.common.black}`
      }
    },
    acceptNotAcceptIcon: {
      width: '26px'
    },
    radioButton: {
      display: 'none'
    },
    label: {
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      fontWeight: 900
    }
  });
});

export default manualQCDecisionStyles;
