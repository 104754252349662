import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const returnModalStyles = makeStyles((theme: Theme) => {
  return createStyles({
    submitLine: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      marginTop: 0,
      marginBottom: 0,
      padding: '0'
    },
    cancelButton: {
      color: theme.palette.secondary.light
    },
    button: {
      marginLeft: '20px',
      marginTop: '20px'
    },
    submitPanel: {
      marginTop: '11px'
    },
    title: {
      color: theme.palette.secondary.main,
      fontFamily: 'Nunito Sans',
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '24px'
    },

    modalUpperContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '&:focus': {
        outline: 'none'
      }
    },
    tableContainerExit: {
      width: '684px',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      padding: '20px'
    }
  });
});

export default returnModalStyles;
