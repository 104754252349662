import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { BankAccountGetListModel } from '@inbound/api';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  LuxButton,
  MaterialIcons,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@luxclusif/material';
import { useSnackbar } from 'notistack';
import { ArrayField, Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SelectInput from 'components/Input/Select/Select';
import useDebounce from 'hooks/useDebounce';
import { IBankDetails, ICountry } from 'models/supplier';
import { supplierBankSchema } from 'pages/Suppliers/schemas/bankDetailSchema';
import financesService from 'services/Finances.service';

import bankDetailsStyles from './bankDetails.styles';

const defaultValues = {
  bankAccountId: '',
  bankAccountNumber: '',
  bankAddress: '',
  bankCountry: '',
  bankCountryHTML: '',
  bankEmail: '',
  bankName: '',
  beneficiaryName: '',
  swiftOrBic: ''
};

interface IBankDetailsProps {
  countries?: ICountry[];
}

const BankDetails: React.FC<IBankDetailsProps> = ({ countries }) => {
  const classes = bankDetailsStyles();
  const { t } = useTranslation(['common', 'supplier']);
  const { enqueueSnackbar } = useSnackbar();

  const [banks, setBanks] = useState<BankAccountGetListModel[]>([]);
  const [bankSearch, setBankSearch] = useState<string>('');
  const [loadingBanks, setLoadingBanks] = useState<boolean>(false);

  const [addUpdateModal, setAddUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [toDeleteBankDetail, setToDeleteBankDetail] = useState<{ index: number; name: string } | undefined>();

  const [showFields, setShowFields] = useState<string>('');
  const { control, getValues, setValue } = useFormContext();
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'supplierBankDetails'
  });

  const bankForm = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(supplierBankSchema),
    shouldUnregister: false
  });

  const bankCountry = bankForm.watch('bankCountry');
  const debouncedBankSearch = useDebounce(bankSearch);

  const handleRadioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowFields(event.target.value);
    },
    [setShowFields]
  );

  const handleAddUpdateBank = useCallback(() => {
    const data = bankForm.getValues();

    if (showFields === 'create') {
      append(data);
      bankForm.reset(defaultValues);
      setBankSearch('');
    } else {
      setValue(`supplierBankDetails[${showFields}]`, data);
      setAddUpdateModal(true);
    }
  }, [bankForm]);

  const handleDeleteConfirm = useCallback((index: number, name: string) => {
    setDeleteModal(true);
    setToDeleteBankDetail({ index, name });
  }, []);

  const handleDeleteBank = useCallback(() => {
    if (toDeleteBankDetail) {
      remove(toDeleteBankDetail.index);
      setDeleteModal(false);
      setToDeleteBankDetail(undefined);
    }
  }, [toDeleteBankDetail]);

  useEffect(() => {
    if (showFields !== '') {
      if (showFields !== 'create') {
        const bankDetails = getValues('supplierBankDetails')[parseInt(showFields)];

        bankForm.reset(bankDetails);
        setBankSearch((bankDetails as IBankDetails).bankName);
      } else {
        bankForm.reset(defaultValues);
        setBankSearch(defaultValues.bankName || '');
      }
    }
  }, [showFields]);

  useEffect(() => {
    const fetchBanks = () => {
      setLoadingBanks(true);
      financesService
        .getBanks(bankCountry, debouncedBankSearch)
        .then(data => setBanks(data))
        .catch(() => enqueueSnackbar(t('supplier:bankDetails.errorGetBanks'), { variant: 'error' }))
        .finally(() => setLoadingBanks(false));
    };

    if (bankCountry && debouncedBankSearch.trim() === bankSearch && debouncedBankSearch.length >= 3) {
      fetchBanks();
    }
  }, [bankCountry, debouncedBankSearch]);

  const renderFields = () => (
    <>
      <Grid className={classes.containerTitle} item md={12}>
        <Divider className={classes.titleDivider} />
      </Grid>
      <Grid item md={12} paddingRight="32px">
        <Grid container spacing={4}>
          <Grid item md={4}>
            <TextField
              error={!!bankForm.errors.bankAccountNumber}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputRef={bankForm.register}
              label={`${t('supplier:bankDetails.bankAccountNumber')}*`}
              name="bankAccountNumber"
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              error={!!bankForm.errors.beneficiaryName}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputRef={bankForm.register}
              label={`${t('supplier:bankDetails.beneficiaryName')}*`}
              name="beneficiaryName"
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              error={!!bankForm.errors.bankEmail}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputRef={bankForm.register}
              label={`${t('supplier:bankDetails.bankEmail')}*`}
              name="bankEmail"
            />
          </Grid>
          <Grid item md={4}>
            <SelectInput
              control={bankForm.control}
              errors={bankForm.errors}
              defaultValue=""
              fullWidth
              label={`${t('common:country')}*`}
              name="bankCountry"
              setValue={bankForm.setValue}
            >
              {countries?.map((row: ICountry) => (
                <MenuItem key={row.countryId} value={row.countryId}>
                  {row.name}
                </MenuItem>
              ))}
            </SelectInput>
          </Grid>
          <Grid item md={4}>
            <Controller
              control={bankForm.control}
              name="bankAccountId"
              render={({ ref, ...controlProps }) => (
                <Autocomplete
                  {...controlProps}
                  filterOptions={x => x}
                  getOptionLabel={option => option?.name || ''}
                  noOptionsText={`${t('supplier:bankDetails.searchBankName')}`}
                  onChange={(_, newValue) => {
                    setBankSearch(newValue?.name || '');
                    controlProps.onChange(newValue?.id || defaultValues.bankAccountId);
                    bankForm.setValue('bankName', newValue?.name || defaultValues.bankName);
                    bankForm.setValue('swiftOrBic', newValue?.swift || defaultValues.swiftOrBic);
                  }}
                  options={banks}
                  popupIcon={null}
                  renderInput={inputParams => (
                    <TextField
                      {...inputParams}
                      inputRef={ref}
                      error={!!bankForm.errors.bankAccountId}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...inputParams.InputProps,
                        endAdornment: (
                          <>
                            {loadingBanks ? <CircularProgress color="inherit" size={20} /> : null}
                            {inputParams.InputProps.endAdornment}
                          </>
                        )
                      }}
                      label={`${t('supplier:bankDetails.bankName')}*`}
                      onChange={({ target }) => setBankSearch(target.value)}
                      value={bankSearch}
                    />
                  )}
                  value={
                    bankSearch.length
                      ? {
                          id: 'search',
                          name: bankSearch
                        }
                      : banks.find(option => option.id === controlProps.value) || null
                  }
                />
              )}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              error={!!bankForm.errors.swiftOrBic}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputRef={bankForm.register}
              label={`${t('supplier:bankDetails.swiftOrBic')}*`}
              name="swiftOrBic"
            />
          </Grid>
          <Grid item md={8}>
            <TextField
              error={!!bankForm.errors.bankAddress}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputRef={bankForm.register}
              label={`${t('supplier:bankDetails.bankAddress')}*`}
              name="bankAddress"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.buttonContainer} item md={12}>
        <Button
          color="secondary"
          disabled={!bankForm.formState.isValid}
          onClick={handleAddUpdateBank}
          variant="contained"
        >
          {showFields === 'create'
            ? t('supplier:bankDetails.addSupplierBank')
            : t('supplier:bankDetails.updateSupplierBank')}
        </Button>
      </Grid>
    </>
  );

  return (
    <>
      <div>
        <Typography className={classes.title} variant="h1">
          {t('supplier:bankDetails.supplierBankDetails')}
        </Typography>
        <RadioGroup aria-label="position" name="BankDetail" onChange={handleRadioChange} row value={showFields}>
          {fields.map((value: Partial<ArrayField<IBankDetails, 'id'>>, index) => {
            return (
              <Grid className={classes.container} container key={index} spacing={4}>
                <Grid flex={1} className={classes.containerTitle} item>
                  <FormControlLabel
                    className={classes.radioButtonText}
                    control={<Radio color="default" />}
                    label={value.bankName}
                    value={index.toString()}
                  />
                </Grid>
                <Grid className={classes.containerTitle} item>
                  <IconButton
                    color="secondary"
                    component="button"
                    onClick={() => handleDeleteConfirm(index, value.bankName as string)}
                  >
                    <MaterialIcons.Delete />
                  </IconButton>
                </Grid>
                {showFields === index.toString() && renderFields()}
              </Grid>
            );
          })}
          <Grid className={classes.container} container spacing={4}>
            <Grid className={classes.containerTitle} item md={12}>
              <FormControlLabel
                className={classes.radioButtonText}
                control={<Radio color="default" />}
                label={t('supplier:bankDetails.addNewSupplierBank')}
                value="create"
              />
            </Grid>
            {showFields === 'create' && renderFields()}
          </Grid>
        </RadioGroup>
        <Divider className={classes.divider} />
      </div>

      <Dialog onClose={() => setAddUpdateModal(false)} open={addUpdateModal}>
        <Grid className={classes.dialogContainer} container>
          <Grid item marginBottom="20px" xs={12}>
            <Typography fontWeight="bold" variant="h6">
              {t('supplier:bankDetails.modalUpdateTitle')}
            </Typography>
          </Grid>
          <Grid className={classes.dialogActions} item xs={12}>
            <LuxButton luxColor="secondary" onClick={() => setAddUpdateModal(false)} variant="contained">
              {t('common:close')}
            </LuxButton>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog open={deleteModal} onClose={() => setDeleteModal(false)}>
        <Grid className={classes.dialogContainer} container>
          <Grid item marginBottom="20px" xs={12}>
            <Typography className={classes.dialogTitle} variant="body1">
              {t('supplier:bankDetails.modalDeleteTitle')}
            </Typography>
            <Typography variant="body1">
              {t('supplier:bankDetails.modalDeleteMessage', {
                bank_name: toDeleteBankDetail?.name
              })}
            </Typography>
          </Grid>
          <Grid className={classes.dialogActions} item xs={12}>
            <Button onClick={() => setDeleteModal(false)}>{t('common:neverMind')}</Button>
            <LuxButton luxColor="secondary" onClick={handleDeleteBank} variant="contained">
              {t('common:delete')}
            </LuxButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default BankDetails;
