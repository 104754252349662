import { LOCAL_STORAGE_USER_MEASUREMENT_UNIT } from 'models/warehouse';

import { measurementUnit } from '../constants';

const INCHES_CONVERSION = 0.393701;

export const convertToMeasurementUnit = (val: number) =>
  measurementUnit[0] === localStorage.getItem(LOCAL_STORAGE_USER_MEASUREMENT_UNIT) ? val * INCHES_CONVERSION : val;

export const convertToMetric = (val: number) =>
  measurementUnit[0] === localStorage.getItem(LOCAL_STORAGE_USER_MEASUREMENT_UNIT) ? val / INCHES_CONVERSION : val;
