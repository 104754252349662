import React from 'react';

import { LuxButton, Paper } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LUX_CORE_APP_ROUTE_CHANGE } from 'constants/Events';

import welcomeCardStyles from './welcomeCard.styles';

interface IProps {
  description: string;
  image: string;
  title: string;
  url: string;
}

const WelcomeCard: React.FC<IProps> = ({ description, image, title, url }) => {
  const classes = welcomeCardStyles();
  const { t } = useTranslation('welcome');

  const handleOnClick = () => {
    document.dispatchEvent(new CustomEvent(LUX_CORE_APP_ROUTE_CHANGE, { detail: { path: url } }));
  };

  return (
    <Paper className={classes.card} variant="outlined">
      <div className={classes.leftColumn}>
        <div className={classes.cardInfo}>
          <div className={classes.cardTitle}>{title}</div>
          <div className={classes.cardDescription}>{description}</div>
        </div>
        <Link className={classes.cardLink} onClick={handleOnClick} to={url}>
          <LuxButton className={classes.cardButton} variant="outlined">{`${t('goTo')} ${title}`}</LuxButton>
        </Link>
      </div>
      <img className={classes.cardImage} src={image} />
    </Paper>
  );
};

export default WelcomeCard;
