import React from 'react';

import { EStationAlert } from 'models/warehouse';

import stationAlertChipStyles, { stationAlertColorsStyles } from './stationAlertChip.styles';

interface IProps {
  label: string;
  type: EStationAlert;
}

const StationAlertChip: React.FC<IProps> = ({ label, type }) => {
  const classes = stationAlertChipStyles();
  const classesColors = stationAlertColorsStyles();

  return (
    <div className={`${classes.container} ${classesColors[type]}`}>
      <p>{label}</p>
    </div>
  );
};

export default StationAlertChip;
