import React from 'react';

import { IconButton, Modal, Paper } from '@luxclusif/material';
import Close from '@mui/icons-material/Close';

import imageModalStyles from './imageModal.styles';

interface IProps {
  image: string;
  onClose: () => void;
}

const ImageModal: React.FC<IProps> = ({ image, onClose }) => {
  const classes = imageModalStyles();

  return (
    <Modal onClose={onClose} open>
      <Paper className={classes.modalImageContainer}>
        <IconButton className={classes.closeZoomButton} onClick={onClose}>
          <Close />
        </IconButton>
        <img src={image} />
      </Paper>
    </Modal>
  );
};

export default ImageModal;
