import React, { useEffect, useMemo } from 'react';

import { CircularProgress, MenuItem, TextField } from '@luxclusif/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  EPurchaseOrderAllStatus,
  EPurchaseOrderCurationTypes,
  EPurchaseOrderProcurementTypes,
  TGetAllStatus
} from 'models/purchaseOrders';
import { IPurchaseInfo } from 'pages/PurchaseOrder/schema';

import purchaseOrderStyles from 'pages/PurchaseOrder/purchaseOrder.styles';

import orderInformationStyles from '../../orderInformation.styles';

interface IProps {
  isLoadingOptions: boolean;
  status?: EPurchaseOrderAllStatus;
  statuses: TGetAllStatus;
}

const DetailsSection: React.FC<IProps> = ({ isLoadingOptions, status, statuses }) => {
  const form = useFormContext<IPurchaseInfo>();
  const { errors } = form.formState;

  const poNumber = useWatch<string>({ name: 'poNumber' });
  const splitFrom = useWatch<string>({ name: 'splitFrom' });

  const { t } = useTranslation(['common', 'purchaseOrders']);

  const commonClasses = purchaseOrderStyles();
  const orderInformationClasses = orderInformationStyles();

  const statusOptions = statuses
    .filter(({ id }) => {
      if (poNumber) {
        let statusCondition: boolean;

        switch (status) {
          case EPurchaseOrderAllStatus.Cancelled:
            statusCondition = false;
            break;

          case EPurchaseOrderAllStatus.Draft:
            statusCondition =
              id === EPurchaseOrderAllStatus.Draft ||
              id === EPurchaseOrderAllStatus.RecentlyPurchased ||
              id === EPurchaseOrderAllStatus.WarehouseInbound;
            break;

          case EPurchaseOrderAllStatus.Finalized:
            statusCondition = id === EPurchaseOrderAllStatus.Finalized;
            break;

          case EPurchaseOrderAllStatus.RecentlyPurchased:
          case EPurchaseOrderAllStatus.WarehouseInbound:
            statusCondition =
              id === EPurchaseOrderAllStatus.RecentlyPurchased ||
              id === EPurchaseOrderAllStatus.Review ||
              id === EPurchaseOrderAllStatus.WarehouseInbound;
            break;

          case EPurchaseOrderAllStatus.Review:
            statusCondition =
              id === EPurchaseOrderAllStatus.Finalized ||
              id === EPurchaseOrderAllStatus.RecentlyPurchased ||
              id === EPurchaseOrderAllStatus.Review ||
              id === EPurchaseOrderAllStatus.WarehouseInbound;
            break;

          default:
            return true;
        }

        return statusCondition || id === EPurchaseOrderAllStatus.Cancelled;
      }

      return (
        id === EPurchaseOrderAllStatus.Draft ||
        id === EPurchaseOrderAllStatus.RecentlyPurchased ||
        id === EPurchaseOrderAllStatus.WarehouseInbound
      );
    })
    .sort(({ name: nameA }, { name: nameB }) => (nameA > nameB ? 1 : -1));

  const curationOptions = useMemo(
    () => [
      { id: EPurchaseOrderCurationTypes.HighCurated, name: t('purchaseOrders:curationTypes.highCurated') },
      { id: EPurchaseOrderCurationTypes.Regular, name: t('purchaseOrders:curationTypes.regular') },
      { id: EPurchaseOrderCurationTypes.LowCurated, name: t('purchaseOrders:curationTypes.lowCurated') }
    ],
    []
  );

  const procurementOptions = useMemo(
    () => [
      { id: EPurchaseOrderProcurementTypes.BuyingTrip, name: t('purchaseOrders:procurementTypes.buyingTrip') },
      { id: EPurchaseOrderProcurementTypes.Remote, name: t('purchaseOrders:procurementTypes.remote') },
      { id: EPurchaseOrderProcurementTypes.SoloTrip, name: t('purchaseOrders:procurementTypes.soloTrip') }
    ],
    []
  );

  useEffect(() => {
    if (!isLoadingOptions && !poNumber) {
      form.setValue('statusId', EPurchaseOrderAllStatus.Draft);

      if (process.env.REACT_APP_ENABLE_PO_CURPRO === 'true') {
        form.setValue('curationType', EPurchaseOrderCurationTypes.HighCurated);
        form.setValue('procurementType', EPurchaseOrderProcurementTypes.Remote);
      }
    }
  }, [isLoadingOptions]);

  return (
    <>
      <h4 className={commonClasses.formSectionTitle}>{t('purchaseOrders:poInformation.poDetails')}</h4>
      <div className={orderInformationClasses.inputsContainer}>
        {poNumber && (
          <TextField
            disabled
            InputLabelProps={{ shrink: true }}
            inputRef={form.register}
            label={t('purchaseOrders:poNumber')}
            name="poNumber"
          />
        )}
        <TextField
          error={!!errors.title}
          InputLabelProps={{ shrink: true }}
          inputRef={form.register}
          label={`${t('purchaseOrders:poTitle')} *`}
          name="title"
        />

        <Controller
          control={form.control}
          name="statusId"
          render={({ ref, ...controlProps }) => (
            <TextField
              {...controlProps}
              error={!!errors.statusId}
              inputRef={ref}
              label={`${t('common:status')} *`}
              select
              SelectProps={{
                IconComponent: isLoadingOptions ? props => <CircularProgress size={16} {...props} /> : undefined,
                MenuProps: {
                  PaperProps: {
                    className: orderInformationClasses.selectMenu
                  },
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom'
                  }
                },
                disabled: isLoadingOptions
              }}
            >
              {statusOptions.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {process.env.REACT_APP_ENABLE_PO_CURPRO === 'true' ? (
          <>
            <Controller
              control={form.control}
              name="curationType"
              render={({ ref, ...controlProps }) => (
                <TextField
                  {...controlProps}
                  error={!!errors.curationType}
                  inputRef={ref}
                  label={`${t('purchaseOrders:curationType')} *`}
                  select
                  SelectProps={{
                    IconComponent: isLoadingOptions ? props => <CircularProgress size={16} {...props} /> : undefined,
                    MenuProps: {
                      PaperProps: {
                        className: orderInformationClasses.selectMenu
                      },
                      anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom'
                      }
                    },
                    disabled: isLoadingOptions
                  }}
                >
                  {curationOptions.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              control={form.control}
              name="procurementType"
              render={({ ref, ...controlProps }) => (
                <TextField
                  {...controlProps}
                  error={!!errors.procurementType}
                  inputRef={ref}
                  label={`${t('purchaseOrders:procurementType')} *`}
                  select
                  SelectProps={{
                    IconComponent: isLoadingOptions ? props => <CircularProgress size={16} {...props} /> : undefined,
                    MenuProps: {
                      PaperProps: {
                        className: orderInformationClasses.selectMenu
                      },
                      anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom'
                      }
                    },
                    disabled: isLoadingOptions
                  }}
                >
                  {procurementOptions.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </>
        ) : null}

        {splitFrom && (
          <TextField
            disabled
            InputLabelProps={{ shrink: true }}
            inputRef={form.register}
            label={t('purchaseOrders:poInformation.splitFrom')}
            name="splitFrom"
          />
        )}
      </div>
    </>
  );
};

export default DetailsSection;
