import { InboundFlowGetModel2 } from '@inbound/api';

import { EInboundFlow } from 'models/warehouse';

const checkIsRegularFlow = (inboundFlow?: InboundFlowGetModel2, additionalFlow: EInboundFlow[] = []) =>
  inboundFlow?.id
    ? [EInboundFlow.Regular, EInboundFlow.TransferNotProcessed, ...additionalFlow].includes(inboundFlow.id)
    : false;

export default checkIsRegularFlow;
