import { createStyles, makeStyles, Theme } from '@luxclusif/material';

const headerStyles = makeStyles((theme: Theme) => {
  return createStyles({
    formHeader: {
      height: '64px',
      backgroundColor: theme.palette.primary.contrastText,
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 24px'
    },
    itemTitle: {
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 300
    }
  });
});

export default headerStyles;
