import React, { useState } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  LuxButton,
  MaterialIcons,
  Radio,
  RadioGroup
} from '@luxclusif/material';
import { Controller, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IOnHoldInputs } from 'pages/WarehouseList/schemas/onHoldSchema';

import HelperModal from './HelperModal';

import decisionFormStyles from './decisionForm.styles';

interface IProps {
  form: UseFormMethods<IOnHoldInputs>;
  isSubmitting: boolean;
  onSubmit: SubmitHandler<IOnHoldInputs>;
}

const DecisionForm: React.FC<IProps> = ({ form, isSubmitting, onSubmit }) => {
  const classes = decisionFormStyles();

  const [openHelper, setOpenHelper] = useState<boolean>(false);
  const { t } = useTranslation(['common', 'warehouse']);

  const {
    formState: { errors }
  } = form;

  const toggleHelper = () => setOpenHelper(prevState => !prevState);

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>{t('warehouse:onHoldStation.decision')}</h3>
      <div className={classes.formContainer}>
        <Controller
          name="decision"
          control={form.control}
          render={({ onChange, value }) => (
            <FormControl component="fieldset" disabled={isSubmitting} variant="standard" error={!!errors?.decision}>
              <FormLabel className={classes.formLabel}>
                {t('warehouse:onHoldStation.decisionHelper.itemOnHold')}
                <MaterialIcons.HelpOutline onClick={toggleHelper} />
              </FormLabel>
              <RadioGroup value={JSON.stringify(value)} onChange={(_, value) => onChange(JSON.parse(value))}>
                <FormControlLabel value="false" control={<Radio />} label={t('warehouse:onHoldStation.reject')} />
                <FormControlLabel value="true" control={<Radio />} label={t('warehouse:onHoldStation.accept')} />
              </RadioGroup>
              {errors?.decision && <FormHelperText>{t('common:required')}</FormHelperText>}
            </FormControl>
          )}
        />
        <LuxButton
          className={classes.formSubmitButton}
          disabled={isSubmitting}
          fullWidth
          luxColor="secondary"
          variant="contained"
          onClick={form.handleSubmit(onSubmit)}
        >
          {!isSubmitting ? t('warehouse:onHoldStation.submit') : t('warehouse:pleaseWait')}
        </LuxButton>
      </div>

      {openHelper && <HelperModal onClose={toggleHelper} />}
    </div>
  );
};

export default DecisionForm;
