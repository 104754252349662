import * as yup from 'yup';

export interface IOnHoldInputs {
  decision: boolean | null;
}

export const onHoldSchema = yup.object().shape({
  decision: yup.boolean().nullable().required()
});

export const defaultOnHold: IOnHoldInputs = {
  decision: null
};
