import React, { useEffect, useState } from 'react';

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  icon: string;
}

const LuxFlagIcon: React.FC<IProps> = ({ icon, ...props }) => {
  const [flag, setFlag] = useState('');

  useEffect(() => {
    const getFlag = async () => {
      return await import(`assets/svg/flags/${icon}.svg`)
        .then(response => setFlag(response.default))
        .catch(response => console.log(response));
    };

    getFlag();
  }, []);

  return <img {...props} alt={`flag-${flag}`} src={flag} />;
};

export default LuxFlagIcon;
