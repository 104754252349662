import React from 'react';

import { TextField } from '@luxclusif/material';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IPurchaseInfo } from 'pages/PurchaseOrder/schema';

import purchaseOrderStyles from 'pages/PurchaseOrder/purchaseOrder.styles';

import orderInformationStyles from '../../orderInformation.styles';

const DatesSection: React.FC = () => {
  const form = useFormContext<IPurchaseInfo>();
  const { errors } = form.formState;

  const watchDate = form.watch('date');
  const { t } = useTranslation('purchaseOrders');

  const commonClasses = purchaseOrderStyles();
  const orderInformationClasses = orderInformationStyles();

  const budgetWeek = `${t('poInformation.week')}: ${moment(watchDate).format('WW')} · ${moment(watchDate)
    .startOf('isoWeek')
    .format('DD/MM/YYYY')} - ${moment(watchDate).endOf('isoWeek').format('DD/MM/YYYY')}`;

  return (
    <>
      <h4 className={commonClasses.formSectionTitle}>{t('poInformation.dates')}</h4>
      <div className={orderInformationClasses.inputsContainer}>
        <TextField
          error={!!errors?.date}
          inputRef={form.register}
          InputLabelProps={{ shrink: true }}
          label={`${t('purchaseDate')} *`}
          type="date"
          name="date"
        />
        <TextField
          error={!!errors?.estimatedDeliveryDate}
          inputRef={form.register}
          InputLabelProps={{ shrink: true }}
          label={`${t('estimatedDeliveryDate')} *`}
          type="date"
          name="estimatedDeliveryDate"
        />
        <TextField
          className={orderInformationClasses.budgetWeekContainer}
          disabled
          InputLabelProps={{ shrink: true }}
          label={t('poInformation.budgetWeek')}
          value={budgetWeek}
        />
      </div>
    </>
  );
};

export default DatesSection;
