import { createStyles, makeStyles, secondaryNeutral } from '@luxclusif/material';

const checkInStyles = makeStyles(() => {
  return createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '45px'
    },
    nextStepBtn: {
      marginBottom: '20px'
    },
    whiteButtonBorder: {
      border: `1px solid ${secondaryNeutral.light75}`,
      boxShadow: '1px 1px 2px #DDDDDA',
      marginBottom: '20px',
      marginRight: '15px'
    }
  });
});

export default checkInStyles;
