import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { EPhotoFrame } from '@inbound/api';
import { CircularProgress, LuxButton } from '@luxclusif/material';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormTabs from 'components/FormTabs/FormTabs';
import TabPanel from 'components/TabPanel/TabPanel';
import { IAuthUploadedPhoto } from 'models/warehouse';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { additionalPhotoFrame } from 'pages/WarehouseList/constants';
import { checkInInitialize, ICheckinInputs, IPhotoAuthFrame } from 'pages/WarehouseList/schemas/checkInSchema';
import warehouseService from 'services/Warehouse.service';

import Found from './sections/Found/Found';
import PhotoAuthentication from './sections/PhotoAuthentication/PhotoAuthentication';

import checkInStyles from './checkIn.styles';

const ENABLE_RA_INTEGRATION_MODULE = process.env.REACT_APP_ENABLE_RA_INTEGRATION_MODULE === 'true';

const CheckIn: React.FC = () => {
  const classes = checkInStyles();
  const { t } = useTranslation('warehouse');
  const { isViewMode } = useWarehouseItemContext();
  const { checkInTab, getCountries, itemDetails, onChangeCheckInTab, warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<ICheckinInputs>, [warehouseForm]);

  const checkinTabs = [
    { icon: 'MarkunreadMailbox', label: t('checkinStation.found'), value: 0 },
    { icon: 'CameraAlt', label: t('checkinStation.photoAuthentication'), value: 1 }
  ];

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { reset } = form;

  const handleChangeTab = useCallback((newValue: number) => onChangeCheckInTab(newValue), [onChangeCheckInTab]);

  const nextStepButton = useCallback(() => handleChangeTab(1), [handleChangeTab]);

  const loadCheckInStation = useCallback(async () => {
    try {
      const [getPhotoAuthConfig, getCheckInDetails] = await Promise.allSettled([
        ENABLE_RA_INTEGRATION_MODULE ? warehouseService.getPhotoAuthConfig(itemDetails.itemProcessId as string) : [],
        warehouseService.getByItemId('check-in', itemDetails.itemProcessId as string),
        getCountries()
      ]);

      if (getCheckInDetails.status === 'fulfilled' && getPhotoAuthConfig.status === 'fulfilled') {
        const { value: checkInDetails } = getCheckInDetails;
        const { value: photoAuthConfig } = getPhotoAuthConfig;

        const defaultPhotoValues = {
          photoAdditionalFrames: [additionalPhotoFrame],
          photoAuthFrames: (photoAuthConfig as IPhotoAuthFrame[]).sort(a => (a?.isRequired ? -1 : 1)),
          uploadedPhotos: ENABLE_RA_INTEGRATION_MODULE ? [] : checkInDetails.photos
        };

        const { photoAdditionalFrames, photoAuthFrames, uploadedPhotos } = ENABLE_RA_INTEGRATION_MODULE
          ? checkInDetails.photos.reduce(
              (
                {
                  photoAdditionalFrames: newPhotoAdditionalFrames,
                  photoAuthFrames: newPhotoAuthFrames,
                  uploadedPhotos: prevUploadedPhotos
                }: {
                  photoAdditionalFrames: IPhotoAuthFrame[];
                  photoAuthFrames: IPhotoAuthFrame[];
                  uploadedPhotos: IAuthUploadedPhoto[];
                },
                uploadedPhoto: IAuthUploadedPhoto
              ) => {
                const hasNoFrame = uploadedPhoto.photoFrameId === EPhotoFrame.Undefined;

                if (!hasNoFrame) {
                  if (uploadedPhoto.photoFrameId === EPhotoFrame.Additional) {
                    const newAdditionalFrame = {
                      ...additionalPhotoFrame,
                      fileId: uploadedPhoto.fileId,
                      uploadDate: uploadedPhoto.uploadDate,
                      url: uploadedPhoto.url
                    };

                    newPhotoAdditionalFrames.splice(newPhotoAdditionalFrames.length - 1, 0, newAdditionalFrame);
                  } else {
                    const [photoFrameNameEnum] = Object.entries(EPhotoFrame).find(
                      ([, enumFrameValue]) => uploadedPhoto.photoFrameId === enumFrameValue
                    ) || [''];

                    const photoAuthFrameIndex = newPhotoAuthFrames.findIndex(
                      ({ photoFrameName }) => photoFrameNameEnum === photoFrameName
                    );

                    if (photoAuthFrameIndex !== -1) {
                      newPhotoAuthFrames[photoAuthFrameIndex].fileId = uploadedPhoto.fileId;
                      newPhotoAuthFrames[photoAuthFrameIndex].photoFrameId = uploadedPhoto.photoFrameId;
                      newPhotoAuthFrames[photoAuthFrameIndex].uploadDate = uploadedPhoto.uploadDate;
                      newPhotoAuthFrames[photoAuthFrameIndex].url = uploadedPhoto.url;
                    }
                  }
                }

                return {
                  photoAdditionalFrames: newPhotoAdditionalFrames,
                  photoAuthFrames: newPhotoAuthFrames,
                  uploadedPhotos: hasNoFrame ? [...prevUploadedPhotos, uploadedPhoto] : prevUploadedPhotos
                };
              },
              defaultPhotoValues
            )
          : defaultPhotoValues;

        checkInInitialize(checkInDetails, reset, {
          inboundFlow: itemDetails.inboundFlow,
          itemId: checkInDetails.itemId,
          itemProcessId: itemDetails.itemProcessId as string,
          photoAdditionalFrames,
          photoAuthFrames,
          uploadedPhotos
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [getCountries, itemDetails.inboundFlow, itemDetails.itemProcessId, reset]);

  useEffect(() => {
    if (itemDetails.itemProcessId) {
      loadCheckInStation();
    }
  }, [itemDetails.itemProcessId, loadCheckInStation]);

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <div>
      <FormTabs changeTab={handleChangeTab} value={checkInTab} tabObjArray={checkinTabs} full={true} fullWidth={true} />
      <TabPanel value={checkInTab} index={0}>
        <Found />
      </TabPanel>
      <TabPanel value={checkInTab} index={1}>
        <PhotoAuthentication />
      </TabPanel>

      <div>
        {checkInTab === 0 && !isViewMode && (
          <LuxButton
            className={classes.nextStepBtn}
            variant="contained"
            luxColor="secondary"
            onClick={() => nextStepButton()}
          >
            {t('checkinStation.nextStep')}
          </LuxButton>
        )}
      </div>
    </div>
  );
};

export default CheckIn;
