import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const supplierDetailsStyles = makeStyles((theme: Theme) => {
  return createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minHeight: '50vh',
      width: '100%'
    },
    upperDivContainer: {
      width: '100%',
      padding: '24px',
      flexGrow: 1
    },
    modalRoot: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100vw',
      height: '100vh',
      overflowY: 'auto',
      backgroundColor: secondaryNeutral.light85,
      '&:focus': {
        outline: 'none'
      }
    },
    header: {
      height: '64px',
      backgroundColor: theme.palette.primary.contrastText,
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 24px',
      boxShadow: '0 2px 4px 0 rgba(68, 68, 66, 0.05)'
    },
    clickableTitle: {
      color: secondaryNeutral.light40,
      fontSize: '18px',
      fontWeight: 600,
      cursor: 'pointer'
    },
    nonClickableTitle: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600
    },

    statusNonClickable: {
      color: theme.palette.secondary.main,
      fontSize: '14px'
    },
    closeIcon: {
      marginLeft: 'auto',
      cursor: 'pointer'
    },

    green: {
      color: theme.palette.success.main,
      display: 'inline-block',
      height: '10px'
    },
    red: {
      color: theme.palette.error.main,
      display: 'inline-block',
      height: '10px'
    },
    status: {
      display: 'flex',
      alignItems: 'center'
    },
    statusLogo: {
      marginRight: '5px'
    },

    gray: {
      backgroundColor: theme.palette.background.paper
    },
    title: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '18px',
      paddingBottom: '10px',
      paddingTop: '10px'
    },
    businessModelTitle: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '18px',
      paddingBottom: '10px',
      paddingTop: '40px'
    },
    subTitle: {
      color: theme.palette.secondary.main,
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: '0'
    },
    input: {
      marginRight: '24px'
    },
    button: {
      marginLeft: '20px',
      marginTop: '20px'
    },

    AddSupplierProfile: {
      marginLeft: '20px',
      marginBottom: '45px'
    },
    imageUploadContainer: {
      textAlign: 'center',
      margin: 'auto'
    },
    appBar: {
      color: secondaryNeutral.light40,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '16px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      borderBottom: `1px solid ${secondaryNeutral.light75}`,
      fontSize: '16px'
    },
    tab: {
      '&[aria-selected=true]': {
        fontSize: '16px',
        fontWeight: '600',
        color: theme.palette.primary.main
      }
    },
    inkBarStyle: {
      backgroundColor: theme.palette.primary.main
    },
    formControl: {
      paddingBottom: '16px',
      paddingTop: '16px'
    },
    textArea: {
      marginRight: '24px',
      height: '100%'
    },
    hr: {
      marginTop: '47.5px',
      width: '100%',
      borderTop: `1px solid ${secondaryNeutral.light75}`,
      backgroundColor: secondaryNeutral.light75
    },
    formContainer: {
      boxSizing: 'border-box',

      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '4px',
      backgroundColor: theme.palette.background.paper,
      margin: '24px',
      width: '100%',
      padding: '24px'
    },
    margin23: {
      marginBottom: '23.5px'
    },
    line: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '4em 0',
      padding: '0'
    },
    subLine: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '0.5em 0',
      padding: '0'
    },

    submitLine: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      marginTop: 0,
      marginBottom: 0,
      padding: '0'
    },
    submitPanel: {
      marginTop: '11px',
      marginBottom: '31px',
      paddingRight: '24px'
    },

    warehouseButtonContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: '0 32px 32px'
    },
    warehouseContainer: {
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '4px',
      backgroundColor: theme.palette.background.paper,
      margin: '10px'
    },
    warehouseTitlePadding: {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
      paddingRight: '32px'
    },
    radioButtonText: {
      flex: 1,
      width: '100%',

      '& > span.MuiFormControlLabel-label': {
        color: theme.palette.secondary.main,
        fontFamily: 'Nunito Sans',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '16px'
      }
    },
    modalUpperContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '&:focus': {
        outline: 'none'
      }
    },
    tableContainer: {
      height: 'calc(35vh - 100px)',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      padding: '20px'
    },
    tableContainerExit: {
      height: '30%',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      padding: '20px'
    },
    tableContainerDelete: {
      height: '228',
      width: '507px',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      padding: '20px'
    },
    tableContainerUpdateModal: {
      height: '200',
      width: '507px',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      padding: '20px'
    },
    deleteWarehouseDet: {
      color: theme.palette.secondary.main,
      fontFamily: 'Nunito Sans',
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '24px'
    },

    thisActionWillRem: {
      color: theme.palette.secondary.main,
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      letterSpacing: 0,
      lineHeight: '22px'
    },

    grid: {
      padding: '10px'
    },
    list: {
      marginLeft: '100px',
      marginRight: '100px'
    },
    center: {
      textAlign: 'center'
    },
    error: {
      color: theme.palette.error.main
    },
    check: {
      color: theme.palette.success.main
    },
    titleLogo: {
      color: theme.palette.warning.main
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      minHeight: 400
    },

    alignment: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiTab-wrapper': {
        '& .MuiSvgIcon-root': {
          marginRight: '5px'
        },
        flexDirection: 'row',
        textTransform: 'none',
        color: theme.palette.secondary.main,
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '16px',
        justifyContent: 'flex-start',
        marginLeft: '24px'
      }
    },

    titleSideTab: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '& .MuiTab-wrapper': {
        marginLeft: '24px',
        marginTop: '48px',
        marginBottom: '24px',
        color: '#1D1D1B',
        fontFamily: 'Nunito Sans',
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'none',
        alignItems: 'flex-start',
        marginRight: '34px',
        letterSpacing: 0
      }
    },

    tabPanel: {
      width: '100%'
    },

    flexGrow: {
      flexGrow: 1,
      marginLeft: '20px',
      marginRight: '20px'
    },

    daysButtonSelected: {
      border: `1px solid ${secondaryNeutral.light75}`,
      borderRadius: '4px 0 0 4px',
      backgroundColor: secondaryNeutral.light75,
      paddingTop: '9px',
      paddingBottom: '9px',
      paddingRight: '11px',
      paddingLeft: '11px',
      '& > span': {
        color: theme.palette.secondary.main,
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '14px',
        textAlign: 'center'
      },
      margin: 0
    },

    daysButtonNotSelected: {
      border: `1px solid ${secondaryNeutral.light75}`,
      borderRadius: '4px 0 0 4px',
      paddingTop: '9px',
      paddingBottom: '9px',
      paddingRight: '11px',
      paddingLeft: '11px',
      '& > span': {
        color: secondaryNeutral.light40,
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '14px',
        textAlign: 'center'
      },
      margin: 0
    },

    radioDaysButton: {
      display: 'none'
    },

    paddingInput: {
      marginTop: '32px'
    },

    tabs: {
      width: 255,
      borderRight: `1px solid ${secondaryNeutral.light75}`,
      '& .MuiTabs-scroller.MuiTabs-scrollable .MuiTabs-flexContainer.MuiTabs-flexContainerVertical button[aria-selected=true]':
        {
          backgroundColor: secondaryNeutral.light75
        }
    },
    tabIndicatorProps: {
      display: 'none'
    },
    numberInputProps: {
      textAlign: 'center',
      padding: 10
    },
    boxPadding: {
      marginRight: '8px'
    },

    headerText: {
      color: theme.palette.secondary.main,
      fontFamily: 'Nunito Sans',
      fontSize: '12px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '12px'
    },
    table: {
      width: '100%'
    },
    tableInputsTargetSettings: {
      width: '150px'
    },

    tableInputsCommand: {
      height: '32px',
      width: '32px',
      borderRadius: 0
    },

    tableInputsLogo: {
      height: '18px',
      width: '18px'
    },

    addTargetSettingsContainer: {
      display: 'table',
      boxSizing: 'border-box',
      height: '72px',
      width: '100%',
      border: '1px solid #DDDDDA',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      marginTop: '8px',
      marginBottom: '8px'
    },
    tableCell: {
      display: 'table-cell',
      verticalAlign: 'middle'
    },
    addTargetSettingsText: {
      marginLeft: '24px',
      color: '#86867E',
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '16px'
    },
    boxTargetSettingsInfo: {
      boxSizing: 'border-box',
      height: '112px',
      border: '1px solid #DDDDDA',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      marginTop: '8px',
      marginBottom: '8px',
      paddingLeft: '5px',
      paddingRight: '25px'
    }
  });
});

export default supplierDetailsStyles;
