import { createStyles, makeStyles } from '@luxclusif/material';

const forAuthenticationStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: '#FBFAFB',
      marginLeft: '-24px',
      marginRight: '-24px',
      padding: '24px'
    },
    title: {
      fontSize: '18px'
    },
    progressContainer: {
      alignItems: 'center',
      display: 'flex',
      gap: '12px',
      marginTop: '12px'
    },
    progress: {
      backgroundColor: '#DFDCE0',
      borderRadius: '4px',
      height: '6px',
      width: '207px',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#5EB04E',
        borderRadius: '4px'
      }
    },
    progressLabel: {
      fontSize: '12px'
    },
    imagesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '26px',
      marginTop: '24px'
    }
  })
);

export default forAuthenticationStyles;
