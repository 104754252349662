import React, { useCallback, useMemo } from 'react';

import { IssueModel2 } from '@inbound/api';
import { LoadingButton } from '@lux-ds/button';
import Chip from '@lux-ds/chip';
import { LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Divider from '@lux-ds/divider';
import IconButton from '@lux-ds/icon-button';
import Skeleton from '@lux-ds/skeleton';
import Typography from '@lux-ds/typography';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import useWarehouseContext from 'hooks/useWarehouseContext';

import { StyledJay } from './WarehouseIssue.styles';

interface IWarehouseIssueProps {
  onClose?: () => void;
  onSuccess?: () => void;
  version?: 'v1' | 'v2';
}

const ISSUE_TYPE_LIST = ['Check Item Info', 'Quality Control', 'Photography', 'Supplier Return', 'Authentication'];

const WarehouseIssue: React.FC<IWarehouseIssueProps> = ({ onClose, onSuccess, version = 'v1' }) => {
  const { isResubmitIssueLoading, isWarehouseIssueLoading, onResubmitWarehouseIssue, warehouseIssueDetails } =
    useWarehouseContext();
  const { t } = useTranslation(['common', 'warehouse']);

  const handleClose = useCallback(() => onClose?.(), [onClose]);

  const handleResubmit = useCallback(() => onResubmitWarehouseIssue(onSuccess), [onResubmitWarehouseIssue, onSuccess]);

  const issues = useMemo(
    () =>
      version === 'v2'
        ? (ISSUE_TYPE_LIST.map(issueType =>
            warehouseIssueDetails.issues?.find(issue => issueType === issue.issueType)
          ).filter(issue => !!issue) as IssueModel2[])
        : warehouseIssueDetails.issues,
    [version, warehouseIssueDetails]
  );

  return (
    <StyledJay>
      <LayoutHerodias sx={{ alignItems: 'flex-start', height: 'unset', justifyContent: 'space-between' }}>
        <LayoutJay gap="xxs">
          <Typography variant="bodyTextNormal" weight="semibold">
            {t('warehouse:list.issues')}
          </Typography>
          {isWarehouseIssueLoading ? (
            <Skeleton width={150} sx={{ transform: 'scale(1, 0.8)' }} />
          ) : (
            <LayoutHerodias>
              <Typography variant="bodyTextNormal">{warehouseIssueDetails.sku}</Typography>
            </LayoutHerodias>
          )}
        </LayoutJay>
        {!!onClose && (
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </LayoutHerodias>
      {!isWarehouseIssueLoading && !!warehouseIssueDetails.issues?.length && (
        <LayoutJay scroll="auto">
          {issues?.map(({ comment, issueType, reasons }, index, list) => (
            <LayoutJay key={issueType}>
              <LayoutJay gap="xs">
                <Typography variant="caption">{t('warehouse:itemIssues.issueType')}</Typography>
                <Typography variant="bodyTextNormal">{issueType}</Typography>
              </LayoutJay>
              <LayoutJay gap="xs">
                <Typography variant="caption">{t('warehouse:itemIssues.reasons')}</Typography>
                <LayoutHerodias gap="xs" sx={{ flexWrap: 'wrap' }}>
                  {reasons?.map(reason => (
                    <Chip key={reason} label={reason} squared />
                  ))}
                </LayoutHerodias>
              </LayoutJay>
              <LayoutJay gap="xs">
                <Typography variant="caption">{t('warehouse:itemIssues.comments')}</Typography>
                <Typography variant="bodyTextNormal">{comment}</Typography>
              </LayoutJay>
              {list.length - 1 > index && <Divider />}
            </LayoutJay>
          ))}
          <LayoutHerodias paddingTop="m" sx={{ justifyContent: 'center' }}>
            <LoadingButton loading={isResubmitIssueLoading} onClick={handleResubmit} variant="contained">
              {t('common:resolve')}
            </LoadingButton>
          </LayoutHerodias>
        </LayoutJay>
      )}
    </StyledJay>
  );
};

export default WarehouseIssue;
