import React, { useEffect, useState } from 'react';

import { Grid, MenuItem } from '@luxclusif/material';
import { useFormContext, UseFormMethods } from 'react-hook-form';

import Input from 'components/Input/Input/Input';
import SelectInput from 'components/Input/Select/Select';
import { ICommercialModels, IStatus, ITiers } from 'models/supplier';

import supplierDetailsStyles from '../../supplierDetails.styles';

const STATUS = {
  ACTIVE: 'Active',
  TEST_PERIOD: 'Test period'
};

interface IProps {
  businessModelForm: UseFormMethods<Record<string, any>>;
  commercialModels: ICommercialModels[];
  showBusinessModel: number;
  status: IStatus[];
  tiers: ITiers[];
}

const BusinessModelDetails: React.FC<IProps> = ({
  businessModelForm,
  commercialModels,
  showBusinessModel,
  status,
  tiers
}) => {
  const classes = supplierDetailsStyles();

  const [filteredProfileStatus, setFilteredProfileStatus] = useState<IStatus[]>([]);

  const [defaultBusinessModel] = useState([
    { id: 1, name: 'Owned Goods' },
    { id: 2, name: 'Physical Consignment' },
    { id: 3, name: 'Virtual Consignment' },
    { id: 4, name: 'Buyback' },
    { id: 5, name: 'Hybrid' }
  ]);

  const { getValues } = useFormContext();

  const businessModelId = businessModelForm.watch('businessModelId');

  useEffect(() => {
    const newFilteredProfileStatus = businessModelId
      ? status
      : status.filter((row: any) => row.name === STATUS.ACTIVE || row.name === STATUS.TEST_PERIOD);

    setFilteredProfileStatus(newFilteredProfileStatus);
  }, [businessModelId, status]);

  return (
    <>
      <Grid container spacing={4} justifyContent="space-evenly" alignItems="center">
        {showBusinessModel === 0 ? (
          <Grid item md>
            <SelectInput
              label="Business Model*"
              className={classes.input}
              fullWidth={true}
              name="businessModelType"
              variant="outlined"
              control={businessModelForm.control}
              errors={businessModelForm.errors}
              setValue={businessModelForm.setValue}
            >
              <MenuItem aria-label="None" value="" key={'none'} />
              {defaultBusinessModel
                .filter(
                  (f: any) =>
                    !getValues('businessModel')
                      .map((b: any) => b.businessModelType)
                      .includes(f.id)
                )
                .map((e: any) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
            </SelectInput>
          </Grid>
        ) : (
          <Grid item md>
            <Input
              name="code"
              control={businessModelForm.control}
              errors={businessModelForm.errors}
              label="Short Code"
              type="text"
              className={classes.input}
              variant="outlined"
              fullWidth={true}
              errorText="Required"
              disabled={true}
            />
          </Grid>
        )}
        <Grid item md>
          <SelectInput
            label="Status*"
            className={classes.input}
            fullWidth={true}
            name="status"
            variant="outlined"
            control={businessModelForm.control}
            errors={businessModelForm.errors}
            setValue={businessModelForm.setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            {filteredProfileStatus.map((row: IStatus) => (
              <MenuItem key={row.profileStatusId} value={row.profileStatusId}>
                {row.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Grid item md>
          <SelectInput
            label="Commercial Model*"
            className={classes.input}
            fullWidth={true}
            name="commercialModel"
            variant="outlined"
            control={businessModelForm.control}
            errors={businessModelForm.errors}
            setValue={businessModelForm.setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            {commercialModels.map((row: ICommercialModels) => (
              <MenuItem key={row.commercialModelId} value={row.commercialModelId}>
                {row.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Grid item md>
          <SelectInput
            label="Tier"
            className={classes.input}
            fullWidth={true}
            name="tier"
            variant="outlined"
            control={businessModelForm.control}
            errors={businessModelForm.errors}
            setValue={businessModelForm.setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            {tiers.map((row: ITiers) => (
              <MenuItem key={row.tierId} value={row.tierId}>
                {row.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Grid item md>
          <SelectInput
            label="Supplier Photos*"
            className={classes.input}
            fullWidth={true}
            name="photos"
            variant="outlined"
            control={businessModelForm.control}
            errors={businessModelForm.errors}
            setValue={businessModelForm.setValue}
          >
            <MenuItem aria-label="None" value="" key={'none'} />
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </SelectInput>
        </Grid>
      </Grid>
      <hr className={classes.line} />
    </>
  );
};

export default BusinessModelDetails;
