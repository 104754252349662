import React from 'react';

import { FormControl, RadioGroup, FormHelperText } from '@luxclusif/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  control: any;
  errors: any;
  name: string;
  row?: boolean;
}

const LuxToggleButton: React.FC<IProps> = ({ children, control, errors, name, row }) => {
  const { t } = useTranslation(['warehouse']);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ onBlur, onChange, value }) => (
        <FormControl error={errors[name] ? true : false}>
          <RadioGroup row={row} value={value} onChange={onChange} onBlur={onBlur}>
            {children}
          </RadioGroup>
          <FormHelperText>{errors[name] ? t(errors[name].message) : ''}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default LuxToggleButton;
