import React, { useState } from 'react';

import { EPhotoFrame } from '@inbound/api';
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@luxclusif/material';
import Delete from '@mui/icons-material/Delete';
import Download from '@mui/icons-material/Download';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import ZoomIn from '@mui/icons-material/ZoomIn';
import moment from 'moment';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import ImageFrame from 'components/ImageFrame/ImageFrame';
import { EDnDPhotoAuth, IAuthUploadedPhoto } from 'models/warehouse';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { IPhotoAuthFrame } from 'pages/WarehouseList/schemas/checkInSchema';
import downloadResource from 'utils/downloadResource';

import photoFrameInputStyles from './photoFrameInput.styles';

export interface IDeleteInfo {
  fileId: string;
  photoFrameId: EPhotoFrame;
}

interface IProps extends Omit<IPhotoAuthFrame, 'photoFrameName'> {
  error: boolean;
  isAuthComplete: boolean;
  label: string;
  onDelete: (item: IDeleteInfo) => void;
  onDrop: (draggedType: EDnDPhotoAuth | null, photoFrame: EPhotoFrame, item: IAuthUploadedPhoto) => void;
  setZoomPhoto: React.Dispatch<React.SetStateAction<string>>;
}

const PhotoFrameInput: React.FC<IProps> = ({
  error,
  fileId,
  isAuthComplete,
  isRequired,
  label,
  onDelete,
  onDrop,
  photoFrameId,
  setZoomPhoto,
  uploadDate,
  url
}) => {
  const classes = photoFrameInputStyles();
  const { t } = useTranslation('common');
  const { isViewMode } = useWarehouseItemContext();
  const {
    itemDetails: { sku }
  } = useWarehouseItemDetailsContext();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: [EDnDPhotoAuth.ForUpload, EDnDPhotoAuth.ForAuthentication],
      canDrop: () => !url && !isAuthComplete,
      collect: monitor => ({
        isOver: monitor.isOver()
      }),
      drop: (item: IAuthUploadedPhoto, monitor: DropTargetMonitor) =>
        onDrop(monitor.getItemType() as EDnDPhotoAuth, photoFrameId, item)
    }),
    [photoFrameId, url, error]
  );

  return (
    <div
      ref={dropRef}
      className={`${classes.container}${error ? ` ${classes.error}` : ''}${
        !url && isAuthComplete ? ` ${classes.disableFrameContainer}` : ''
      }`}
    >
      <Typography className={classes.label}>
        {label} {isRequired && '*'}
      </Typography>
      {url ? (
        <>
          <ImageFrame
            actions={[
              {
                icon: <ZoomIn />,
                onClick: () => setZoomPhoto(url)
              },
              {
                icon: isAuthComplete || isViewMode ? <Download /> : <MoreVert />,
                onClick: (event: React.MouseEvent<HTMLButtonElement>) =>
                  isAuthComplete || isViewMode ? downloadResource(url, sku) : setAnchorEl(event.currentTarget)
              }
            ]}
            canDrag={!isAuthComplete}
            dragItemInfo={{ fileId, photoFrameId, uploadDate: uploadDate || new Date().toISOString(), url }}
            dragType={EDnDPhotoAuth.ForAuthentication}
            label={moment(uploadDate || '').format('MM/DD/YYYY')}
            src={url}
          />

          {anchorEl && (
            <Menu
              open
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem className={classes.otherActionItem} onClick={() => onDelete({ fileId, photoFrameId })}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText>{t('delete')}</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.otherActionItem}
                onClick={() => {
                  downloadResource(url, sku);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <Download />
                </ListItemIcon>
                <ListItemText>{t('uploadImages.download')}</ListItemText>
              </MenuItem>
            </Menu>
          )}
        </>
      ) : (
        <div className={`${classes.placeholder}${isOver ? ` ${classes.placeHolderHovered}` : ''}`}>
          <ImageOutlinedIcon />
        </div>
      )}
    </div>
  );
};

export default PhotoFrameInput;
