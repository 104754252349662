import { LayoutHerodias } from '@lux-ds/content-layouts';
import InfoIcon from '@mui/icons-material/Info';

import { css, styled } from 'utils/styles';

export const StyledInfoIcon = styled(InfoIcon)(
  ({ theme }) => css`
    color: ${theme.palette.purdie.main};
    height: ${theme.space.s};
    width: ${theme.space.s};
  `
);

export const StyledHerodias = styled(LayoutHerodias)(
  ({ theme }) => css`
    background-color: ${theme.palette.purdie.light};
    border-radius: ${theme.space.xxs};
    gap: ${theme.space.xs};
    padding: ${theme.space.s};
  `
);
