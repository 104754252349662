import React, { useCallback, useState } from 'react';

import { CircularProgress } from '@luxclusif/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';

import ForAuthentication from './sections/ForAuthentication/ForAuthentication';
import ImageModal from './sections/ImageModal/ImageModal';
import PhotoUpload from './sections/PhotoUpload/PhotoUpload';

import photoAuthenticationStyles from './photoAuthentication.styles';

const PhotoAuthentication: React.FC = () => {
  const classes = photoAuthenticationStyles();
  const { isStationLoading } = useWarehouseItemDetailsContext();

  const [zoomPhoto, setZoomPhoto] = useState<string>();

  const handleZoomPhoto = useCallback((url?: string) => setZoomPhoto(url), [setZoomPhoto]);

  return isStationLoading ? (
    <div className={classes.loadingContainer}>
      <CircularProgress size={60} />
    </div>
  ) : (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.container}>
        <PhotoUpload handleZoomPhoto={handleZoomPhoto} />
        <ForAuthentication handleZoomPhoto={handleZoomPhoto} />
        {zoomPhoto && <ImageModal image={zoomPhoto} onClose={() => handleZoomPhoto('')} />}
      </div>
    </DndProvider>
  );
};

export default PhotoAuthentication;
