import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const qualityCheckStyles = makeStyles((theme: Theme) => {
  return createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '45px'
    },
    container: {
      marginBottom: '64px'
    },

    headerTitle: {
      color: theme.palette.secondary.main,
      fontSize: '20px',
      letterSpacing: 0,
      lineHeight: 1,
      marginTop: 0,
      marginBottom: '32px'
    },
    commentSupplierLabel: {
      color: '#7D717E',
      fontSize: '12px',
      lineHeight: 1
    },
    commentSupplierValue: {
      color: '#131113',
      lineHeight: 1,
      marginTop: '8px',
      marginBottom: 0
    },

    title: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: 1,
      marginTop: '8px',
      marginBottom: '32px'
    },
    subTitle: {
      color: theme.palette.secondary.main,
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: '0',
      lineHeight: 1,
      marginBottom: '32px !important'
    },
    divider: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '40px 0',
      padding: '0'
    }
  });
});

export default qualityCheckStyles;
