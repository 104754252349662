import React, { useState } from 'react';

import { Button, MaterialIcons } from '@luxclusif/material';
import { useSnackbar } from 'notistack';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import { IQualityCheckFormInputs } from 'pages/WarehouseList/schemas/qualityCheckSchema';
import warehouseService from 'services/Warehouse.service';

import computeQCDecisionStyles from './computeQCDecision.styles';

interface IProps {
  form: UseFormMethods<IQualityCheckFormInputs>;
}

const ComputeQCDecision: React.FC<IProps> = ({ form }) => {
  const classes = computeQCDecisionStyles();
  const { t } = useTranslation(['common', 'warehouse']);
  const { isViewMode } = useWarehouseItemContext();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { errors } = form.formState;

  const hasPassedQualityCheck = form.watch('hasPassedQualityCheck');

  const submitDamages = async () => {
    setIsLoading(true);

    try {
      const qcDecision = await warehouseService.computeQCDecision(form.getValues());

      form.setValue('hasPassedQualityCheck', qcDecision, {
        shouldDirty: true
      });
      form.clearErrors('hasPassedQualityCheck');
    } catch {
      enqueueSnackbar(t('warehouse:details.errorOccurred'), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={classes.container}>
        {errors?.hasPassedQualityCheck && <div className={classes.error}>{t('common:required')}*</div>}
        {!isViewMode && (
          <Button variant="contained" className={classes.submitButton} disabled={isLoading} onClick={submitDamages}>
            {t(isLoading ? 'warehouse:pleaseWait' : 'warehouse:qualityCheckStation.submitDamages')}
          </Button>
        )}

        <div className={classes.resultContainer}>
          {t('warehouse:qualityCheckDecision')}:
          {typeof hasPassedQualityCheck === 'boolean' && !isLoading ? (
            <>
              {hasPassedQualityCheck ? (
                <MaterialIcons.CheckCircle className={`${classes.resultIcon} ${classes.resultIconPass}`} />
              ) : (
                <MaterialIcons.Cancel className={`${classes.resultIcon} ${classes.resultIconFail}`} />
              )}
              <span>{t(hasPassedQualityCheck ? 'warehouse:qualityCheckPass' : 'warehouse:qualityCheckFail')}</span>
            </>
          ) : (
            <div className={classes.resultPlaceholder} />
          )}
        </div>
      </div>
      <div className={classes.divider} />
    </>
  );
};

export default ComputeQCDecision;
