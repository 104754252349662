import React from 'react';

import { useWatch } from 'react-hook-form';

import { EPurchaseOrderAllStatus, TGetAllCouriers, TGetAllCurrencies, TGetAllStatus } from 'models/purchaseOrders';

import BuyerSection from './components/BuyerSection/BuyerSection';
import DatesSection from './components/DatesSection/DatesSection';
import DetailsSection from './components/DetailsSection/DetailsSection';
import FilesSection from './components/FilesSection/FilesSection';
import FinancialAndOperationSection from './components/FinancialAndOperationSection/FinancialAndOperationSection';
import NotesSection from './components/NotesSection/NotesSection';
import SupplierSection from './components/SupplierSection/SupplierSection';
import TrackingNumbersSection from './components/TrackingNumbersSection/TrackingNumbersSection';

import orderInformationStyles from './orderInformation.styles';

interface IProps {
  couriers: TGetAllCouriers;
  currencies: TGetAllCurrencies;
  isLoadingOptions: boolean;
  status?: EPurchaseOrderAllStatus;
  statuses: TGetAllStatus;
}

const OrderInformation: React.FC<IProps> = ({ couriers, currencies, isLoadingOptions, status, statuses }) => {
  const classes = orderInformationStyles();
  const watchPoNumber = useWatch<string>({ defaultValue: '', name: 'poNumber' });

  return (
    <div className={classes.container}>
      <DetailsSection isLoadingOptions={isLoadingOptions} status={status} statuses={statuses} />
      <hr className={classes.divider} />
      <SupplierSection />
      <hr className={classes.divider} />
      <FinancialAndOperationSection
        couriers={couriers}
        currencies={currencies}
        isLoadingOptions={isLoadingOptions}
        status={status}
      />
      <hr className={classes.divider} />
      <DatesSection />
      <hr className={classes.divider} />

      {watchPoNumber && (
        <>
          <TrackingNumbersSection />
          <hr className={classes.divider} />
        </>
      )}

      <NotesSection />
      <hr className={classes.divider} />
      <BuyerSection />
      <hr className={classes.divider} />
      <FilesSection />
    </div>
  );
};

export default OrderInformation;
