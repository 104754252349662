import { createStyles, makeStyles } from '@luxclusif/material';

const currencyChangedModalStyle = makeStyles(() =>
  createStyles({
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      maxWidth: '512px',
      padding: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    title: {
      fontSize: '20px',
      lineHeight: '24px',
      margin: 0
    },
    description: {
      fontSize: '16px',
      lineHeight: '22px',
      margin: '32px 0 0'
    },
    actions: {
      alignSelf: 'flex-end',
      display: 'flex',
      gap: '16px',
      marginTop: '32px'
    },
    button: {
      lineHeight: '19px',
      padding: '8px 24px',
      width: '140px'
    }
  })
);

export default currencyChangedModalStyle;
