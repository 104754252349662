import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const trackingNumberModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      left: '50%',
      minWidth: '853px',
      padding: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    title: {
      fontSize: '20px',
      lineHeight: '24px',
      margin: 0
    },
    closeButton: {
      padding: '8px',
      position: 'absolute',
      right: '16px',
      top: '16px'
    },
    description: {
      fontSize: '16px',
      lineHeight: '22px',
      marginTop: '16px'
    },
    subtitle: {
      fontSize: '16px',
      lineHeight: '24px',
      marginTop: '32px'
    },
    trackingNumbers: {
      display: 'flex',
      flexDirection: 'column',
      height: '128px',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '14px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundClip: 'content-box',
        backgroundColor: 'rgba(0, 0, 0, .25)',
        border: '3px solid transparent',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, .5)'
        }
      }
    },
    emptyTrackingNumbers: {
      alignSelf: 'center',
      lineHeight: '19px',
      margin: 'auto 0'
    },
    trackingNumber: {
      alignItems: 'flex-start',
      display: 'flex',
      gap: '8px',
      marginTop: '16px'
    },
    inputs: {
      display: 'flex',
      gap: '24px',

      '& .MuiInputLabel-root[data-shrink=false]': {
        lineHeight: '22px',
        transform: 'translate(14px, 14px) scale(1)'
      },

      '& .MuiInputBase-input, .MuiSelect-select': {
        height: '22px',
        minHeight: '22px',
        padding: '13px'
      },

      '& .Mui-error .MuiInputBase-input': {
        color: theme.palette.error.main
      },

      '& .MuiInputBase-input': {
        width: '176px'
      },

      '& .MuiSelect-select': {
        lineHeight: '24px',
        width: '245px'
      },

      // This is needed until we upgrade the material-ui version. It fixes a long label giving extra height to the input.
      '& .MuiOutlinedInput-notchedOutline legend': {
        lineHeight: '11px'
      }
    },
    selectMenu: {
      maxHeight: '240px'
    },
    deleteButton: {
      marginTop: '4px',
      padding: '8px'
    },
    assignButton: {
      alignSelf: 'flex-end',
      lineHeight: '20px',
      marginTop: '16px',
      padding: '8px 24px',

      '& .MuiButton-startIcon': {
        marginRight: '8px'
      }
    },
    actions: {
      alignSelf: 'flex-end',
      display: 'flex',
      gap: '16px',
      marginTop: '32px'
    },
    button: {
      lineHeight: '20px',
      padding: '8px 24px',

      '&.MuiButton-outlined': {
        borderColor: secondaryNeutral.light75
      }
    }
  })
);

export default trackingNumberModalStyles;
