import moment from 'moment';

export const DATE_ONLY_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD H:mm';

export const getUtcDate = () => {
  return moment.utc().toDate();
};

export const formatDate = (date: Date, formatString = DATE_TIME_FORMAT) => {
  return moment(date).format(formatString);
};
