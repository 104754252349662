import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { EAuthenticationResultStatus, EStation } from '@inbound/api';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LuxButton,
  MaterialIcons,
  Modal,
  Paper,
  TableContainer
} from '@luxclusif/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FinishProcessModal from 'components/Warehouse/FinishProcessModal/FinishProcessModal';
import PrintBarcodeModal from 'components/Warehouse/PrintBarcodeModal/PrintBarcodeModal';
import { DamageRecognition } from 'components/Warehouse/PrintDamageCardModal/common';
import PrintDamageCardModal from 'components/Warehouse/PrintDamageCardModal/PrintDamageCardModal';
import ReturnModal from 'components/Warehouse/ReturnModal/ReturnModal';
import Authentication from 'components/Warehouse/Steps/Authentication/Authentication';
import CheckIn from 'components/Warehouse/Steps/CheckIn/CheckIn';
import NewCheckIn from 'components/Warehouse/Steps/NewCheckIn/NewCheckIn';
import PhotoAuthentication from 'components/Warehouse/Steps/PhotoAuthentication/PhotoAuthentication';
import QualityCheck from 'components/Warehouse/Steps/QualityCheck/QualityCheck';
import Storage from 'components/Warehouse/Steps/Storage/Storage';
import WarehouseAppBar from 'components/Warehouse/WarehouseAppBar/WarehouseAppBar';
import WarehouseCard from 'components/Warehouse/WarehouseCard/WarehouseCard';
import WarehouseStepper from 'components/Warehouse/WarehouseStepper/WarehouseStepper';
import WarehouseTitleSection from 'components/Warehouse/WarehouseTitleSection/WarehouseTitleSection';
import useWarehouseContext from 'hooks/useWarehouseContext';
import {
  EWarehouseName,
  LOCAL_STORAGE_USER_WAREHOUSE_WARNING_DATE,
  USER_WAREHOUSE_SHOW_WARNING_HOURS
} from 'models/warehouse';
import { HOUR_MS } from 'pages/WarehouseList/constants';
import BaseConfiguration from 'setup/BaseConfiguration';

import useWarehouseItemContext from '../hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from '../hooks/useWarehouseItemDetailsContext';
import WarehouseIssue from './components/WarehouseIssue/WarehouseIssue';

import warehouseDetailsStyles from './warehouseDetails.styles';

const ENABLE_RETURN_TO_SUPPLIER_NEW = process.env.REACT_APP_ENABLE_RETURN_TO_SUPPLIER_NEW === 'true';

const WarehouseDetails: React.FC = () => {
  const classes = warehouseDetailsStyles();
  const { t } = useTranslation(['common', 'warehouse']);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { isViewMode } = useWarehouseItemContext();
  const {
    activeStation,
    currentItemStation,
    handleNextStation,
    hasUserWarehouse,
    isDoneAutomatically,
    isFetching,
    isFormValid,
    isInconclusiveAuthFail,
    isSaving,
    itemDetails,
    onCloseDetailFormPrompt,
    onErrorSave,
    onSaveChanges,
    onToggleFinishModal,
    showFinishModal,
    warehouseForm,
    warehouseStations
  } = useWarehouseItemDetailsContext();
  const { getWarehouseIssueDetails, warehouseIssueDetails } = useWarehouseContext();

  const [printSkuBarcodeOpen, setPrintSkuBarcodeOpen] = useState<boolean>(false);
  const [printDamageCardOpen, setPrintDamageCardOpen] = useState<boolean>(false);
  const [returnModalOpened, setReturnModalOpened] = useState<boolean>(false);

  const [isCalculatedDecision, setIsCalculatedDecision] = useState<boolean>(true);

  useEffect(() => {
    if (itemDetails.itemProcessId && !hasUserWarehouse) {
      const lastWarningDate = localStorage.getItem(LOCAL_STORAGE_USER_WAREHOUSE_WARNING_DATE);

      const showWarning = () => {
        const action = (key: SnackbarKey) => (
          <IconButton className={classes.userWarehouseWarningClose} onClick={() => closeSnackbar(key)}>
            <MaterialIcons.Close fontSize="inherit" />
          </IconButton>
        );

        enqueueSnackbar(
          <div className={classes.userWarehouseWarning}>
            <p className={classes.userWarehouseWarningTitle}>{t('warehouse:details.userWarehouseWarningTitle')}</p>
            <p className={classes.userWarehouseWarningDescription}>
              {t('warehouse:details.userWarehouseWarningDescription')}
            </p>
          </div>,
          { action, persist: true, variant: 'warning' }
        );

        localStorage.setItem(LOCAL_STORAGE_USER_WAREHOUSE_WARNING_DATE, new Date().toJSON());
      };

      if (lastWarningDate) {
        const hourDiff = (new Date().getTime() - new Date(lastWarningDate).getTime()) / HOUR_MS;

        if (hourDiff > USER_WAREHOUSE_SHOW_WARNING_HOURS) {
          showWarning();
        }
      } else {
        showWarning();
      }
    }
  }, [hasUserWarehouse, itemDetails.itemProcessId]);

  const isReturnToSupplier = warehouseForm.watch('isReturnToSupplier');
  const returnToSupplierReasons = warehouseForm.watch('returnToSupplierReasons', []);
  const isQCStationFail = useMemo(
    (): boolean =>
      activeStation === EStation.QualityControl && !warehouseForm.getValues('hasPassedQualityCheck')
        ? itemDetails.warehouse === EWarehouseName.Japan
          ? ENABLE_RETURN_TO_SUPPLIER_NEW
            ? warehouseForm.getValues('supplierReturnReason') === undefined
            : !isReturnToSupplier || returnToSupplierReasons.length === 0
          : true
        : false,
    [
      activeStation,
      isReturnToSupplier,
      itemDetails.warehouse,
      returnToSupplierReasons,
      warehouseForm.watch(['hasPassedQualityCheck', 'supplierReturnReason'])
    ]
  );

  const renderStep = useCallback(() => {
    switch (activeStation) {
      case EStation.Authentication:
        return (
          <BaseConfiguration>
            <Authentication />
          </BaseConfiguration>
        );
      case EStation.Checkin:
        return <CheckIn />;
      case EStation.CheckinV2:
        return <NewCheckIn />;
      case EStation.PhotoAuthentication:
        return <PhotoAuthentication />;
      case EStation.QualityControl:
        return (
          <QualityCheck isCalculatedDecision={isCalculatedDecision} setIsCalculatedDecision={setIsCalculatedDecision} />
        );
      case EStation.Storage:
        return (
          <BaseConfiguration>
            <Storage />
          </BaseConfiguration>
        );

      default:
        return null;
    }
  }, [activeStation, isCalculatedDecision, setIsCalculatedDecision]);

  const isAuthReturnReasonSelected = useMemo(
    () =>
      activeStation === EStation.Authentication &&
      (warehouseForm.getValues('authResult') === EAuthenticationResultStatus.Fake || isInconclusiveAuthFail)
        ? warehouseForm.getValues('supplierReturnReason') !== undefined
        : isFormValid,
    [activeStation, isFormValid, isInconclusiveAuthFail, warehouseForm.watch(['authResult', 'supplierReturnReason'])]
  );

  const handleErrorSubmitChanges = useCallback(
    (errors: FieldErrors) => {
      onErrorSave(errors);
      onToggleFinishModal(false);
    },
    [onErrorSave, onToggleFinishModal]
  );

  const handleSubmitChanges = useCallback(
    (isSaveAndGoNextStation: boolean, isFinishProcess?: boolean) => {
      if (
        !isFinishProcess &&
        ((activeStation === EStation.Authentication &&
          (warehouseForm.getValues('authResult') === EAuthenticationResultStatus.Fake || isInconclusiveAuthFail)) ||
          (ENABLE_RETURN_TO_SUPPLIER_NEW &&
            activeStation === EStation.QualityControl &&
            !warehouseForm.getValues('hasPassedQualityCheck')))
      ) {
        onToggleFinishModal(true);
      } else {
        warehouseForm.handleSubmit(
          () =>
            onSaveChanges(isSaveAndGoNextStation, isFinishProcess, () => {
              if (!isSaveAndGoNextStation) {
                warehouseForm.reset(warehouseForm.getValues());
              }
            }),
          handleErrorSubmitChanges
        )();
      }
    },
    [activeStation, handleErrorSubmitChanges, isInconclusiveAuthFail, onSaveChanges, onToggleFinishModal, warehouseForm]
  );

  const isBehindStation = useMemo(() => {
    if (
      (activeStation === EStation.Authentication &&
        isAuthReturnReasonSelected &&
        (isInconclusiveAuthFail ||
          (!itemDetails.isProcessed && warehouseForm.getValues('authResult') === EAuthenticationResultStatus.Fake))) ||
      (ENABLE_RETURN_TO_SUPPLIER_NEW &&
        activeStation === EStation.QualityControl &&
        !itemDetails.isProcessed &&
        !warehouseForm.getValues('hasPassedQualityCheck') &&
        warehouseForm.getValues('supplierReturnReason') !== undefined)
    ) {
      return false;
    }

    const selectedStationOrder = warehouseStations.find(station => station.stationId === activeStation)?.order || 0;
    const currentStationOrder = warehouseStations.find(station => station.stationId === currentItemStation)?.order || 0;

    return selectedStationOrder < currentStationOrder;
  }, [
    activeStation,
    currentItemStation,
    isAuthReturnReasonSelected,
    isInconclusiveAuthFail,
    itemDetails.isProcessed,
    warehouseForm,
    warehouseStations
  ]);

  const submitButtonLabel = useMemo(() => {
    if (isSaving) {
      return t('warehouse:pleaseWait');
    }

    const isLastStep = handleNextStation(activeStation, 'order') === undefined;

    return isLastStep ? t('warehouse:details.saveFinish') : t('warehouse:details.saveNext');
  }, [activeStation, handleNextStation, isSaving, t]);

  const damageRecognition = useMemo(
    (): DamageRecognition => ({
      exteriorPart: {
        back: warehouseForm.getValues('back'),
        bottom: warehouseForm.getValues('bottom'),
        corners: warehouseForm.getValues('corners'),
        front: warehouseForm.getValues('front'),
        handleOrStrap: warehouseForm.getValues('handleOrStrap'),
        pocket: warehouseForm.getValues('pocket'),
        side: warehouseForm.getValues('side'),
        top: warehouseForm.getValues('top')
      },
      hardwarePart: {
        closure: warehouseForm.getValues('closure'),
        embellishment: warehouseForm.getValues('embellishment'),
        key: warehouseForm.getValues('key'),
        practicalAttachment: warehouseForm.getValues('practicalAttachment'),
        zipper: warehouseForm.getValues('zipper')
      },
      interiorPart: {
        interiorPocket: warehouseForm.getValues('interiorPocket'),
        lining: warehouseForm.getValues('lining')
      }
    }),
    [warehouseForm]
  );

  useEffect(() => {
    if (Object.keys(itemDetails).length > 0) {
      getWarehouseIssueDetails(itemDetails.itemProcessId as string, itemDetails.itemId as string, itemDetails.sku);
    }
  }, [itemDetails]);

  return (
    <>
      <Modal open onClose={onCloseDetailFormPrompt}>
        <Paper className={classes.modalRoot}>
          {isFetching ? (
            <div className={classes.loadingContainer}>
              <CircularProgress size={60} />
            </div>
          ) : (
            <>
              <WarehouseAppBar mode="update" onClose={onCloseDetailFormPrompt} sku={itemDetails.sku} />
              <div className={classes.mainWrapper}>
                <TableContainer component={Paper}>
                  <WarehouseTitleSection
                    setPrintDamageCardOpen={setPrintDamageCardOpen}
                    setPrintSkuBarcodeOpen={setPrintSkuBarcodeOpen}
                  />
                  <div className={classes.lineHeader} />
                  <div className={classes.formContainer}>
                    <Box display="flex" flexWrap="nowrap" className={classes.containerWidth}>
                      <BaseConfiguration>
                        <WarehouseStepper />
                      </BaseConfiguration>

                      <div className={classes.warehouseContainer}>
                        <WarehouseCard />
                        <hr className={classes.line} />
                        {renderStep()}
                      </div>
                    </Box>
                  </div>
                  {!isViewMode && (
                    <>
                      <hr className={classes.submitLine} />
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        className={classes.submitPanel}
                      >
                        <LuxButton
                          disabled={isSaving}
                          onClick={onCloseDetailFormPrompt}
                          className={`${classes.button} ${classes.discardButton}`}
                        >
                          {t('common:discardChanges')}
                        </LuxButton>

                        <LuxButton
                          className={`${classes.button} ${classes.saveButton}`}
                          variant="contained"
                          luxColor="white"
                          onClick={() => handleSubmitChanges(false, true)}
                          disabled={isSaving || !isFormValid || !hasUserWarehouse}
                        >
                          {isSaving ? t('warehouse:pleaseWait') : t('common:saveChanges')}
                        </LuxButton>

                        <LuxButton
                          className={`${classes.button}`}
                          variant="contained"
                          luxColor="secondary"
                          onClick={() => handleSubmitChanges(true)}
                          disabled={
                            isSaving ||
                            !isFormValid ||
                            isBehindStation ||
                            !hasUserWarehouse ||
                            isQCStationFail ||
                            !isAuthReturnReasonSelected ||
                            isDoneAutomatically
                          }
                        >
                          {submitButtonLabel}
                        </LuxButton>
                      </Grid>
                    </>
                  )}
                </TableContainer>
                {!!warehouseIssueDetails.issues?.length && (
                  <BaseConfiguration>
                    <WarehouseIssue />
                  </BaseConfiguration>
                )}
              </div>

              {/* MODALS: Please make sure the modals renders if needed such as if it's under the right step */}
              <ReturnModal setReturnModalOpened={setReturnModalOpened} returnModalOpened={returnModalOpened} />

              {printSkuBarcodeOpen && (
                <PrintBarcodeModal
                  itemDetails={itemDetails}
                  isOpen={printSkuBarcodeOpen}
                  toggleModal={() => setPrintSkuBarcodeOpen(prevState => !prevState)}
                />
              )}

              {printDamageCardOpen && (
                <PrintDamageCardModal
                  damageRecognition={damageRecognition}
                  isOpen={printDamageCardOpen}
                  itemDetails={itemDetails}
                  showDamages={activeStation === EStation.QualityControl}
                  toggleModal={() => setPrintDamageCardOpen(prevState => !prevState)}
                />
              )}

              {showFinishModal && (
                <BaseConfiguration>
                  <FinishProcessModal
                    isAccepted={!!warehouseForm.getValues('supplierReturnReason')}
                    onClose={() => onToggleFinishModal(false)}
                    onSubmit={() => handleSubmitChanges(true, true)}
                  />
                </BaseConfiguration>
              )}
            </>
          )}
        </Paper>
      </Modal>
    </>
  );
};

export default WarehouseDetails;
