import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const accordionStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      width: '100%',
      boxSizing: 'border-box',
      border: `1px solid ${secondaryNeutral.light75} !important`,
      borderRadius: '4px',
      boxShadow: 'none'
    },
    line1: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      padding: '0'
    },
    heading: {
      color: theme.palette.secondary.main,
      fontSize: '16px',
      letterSpacing: 0,
      lineHeight: '16px',
      marginBottom: '15px !important',
      marginTop: '15px !important'
    },
    header: {
      borderBottom: `1px solid ${secondaryNeutral.light75}`,
      '& > .MuiAccordionSummary-content': {
        margin: '0px !important'
      }
    }
  });
});

export default accordionStyles;
