import React from 'react';

import { Box } from '@luxclusif/material';

interface IProps {
  className?: string;
  index: number;
  padding?: number;
  value: number;
}

const TabPanel: React.FC<IProps> = ({ children, className, index, padding = 0, value }) => (
  <div
    aria-labelledby={`wrapped-tab-${index}`}
    className={className}
    hidden={value !== index}
    id={`wrapped-tabpanel-${index}`}
    role="tabpanel"
  >
    <Box p={padding}>{children}</Box>
  </div>
);

export default TabPanel;
