import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const warehouseCardStyles = makeStyles((theme: Theme) => {
  return createStyles({
    warehouseCardDetailsContainer: {
      flex: 3,
      padding: '8px 0',
      width: '100%'
    },
    warehouseTextTitle: {
      color: secondaryNeutral.light40,
      fontFamily: 'Nunito Sans',
      fontSize: '12px',
      marginTop: '0',
      marginBottom: '8px',
      letterSpacing: 0
    },
    warehouseTextDetails: {
      wordBreak: 'break-word',
      color: theme.palette.secondary.main,
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: 0,
      marginTop: '0 !important',
      marginBottom: '24px !important'
    },
    bBoxContainer: {
      display: 'flex',
      gap: '8px'
    },

    // Temporary classes of warehouseCardImage. Remove once card details when revamp the warehouse card
    warehouseCardImageContainer: {
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center'
    },
    warehouseCardImage: {
      width: '100px',
      height: '100px',
      objectFit: 'cover'
    },
    warehouseCardImageLink: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '0.5rem'
    },

    line: {
      display: 'block',
      height: '1px',
      border: '0',
      borderTop: `2px solid ${secondaryNeutral.light75}`,
      margin: '4em 0',
      padding: '0'
    }
  });
});

export default warehouseCardStyles;
