import {
  EAuthenticationResultStatus,
  GetAuthenticationStationQueryResponse,
  UpdateAuthenticationRequest
} from '@inbound/api';
import * as yup from 'yup';

import { IUploadedPhoto } from 'models/warehouse';
import camelize from 'utils/camelize';
import pascalize from 'utils/pascalize';

export enum EMatrixParent {
  Exterior = 'exterior',
  Interior = 'interior'
}

enum EMatrixLocation {
  Body = 3,
  Logo = 4,
  HardwareClosures = 5,
  HardwareEngravings = 6,
  Lining = 7,
  InteriorLogo = 8,
  InteriorHardwareClosures = 9,
  InteriorHardwareEngravings = 10,
  MadeInTag = 11,
  SerialNumberTag = 12
}

enum EMatrixConcern {
  Craftmanship = 1,
  Material = 2,
  Typography = 3,
  Hardware = 4
}

export type TExteriorMatrixLocationKeys = 'body' | 'logo' | 'hardwareClosures' | 'hardwareEngravings';

export type TInteriorMatrixLocationKeys =
  | 'lining'
  | 'interiorLogo'
  | 'interiorHardwareClosures'
  | 'interiorHardwareEngravings'
  | 'madeInTag'
  | 'serialNumberTag';

export type TMatrixConcernKeys = 'craftmanship' | 'hardware' | 'material' | 'typography';

type TMatrixExteriorLocation = {
  [key in TExteriorMatrixLocationKeys]: Record<TMatrixConcernKeys, boolean | undefined>;
};

type TMatrixInteriorLocation = {
  [key in TInteriorMatrixLocationKeys]: Record<TMatrixConcernKeys, boolean | undefined>;
};

export interface IItemAuthenticationSchema
  extends TMatrixExteriorLocation,
    TMatrixInteriorLocation,
    Pick<GetAuthenticationStationQueryResponse, 'comments' | 'partnerResults'> {
  authResult: EAuthenticationResultStatus;
  hasMatrixConcern?: boolean;
  supplierReturnReason?: number;
  uploadedPhotos: IUploadedPhoto[];
}

const defaultConcern: Record<TMatrixConcernKeys, boolean | undefined> = {
  craftmanship: undefined,
  hardware: undefined,
  material: undefined,
  typography: undefined
};

const defaultLocation: Record<TExteriorMatrixLocationKeys | TInteriorMatrixLocationKeys, typeof defaultConcern> = {
  body: defaultConcern,
  hardwareClosures: defaultConcern,
  hardwareEngravings: defaultConcern,
  interiorHardwareClosures: defaultConcern,
  interiorHardwareEngravings: defaultConcern,
  interiorLogo: defaultConcern,
  lining: defaultConcern,
  logo: defaultConcern,
  madeInTag: defaultConcern,
  serialNumberTag: defaultConcern
};

const exteriorInteriorConcernSchema = yup.object().shape({
  craftmanship: yup.boolean().notRequired(),
  hardware: yup.boolean().notRequired(),
  material: yup.boolean().notRequired(),
  typography: yup.boolean().notRequired()
});

export const authenticationSchema = yup.object().shape({
  authResult: yup.number().required(),
  body: exteriorInteriorConcernSchema,
  comments: yup
    .string()
    .nullable()
    .when(['authResult'], {
      is: (authResult: EAuthenticationResultStatus) =>
        [EAuthenticationResultStatus.Fake, EAuthenticationResultStatus.Inconclusive].includes(authResult),
      then: yup.string().required()
    }),
  hardwareClosures: exteriorInteriorConcernSchema,
  hardwareEngravings: exteriorInteriorConcernSchema,
  hasMatrixConcern: yup
    .boolean()
    .nullable()
    .when(['authResult'], {
      is: (authResult: EAuthenticationResultStatus) =>
        [EAuthenticationResultStatus.Fake, EAuthenticationResultStatus.Inconclusive].includes(authResult),
      then: yup.boolean().required().oneOf([true])
    }),
  interiorHardwareClosures: exteriorInteriorConcernSchema,
  interiorHardwareEngravings: exteriorInteriorConcernSchema,
  interiorLogo: exteriorInteriorConcernSchema,
  lining: exteriorInteriorConcernSchema,
  logo: exteriorInteriorConcernSchema,
  madeInTag: exteriorInteriorConcernSchema,
  serialNumberTag: exteriorInteriorConcernSchema,
  supplierReturnReason: yup.boolean().nullable(),
  uploadedPhotos: yup
    .array()
    .nullable()
    .when(['authResult'], {
      is: (authResult: EAuthenticationResultStatus) =>
        [EAuthenticationResultStatus.Fake, EAuthenticationResultStatus.Inconclusive].includes(authResult),
      then: yup
        .array()
        .of(
          yup.object().shape({
            uploadDate: yup.date(),
            url: yup.string()
          })
        )
        .min(1)
    })
});

const getDefaultMatrixConcerns = (concernLocations: GetAuthenticationStationQueryResponse['concernLocations']) =>
  concernLocations?.reduce(
    (result, location) => ({
      ...result,
      [camelize(EMatrixLocation[location.id as number])]: location.concernIds?.reduce(
        (res, concernId) => ({ ...res, [camelize(EMatrixConcern[concernId])]: true }),
        defaultConcern
      )
    }),
    defaultLocation
  ) || defaultLocation;

export const getDefaultItemAuthenticationValues = (
  values: GetAuthenticationStationQueryResponse
): IItemAuthenticationSchema => ({
  authResult: values.statusId as EAuthenticationResultStatus,
  comments: values.comments,
  partnerResults: values.partnerResults,
  supplierReturnReason: values.wasAcceptedBySupplier === null ? undefined : values.wasAcceptedBySupplier ? 1 : 0,
  uploadedPhotos:
    values.photos?.map(({ id, uploadedAtUtc, url }) => ({
      fileId: id as string,
      uploadDate: uploadedAtUtc,
      url: url as string
    })) || [],
  ...getDefaultMatrixConcerns(values.concernLocations)
});

const formatMatrixConcerns = (
  concernLocations: Record<TExteriorMatrixLocationKeys | TInteriorMatrixLocationKeys, typeof defaultConcern>
) =>
  Object.entries(concernLocations)
    .map(([key, value]) => ({
      concernIds: Object.entries(value)
        .filter(([, concern]) => concern)
        .reduce(
          (result, [concern]) => [...result, EMatrixConcern[pascalize(concern) as keyof typeof EMatrixConcern]],
          [] as number[]
        ),
      id: EMatrixLocation[pascalize(key) as keyof typeof EMatrixLocation]
    }))
    .filter(({ concernIds }) => concernIds.length);

export const formatItemAuthenticationValues = (
  values: IItemAuthenticationSchema,
  initialStationDateTimeUtc: Date,
  isSaveAndGoNextStation: boolean
): UpdateAuthenticationRequest => ({
  comments: values.comments,
  concernLocations: formatMatrixConcerns({
    body: values.body,
    hardwareClosures: values.hardwareClosures,
    hardwareEngravings: values.hardwareEngravings,
    interiorHardwareClosures: values.interiorHardwareClosures,
    interiorHardwareEngravings: values.interiorHardwareEngravings,
    interiorLogo: values.interiorLogo,
    lining: values.lining,
    logo: values.logo,
    madeInTag: values.madeInTag,
    serialNumberTag: values.serialNumberTag
  }),
  finalStationDateTimeUtc: new Date(),
  initialStationDateTimeUtc,
  isSaveAndGoNextStation,
  photos: values.uploadedPhotos.map(({ fileId, url }) => ({ id: fileId?.includes('new_') ? undefined : fileId, url })),
  statusId: values.authResult,
  wasAcceptedBySupplier: values.supplierReturnReason === undefined ? undefined : values.supplierReturnReason === 1
});
