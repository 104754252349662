import React, { useMemo } from 'react';

import { EAuthenticationResultStatus } from '@inbound/api';
import { LayoutJay } from '@lux-ds/content-layouts';
import Divider from '@lux-ds/divider';
import Typography from '@lux-ds/typography';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { IItemAuthenticationSchema } from 'pages/WarehouseList/schemas/authenticationSchema';

import LoadingContainer from '../common/LoadingContainer';
import AuthenticationMatrix from './sections/AuthenticationMatrix/AuthenticationMatrix';
import AuthenticationPhotos from './sections/AuthenticationPhotos';
import AuthenticationResult from './sections/AuthenticationResult/AuthenticationResult';
import AuthenticationSupplierReturn from './sections/AuthenticationSupplierReturn';

const Authentication: React.FC = () => {
  const { t } = useTranslation('warehouse');
  const { isInconclusiveAuthFail, isStationLoading, warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IItemAuthenticationSchema>, [warehouseForm]);

  const isNotAuthentic = useMemo(
    () =>
      form.getValues('authResult')
        ? [EAuthenticationResultStatus.Fake, EAuthenticationResultStatus.Inconclusive].includes(
            form.getValues('authResult')
          )
        : false,
    [form, form.watch('authResult')]
  );

  const isAuthFail = useMemo(
    () => form.getValues('authResult') === EAuthenticationResultStatus.Fake || isInconclusiveAuthFail,
    [form, form.watch('authResult'), isInconclusiveAuthFail]
  );

  return (
    <LayoutJay gap="l" paddingBottom="5xl">
      {isStationLoading ? (
        <LoadingContainer />
      ) : (
        <>
          <Typography variant="h5">{t('authenticationStation.authentication')}</Typography>
          <AuthenticationResult />
          {isNotAuthentic && (
            <>
              <Divider sx={{ margin: theme => `${theme.space.m} 0` }} />
              <AuthenticationMatrix />
              <Divider sx={{ margin: theme => `${theme.space.m} 0` }} />
              <AuthenticationPhotos />
              {isAuthFail && <AuthenticationSupplierReturn />}
            </>
          )}
        </>
      )}
    </LayoutJay>
  );
};

export default Authentication;
