import React, { FC } from 'react';

import { IconButton, MaterialIcons, Modal, Paper } from '@luxclusif/material';
import { useTranslation } from 'react-i18next';

import decisionFormStyles from './decisionForm.styles';

interface IProps {
  onClose: () => void;
}

const HelperModal: FC<IProps> = ({ onClose }) => {
  const classes = decisionFormStyles();
  const { t } = useTranslation('warehouse');

  const inboundTypeHelpers = [
    {
      accept: t('onHoldStation.decisionHelper.returnTypeAccept'),
      flow: t('onHoldStation.decisionHelper.returnType'),
      reject: t('onHoldStation.decisionHelper.returnTypeReject')
    },
    {
      accept: t('onHoldStation.decisionHelper.vlgTypeAccept'),
      flow: t('onHoldStation.decisionHelper.vlgType'),
      reject: t('onHoldStation.decisionHelper.vlgTypeReject')
    },
    {
      accept: t('onHoldStation.decisionHelper.transferSoldTypeAccept'),
      flow: t('onHoldStation.decisionHelper.transferSoldType'),
      reject: t('onHoldStation.decisionHelper.transferSoldTypeReject')
    }
  ];

  return (
    <Modal onClose={onClose} open>
      <Paper className={classes.modalContent}>
        <h5 className={classes.decisionHelperTitle}>{t('onHoldStation.decisionHelper.inboundHelper')}</h5>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <MaterialIcons.Close />
        </IconButton>

        <p className={classes.decisionHelperQuestion}>{t('onHoldStation.decisionHelper.whatsNext')}</p>

        <div className={classes.inboundFlowList}>
          {inboundTypeHelpers.map(({ accept, flow, reject }) => (
            <div key={flow} className={classes.inboundFlowContainer}>
              <h5 className={classes.inboundFlowLabel}>{t('inboundFlow')}</h5>
              <h3 className={classes.inboundFlow}>{flow}</h3>
              <hr className={classes.divider} />
              <div className={classes.inboundInstructions}>
                <h4 className={classes.inboundAction}>{t('onHoldStation.reject')}</h4>
                <p className={classes.inboundActionDescription}>{reject}</p>

                <h4 className={classes.inboundAction}>{t('onHoldStation.accept')}</h4>
                <p className={classes.inboundActionDescription}>{accept}</p>
              </div>
            </div>
          ))}
        </div>
      </Paper>
    </Modal>
  );
};

export default HelperModal;
