import { createStyles, makeStyles, secondaryNeutral, Theme } from '@luxclusif/material';

const uploadFilesStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzoneContainer: {
      alignItems: 'center',
      background: secondaryNeutral.light85,
      border: `1px dashed ${secondaryNeutral.light75}`,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      padding: '24px'
    },
    dropzonePlaceholder: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    dropzoneIcon: {
      color: secondaryNeutral.light40,
      fontSize: '40px'
    },
    dropzoneLabel: {
      color: secondaryNeutral.light40,
      fontSize: '16px',
      lineHeight: '16px',
      margin: '0 16px 0 24px'
    },
    dropzoneButton: {
      borderColor: secondaryNeutral.light75,
      lineHeight: '16px',
      padding: '10px 19px'
    },
    dropzoneSubtitle: {
      color: secondaryNeutral.light40,
      fontSize: '12px',
      lineHeight: '12px',
      marginTop: '18px'
    },
    error: {
      marginTop: '4px'
    },
    fileInfo: {
      border: `1px solid ${secondaryNeutral.light75}`,
      display: 'flex',
      padding: '12px',
      marginTop: '16px'
    },
    fileInfoLeft: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      overflow: 'hidden'
    },
    fileInfoIcon: {
      color: secondaryNeutral.light40,
      fontSize: '24px'
    },
    fileInfoName: {
      lineHeight: '14px',
      marginLeft: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    fileInfoRight: {
      alignItems: 'center',
      display: 'flex',
      marginLeft: '32px'
    },
    fileInfoSize: {
      color: secondaryNeutral.light40,
      lineHeight: '14px'
    },
    fileInfoButton: {
      marginLeft: '40px',
      padding: '7px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.light,
        fontSize: '18px'
      }
    }
  })
);

export default uploadFilesStyles;
