import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Button,
  CircularProgress,
  Grid,
  MaterialIcons,
  Modal,
  Paper,
  TableContainer
} from '@luxclusif/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import FormTabs from 'components/FormTabs/FormTabs';
import { $hideLoading, $showLoading } from 'components/LoadingSpinner/LoadingSpinner';
import { ICountry } from 'models/supplier';
import EInboundRoute from 'navigation/models/EInboundRoute';
import SUPPLIER_CONSTANTS from 'pages/Suppliers/constants';
import { businessModelInitialize, businessModelSchema } from 'pages/Suppliers/schemas/businessModelSchema';
import { defaultSupplierValues, supplierEditInitialize, supplierSchema } from 'pages/Suppliers/schemas/detailsSchema';
import geoService from 'services/Geo.service';
import suppliersService from 'services/Suppliers.service';

import TabPanel from '../../components/TabPanel/TabPanel';
import BankDetails from './sections/BankDetails/BankDetails';
import ContactPerson from './sections/ContactPerson/ContactPerson';
import ExtraDetails from './sections/ExtraDetails/ExtraDetails';
import ProfileDetails from './sections/ProfileDetails/ProfileDetails';
import SupplierProfile from './sections/SupplierProfile/SupplierProfile';
import WarehouseAddress from './sections/WarehouseAddress/WarehouseAddress';

import supplierDetailsStyles from './supplierDetails.styles';

const SupplierDetails: React.FC = () => {
  const classes = supplierDetailsStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('supplier');
  const { supplierId = '' } = useParams<{ supplierId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const mode = supplierId ? 'update' : 'create';
  const id = supplierId ? supplierId : '';

  const [loadingInfo, setLoadingInfo] = useState(mode === 'update');

  const [statusList, setStatusList] = useState([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [exitModalOpened, setExitModalOpened] = useState(false);
  const [supplierProfileFormTouched, setSupplierProfileFormTouched] = useState(false);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [currentName, setCurrentName] = useState('');
  const [currentStatus, setCurrentStatus] = useState(null);

  const supplierForm = useForm({
    defaultValues: defaultSupplierValues(),
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(supplierSchema),
    shouldUnregister: false
  });

  const {
    formState: { isDirty, isValid },
    getValues,
    handleSubmit,
    reset,
    setValue
  } = supplierForm;

  const businessModelForms = useForm({
    defaultValues: businessModelInitialize(),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(businessModelSchema),
    shouldUnregister: false
  });

  const {
    formState: { isDirty: businessModelFormTouched, isValid: businessModelFormIsValid },
    reset: businessModelFormReset
  } = businessModelForms;

  const handleChangeTab = (newValue: number) => {
    let supplierProfileTouched = false;

    if (businessModelFormTouched) {
      supplierProfileTouched = true;
    }

    if (supplierProfileTouched && newValue === 0) {
      setSupplierProfileFormTouched(true);
      setTabValue(tabValue);
    } else {
      setTabValue(newValue);
    }
  };

  useEffect(() => {
    if (mode === 'create') {
      reset(defaultSupplierValues());
    }
  }, []);

  useEffect(() => {
    businessModelFormReset();
  }, [tabValue]);

  useEffect(() => {
    if (mode === 'update') {
      suppliersService
        .getByItemId(id)
        .then((data: any) => {
          supplierEditInitialize(data, setValue, reset);
          setCurrentName(data.name);
          setCurrentStatus(data.status);
        })
        .finally(() => {
          setLoadingInfo(false);
        });
    }
  }, []);

  useEffect(() => {
    setTabValue(0);
    getter('getStatus', suppliersService.getStatus, setStatusList);
  }, []);

  useEffect(() => {
    const fetchCountries = () => {
      const loadingId = 'getCountries';

      $showLoading.next(loadingId);
      geoService
        .getCountries()
        .then((data: ICountry[]) => setCountries(data))
        .catch(() => enqueueSnackbar(t('errorGetCountries'), { variant: 'error' }))
        .finally(() => $hideLoading.next(loadingId));
    };

    fetchCountries();
  }, []);

  const getter = (loading: string, service: any, setter: any) => {
    const loadingId = loading;

    $showLoading.next(loadingId);
    service()
      .then((data: any) => {
        setter(data);
      })
      .finally(() => {
        $hideLoading.next(loadingId);
      });
  };

  const onSaveAndExitOnModal = () => {
    setExitModalOpened(false);
    onSubmit();
  };

  const onSaveDraftAndExitOnModal = (event: any) => {
    setExitModalOpened(false);
    onSaveDraft(event);
  };

  const onSaveDraft = (event: any) => {
    event.preventDefault();
    const loadingId = 'saveSuppliers';

    $showLoading.next(loadingId);

    const supplierIds = id || '';

    suppliersService.saveDraft(getValues(), supplierIds).finally(() => {
      $hideLoading.next(loadingId);
      discardChangesAndCloseModalDetails();
    });
  };

  const onCloseModal = () => {
    setExitModalOpened(false);
  };

  const discardChangesAndCloseModalDetails = () => {
    if (exitModalOpened) {
      setExitModalOpened(false);
    }

    navigate(EInboundRoute.Suppliers);
  };

  const discardChangesAndCloseModalDetailsPrompt = () => {
    if (isDirty) {
      setExitModalOpened(true);
    } else {
      discardChangesAndCloseModalDetails();
    }
  };

  const onSubmit = () => {
    const loadingId = 'saveSuppliers';

    $showLoading.next(loadingId);

    if (mode === 'update' && currentStatus !== SUPPLIER_CONSTANTS.DRAFT_STATUS_NAME) {
      suppliersService.updateSupplier(getValues(), id).finally(() => {
        $hideLoading.next(loadingId);
        discardChangesAndCloseModalDetails();
      });
    } else {
      suppliersService.createSupplier(getValues()).finally(() => {
        $hideLoading.next(loadingId);
        discardChangesAndCloseModalDetails();
      });
    }
  };

  return (
    <Modal open onClose={discardChangesAndCloseModalDetailsPrompt}>
      <Paper className={classes.modalRoot}>
        <AppBar position="relative" className={classes.header}>
          <span className={classes.clickableTitle} onClick={discardChangesAndCloseModalDetailsPrompt}>
            Inbound
          </span>
          <MaterialIcons.ChevronRight color="primary"></MaterialIcons.ChevronRight>
          <span className={classes.clickableTitle} onClick={discardChangesAndCloseModalDetailsPrompt}>
            Suppliers
          </span>
          <MaterialIcons.ChevronRight color="primary"></MaterialIcons.ChevronRight>
          {mode === 'update' ? (
            <>
              {!loadingInfo && (
                <>
                  <span className={classes.nonClickableTitle}>{currentName}</span>
                  <span className={classes.statusNonClickable}>
                    <MaterialIcons.FiberManualRecord
                      className={
                        currentStatus === 'Inactive'
                          ? `${classes.red} ${classes.statusLogo}`
                          : `${classes.green} ${classes.statusLogo}`
                      }
                    />
                    {currentStatus}
                  </span>
                </>
              )}
            </>
          ) : (
            <span className={classes.nonClickableTitle}>Create New Supplier</span>
          )}

          <MaterialIcons.Close
            className={classes.closeIcon}
            color="primary"
            onClick={discardChangesAndCloseModalDetailsPrompt}
          ></MaterialIcons.Close>
        </AppBar>
        {loadingInfo ? (
          <div className={classes.loadingContainer}>
            <CircularProgress size={60} />
          </div>
        ) : (
          <FormProvider {...supplierForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.upperDivContainer}>
                <TableContainer component={Paper} className={classes.gray}>
                  <FormTabs
                    changeTab={handleChangeTab}
                    value={tabValue}
                    tabObjArray={SUPPLIER_CONSTANTS.SUPPLIER_TABS}
                  />

                  <TabPanel value={tabValue} index={0} padding={3}>
                    <ProfileDetails status={currentStatus} />
                    <BankDetails countries={countries} />
                    <ContactPerson />
                    <WarehouseAddress countries={countries} mode={mode} />
                    <ExtraDetails />
                  </TabPanel>

                  <TabPanel value={tabValue} index={1}>
                    <SupplierProfile
                      businessModelForm={businessModelForms}
                      exit={setTabValue}
                      formTouched={supplierProfileFormTouched}
                      isValid={businessModelFormIsValid}
                      mode={currentStatus === 'Draft' ? 'create' : mode}
                      setFormTouched={setSupplierProfileFormTouched}
                      status={currentStatus}
                      statusList={statusList}
                    />
                  </TabPanel>
                  <hr className={classes.submitLine} />
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    className={classes.submitPanel}
                  >
                    {/* <div>values: {JSON.stringify(getValues())}</div> */}
                    <Button onClick={() => discardChangesAndCloseModalDetails()} className={classes.button}>
                      Discard Changes
                    </Button>
                    {(!currentStatus || currentStatus === SUPPLIER_CONSTANTS.DRAFT_STATUS_NAME) && (
                      <Button
                        type="submit"
                        className={classes.button}
                        onClick={onSaveDraft}
                        variant="contained"
                        color="secondary"
                      >
                        Save Draft
                      </Button>
                    )}
                    <Button
                      type="submit"
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      disabled={!isValid}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </TableContainer>
              </div>

              <Modal open={exitModalOpened} onClose={onCloseModal}>
                <div className={classes.modalUpperContainer}>
                  <TableContainer className={classes.tableContainerExit} component={Paper}>
                    <div>
                      <h3>Unsaved changes</h3>
                      <p>You have unsaved changes. Are you sure you want to continue and lose this information?</p>
                    </div>
                    <Grid
                      className={classes.submitPanel}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button className={classes.button} onClick={() => discardChangesAndCloseModalDetails()}>
                        Discard Changes
                      </Button>

                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={!isValid}
                        onClick={onSaveAndExitOnModal}
                      >
                        Save and Exit
                      </Button>

                      {(!currentStatus || currentStatus === SUPPLIER_CONSTANTS.DRAFT_STATUS_NAME) && (
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={onSaveDraftAndExitOnModal}
                        >
                          Save Draft and Exit
                        </Button>
                      )}
                    </Grid>
                  </TableContainer>
                </div>
              </Modal>
            </form>
          </FormProvider>
        )}
      </Paper>
    </Modal>
  );
};

export default SupplierDetails;
