import React from 'react';

import { CircularProgress, LuxButton, MaterialIcons } from '@luxclusif/material';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import uploadImagesStyles from './uploadImages.styles';

interface IProps {
  disabled?: boolean;
  error?: boolean;
  isUploading: boolean;
  onUpload: DropzoneOptions['onDrop'];
}

const UploadImages: React.FC<IProps> = ({ disabled, error, isUploading, onUpload }) => {
  const { t } = useTranslation('common');

  const classes = uploadImagesStyles();

  const { getInputProps, getRootProps, open } = useDropzone({
    accept: '.jpg, .jpeg, .png',
    disabled: isUploading || disabled,
    multiple: true,
    noClick: true,
    onDrop: onUpload
  });

  return (
    <div
      {...getRootProps({
        className: `${classes.dropZoneContainer}${error ? ` ${classes.uploadImageError}` : ''}`
      })}
    >
      <input {...getInputProps()} />
      {isUploading ? (
        <CircularProgress />
      ) : (
        <>
          <MaterialIcons.CloudUpload className={classes.dropZoneIcon} />
          <span className={classes.dropZoneLabel}>{t('uploadImages.singleDropToUpload')}</span>
          <span className={classes.dropZoneLabel}>{t('or')}</span>
          <LuxButton
            className={classes.dropZoneButton}
            disabled={disabled || isUploading}
            onClick={open}
            variant="outlined"
          >
            {t('browseFiles')}
          </LuxButton>
        </>
      )}
    </div>
  );
};

export default UploadImages;
