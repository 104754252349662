export interface ISupplier {
  businessModelTypes: string[];
  country: string;
  currency: string;
  logo: string | null;
  name: string;
  shippingWarehouse: string | null;
  status: string;
  supplierId: string;
}

export enum ESupplierStatus {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
  Supplier = 'Supplier'
}

export type TSupplierStatus = Exclude<ESupplierStatus, ESupplierStatus.Supplier>;
