import { createStyles, makeStyles } from '@luxclusif/material';

import { EDamageSeverityLevel } from 'models/warehouse';

const damageSeverityTickerStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '8px',
      padding: '8px',
      width: '172px',
      height: '41px'
    },

    containerLevelColors: {
      '&[aria-value-now=null]': {
        border: '1px solid #DFDCE0',
        justifyContent: 'center'
      },
      [`&[aria-value-now=${EDamageSeverityLevel.Low}]`]: {
        backgroundColor: '#AEA6AF'
      },
      [`&[aria-value-now=${EDamageSeverityLevel.Medium}]`]: {
        backgroundColor: '#625963'
      },
      [`&[aria-value-now=${EDamageSeverityLevel.High}]`]: {
        backgroundColor: '#060506'
      }
    },
    containerDefaultLevelColor: {
      backgroundColor: '#ECEAEC'
    },

    buttonTicker: {
      padding: 0,
      '& .MuiSvgIcon-root': {
        fontSize: '16px'
      }
    },
    tickerColor: {
      [`&[aria-value-now=${EDamageSeverityLevel.Low}] .MuiSvgIcon-root,
        &[aria-value-now=${EDamageSeverityLevel.Medium}] .MuiSvgIcon-root,
        &[aria-value-now=${EDamageSeverityLevel.High}]  .MuiSvgIcon-root`]: {
        color: palette.common.white
      }
    },
    tickerDefaultColor: {
      [`& .MuiSvgIcon-root`]: {
        color: '#625963'
      }
    },

    buttonTickerDecreaseDefault: {
      opacity: 0
    },
    buttonTickerIncrease: {
      [`&[aria-value-now=${EDamageSeverityLevel.High}]  .MuiSvgIcon-root`]: {
        opacity: 0
      }
    },

    label: {
      textAlign: 'center'
    },
    labelDefaultColor: {
      color: '#625963'
    },
    mainLabel: {
      fontSize: '12px'
    },
    mainLabelColors: {
      color: '#131113',

      [`&[aria-value-now=${EDamageSeverityLevel.Low}],
      &[aria-value-now=${EDamageSeverityLevel.Medium}],
      &[aria-value-now=${EDamageSeverityLevel.High}]`]: {
        color: palette.common.white
      }
    },
    subLabel: {
      fontSize: '10px'
    },
    subLabelColors: {
      color: '#968B97',

      [`&[aria-value-now=${EDamageSeverityLevel.Low}],
     &[aria-value-now=${EDamageSeverityLevel.Medium}],
     &[aria-value-now=${EDamageSeverityLevel.High}]`]: {
        color: palette.common.white
      }
    }
  })
);

export default damageSeverityTickerStyles;
