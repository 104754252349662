import { LayoutHerodias } from '@lux-ds/content-layouts';
import Image from '@lux-ds/image';
import Typography from '@lux-ds/typography';
import Slider from 'react-slick';

import { css, styled } from 'utils/styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const StyledHerodias = styled(LayoutHerodias)(
  () => css`
    height: 460px;
    justify-content: space-between;
  `
);

export const StyledSliderCounter = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.primary.shade[40]};
    padding-bottom: ${theme.space.l};
    padding-top: ${theme.space.s};
    text-align: center;
  `
);

export const StyledThumbnailSlider = styled(Slider, { shouldForwardProp: prop => prop !== '$length' })<{
  $length: number;
}>(
  ({ $length, theme }) => css`
    display: flex !important;
    gap: ${theme.space.s};
    margin: 0 auto;
    width: 698px;

    .slick-arrow {
      color: unset !important;
      left: unset;
      position: relative;
      right: unset;
      top: unset;
      transform: unset;

      svg {
        width: 12px;
      }
    }

    .slick-disabled {
      cursor: default;

      svg {
        color: ${theme.palette.primary.shade[80]};
      }
    }

    ${$length < 8 &&
    css`
      .slick-track {
        display: flex;
        gap: ${theme.space.s};
        justify-content: center;

        .slick-slide {
          width: unset !important;
        }
      }
    `}
  `
);

export const StyledThumbnailImage = styled(Image, { shouldForwardProp: prop => prop !== '$isActive' })<{
  $isActive: boolean;
}>(
  ({ $isActive, theme }) => css`
    ${$isActive &&
    css`
      border-color: ${theme.palette.primary.main};
    `}
  `
);
