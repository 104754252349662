import React, { useCallback, useMemo } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableRow } from '@luxclusif/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DamageSeverityTicker from 'components/Input/DamageSeverityTicker/DamageSeverityTicker';
import { EDamageSeverityLevel, GenericDamageKeys, GenericDamageSmellKeys } from 'models/warehouse';
import useWarehouseItemContext from 'pages/WarehouseItem/hooks/useWarehouseItemContext';
import useWarehouseItemDetailsContext from 'pages/WarehouseItem/hooks/useWarehouseItemDetailsContext';
import { genericDamageKeys } from 'pages/WarehouseList/constants';
import {
  defaultGenericDamages,
  IDamage,
  IQualityCheckFormInputs,
  TGenericDamages
} from 'pages/WarehouseList/schemas/qualityCheckSchema';
import { getAllDamagesCount } from 'pages/WarehouseList/utils/getCountHelper';

import qualityCheckTableStyles from '../../qualityCheckTable.styles';

const Generic: React.FC = () => {
  const classes = qualityCheckTableStyles();
  const { t } = useTranslation('warehouse');
  const { isViewMode } = useWarehouseItemContext();
  const { isRegularFlow, warehouseForm } = useWarehouseItemDetailsContext();

  const form = useMemo(() => warehouseForm as UseFormMethods<IQualityCheckFormInputs>, [warehouseForm]);

  const onTickerChange = useCallback(
    (currentValue: IDamage, newValueSeverity: EDamageSeverityLevel | null, onChange: (newDamage: IDamage) => void) => {
      onChange({
        ...currentValue,
        isAdditional: currentValue.isAdditional && currentValue.previousSeverityId === newValueSeverity ? false : true,
        isChecked: !!newValueSeverity,
        severityId: newValueSeverity
      });

      form.setValue('hasPassedQualityCheck', null);
    },
    [form]
  );

  const getCells = useCallback(
    (key: GenericDamageKeys): TGenericDamages => form.watch(key, defaultGenericDamages),
    [form]
  );

  const count = useMemo(
    () => ({ smell: getAllDamagesCount(form.watch('smell', defaultGenericDamages), false, true) }),
    [form.watch('smell')]
  );

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {genericDamageKeys.map(key => (
            <TableRow key={key}>
              <TableCell scope="row" className={classes.cellBorder} width={174}>
                <div className={classes.damageColumn}>
                  <span className={classes.rowLabel}>{t(`qualityCheckStation.damageCategory.${key}`)}</span>
                  <br />
                  <span className={classes.damageCount}>
                    {t('qualityCheckStation.damagesCount', { count: count[key]?.isChecked || 0 })}
                  </span>
                </div>
              </TableCell>
              <TableCell scope="row" className={`${classes.cellBorder} ${classes.cellGeneric}`}>
                {(Object.keys(getCells(key)) as GenericDamageSmellKeys[]).map(damageKey => (
                  <Controller
                    key={damageKey}
                    control={form.control}
                    name={`${key}.${damageKey}`}
                    render={({ onChange, value }) => (
                      <DamageSeverityTicker
                        isAdditional={value.isAdditional}
                        isDisabled={isViewMode}
                        isRegularFlow={isRegularFlow}
                        label={t(`qualityCheckStation.damage.${damageKey}`)}
                        onChange={newValueSeverity => onTickerChange(value, newValueSeverity, onChange)}
                        value={value?.severityId || null}
                      />
                    )}
                  />
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Generic;
