import React, { ReactNode, useCallback } from 'react';

import Button from '@lux-ds/button';
import { ILayoutJayProps, LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Dialog, { IDialogProps } from '@lux-ds/dialog';
import IconButton from '@lux-ds/icon-button';
import Typography from '@lux-ds/typography';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { StyledLayoutJay } from './SimpleDialog.styles';

interface ISimpleDialogAction {
  cancelLabel?: string;
  disabled?: boolean;
  gap?: ILayoutJayProps['gap'];
  hideCancel?: boolean;
  label: string;
  onClick: () => void;
  shouldCloseDialog?: boolean;
}

export interface ISimpleDialogProps extends Omit<IDialogProps, 'open' | 'title'> {
  action?: ISimpleDialogAction;
  hideClose?: boolean;
  onClose: () => void;
  title: ReactNode;
}

const SimpleDialog: React.FC<ISimpleDialogProps> = ({ action, children, hideClose, onClose, title, ...props }) => {
  const { t } = useTranslation('common');

  const handleAction = useCallback(() => {
    action?.onClick();

    if (action?.shouldCloseDialog !== false) {
      onClose();
    }
  }, [action, onClose]);

  return (
    <Dialog {...props} open onClose={onClose}>
      <StyledLayoutJay gap={action?.gap || 'l'} padding="m" scroll="hidden">
        <LayoutJay gap="s" scroll="hidden">
          <LayoutHerodias sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h5" weight="semibold">
              {title}
            </Typography>
            {!hideClose && (
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            )}
          </LayoutHerodias>
          {typeof children === 'string' ? (
            <Typography sx={{ textWrap: 'wrap', whiteSpace: 'pre' }} variant="bodyTextNormal">
              {children}
            </Typography>
          ) : (
            children
          )}
        </LayoutJay>
        {action && (
          <LayoutHerodias align="right">
            {!action.hideCancel && <Button onClick={onClose}>{action.cancelLabel || t('cancel')}</Button>}
            <Button disabled={action.disabled} onClick={handleAction} variant="contained">
              {action.label || t('saveChanges')}
            </Button>
          </LayoutHerodias>
        )}
      </StyledLayoutJay>
    </Dialog>
  );
};

export default SimpleDialog;
